import React, {FC} from 'react';
import {View} from 'react-native';

import ArtistFeedCard from '../FeedCard/ArtistFeedCard';
import {styles} from '../FeedCard/common.styles';
import GenresFeedCard from '../FeedCard/GenresFeedCard';
import LocalMessageFeedCard from '../FeedCard/LocalMessageFeedCard';
import MessageFeedCard from '../FeedCard/MessageFeedCard';
import TrackFeedCard from '../FeedCard/TrackFeedCard';
import Icon from '@/components/Icon';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {IFeedEntityType, IFeedItem, ILocalMessageFeedItem} from '@/types/feed';
import {isWeb} from '@/utils/platform';

export type SetScrollToTop = (scrollToTop: () => void) => void;

export interface IFeedCarouselProps {
  feedItems: (IFeedItem | ILocalMessageFeedItem)[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  onRefresh: () => Promise<void>;
  toggleLike?: (feedItem: IFeedItem | ILocalMessageFeedItem) => void;
  toggleHide?: (feedItem: IFeedItem | ILocalMessageFeedItem) => void;
  onEndReached?: () => void;
  queueTitleId: string;
  setScrollToTop?: SetScrollToTop;
}

interface IRenderFeedItemParams {
  item: IFeedItem | ILocalMessageFeedItem;
  isActive: boolean;
  toggleHide?: (feedItem: IFeedItem | ILocalMessageFeedItem) => void;
  toggleLike?: (feedItem: IFeedItem | ILocalMessageFeedItem) => void;
  next: () => void;
  setCarouselEnabled: (enabled: boolean) => void;
}

export const renderFeedItem = ({
  item,
  isActive,
  toggleHide,
  toggleLike,
  next,
  setCarouselEnabled,
}: IRenderFeedItemParams) => {
  // On mobile we don't want to set keys to make use of FlashList recycling benefits.
  const key = isWeb ? item.id : undefined;
  // On the other hand LocalMessageFeedCard and FeedCard are not displayed that often and are cheap to render,
  // so we can set key for them for easier development.

  switch (item.entityType) {
    case IFeedEntityType.track:
      return (
        <TrackFeedCard
          key={key}
          feedItem={item}
          toggleLike={toggleLike}
          toggleHide={toggleHide}
          isActive={isActive}
          next={next}
          setCarouselEnabled={setCarouselEnabled}
        />
      );
    case IFeedEntityType.artist:
      return (
        <ArtistFeedCard
          key={key}
          feedItem={item}
          toggleLike={toggleLike}
          toggleHide={toggleHide}
          isActive={isActive}
          next={next}
          setCarouselEnabled={setCarouselEnabled}
        />
      );
    case IFeedEntityType.localMessage:
      return <LocalMessageFeedCard key={item.id} feedItem={item} />;
    case IFeedEntityType.refill:
      return (
        <GenresFeedCard
          key={item.id}
          item={item}
          onEnd={next}
          feature={<GenreRefillFeature />}
        />
      );
    case IFeedEntityType.message:
      return <MessageFeedCard key={key} feedItem={item} />;
  }
};

const GenreRefillFeature: FC = () => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.featureContainer}>
      <Space pt="xs" />
      <Icon
        name="personCelebrate"
        provider="custom"
        size={28}
        style={style.featureIcon}
      />
      <Space p="xs">
        <Text
          id={'feed.refill.title'}
          weight="semibold"
          style={style.centeredText}
          size="l"
        />
      </Space>
      <Text
        id={'feed.refill.description'}
        style={style.centeredText}
        size="xs"
        weight="regular"
      />
      <Space pt="xs" />
    </View>
  );
};
