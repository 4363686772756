import {gql} from 'graphql-request';

import {pipelineApi} from '@/services/pipelineApi';
import {spinampServicesApi} from '@/services/spinampServicesApi';
import {
  IApiResponseFeedItem,
  IPaginatedResponse,
  IRequestFeedGenerationResponse,
} from '@/types/api';
import {IFeedItem, IFeedItemRaw, IGeneratorConfig} from '@/types/feed';
import {ISigner} from '@/types/session';
import {analytics} from '@/utils/analytics';
import {
  ARTIST_WITH_5_TRACKS_FRAGMENT,
  parseFeedItem,
} from '@/utils/apiModelParsers';
import {isNotNil} from '@/utils/functions';

import {submitEntityFieldUpdate} from './entityFieldUpdates';

interface IPaginationOptions {
  first?: number;
  after?: string;
}

export const fetchFeed = async (
  userId: string,
  {first, after}: IPaginationOptions,
  conditions: Partial<IFeedItem> = {},
): Promise<IPaginatedResponse<IFeedItem>> => {
  // conditions.userId = '0xeF42cF85bE6aDf3081aDA73aF87e27996046fE63';
  // conditions.entityType = IFeedEntityType.artist;

  const response = await pipelineApi.request<{
    allFeedItems: IPaginatedResponse<IApiResponseFeedItem>;
  }>(
    gql`
      query Feed($first: Int, $after: Cursor, $condition: FeedItemCondition) {
        allFeedItems(
          condition: $condition
          first: $first
          after: $after
          orderBy: [
            POSITION_DESC
            CREATED_AT_TIME_DESC
            INSERTION_ID_DESC
            ID_DESC
          ]
        ) {
          items: nodes {
            id
            userId
            entityId
            entityType
            userAction
            createdAtTime
            updatedAtTime
            insertionId
            generator
            generatorMetadata
            position
            artistByEntityId {
              ...ArtistWithTracksDetails
            }
            processedTrackByEntityId {
              ...TrackDetails
            }
          }

          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
      ${ARTIST_WITH_5_TRACKS_FRAGMENT}
    `,
    {
      first,
      after,
      condition: {
        userId,
        ...conditions,
      },
    },
  );

  const items = response.allFeedItems.items
    .map(item => ({
      ...item,
      pageCursor: response.allFeedItems.pageInfo.endCursor,
    }))
    .map(parseFeedItem)
    .filter(isNotNil);

  return {
    items,
    pageInfo: response.allFeedItems.pageInfo,
  };
};

export const fetchFeedCount = async (userId: string) => {
  const response = await pipelineApi.request<{
    allFeedItems: {totalCount: number};
  }>(
    gql`
      query FeedCount($userId: String) {
        allFeedItems(condition: {userId: $userId}) {
          totalCount
        }
      }
    `,

    {
      userId,
    },
  );

  return response.allFeedItems.totalCount;
};

/**
 * Requests Spindexer to generate an up-to-date feed for this userId, optionally
 * specifying which generators to use.
 */
export const requestFeedGeneration = async (
  userId: string,
  generatorsConfig?: IGeneratorConfig[],
  insertAtPosition?: number,
  limit?: number,
) => {
  const response: IRequestFeedGenerationResponse =
    await spinampServicesApi.post(
      `/feed/feed-generation/${userId}`,
      generatorsConfig,
      {params: {insertAtPosition, pageSize: limit}},
    );

  analytics.feedGenerationRequested();

  const {generatedFeedItemsCount} = response;

  return generatedFeedItemsCount;
};

export const upsertFeedItem = (
  feedItem: Partial<IFeedItemRaw>,
  signer: ISigner,
) =>
  submitEntityFieldUpdate(
    'feedItems',
    {
      id: feedItem.id,
      userId: feedItem.userId,
      entityType: feedItem.entityType,
      entityId: feedItem.entityId,
      userAction: feedItem.userAction,
      updatedAtTime: feedItem.updatedAtTime,
    },
    signer.signMessage,
    'upsert',
  );
