export enum MimeEnum {
  mp3 = 'audio/mpeg',
  mp4 = 'video/mp4',
  wav = 'audio/wav',
  xWav = 'audio/x-wav',
  gif = 'image/gif',
  jpeg = 'image/jpeg',
  png = 'image/png',
  pdf = 'application/pdf',
}

export enum ImageSize {
  avatar = 75,
  thumbnailSmall = 32,
  thumbnail = 75,
  grid = 150,
  track = 250,
  artist = 250,
  user = 250,
  player = 500,
  feedCard = 500,
  controlCenter = 450,
  linkPreview = 750,
  fullScreenPreview = 1000,
  avatarPreview = 500,
  playlistArtwork = 120,
}

export interface IImageDimensions {
  width: number;
  height: number;
}
