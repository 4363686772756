import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {fetchTracksByIds} from '@/api/track';
import {fetchTrendingTracks} from '@/api/trending';
import {useDbQuery} from '@/queries/db';
import {QueryKeys} from '@/types/queryKeys';
import {getTracksList} from '@/utils/db';

export const useTrendingTracksQuery = () => {
  const {db, updateDb} = useDbQuery();
  const query = useQuery([QueryKeys.trendingTracks], async () => {
    const trendingTracks = await fetchTrendingTracks();
    const trendingTracksIds = trendingTracks.map(({trackId}) => trackId);
    const tracks = await fetchTracksByIds(trendingTracksIds);
    updateDb({tracks});
    return trendingTracksIds;
  });

  const trendingTracks = useMemo(
    () => getTracksList(db, query.data),
    [db.tracks, query.data],
  );

  return {
    trendingTracks,
    query,
  };
};
