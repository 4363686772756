import {useNavigation} from '@react-navigation/native';
import React, {useCallback, useMemo, useRef} from 'react';
import {View} from 'react-native';

import {IIconProps} from '@/components/Icon';
import HotkeyButton from '@/components/Key/HotkeyButton';
import {useHotkeys} from '@/hooks/useHotkeys';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {
  pause as pauseFeed,
  play as playFeed,
  selectShouldPlay as selectShouldFeedPlay,
} from '@/store/feed';
import {
  pause as pauseMainPlayer,
  resume as playMainPlayer,
  selectCurrentTrack,
  selectIsPlaying,
} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {IFeedEntityType} from '@/types/feed';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {identity, isNotNil, noop} from '@/utils/functions';

import {styles} from './FeedHotkeys.styles';
import {type IHotkeyableFeedItem, type IProps} from './shared';

type HotkeyHandler = () => void;

interface IHotkeyConfig {
  key: string;
  keyText: string;
  labelTextId: string;
  labelIcon?: IIconProps;
  onPress: HotkeyHandler;
}

type GroupedHotkeyMap = readonly (
  | readonly (IHotkeyConfig | null)[]
  | (IHotkeyConfig | null)
)[];

// A group allows us to have one or more hotkeys that will always appear next to each other.
// Hotkeys that aren't in a group can wrap between any hotkey.
const getHotkeyMap = ({
  feedItem,
  toggleHide,
  onPlay,
  toggleLike,
  onShowDetail,
  onCollect,
  onMoveLeft,
  onMoveRight,
}: {
  feedItem: IHotkeyableFeedItem;
  toggleHide: HotkeyHandler | null;
  onPlay: HotkeyHandler;
  toggleLike: HotkeyHandler | null;
  onShowDetail: HotkeyHandler;
  onCollect: HotkeyHandler | null;
  onMoveLeft: HotkeyHandler | null;
  onMoveRight: HotkeyHandler | null;
}): GroupedHotkeyMap => {
  const isLiked = 'userAction' in feedItem && feedItem.userAction === 'like';

  return [
    [
      onMoveLeft && {
        key: 'ArrowLeft',
        keyText: '<',
        labelTextId: 'feed.previous',
        onPress: onMoveLeft,
      },
      onMoveRight && {
        key: 'ArrowRight',
        keyText: '>',
        labelTextId: 'feed.next',
        onPress: onMoveRight,
      },
    ],
    toggleHide && {
      key: 'z',
      keyText: 'Z',
      labelTextId: 'feed.hide',
      labelIcon: {name: 'visibilityOff', provider: 'custom'},
      onPress: toggleHide,
    },
    {
      key: 'x',
      keyText: 'X',
      labelTextId: 'feed.detail',
      labelIcon: {
        name: 'heart',
        provider: 'custom',
      },
      onPress: onShowDetail,
    },
    toggleLike && {
      key: 'c',
      keyText: 'C',
      labelTextId:
        feedItem.entityType === IFeedEntityType.artist && isLiked
          ? 'unfollow'
          : feedItem.entityType === IFeedEntityType.artist
          ? 'follow'
          : 'feed.like',
      labelIcon:
        feedItem.entityType !== IFeedEntityType.artist
          ? {
              name: 'heart',
              provider: 'custom',
              size: 19,
            }
          : undefined,
      onPress: toggleLike,
    },
    onCollect && {
      key: 'c',
      keyText: 'C',
      labelTextId: 'feed.collect',
      labelIcon: {
        name: 'nftMint',
        provider: 'custom',
      },
      onPress: onCollect,
    },
    {
      key: ' ',
      keyText: 'SPACE',
      labelTextId: 'play',
      labelIcon: {
        name: 'play',
        provider: 'custom',
      },
      onPress: onPlay,
    },
  ] as const;
};

const FeedHotkeys: React.FC<IProps> = ({
  disabled,
  activeFeedItem,
  toggleHide,
  toggleLike,
  onCollect,
  onMoveLeft,
  onMoveRight,
}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  const togglePlay = useTripleTogglePlay();

  const onShowDetail = useCallback(
    (feedItem: IHotkeyableFeedItem) => {
      if (feedItem.entityType === 'artist') {
        navigation.navigate(Routes.ArtistModal, {
          slugOrId: feedItem.artist?.slug,
        });
      } else if (feedItem.entityType === 'track') {
        navigation.navigate(Routes.TrackModal, {slug: feedItem.track.slug});
      }
    },
    [navigation],
  );

  const {groupedHotkeyMap, flattenedHotkeyMap} = useMemo(() => {
    const shouldShowCollect =
      onCollect &&
      activeFeedItem &&
      'userAction' in activeFeedItem &&
      activeFeedItem.userAction === 'like';
    const shouldShowLike = !shouldShowCollect;

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const groupedHotkeyMap = getHotkeyMap({
      feedItem: activeFeedItem,
      onPlay: togglePlay,
      toggleHide: toggleHide ? () => toggleHide(activeFeedItem) : null,
      toggleLike:
        shouldShowLike && toggleLike ? () => toggleLike(activeFeedItem) : null,
      onShowDetail: () => onShowDetail(activeFeedItem),
      onCollect: shouldShowCollect ? () => onCollect(activeFeedItem) : null,
      onMoveLeft: onMoveLeft ?? noop,
      onMoveRight: onMoveRight ?? noop,
    }).filter(isNotNil);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const flattenedHotkeyMap = groupedHotkeyMap
      .flatMap(identity)
      .filter(isNotNil);

    return {
      groupedHotkeyMap,
      flattenedHotkeyMap,
    };
  }, [
    activeFeedItem,
    togglePlay,
    toggleHide,
    toggleLike,
    onCollect,
    onMoveLeft,
    onMoveRight,
  ]);

  useHotkeys(!disabled, flattenedHotkeyMap, true);

  return (
    <View style={style.container}>
      {groupedHotkeyMap.map((hotkeyOrGroup, index) => {
        if ('length' in hotkeyOrGroup) {
          return (
            <View style={style.group} key={index}>
              {hotkeyOrGroup.filter(isNotNil).map((props, index2) => (
                <HotkeyButton {...props} key={index2} />
              ))}
            </View>
          );
        }

        return <HotkeyButton {...hotkeyOrGroup} />;
      })}
    </View>
  );
};

/**
 * Controls a triple-toggle for managing play/pause state on main player and feed player.
 *
 * With the triple toggle, the third time you press space it always pauses.
 *
 * If feed is not playing: (the first time plays feed, the second plays main player, the third pauses both)
 * If feed is playing: (the first time plays main player, the second plays feed, the third pauses both)
 *
 * Triple toggle behaviour is only enabled when the main player (in addition to the feed) has a track.
 */
const useTripleTogglePlay = () => {
  const isMainPlayerPlaying = useAppSelector(selectIsPlaying);
  const dispatch = useAppDispatch();
  const isFeedPlaying = useAppSelector(selectShouldFeedPlay);

  const doesMainPlayerHaveTrack = !!useAppSelector(selectCurrentTrack);

  const tripleToggleCount = useRef(0);

  const tripleTogglePlay = useCallback(() => {
    if (tripleToggleCount.current === 2) {
      dispatch(pauseMainPlayer());
      dispatch(pauseFeed());

      tripleToggleCount.current = 0;

      return;
    }

    if (!isFeedPlaying && !isMainPlayerPlaying) {
      dispatch(playFeed());
    } else if (isMainPlayerPlaying) {
      dispatch(pauseMainPlayer());
      dispatch(playFeed());
    } else if (isFeedPlaying) {
      dispatch(playMainPlayer());
      dispatch(pauseFeed());
    }
    tripleToggleCount.current++;
  }, [isMainPlayerPlaying, isFeedPlaying]);

  const normalTogglePlay = useCallback(() => {
    if (!isFeedPlaying) {
      dispatch(playFeed());
    } else {
      dispatch(pauseFeed());
    }
  }, [isMainPlayerPlaying, isFeedPlaying]);

  const togglePlay = doesMainPlayerHaveTrack
    ? tripleTogglePlay
    : normalTogglePlay;

  return togglePlay;
};

export default FeedHotkeys;
