import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ITransfer} from '@/modules/Transfer';

interface ITransfersState {
  [id: string]: ITransfer;
}

export const initialState: ITransfersState = {};

export const transfersSlice = createSlice({
  name: 'transfers',
  initialState,
  reducers: {
    createTransfer: (state, action: PayloadAction<ITransfer>) => {
      state[action.payload.id] = action.payload;
    },
    removeTransfer: (state, action: PayloadAction<{id: string}>) => {
      delete state[action.payload.id];
    },
    updateTransfer: (
      state,
      action: PayloadAction<{id: string; update: Partial<ITransfer>}>,
    ) => {
      const {id, update} = action.payload;

      if (state[id]) {
        state[id] = {
          ...state[id],
          ...update,
        };
      }
    },
    setTransferDeliveryAddress: (
      state,
      action: PayloadAction<{id: string; address: string}>,
    ) => {
      const {id, address} = action.payload;

      if (state[id]) {
        state[id] = {
          ...state[id],
          transferParams: {
            ...state[id].transferParams,
            to: address,
          },
        };
      }
    },
    resetTransferDetails: (state, action: PayloadAction<{id: string}>) => {
      const {id} = action.payload;

      if (state[id]) {
        state[id] = {
          ...state[id],
          txHash: undefined,
          userOpHash: undefined,
          transactionError: undefined,
        };
      }
    },
  },
});

export const {
  createTransfer,
  removeTransfer,
  updateTransfer,
  setTransferDeliveryAddress,
  resetTransferDetails,
} = transfersSlice.actions;

export default transfersSlice.reducer;
