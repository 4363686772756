export type TransferErrorCode =
  | 'WRONG_PASSKEY_SIGNER'
  | 'WALLET_NOT_CONNECTED'
  | 'WRONG_WALLET_CONNECTED'
  | 'USER_CANCELLED'
  | 'TRANSACTION_REVERTED';

export class TransferError extends Error {
  status: TransferErrorCode;

  constructor(status: TransferErrorCode) {
    super(status);
    this.status = status;
  }
}
