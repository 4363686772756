import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const ARTIST_AVATAR_SIZE = 64;

export const styles = createThemedStyles(theme => ({
  tracksContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  artwork: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 0,
  },
  mask: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.textColor,
    opacity: 0.6,
  },
  artworkMask: {
    zIndex: 1,
  },
  bufferingIndicator: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  trackList: {
    zIndex: 3,
    flexGrow: 1,
  },
  trackListContent: {
    flexGrow: 1,
  },
  timeToRelease: {
    zIndex: 4,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  empty: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing.s,
  },
  actionsContainer: {
    paddingVertical: spacing.m,
    gap: spacing.xs * 1.5,
  },
  actionsRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  action: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  artistAvatarContainer: {
    height: ARTIST_AVATAR_SIZE,
    width: ARTIST_AVATAR_SIZE,
    borderRadius: ARTIST_AVATAR_SIZE / 2,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  artistAvatar: {
    ...StyleSheet.absoluteFillObject,
  },
  artistLinkWrapper: {
    alignItems: 'center',
    paddingHorizontal: spacing.s,
  },
  artistLink: {
    maxWidth: '100%',
    display: 'flex',
  },
}));
