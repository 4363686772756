import {useIsFetching, useIsMutating} from '@tanstack/react-query';

import {useDebouncedValue} from '@/hooks/useDebouncedValue';
import {MutationKeys} from '@/types/mutationKeys';
import {QueryKeys} from '@/types/queryKeys';

export const useIsFeedLoading = (userId?: string, debounceTime = 500) => {
  const isGeneratingFeed = useIsMutating({
    mutationKey: [MutationKeys.feedGeneration],
  });
  const isFetchingFeed = useIsFetching({
    queryKey: [QueryKeys.feed, userId],
  });

  const [isLoading] = useDebouncedValue(
    !!(isGeneratingFeed || isFetchingFeed),
    debounceTime,
  );
  return isLoading;
};
