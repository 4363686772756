import React, {FC} from 'react';
import {View} from 'react-native';

import Carousel from '@/components/Carousel';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import {IMintOfferInfo} from '@/modules/Collect/types';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {getTrackArtistsNames} from '@/utils/tracks';

import {styles, IMAGE_SIZE} from './EditionPicker.style';

interface IProps {
  track: ITrack;
  onSelectOffer: (offerId: string) => void;
  selectedOffer: IMintOfferInfo;
  mintOffers: IMintOfferInfo[];
  disabled?: boolean;
}

const EditionPicker: FC<IProps> = ({
  track,
  onSelectOffer,
  selectedOffer,
  mintOffers,
  disabled = false,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      <View>
        <Carousel
          items={mintOffers}
          onPress={_offer => onSelectOffer(_offer.id)}
          renderItem={offer => (
            <>
              <StaticImage
                style={style.editionItemImage}
                source={{
                  uri: track.lossyArtworkUrl,
                }}
                resizeWidth={ImageSize.track}
              />
              {offer.metadata?.sound_tier && (
                <View style={style.editionName}>
                  <Text size="xs">{offer.metadata?.sound_tier}</Text>
                </View>
              )}
            </>
          )}
          keyExtractor={offer => offer.id}
          onSelectItem={offer => onSelectOffer(offer.id)}
          activeKey={selectedOffer.id}
          itemWidth={IMAGE_SIZE}
          disabled={disabled}
        />
      </View>

      <View style={style.trackInfo}>
        <Text weight="semibold" align="center">
          {track.title}
        </Text>
        <Text size="xs" align="center">
          {getTrackArtistsNames(track)}
        </Text>
      </View>
    </View>
  );
};

export default EditionPicker;
