import React, {FC, useCallback, useState} from 'react';
import {View} from 'react-native';

import InfinityList from '@/components/InfinityList';
import LineSeparator, {LINE_SEPARATOR_HEIGHT} from '@/components/LineSeparator';
import {ARTIST_NFT_EVENT_HEIGHT, ArtistNftEvent} from '@/components/NftEvent';
import NftEventsEmpty from '@/components/NftEventsEmpty';
import NftEventsFilters, {
  useNftEventsFilters,
} from '@/components/NftEventsFilters';
import OverScrollMask from '@/components/OverScrollMask';
import PlaylistCard, {PLAYLIST_CARD_HEIGHT} from '@/components/PlaylistCard';
import Space from '@/components/Space/Space';
import Tabs, {ITab} from '@/components/Tabs';
import Text from '@/components/Text';
import {TRACK_CARD_HEIGHT, TrackCardBase} from '@/components/TrackCard';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch} from '@/hooks/useRedux';
import {useArtistTracksQuery} from '@/queries/artists';
import {useArtistNftActivityQuery} from '@/queries/nftActivity';
import {useCollectorPlaylistsQuery} from '@/queries/publicPlaylists';
import {playNewQueue} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {IArtist, ITrack, PlayContextType} from '@/types/common';

import {styles} from './Artist.style';

const tabsConfig: ITab[] = [
  {
    id: 'tracks',
    textId: 'artist.tracks',
  },
  {
    id: 'playlists',
    textId: 'artist.playlists',
  },
  {
    id: 'activity',
    textId: 'artist.activity',
    icon: {
      name: 'trending-up',
      provider: 'material',
    },
  },
];

export interface ITabControl {
  tab: string;
  changeTab: (tab: string) => void;
}

const ArtistScreenContents: FC<{
  artist: IArtist;
  artistInfo: React.ReactNode;
  tabControl: ITabControl;
  onScroll?: (event: any) => void;
  refreshEnabled?: boolean;
}> = ({artist, tabControl, onScroll, artistInfo, refreshEnabled}) => {
  const dispatch = useAppDispatch();
  const style = useThemedStyles(styles);
  const {tab, changeTab} = tabControl;
  const filtersConfig = useNftEventsFilters();
  const {tracks, query: tracksQuery} = useArtistTracksQuery(artist.id);
  const {playlists, query: playlistsQuery} = useCollectorPlaylistsQuery(
    artist.userId,
  );
  const {nftEvents, query: eventsQuery} = useArtistNftActivityQuery(
    artist.id,
    filtersConfig.activeFilter,
  );
  const showFilters =
    tab === 'activity' &&
    (nftEvents.length > 0 || !!filtersConfig.activeFilter);

  const {isTrackActive} = useIsTrackActive(PlayContextType.artist, artist.id);

  const play = useCallback(
    (track: ITrack) => {
      if (artist) {
        dispatch(
          playNewQueue({
            trackId: track.id,
            trackIds: tracks.map(artistTrack => artistTrack.id),
            context: {
              source: 'Artist',
              type: PlayContextType.artist,
              id: artist.id,
              slug: artist.slug,
              title: artist.name,
            },
          }),
        );
      }
    },
    [tracks, artist],
  );
  const ListHeaderComponent = (
    <View style={style.header}>
      <OverScrollMask />
      {artistInfo}
      <Tabs
        tabs={tabsConfig}
        activeTab={tab ?? 'tracks'}
        setActiveTab={(newTab: string) => {
          changeTab?.(newTab);
        }}
      />
      {showFilters && <NftEventsFilters {...filtersConfig} />}
    </View>
  );

  const commonListProps = {
    getItemType: () => tab,
    contentContainerStyle: style.scrollContent,
    onScroll,
    ListHeaderComponent,
    ...(refreshEnabled && {
      refresh: () =>
        Promise.all([
          tracksQuery.refetch(),
          playlistsQuery.refetch(),
          eventsQuery.refetch(),
        ]),
    }),
  };

  // We have to return exactly the same component at the root of each tab to prevent header from unmounting and keep scroll position
  if (tab === 'tracks') {
    return (
      <InfinityList
        data={tracks}
        itemSize={TRACK_CARD_HEIGHT}
        keyExtractor={track => `track_${track.id}`}
        extraData={isTrackActive}
        renderItem={({item: track}) => (
          <TrackCardBase
            track={track}
            onPlay={play}
            isActive={isTrackActive(track.id)}
          />
        )}
        fetchNextPage={tracksQuery.fetchNextPage}
        hasNextPage={tracksQuery.hasNextPage}
        isFetchingNextPage={
          tracksQuery.isFetchingNextPage ||
          (tracksQuery.isLoading && tracks.length === 0)
        }
        {...commonListProps}
      />
    );
  }

  if (tab === 'playlists') {
    return (
      <InfinityList
        data={playlists}
        itemSize={PLAYLIST_CARD_HEIGHT}
        keyExtractor={playlist => `playlist_${playlist.id}`}
        renderItem={({item: playlist}) => (
          <PlaylistCard playlist={playlist} showCreator={false} />
        )}
        isFetchingNextPage={playlistsQuery.isLoading}
        ListEmptyComponent={
          playlistsQuery.isLoading ? null : (
            <Space pv="xs">
              <Text id="artist.emptyPlaylists" size="xs" align="center" />
            </Space>
          )
        }
        {...commonListProps}
      />
    );
  }

  return (
    <InfinityList
      data={nftEvents}
      itemSize={ARTIST_NFT_EVENT_HEIGHT}
      itemSpacing={LINE_SEPARATOR_HEIGHT}
      keyExtractor={event => `event_${event.id}`}
      renderItem={({item: event}) => <ArtistNftEvent event={event} />}
      fetchNextPage={eventsQuery.fetchNextPage}
      hasNextPage={eventsQuery.hasNextPage}
      isFetchingNextPage={
        eventsQuery.isFetchingNextPage || eventsQuery.isLoading
      }
      ItemSeparatorComponent={LineSeparator}
      ListEmptyComponent={
        eventsQuery.isLoading ? null : (
          <Space pv="xs">
            <NftEventsEmpty
              messageId="artist"
              filter={filtersConfig.activeFilter}
              size="xs"
              align="center"
            />
          </Space>
        )
      }
      {...commonListProps}
    />
  );
};

export default ArtistScreenContents;
