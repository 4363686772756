import {
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {getFeedItemId} from 'api-utils';
import {useCallback, useEffect, useMemo, useRef} from 'react';

import {fetchFeed, fetchFeedCount, requestFeedGeneration} from '@/api/feed';
import {upsertFeedItem} from '@/api/feed';
import {useArtistFollow} from '@/hooks/useArtistFollow';
import {useFavToggleDynamic} from '@/hooks/useFavToggle';
import useOnFocus from '@/hooks/useOnFocus';
import useOnOnline from '@/hooks/useOnOnline';
import {useAppSelector} from '@/hooks/useRedux';
import {useDbQuery} from '@/queries/db';
import {isGenreChannel} from '@/screens/Feed/utils';
import {queryClient} from '@/services/reactQuery';
import {Sentry} from '@/services/sentry';
import {store} from '@/store';
import {selectActiveUserId, selectSignerByUserId} from '@/store/user';
import {IArtist, IArtistWithTracks, ITrack} from '@/types/common';
import {IFeedUserAction} from '@/types/feed';
import {
  IFeedEntityType,
  IFeedItem,
  IFeedItemRaw,
  IFeedItemWithArtist,
  IFeedItemWithTrack,
  IFilterableFeedItem,
  IGeneratorConfig,
} from '@/types/feed';
import {MutationKeys} from '@/types/mutationKeys';
import {QueryKeys} from '@/types/queryKeys';
import {getFilterableProperties, isArtist, isTrack} from '@/utils/feed';
import {
  flatChunkedArray,
  isNotNil,
  matchesConditions,
  merge,
  omit,
} from '@/utils/functions';
import {getNextPageParam} from '@/utils/pagination';

export interface IFeedQueryOptions {
  skipFeedQueryInvalidation: boolean;
  skipFeedSync?: boolean;
}

export const FEED_QUERY_DEFAULT_OPTIONS = {
  skipFeedQueryInvalidation: false,
  skipFeedSync: false,
};

function assertNever(x: never) {
  Sentry.captureMessage(
    'Error: assertNever was invoked. Unexpected: ' + JSON.stringify(x),
  );
}

const enrichFeedItem =
  (
    tracks: Record<string, ITrack>,
    artists: Record<string, IArtist>,
    trackIdsByArtist: Record<string, string[]>,
  ) =>
  (item: IFeedItem | IFeedItemRaw): IFeedItem | null => {
    switch (item.entityType) {
      case IFeedEntityType.track: {
        if ('track' in item) {
          // This item is already enriched
          return item;
        }

        const track = tracks[item.entityId];
        if (!track) {
          console.warn(`No track found for feed item id "${item.id}"`);
          Sentry.captureMessage(`No track found for feed item id "${item.id}"`);
          return null;
        }

        return {
          ...item,
          track,
          entityType: IFeedEntityType.track,
        };
      }

      case IFeedEntityType.artist: {
        if ('artist' in item) {
          // This item is already enriched (eg because it was inserted into the cache
          // from the useFeedItemMutation's onMutate)
          return item;
        }

        const selectedTracks = trackIdsByArtist[item.entityId]?.map(
          id => tracks[id],
        );

        if (selectedTracks == null) {
          Sentry.captureMessage(
            `Null or undefined entry in trackIdsByArtist for artist ${item.entityId}`,
          );
          console.warn(
            `Null or undefined entry in trackIdsByArtist for artist ${item.entityId}`,
          );
          return null;
        }

        if (!artists[item.entityId]) {
          Sentry.captureMessage(
            `No artist found for feed item id "${item.id}"`,
          );
          console.warn(`No artist found for feed item id "${item.id}"`);
          return null;
        }

        const artist = {
          ...artists[item.entityId],
          tracks: selectedTracks,
        };

        return {
          ...item,
          artist,
          entityType: IFeedEntityType.artist,
        };
      }

      case IFeedEntityType.message: {
        if (item.message) {
          return {
            ...item,
            entityType: IFeedEntityType.message,
            message: item.message,
          };
        } else {
          item satisfies IFeedItemRaw;
          return null;
        }
      }

      // don't allow localMessages returned from a query -- they must come from this device
      case IFeedEntityType.localMessage: {
        return null;
      }

      case IFeedEntityType.refill: {
        return {
          ...item,
          entityType: IFeedEntityType.refill,
        };
      }

      default: {
        assertNever(item);
        return null;
      }
    }
  };

type UseRawFeedQueryOptions = {
  refetchOnWindowFocus?: boolean;
  refetchOnReconnect?: boolean;
  refetchOnMount?: boolean;
  enabled?: boolean;
  staleTime?: number;
};

export const useRawFeedQuery = (
  userId?: string,
  conditions?: Partial<IFeedItem>,
  options?: UseRawFeedQueryOptions,
) => {
  const {updateDb} = useDbQuery();

  return useInfiniteQuery({
    queryKey: conditions
      ? [QueryKeys.feed, userId, {conditions}]
      : [QueryKeys.feed, userId],
    queryFn: async ({pageParam}) => {
      const pageSize = 15;

      const {items, pageInfo} = await fetchFeed(
        userId!,
        {
          first: pageSize,
          after: pageParam,
        },
        conditions,
      );
      const tracks: ITrack[] = items
        .filter(
          (item): item is IFeedItemWithTrack =>
            item.entityType === IFeedEntityType.track,
        )
        .map(item => item.track);

      const artists: IArtistWithTracks[] = items
        .filter(
          (item): item is IFeedItemWithArtist =>
            item.entityType === IFeedEntityType.artist,
        )
        .map(item => item.artist);

      const selectedTrackIdsByArtist = artists.reduce(
        (acc, artist) => ({
          ...acc,
          [artist.id]: artist.tracks.map(track => track.id),
        }),
        {} as Record<string, string[]>,
      );

      updateDb({
        tracks: tracks.concat(artists.flatMap(artist => artist.tracks)),
        artists,
      });

      const strippedItems: IFeedItemRaw[] = items.map(item => {
        if (isTrack(item)) {
          return omit(item, 'track');
        }
        if (isArtist(item)) {
          return omit(item, 'artist');
        }
        return item;
      });

      return {
        pageInfo,
        items: strippedItems,
        // We want to preserve the API's ability to choose the short selection of tracks we
        // should show for each artist. Returning this allows users of this hook to know
        // which tracks to use when enriching artist feed items.
        trackIdsByArtist: selectedTrackIdsByArtist,
      };
    },
    getNextPageParam,
    enabled: !!userId,
    ...options,
  });
};

export const useFeedCountQuery = (userId?: string) => {
  return useQuery(
    [QueryKeys.feedCount, userId],
    () => fetchFeedCount(userId!),
    {
      enabled: !!userId,
    },
  );
};

export const useFeedQuery = (
  userId?: string,
  conditions?: Partial<IFeedItem>,
  options?: UseRawFeedQueryOptions,
) => {
  const query = useRawFeedQuery(userId, conditions, options);
  const activeUserId = useAppSelector(selectActiveUserId);

  const {db} = useDbQuery();

  const allEnrichedFeedItems = useMemo(() => {
    const allFeedItems = flatChunkedArray(
      query.data?.pages.map(page => page.items) || [],
    );

    // if an artist appears multiple times, their list of selected tracks
    // will be overwritten by the later occurrence
    const allTracksIdsByArtist = merge(
      ...(query.data?.pages.map(page => page.trackIdsByArtist) ?? []),
    );

    const enrichedFeedItems = allFeedItems.map(
      enrichFeedItem(db.tracks, db.artists, allTracksIdsByArtist),
    );

    return enrichedFeedItems.filter(
      item =>
        isNotNil(item) &&
        (activeUserId === userId || item.entityType !== IFeedEntityType.refill),
    ) as IFeedItem[];
  }, [query.data?.pages]);

  return {
    feedItems: query.data?.pages ? allEnrichedFeedItems : null,
    query,
  };
};

interface IMutationOptions {
  onError?: (error: unknown) => void;
  // Use it when provided feed item is created from scratch, e.g. liking someone else's feed item or liking track from artist feed card
  skipOptimisticUpdate?: boolean;
}

interface IUseGenerateFeedMutationOptions extends IMutationOptions {
  onSuccess: (
    data: number,
    variables: IGeneratorConfig[] | undefined,
    context: unknown,
  ) => void;
  staleTime: number;
}

export function useFeedItemMutation(
  userId: string,
  options?: IMutationOptions,
) {
  const mutationKey = [QueryKeys.feed, userId];

  const {isTrackFaved, toggleFaveTrack} = useFavToggleDynamic({
    skipFeedQueryInvalidation: true,
    skipFeedSync: true,
  });
  const {
    getIsFollowed: isFollowed,
    follow,
    unfollow,
  } = useArtistFollow({
    skipFeedQueryInvalidation: true,
  });

  const mutation = useMutation({
    mutationKey,
    networkMode: 'always',
    // The useFeedQuery hook takes in a user ID and a conditions object, which allows you to filter
    // your feed items query, for example to only show liked feed items. It stores this data under the key
    // [QueryKeys.feed, userId, conditions].
    // For instant visual feedback of changes, we run a custom onMutate callback in this mutation to optimistically
    // update the react query cache entries related to feed items.
    // How it works is by looking up cached data for query keys that start with [QueryKeys.feed, userId].
    // and then checking if that data has any conditions on it (`const conditions = key.find(el => el.conditions)`).
    // It then looks at the feed item being mutated. If it matches the conditions (eg if the current mutation is to change a 'hidden' feed item to 'liked',
    // and we are currently looking at the liked feed items cache entry), then we insert it at the top of the first page of the cached data.
    // And we remove it from all other pages (to prevent duplicates).
    // If the feed item does not match the conditions, then we remove it from all pages in the cached data.
    // Finally, if this mutation's mutationFn (defined in setDefaultMutations to allow for offline support) fails, then we
    // revert the changes.
    onMutate: async (newFeedItem: IFeedItemRaw) => {
      if (newFeedItem.entityType === 'track') {
        const trackId = newFeedItem.entityId;

        const newlyLiked =
          newFeedItem.userAction === 'like' && !isTrackFaved(trackId);

        const newlyUnliked =
          newFeedItem.userAction !== 'like' && isTrackFaved(trackId);

        if (newlyLiked || newlyUnliked) {
          toggleFaveTrack(trackId);
        }
      } else if (newFeedItem.entityType === 'artist') {
        const artistId = newFeedItem.entityId;

        const newlyLiked =
          newFeedItem.userAction === 'like' && !isFollowed(artistId);

        const newlyUnliked =
          newFeedItem.userAction !== 'like' && isFollowed(artistId);

        if (newlyLiked) {
          follow(artistId);
        } else if (newlyUnliked) {
          unfollow(artistId);
        }
      }
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({queryKey: mutationKey});

      // Snapshot the previous query datas that we are about to overwrite, so that we can rollback to these on error
      const previousQueriesData = queryClient.getQueriesData({
        queryKey: mutationKey,
        exact: false,
      });
      mutateFeedItemInCache(
        newFeedItem,
        previousQueriesData,
        options?.skipOptimisticUpdate,
      );
      return {previousQueriesData};
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newFeedItem, context) => {
      context?.previousQueriesData.forEach(([key, data]) => {
        queryClient.setQueryData(key, data);
      });

      options?.onError?.(err);
    },
  });

  // Set `updatedAtTime`
  const mutate = useCallback(
    (feedItem: IFeedItemRaw) =>
      mutation.mutate({
        ...feedItem,
        updatedAtTime: new Date().toISOString(),
      }),
    [mutation.mutate],
  );

  return {
    mutate,
    mutation,
  };
}

export const mutateFeedItemInCache = (
  newFeedItem: IFeedItemRaw,
  previousQueriesData: [QueryKey, unknown][],
  skipOptimisticUpdate = false,
) => {
  previousQueriesData.forEach(([key]) => {
    queryClient.setQueryData(
      key,
      (old: ReturnType<typeof useRawFeedQuery>['data']) => {
        if (old == null) {
          return undefined;
        }

        const conditions: Partial<IFilterableFeedItem> | undefined = (
          key.find((el: any) => el?.conditions) as any
        )?.conditions;

        if (
          skipOptimisticUpdate &&
          (!conditions || conditions.userAction === null)
        ) {
          return old;
        }

        const filterableFeedItem = getFilterableProperties(newFeedItem);

        // The feed item should be in `items` for this cache key
        // if there are no filtering conditions or there are filtering conditions
        // and it matches them. Otherwise, it should not be in `items` for this cache key.
        const shouldBeIncluded =
          !conditions || matchesConditions(filterableFeedItem, conditions);

        const excludeNewFeedItem = (items: IFeedItemRaw[]) =>
          items.filter(t => t.id !== newFeedItem.id);

        const newPages = old.pages.map((page, i) => {
          // Add this item to the top of the first page if it should be included under this cache key
          // and remove it from all other pages. This ensures it isn't duplicated or shown when `shouldBeIncluded`
          // is false. The items are always re-sorted in useFeedItemsWithPinnedCards
          const items =
            i === 0 && shouldBeIncluded
              ? [newFeedItem, ...excludeNewFeedItem(page.items)]
              : excludeNewFeedItem(page.items);

          return {
            ...page,
            items,
          };
        });

        return {
          pages: newPages,
          pageParams: old.pageParams,
        };
      },
    );
  });
};

export const findFeedItemInQueriesData = (
  id: string,
  queriesData: [
    QueryKey,
    ReturnType<typeof useRawFeedQuery>['data'] | undefined,
  ][],
): IFeedItemRaw | void => {
  for (const [_key, query] of queriesData) {
    if (!query) {
      continue;
    }

    for (const feedItem of query.pages.flatMap(page => page.items)) {
      if (feedItem.id === id) {
        return feedItem;
      }
    }
  }
};

export function useGenerateFeedMutation(
  userId?: string,
  options?: IUseGenerateFeedMutationOptions,
  insertAtPosition?: number,
  limit?: number,
) {
  const mutation = useMutation({
    mutationKey: [MutationKeys.feedGeneration],
    mutationFn: (generatorsConfig?: IGeneratorConfig[]) =>
      requestFeedGeneration(userId!, generatorsConfig, insertAtPosition, limit),
    onSuccess: async (
      data: number,
      variables: IGeneratorConfig[] | undefined,
      context: unknown,
    ) => {
      await queryClient.invalidateQueries([
        QueryKeys.feed,
        userId,
        {conditions: {userAction: null}}, // don't invalidate hides or likes, which are unaffected by feed generation
      ]);

      options?.onSuccess(data, variables, context);
    },
    ...(options ? omit(options, 'onSuccess') : {}),
  });

  return {
    generateFeed: mutation.mutate,
    generateFeedAsync: mutation.mutateAsync,
    mutation: mutation,
  };
}

/**
 * A hook to manage a feed query that automatically regenerates the feed before refetching it.
 *
 * This is needed because getting a user's feed from spindexer is a 2-step process: regenerate
 * the feed with a POST request to populate it with any new feed items, then query it via GraphQL.
 *
 * This hook sets up automatic regeneration which mirrors React Query's; namely regenerating and then
 * triggering a refetch (via invalidation) whenever the query mounts, network reconnects, or window
 * refocuses. The generation step is skipped for specific queries on the feed that are unaffected by
 * feed generation: hides and likes.
 *
 * Expects `conditions` to be a stable reference. Either define it outside of your component, or use
 * something like https://github.com/janovekj/use-stable-reference.
 *
 * @param userId the ID of the user whose feed we'll be fetching
 * @param conditions a filter that specifies the `feedItem`s we want in this query
 * @param options options passed to the useQuery that fetches the feed
 */
export function useRegeneratingFeedQuery(
  userId?: string,
  conditions?: Partial<IFeedItem>,
  options?: UseRawFeedQueryOptions,
) {
  const needsGeneration =
    conditions?.userAction !== 'hide' &&
    conditions?.userAction !== 'like' &&
    userId &&
    !isGenreChannel(userId);

  const hasGenerated = useRef(false);

  const generateFeedMutation = useGenerateFeedMutation(userId);

  const {feedItems, query} = useFeedQuery(userId, conditions, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    enabled: !needsGeneration || hasGenerated.current,

    ...options,
  });

  const isQueryStale = query.isStale;

  const generateAndRefetch = useCallback(() => {
    async function run() {
      if (!isQueryStale) {
        return;
      }

      if (!needsGeneration) {
        // We can still refetch the feed, which could be useful if on another device
        // a user has modified their likes/hides
        queryClient.invalidateQueries([QueryKeys.feed, userId, {conditions}]);
        return;
      }

      await generateFeedMutation.generateFeedAsync(undefined);
      hasGenerated.current = true;
    }

    run();
  }, [userId, conditions, isQueryStale]);

  const regenerateFeed = useCallback(async () => {
    try {
      await generateFeedMutation.generateFeedAsync(undefined);
    } catch (e) {
      // errors are handled properly within generateFeedMutation
    }
  }, [userId]);

  useEffect(generateAndRefetch, []);
  useOnOnline(generateAndRefetch);
  useOnFocus(generateAndRefetch);

  return {feedItems, query, regenerateFeed};
}

export const toggleLikeFeedItem = async (
  userId: string | undefined,
  entityId: string,
  entityType: IFeedEntityType,
  isFav: boolean,
  options: IFeedQueryOptions = FEED_QUERY_DEFAULT_OPTIONS,
) => {
  if (options.skipFeedSync) {
    return;
  }

  const userAction: IFeedUserAction = isFav ? null : 'like';

  const queryKey = [QueryKeys.feed, userId];

  await queryClient.cancelQueries({queryKey});

  const previousQueriesData = queryClient.getQueriesData<
    ReturnType<typeof useRawFeedQuery>['data']
  >({
    queryKey,
    exact: false,
  });

  const id = getFeedItemId({
    userId,
    entityType,
    entityId,
  });
  const feedItem = findFeedItemInQueriesData(id, previousQueriesData);
  if (!feedItem) {
    return;
  }

  const newFeedItem = {...feedItem, userAction};
  mutateFeedItemInCache(newFeedItem, previousQueriesData);

  const signer = userId && selectSignerByUserId(store.getState(), userId);
  if (signer) {
    upsertFeedItem(
      {
        id,
        userId,
        entityType,
        entityId,
        userAction,
        updatedAtTime: new Date().toISOString(),
      },
      signer,
    ).then(() => {
      // The feed keeps track faves in sync with feed item likes, so when we fave a track
      // the feed must be refetched because it might have changed
      if (!options.skipFeedQueryInvalidation) {
        queryClient.invalidateQueries([QueryKeys.feed, userId]);
      }
    });
  }
};
