import {useNavigation} from '@react-navigation/native';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';

import {fetchArtistById, updateArtist} from '@/api/artist';
import {updateExternalLinksBulk} from '@/api/user';
import {useGlobalModal} from '@/components/GlobalModal/GlobalModal';
import {useCanEditArtist} from '@/hooks/useCanEdit';
import {useImageUploader} from '@/hooks/useImageUploader';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {Sentry} from '@/services/sentry';
import {selectActiveUserSigner} from '@/store/user';
import {EntityUpdateError} from '@/types/api';
import {IArtist, IArtistUpdate, IDatabase, IExternalLink} from '@/types/common';
import {ImageSize} from '@/types/media';
import {QueryKeys} from '@/types/queryKeys';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {compareExternalLinks} from '@/utils/externalLinks';
import {getResizedUri, preloadImages} from '@/utils/image';
import {getImageUrl} from '@/utils/ipfs';

const avatarSizeInfo = {
  minSize: 400,
  maxSize: 4000,
};

export const useArtistEditForm = (artist: IArtist) => {
  const {showToast} = useToast();
  const queryClient = useQueryClient();
  const navigation = useNavigation<MainStackNavigationParams>();
  const signer = useAppSelector(selectActiveUserSigner);
  const modal = useGlobalModal();
  const canEditArtist = useCanEditArtist(artist);

  useEffect(() => {
    // Kick the user out of the edit page if they don't have permission to edit
    if (!canEditArtist) {
      navigation.goBack();
    }
  }, [canEditArtist]);

  const form = useForm({
    mode: 'onTouched',
    defaultValues: {
      name: artist.name || '',
      description: artist.description || '',
      externalLinks: artist.externalLinks,
    },
  });
  const externalLinksForm = useFieldArray({
    control: form.control,
    name: 'externalLinks',
  });

  const avatarUploadProps = useImageUploader({
    maxSize: avatarSizeInfo.maxSize,
    minSize: avatarSizeInfo.minSize,
  });

  const mutation = useMutation<
    unknown,
    EntityUpdateError,
    IArtistUpdate & {externalLinks: IExternalLink[]}
  >({
    mutationFn: ({externalLinks, ...artistChanges}) => {
      if (!signer) {
        const error = new Error('missing signer');
        Sentry.captureException(error);
        throw error;
      }

      const linksToSave = compareExternalLinks(
        artist.externalLinks,
        externalLinks,
      );

      return Promise.all([
        updateArtist(artistChanges, signer),
        updateExternalLinksBulk(artist.userId, linksToSave, signer),
      ]);
    },
    onSuccess: async (response, variables) => {
      if (variables.avatarUrl) {
        preloadImages([getResizedUri(variables.avatarUrl, ImageSize.artist)]);
      }

      const updatedArtist = await fetchArtistById(artist.id);
      // set updated avatarUrl to local uri, so the preview is visible instant
      const avatarUrl =
        avatarUploadProps?.pickedImage?.uri || updatedArtist.avatarUrl;

      queryClient.setQueryData<IDatabase>([QueryKeys.db], db => {
        return (
          db && {
            ...db,
            artists: {
              ...db.artists,
              [artist.id]: {
                ...updatedArtist,
                avatarUrl,
              },
            },
          }
        );
      });

      queryClient.invalidateQueries([QueryKeys.userProfiles]);
      navigation.navigate(Routes.Artist, {slugOrId: artist.slug});
      showToast({textId: 'profileEdit.saved'});
    },
    onError: error => {
      if (error?.data?.error?.code === 'RESTRICTED_NAME_ERROR') {
        form.setError('name', {message: 'profileEdit.error.restrictedName'});
        return;
      }

      showToast({textId: 'profileEdit.errorSaving'});
    },
  });

  const onDiscard = () => {
    modal.openModal({
      titleId: 'profileEdit.discard.title',
      descriptionId: 'profileEdit.discard.description',
      confirmTextId: 'profileEdit.discard.confirm',
      cancelTextId: 'profileEdit.discard.cancel',
      onConfirm: navigation.goBack,
    });
  };

  const onSubmit = form.handleSubmit(formValues => {
    if (avatarUploadProps.isUploading) {
      return;
    }

    const {ipfsHash: avatarIPFSHash} = avatarUploadProps;
    mutation.mutate({
      id: artist.id,
      name: formValues.name,
      description: formValues.description,
      avatarUrl: getImageUrl(avatarIPFSHash),
      avatarIPFSHash,
      externalLinks: formValues.externalLinks,
    });
  });

  return {
    form,
    onDiscard,
    onSubmit,
    isSubmitting: mutation.isLoading,
    avatarUploadProps,
    avatarSizeInfo,
    externalLinksForm,
  };
};
