export const SPACES = {
  xxs: 4,
  xs: 8,
  s: 16,
  m: 20,
  l: 24,
  xl: 40,
  xxl: 44,
} as const;

export const HIT_SLOP = {
  top: SPACES.s,
  bottom: SPACES.s,
  left: SPACES.s,
  right: SPACES.s,
} as const;

export const getHitSlop = (size: keyof typeof SPACES) => ({
  top: SPACES[size],
  bottom: SPACES[size],
  left: SPACES[size],
  right: SPACES[size],
});

export const getVerticalHitSlop = (size: keyof typeof SPACES) => ({
  top: SPACES[size],
  bottom: SPACES[size],
});

export const DESKTOP_LAYOUT_SIZE = {
  contentContainerBase: 600,
  playerContainerBase: 400,
  playerContainerRatio: 0.58,
  maxModalWidth: 420,
  maxModalHeight: 1000,
} as const;

export default SPACES;
