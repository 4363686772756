import {useIsFocused} from '@react-navigation/native';
import {useEffect} from 'react';

import {UseHotkeys} from './types';

export const useHotkeys: UseHotkeys = (
  enabled,
  hotkeyMap,
  useCapture = false,
) => {
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused || !enabled) {
      return;
    }

    const listener = (event: KeyboardEvent) => {
      const hotkey = hotkeyMap.find(h => h.key === event.key);

      if (event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) {
        return;
      }

      hotkey?.onPress();

      // Never allow more than one handler per hotkey.
      event.stopImmediatePropagation();
    };

    window.addEventListener('keydown', listener, useCapture);

    return () => window.removeEventListener('keydown', listener, useCapture);
  }, [hotkeyMap, isFocused]);
};
