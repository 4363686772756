import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {useCallback, useMemo, useState} from 'react';

import AsyncContent from '@/components/AsyncContent';
import {IDragEndParams} from '@/components/DraggableList';
import DraggableList from '@/components/DraggableList/DraggableList';
import Header from '@/components/Header';
import IconButton from '@/components/IconButton';
import Loader from '@/components/Loader';
import PlaylistFormModal from '@/components/PlaylistFormModal';
import Screen from '@/components/Screen/Screen';
import Space from '@/components/Space';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useIsFollowedPlaylist} from '@/hooks/useIsFollowedPlaylist';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch} from '@/hooks/useRedux';
import {
  useToggleTrackInFollowedPlaylist,
  useUpdateFollowedPlaylistTracks,
} from '@/queries/followedPlaylists';
import {
  useToggleTrackInPlaylist,
  useUpdatePlaylist,
  useUpdatePlaylistTracks,
} from '@/queries/ownedPlaylists';
import {
  usePlaylistByIdQuery,
  usePlaylistCollaboratorsQuery,
  usePlaylistVotesQuery,
} from '@/queries/publicPlaylists';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {useTracksByIdsQuery} from '@/queries/tracks';
import {useFullUserByIdQuery} from '@/queries/user';
import PlaylistInfo from '@/screens/Playlist/components/PlaylistInfo';
import PlaylistTrackCard from '@/screens/Playlist/components/PlaylistTrackCard';
import SuggestedTrack from '@/screens/Playlist/components/SuggestedTrack';
import {playNewQueue} from '@/store/player';
import {ThemedScreen, useThemedScreenStyles} from '@/theme';
import {ITrack, PlayContextType} from '@/types/common';
import {
  MainStackParams,
  RootStackNavigationParams,
  Routes,
} from '@/types/routes';
import {
  getCountedVotes,
  getOrderedSuggestions,
  getPlaylistThemeConfig,
  isInCollaborators,
  shouldShowContributors,
} from '@/utils/playlists';

import {styles} from './Playlist.style';

const PlaylistScreen = () => {
  const activeUser = useActiveUser();
  const dispatch = useAppDispatch();
  const {scrollPosition, onScrollOffsetChange} = useAnimatedHeader();
  const navigation = useNavigation<RootStackNavigationParams>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const {
    params: {id: playlistId},
  } = useRoute<RouteProp<MainStackParams, Routes.Playlist>>();

  const {
    playlist,
    parentPlaylistId,
    playlistTracks,
    isOwnPlaylist,
    areTracksFetching,
    query: {isInitialLoading, isError},
  } = usePlaylistByIdQuery(playlistId);
  const {user} = useFullUserByIdQuery(playlist?.collector);
  const collaborators = usePlaylistCollaboratorsQuery(playlist);
  const {votes} = usePlaylistVotesQuery(playlistId);
  const {tracks: suggestedTracks} = useTracksByIdsQuery(
    Object.keys(playlist?.suggestions || {}),
  );

  const countedVotes = useMemo(() => getCountedVotes(votes), [votes]);
  const orderedSuggestions = useMemo(
    () => getOrderedSuggestions(suggestedTracks, countedVotes),
    [suggestedTracks, countedVotes],
  );

  const isCollaborator = useMemo(
    () => isInCollaborators(playlist?.collaborators, activeUser?.id),
    [playlist, activeUser?.id],
  );
  const {isFollowed, unfollowId} = useIsFollowedPlaylist(
    'followedPlaylistId',
    playlistId,
  );
  const canEditTracks =
    isOwnPlaylist ||
    (isCollaborator && isFollowed && playlist?.mode === 'free-for-all');
  const canSuggest = isCollaborator && isFollowed && playlist?.mode === 'solo';

  const showContributor = useMemo(
    () => shouldShowContributors(playlist),
    [playlist],
  );

  const {updatePlaylistMutation} = useUpdatePlaylist();
  const {updatePlaylistTracksMutation} = useUpdatePlaylistTracks();
  const {updateFollowedPlaylistTracksMutation} =
    useUpdateFollowedPlaylistTracks();
  const {toggleTrackInPlaylistMutation} = useToggleTrackInPlaylist();
  const {toggleTrackInFollowedPlaylistMutation} =
    useToggleTrackInFollowedPlaylist();

  const {playlistThemes, artistThemes} = useCustomThemesConfigQuery();
  const {isTrackActive} = useIsTrackActive(
    PlayContextType.playlist,
    playlistId,
  );

  const {style, theme} = useThemedScreenStyles(
    styles,
    getPlaylistThemeConfig({
      playlistId,
      creator: user,
      playlistThemes,
      artistThemes,
    }),
  );

  const toggleEditModal = () => setIsEditModalOpen(v => !v);

  const play = useCallback(
    (track?: ITrack) => {
      const trackIds = playlistTracks.map(t => t.id);

      dispatch(
        playNewQueue({
          trackId: track?.id,
          trackIds,
          context: {
            source: isOwnPlaylist ? 'OwnPlaylist' : 'Playlist',
            type: PlayContextType.playlist,
            id: playlistId,
            title: playlist?.title,
          },
        }),
      );
    },
    [isOwnPlaylist, playlistId, playlist?.title, playlistTracks],
  );

  const playSuggestion = useCallback(
    (track: ITrack) => {
      const trackIds = suggestedTracks.map(t => t.id);

      dispatch(
        playNewQueue({
          trackId: track?.id,
          trackIds,
          context: {
            source: 'PlaylistSuggestions',
            type: PlayContextType.playlistSuggestions,
            id: playlistId,
            title: `${playlist?.title} suggestions`,
          },
        }),
      );
    },
    [playlistId, playlist?.title, suggestedTracks],
  );

  const onTrackDelete = useCallback(
    (track: ITrack) => {
      if (parentPlaylistId) {
        toggleTrackInFollowedPlaylistMutation({
          parentPlaylistId,
          playlistId: playlistId,
          trackId: track.id,
        });
      } else {
        toggleTrackInPlaylistMutation({
          playlistId: playlistId,
          trackId: track.id,
        });
      }
    },
    [playlistId, parentPlaylistId],
  );

  const onDragEnd = (params: IDragEndParams<ITrack>) => {
    const trackIds = params.data.map(({id}) => id);

    if (parentPlaylistId) {
      updateFollowedPlaylistTracksMutation({
        parentPlaylistId,
        playlistId: playlistId,
        trackIds,
      });
    } else {
      updatePlaylistTracksMutation({
        playlistId,
        trackIds,
      });
    }
  };

  return (
    <ThemedScreen theme={theme}>
      <Screen>
        <Header
          showBack
          title={playlist?.title}
          scrollPosition={scrollPosition}
          rightActions={
            <>
              {isOwnPlaylist && (
                <IconButton
                  onPress={toggleEditModal}
                  icon={{provider: 'custom', name: 'edit'}}
                />
              )}
              {isOwnPlaylist && activeUser && (
                <IconButton
                  onPress={() =>
                    navigation.navigate(Routes.PlaylistCollaborators, {
                      id: playlistId,
                    })
                  }
                  icon={{provider: 'custom', name: 'curatorAdd'}}
                />
              )}
              {canEditTracks && (
                <IconButton
                  onPress={() =>
                    navigation.navigate(Routes.AddToPlaylist, {
                      id: playlistId,
                    })
                  }
                  icon={{provider: 'custom', name: 'playlistAdd'}}
                />
              )}
              {!canEditTracks && canSuggest && (
                <IconButton
                  onPress={() =>
                    navigation.navigate(Routes.SuggestToPlaylist, {
                      id: playlistId,
                    })
                  }
                  icon={{provider: 'custom', name: 'playlistAdd'}}
                />
              )}
            </>
          }
        />
        <AsyncContent
          isLoading={isInitialLoading}
          isError={isError || !playlist}
          data={playlist}
          renderContent={loadedPlaylist => (
            <DraggableList
              ListHeaderComponent={
                <>
                  <PlaylistInfo
                    playlist={loadedPlaylist}
                    collaborators={collaborators}
                    isOwnPlaylist={isOwnPlaylist}
                    isCollaborator={isCollaborator}
                    isFollowed={isFollowed}
                    unfollowId={unfollowId}
                    creator={user}
                    onPlay={play}
                  />
                  <Text id="tracks" weight="semibold" size="m" />
                  <Space h="xs" />
                </>
              }
              style={style.container}
              itemSpacing={spacing.xs}
              contentContainerStyle={[
                style.content,
                {gap: showContributor ? spacing.xs + spacing.xxs : spacing.xs},
              ]}
              data={playlistTracks}
              keyExtractor={track => track.id}
              onScrollOffsetChange={onScrollOffsetChange}
              renderItem={({item: track, isDragged, drag}) => (
                <PlaylistTrackCard
                  showContributor={showContributor}
                  playlist={playlist}
                  track={track}
                  isActive={isTrackActive(track.id)}
                  onPlay={play}
                  onDelete={canEditTracks ? onTrackDelete : undefined}
                  showArtist
                  isDraggable={canEditTracks}
                  isDragged={isDragged}
                  drag={drag}
                  referral={loadedPlaylist.collector}
                />
              )}
              ListEmptyComponent={
                areTracksFetching ? (
                  <Loader />
                ) : (
                  <Text id="playlist.empty" align="center" size="xs" />
                )
              }
              ListFooterComponent={
                orderedSuggestions.length > 0 ? (
                  <Space mt="l">
                    <Text weight="semibold" size="m">
                      Suggested
                    </Text>
                    <Space pv="xs" style={{gap: 1.5 * spacing.xs}}>
                      {orderedSuggestions.map(track => (
                        <SuggestedTrack
                          key={track.id}
                          isOwner={isOwnPlaylist}
                          playlist={loadedPlaylist}
                          track={track}
                          onPlay={playSuggestion}
                          votes={votes}
                          countedVotes={countedVotes}
                        />
                      ))}
                    </Space>
                  </Space>
                ) : null
              }
              onDragEnd={onDragEnd}
              draggingEnabled={canEditTracks}
              showsVerticalScrollIndicator={false}
            />
          )}
        />

        {isEditModalOpen && playlist && (
          <PlaylistFormModal
            isOpen
            modalTitleId="editPlaylist.title"
            submitTextId="save"
            onClose={() => setIsEditModalOpen(false)}
            initialData={{...playlist}}
            onSubmit={({title, description, mintable, chainId}) => {
              updatePlaylistMutation({
                id: playlistId,
                updatedPlaylist: {title, description, mintable, chainId},
              });
              setIsEditModalOpen(false);
            }}
          />
        )}
      </Screen>
    </ThemedScreen>
  );
};

export default PlaylistScreen;
