import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing.xxs * 1.5,
    gap: spacing.s,
  },
  infoColumn: {
    gap: spacing.xxs / 2,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxs / 2,
  },
  chainInfo: {
    alignSelf: 'flex-end',
  },
  tooltipContainer: {
    alignSelf: 'flex-start',
  },
  tooltipRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: spacing.xxs * 1.5,
    gap: spacing.s,
  },
  tooltipDivider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.textColor,
    opacity: 0.2,
  },
}));
