import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';

import Button from '@/components/Button';
import ModalScreenWrapper from '@/components/ModalScreenWrapper';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {
  RootStackNavigationParams,
  RootStackParams,
  Routes,
} from '@/types/routes';

const NotificationsPrompt = () => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const {params} =
    useRoute<RouteProp<RootStackParams, Routes.NotificationsPrompt>>();

  return (
    <ModalScreenWrapper titleId="notificationsPrompt.title">
      <View style={{padding: 16, gap: 8}}>
        <Text id={params.messageId} />

        <View style={{height: 16}} />

        <Button onPress={() => {}} textId="notificationsPrompt.enable" />

        <TransparentButton
          onPress={() => navigation.goBack()}
          text={{id: 'notificationsPrompt.skip'}}
        />
      </View>
    </ModalScreenWrapper>
  );
};

export default NotificationsPrompt;
