import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    container: {
      pointerEvents: 'box-none',
      alignSelf: 'center',
      position: 'absolute',
      top: safeAreaInsets.top + (responsive.isMobile ? spacing.xs : spacing.l),
      marginHorizontal: spacing.l,
      zIndex: isWeb ? 10000 : undefined,
      justifyContent: 'center',
      alignItems: 'center',
      gap: spacing.xs,
    },
  }),
);
