import {MintPreconditionError, MintDetails} from 'api-utils';

import {
  ICollectOfferError,
  ICollectTransaction,
  IMintOfferInfo,
  ITrackCollectInfo,
  OfferAvailability,
} from '@/modules/Collect/types';
import {IWalletsSettings} from '@/modules/Wallets/types';
import {ITrack} from '@/types/common';
import {IBaseUser, IUser} from '@/types/user';
import {areAddressesEqual} from '@/utils/ethereum';
import {generateId} from '@/utils/functions';
import {getUserDeliveryWallet, getUserPaymentWallet} from '@/utils/user';

export const createOfferId = (
  trackId: string,
  type: OfferAvailability,
  metadata: MintDetails['metadata'],
) => {
  // We assume that if there are multiple mint offers for a track, they always should have unique metadata.
  return `${trackId}-${type}-${JSON.stringify(metadata || 1)}`;
};

// Offers for single track should have unique metadata, resulting in unique ids.
// If for some reason metadata is the same, we need to de-duplicate them.
export const removeDuplicatedOffers = (offers: IMintOfferInfo[]) => {
  const includedOffers: {[id: string]: boolean} = {};

  return offers.filter(offer => {
    if (includedOffers[offer.id]) {
      return false;
    }

    includedOffers[offer.id] = true;
    return true;
  });
};

export const sortOffersFromCheapest = (a: IMintOfferInfo, b: IMintOfferInfo) =>
  BigInt(a.price.value) > BigInt(b.price.value) ? 1 : -1;

export const parseOfferError = (
  error?: MintPreconditionError,
): ICollectOfferError | undefined => {
  if (!error) {
    return undefined;
  }

  if (error?.metadata?.requiredFollow) {
    return {
      type: 'lens_follow',
      url: error.metadata.suggestedUrl,
    };
  }

  if (error?.reason === 'INSUFFICIENT_FUNDS') {
    return {
      type: 'insufficient_balance',
    };
  }

  return {
    type: 'unknown',
  };
};

export const getOfferIcon = (offer: ITrackCollectInfo | undefined) => {
  if (!offer) {
    return 'nftLoading';
  }

  if (['direct', 'external'].includes(offer.availability)) {
    return offer.isAlreadyOwned ? 'nftOwned' : 'nftMint';
  }

  return 'nftInfo';
};

export const getOptionalUserWalletById = (user?: IBaseUser, address?: string) =>
  user?.addresses.find(a => areAddressesEqual(a.address, address));

export const getUserDefaultWalletsSettings = (
  user?: IUser,
): IWalletsSettings | undefined => {
  const paymentAddress = user && getUserPaymentWallet(user)?.address;

  if (!paymentAddress) {
    return undefined;
  }

  return {
    paymentAddress,
    deliveryAddress: getUserDeliveryWallet(user)?.address || paymentAddress,
  };
};

export const initTransactionState = ({
  track,
  user,
  referralAddress,
}: {
  track: ITrack;
  user?: IUser;
  referralAddress?: string;
}): ICollectTransaction => {
  const id = generateId();

  return {
    createdDate: Date.now(),
    id,
    isMinimized: false,
    userId: user?.id,
    slug: track.slug,
    referralAddress,
    modalsStack: [],
    walletsSettings: getUserDefaultWalletsSettings(user),
    quantity: 1,
    collectInfo: undefined,
    selectedOfferId: undefined,
    txHash: undefined,
    approvalTxHash: undefined,
    userOpHash: undefined,
    approvalUserOpHash: undefined,
    transactionStep: 'checkout',
    transactionError: undefined,
    token: undefined,
  };
};
