import {ISyncedRecord, ITrack} from '@/types/common';

export enum PlaylistType {
  favorites = 'favorites',
  artist = 'artist',
  custom = 'custom',
  followedPlaylist = 'followedPlaylist',
  followedCollection = 'followedCollection',
  platform = 'platform',
}

export type PlaylistMode = 'solo' | 'free-for-all';

export interface IPlaylist extends ISyncedRecord {
  id: string;
  collector?: string;
  title?: string;
  titleId?: string;
  description?: string;
  isPermanent?: boolean;
  type: PlaylistType;
  trackIds?: string[];
  artistId?: string;
  followedPlaylistId?: string;
  followedPlaylist?: IPlaylist;
  followedCollectionAddress?: string;
  platformId?: string;
  artworkIPFSHash?: string;
  chainId?: string; // the chainId that we'll mint on when we mint this playlist
  mintable?: boolean;
  album?: boolean;

  // collaborative playlists settings
  collaborators?: {userId: string}[];
  contributions?: {[trackId: string]: {userId: string}};
  mode?: PlaylistMode;
  suggestions?: {[trackId: string]: {userId: string; timestamp?: number}};
}

export interface IPartialPlaylist
  extends Pick<IPlaylist, 'id' | 'collector' | 'title' | 'type'> {}

export interface IForkedPlaylist extends IPlaylist {
  parentPlaylistId: string;
}

export interface IPublishPlaylistRequestPayload extends Omit<IPlaylist, 'id'> {
  id?: string;
}

export interface IForkPlaylistRequestPayload
  extends IPublishPlaylistRequestPayload {
  parentPlaylistId: string;
}

export type IPlaylistFormData = Partial<
  Pick<
    IPlaylist,
    'title' | 'description' | 'collector' | 'mintable' | 'chainId'
  >
>;

export interface IPlaylistFormSubmitData extends IPlaylistFormData {
  title: string;
}

export interface IPlaylistWithTracks {
  playlist: IPlaylist;
  tracks: ITrack[];
}

export interface IDeletedPlaylist {
  id: string;
  collector: string;
  type: PlaylistType;
  artistId?: string;
}

export interface IUpdatePlaylistData {
  id: string;
  updatedPlaylist: Partial<IPlaylist>;
}

export interface IUpdateFollowedPlaylistData extends IUpdatePlaylistData {
  parentPlaylistId: string;
}

export interface ITrackVotes {
  [userId: string]: {
    upvote: boolean;
  };
}

export interface IPlaylistVotes {
  [trackId: string]: ITrackVotes;
}

export interface ICountedVotes {
  [trackId: string]: number;
}
