import React, {FC, ReactNode} from 'react';
import {Insets, Pressable, StyleProp, View} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import Icon, {IIconProps} from '@/components/Icon';
import Text, {TextProps} from '@/components/Text';
import spacing, {HIT_SLOP} from '@/constants/spacing';
import {isThemeSquare, useTheme, useThemedStyles} from '@/theme';
import {Color} from '@/types/theme';

import {styles, HEIGHT} from './TransparentButton.style';

interface IProps {
  onPress: () => void;
  text: TextProps;
  icon?: IIconProps;
  iconSpacing?: number;
  suffix?: ReactNode;
  height?: number;
  style?: StyleProp<any>;
  contentStyle?: StyleProp<any>;
  pressedColor?: Color;
  disabled?: boolean;
  rippleDisabled?: boolean;
  rippleScale?: number;
  hitSlop?: Insets;
}

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const TransparentButton: FC<IProps> = ({
  onPress,
  text,
  icon,
  iconSpacing = spacing.xs,
  suffix,
  height = HEIGHT,
  style: customStyle,
  contentStyle: customContentStyle,
  pressedColor = 'backgroundDark',
  disabled = false,
  rippleDisabled = false,
  rippleScale = 1,
  hitSlop = HIT_SLOP,
}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();
  const pressed = useSharedValue(false);
  const hovered = useSharedValue(false);

  const hoverStyle = useAnimatedStyle(() => ({
    opacity: withTiming(hovered.value && !pressed.value ? 0.4 : 1, {
      duration: 150,
    }),
  }));

  const pressStyle = useAnimatedStyle(() => ({
    transform: [
      {
        scale: pressed.value
          ? withSpring(rippleScale, {mass: 0.1})
          : withTiming(0.8, {duration: 150}),
      },
    ],
    opacity: pressed.value
      ? withSpring(0.4, {mass: 0.1})
      : withTiming(0, {duration: 150}),
  }));

  const contentStyle = useAnimatedStyle(() => ({
    transform: [{scale: withSpring(pressed.value ? 0.9 : 1, {mass: 0.1})}],
  }));

  const borderRadius = isThemeSquare(theme)
    ? theme.box.borderRadius
    : height / 2;

  return (
    <AnimatedPressable
      onPress={onPress}
      onPressIn={() => (pressed.value = true)}
      onPressOut={() => (pressed.value = false)}
      onHoverIn={() => (hovered.value = true)}
      onHoverOut={() => (hovered.value = false)}
      hitSlop={hitSlop}
      style={[style.button, {height, borderRadius}, customStyle, hoverStyle]}
      disabled={disabled}>
      {!rippleDisabled && (
        <Animated.View
          style={[
            style.pressIndicator,
            {
              borderRadius,
              backgroundColor: theme.colors[pressedColor],
            },
            pressStyle,
          ]}
        />
      )}
      <Animated.View
        style={[
          style.content,
          contentStyle,
          disabled && style.content_disabled,
          customContentStyle,
        ]}>
        {icon && (
          <View style={{marginRight: iconSpacing}}>
            <Icon {...icon} />
          </View>
        )}
        <Text size="s" weight="semibold" {...text} align="center" />
        {suffix}
      </Animated.View>
    </AnimatedPressable>
  );
};

export default TransparentButton;
