import {
  DefaultTheme,
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {createStackNavigator} from '@react-navigation/stack';
import React, {FC} from 'react';
import {useIntl} from 'react-intl';
import {Platform} from 'react-native';

import Layout from '@/components/Layout/Layout';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useResponsive} from '@/hooks/useResponsive';
import {CollectScreen} from '@/modules/Collect';
import {ExternalWalletProvider} from '@/modules/ExternalWallet';
import {LoginScreen} from '@/modules/Login';
import {ToastProvider} from '@/modules/Toasts';
import TopUpScreen from '@/modules/TopUp/TopUpScreen';
import {TransferScreen} from '@/modules/Transfer';
import MainNavigation from '@/navigation/MainNavigation/MainNavigation';
import {linkingConfig} from '@/navigation/routesConfig';
import AddToPlaylist from '@/screens/AddToPlaylist/AddToPlaylist';
import ArtistModal from '@/screens/Artist/ArtistModal';
import {isFeedFocused} from '@/screens/Feed/utils';
import NotificationsPrompt from '@/screens/NotificationsPrompt';
import Player from '@/screens/Player/Player';
import PlaylistCollaborators from '@/screens/PlaylistCollaborators/PlaylistCollaborators';
import QueueScreen from '@/screens/Queue/Queue';
import SpinampWrapped from '@/screens/SpinampWrapped/SpinampWrapped';
import SuggestToPlaylist from '@/screens/SuggestToPlaylist/SuggestToPlaylist';
import TrackModal from '@/screens/Track/TrackModal';
import {setIsFeedFocused} from '@/store/feed';
import {setNavigationState} from '@/store/navigation';
import {selectSelectedUserId} from '@/store/user';
import {useTheme} from '@/theme';
import {RootStackParams, Routes} from '@/types/routes';
import {isNative, isWeb} from '@/utils/platform';

// using Stack instead of NativeStack on web to support animations
const RootStack = isWeb
  ? createStackNavigator<RootStackParams>()
  : createNativeStackNavigator<RootStackParams>();

const RootNavigation: FC = () => {
  const dispatch = useAppDispatch();
  const activeUserId = useAppSelector(selectSelectedUserId);
  const {formatMessage} = useIntl();
  const theme = useTheme();
  const {isMobile} = useResponsive();

  const navigationRef = useNavigationContainerRef<RootStackParams>();

  const navigationTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: theme.background?.image
        ? 'transparent'
        : theme.colors.backgroundLight,
    },
  };

  const updateNavigationState = () => {
    if (navigationRef) {
      dispatch(setIsFeedFocused(isFeedFocused(navigationRef.getRootState())));
      dispatch(
        setNavigationState({
          currentScreen: navigationRef.getCurrentRoute()!.name as Routes,
          canGoBack: navigationRef.canGoBack(),
        }),
      );
    }
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={updateNavigationState}
      onStateChange={updateNavigationState}
      linking={linkingConfig}
      theme={navigationTheme}
      documentTitle={{formatter: () => formatMessage({id: 'appName'})}}>
      <ToastProvider>
        <ExternalWalletProvider>
          <Layout>
            <RootStack.Navigator screenOptions={{headerShown: false}} id="root">
              <RootStack.Screen
                name={Routes.MainNavigation}
                component={MainNavigation}
              />
              {isMobile && (
                <RootStack.Screen
                  name={Routes.Player}
                  component={Player}
                  options={{
                    presentation: 'modal',
                    gestureEnabled: true,
                    ...Platform.select({
                      android: {
                        animation: 'slide_from_bottom',
                      },
                      web: {
                        animationEnabled: true,
                        detachPreviousScreen: false,
                      },
                    }),
                  }}
                />
              )}
              <RootStack.Screen
                name={Routes.Queue}
                component={QueueScreen}
                options={{
                  presentation: 'modal',
                  gestureEnabled: true,
                  ...Platform.select({
                    android: {
                      animation: 'slide_from_bottom',
                    },
                    web: {
                      animationEnabled: true,
                      cardOverlayEnabled: isMobile,
                    },
                  }),
                }}
              />
              <RootStack.Screen
                name={Routes.AddToPlaylist}
                component={AddToPlaylist}
                options={{
                  presentation: 'modal',
                  animationEnabled: true,
                  ...Platform.select({
                    android: {
                      animation: 'slide_from_bottom',
                    },
                  }),
                }}
              />
              <RootStack.Screen
                name={Routes.SuggestToPlaylist}
                component={SuggestToPlaylist}
                options={{
                  presentation: 'modal',
                  animationEnabled: true,
                  ...Platform.select({
                    android: {
                      animation: 'slide_from_bottom',
                    },
                  }),
                }}
              />
              <RootStack.Screen
                name={Routes.PlaylistCollaborators}
                component={PlaylistCollaborators}
                options={{
                  presentation: 'modal',
                  animationEnabled: true,
                  ...Platform.select({
                    android: {
                      animation: 'slide_from_bottom',
                    },
                  }),
                }}
              />
              <RootStack.Screen
                name={Routes.SpinampWrapped}
                component={SpinampWrapped}
                options={{
                  gestureEnabled: true,
                  presentation: Platform.select({
                    ios: 'transparentModal',
                    android: 'modal',
                    web: isMobile ? 'modal' : 'transparentModal',
                  }),
                  ...Platform.select({
                    android: {
                      animation: 'slide_from_bottom',
                    },
                    web: {
                      animationEnabled: isMobile,
                      detachPreviousScreen: false,
                    },
                  }),
                }}
              />
              <RootStack.Screen
                name={Routes.Collect}
                component={CollectScreen}
                options={{
                  presentation: isMobile ? 'modal' : 'transparentModal',
                  animation: Platform.select({
                    ios: 'default',
                    android: 'slide_from_bottom',
                    web: isMobile ? 'slide_from_bottom' : 'fade',
                  }),
                  animationEnabled: true,
                  gestureEnabled: true,
                }}
              />
              <RootStack.Screen
                name={Routes.ArtistModal}
                component={ArtistModal}
                options={{
                  presentation: isMobile ? 'modal' : 'transparentModal',
                  animation: Platform.select({
                    ios: 'default',
                    android: 'slide_from_bottom',
                    web: isMobile ? 'slide_from_bottom' : 'fade',
                  }),
                  animationEnabled: true,
                  gestureEnabled: true,
                }}
              />
              <RootStack.Screen
                name={Routes.TrackModal}
                component={TrackModal}
                options={{
                  presentation: isMobile ? 'modal' : 'transparentModal',
                  animation: Platform.select({
                    ios: 'default',
                    android: 'slide_from_bottom',
                    web: isMobile ? 'slide_from_bottom' : 'fade',
                  }),
                  animationEnabled: true,
                  gestureEnabled: true,
                }}
              />
              <RootStack.Screen
                name={Routes.Transfer}
                component={TransferScreen}
                options={{
                  presentation: isMobile ? 'modal' : 'transparentModal',
                  animation: Platform.select({
                    ios: 'default',
                    android: 'slide_from_bottom',
                    web: isMobile ? 'slide_from_bottom' : 'fade',
                  }),
                  animationEnabled: true,
                  gestureEnabled: true,
                }}
              />
              <RootStack.Screen
                name={Routes.Login}
                component={LoginScreen}
                options={{
                  presentation: 'transparentModal',
                  animation: 'fade',
                  gestureEnabled: false,
                }}
              />
              {activeUserId && (
                <RootStack.Screen
                  name={Routes.TopUp}
                  component={TopUpScreen}
                  options={{
                    presentation: 'transparentModal',
                    animation: 'fade',
                    gestureEnabled: false,
                  }}
                />
              )}
              {isNative && (
                <RootStack.Screen
                  name={Routes.NotificationsPrompt}
                  component={NotificationsPrompt}
                  options={{
                    presentation: 'transparentModal',
                    animation: 'fade',
                    gestureEnabled: false,
                  }}
                />
              )}
            </RootStack.Navigator>
          </Layout>
        </ExternalWalletProvider>
      </ToastProvider>
    </NavigationContainer>
  );
};

export default RootNavigation;
