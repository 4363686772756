import axios from 'axios';

import {config} from '@/constants/config';
import {ImageToUpload} from '@/utils/imagePicker/types';

export const uploadImage = async (
  file: ImageToUpload,
  onProgressUpdate: (progress: number) => void,
): Promise<string> => {
  const formData = new FormData();

  // @ts-ignore - object shape from ImageToUpload type is valid as formData payload
  formData.append('file', file);

  const response = await axios.post(config.PINATA_UPLOAD_URL, formData, {
    maxBodyLength: Infinity,
    headers: {
      'Content-Type': 'multipart/form-data;',
      Authorization: `Bearer ${config.PINATA_API_KEY}`,
    },
    transformRequest: data => data,
    onUploadProgress: ({loaded, total}) => {
      const progress = Math.round((loaded * 100) / total);
      onProgressUpdate(progress);
    },
  });

  return response.data.IpfsHash;
};
