import spacing from '@/constants/spacing';
import {calculateBackgroundColorLight, createThemedStyles} from '@/theme';

const SCREEN_PADDING = spacing.s;
const SCREEN_PADDING_TOP = spacing.xs;
export const styles = createThemedStyles(theme => ({
  scrollContent: {
    flexGrow: 1,
    paddingTop: SCREEN_PADDING_TOP,
    padding: SCREEN_PADDING,
    backgroundColor: calculateBackgroundColorLight(theme),
  },
  footer: {
    marginTop: spacing.s,
    gap: spacing.xxs,
    alignSelf: 'center',
  },
}));
