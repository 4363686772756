import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const CONTENT_TOP_PADDING = spacing.xs;
export const CONTENT_PADDING = spacing.s;

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    container: {
      flex: 1,
    },
    content: {
      flexGrow: 1,
      paddingTop: CONTENT_TOP_PADDING,
      paddingHorizontal: CONTENT_PADDING,
      paddingBottom: CONTENT_PADDING + safeAreaInsets.bottom,
    },
    header: {
      paddingTop: Platform.select({
        ios: CONTENT_PADDING,
        default: CONTENT_PADDING + safeAreaInsets.top,
      }),
      paddingHorizontal: CONTENT_PADDING,
      paddingBottom: spacing.xs,
    },
    headerContent: {
      flexDirection: 'row',
    },
    headerTitle: {
      flex: 1,
      paddingRight: spacing.xs,
    },
    headerDivider: {
      width: '100%',
      height: 2,
      backgroundColor: theme.colors.borderColor,
    },
    playlistTitle: {
      alignSelf: 'flex-start',
    },
  }),
);
