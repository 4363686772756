import React, {FC} from 'react';
import {View} from 'react-native';

import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import Text, {TextProps} from '@/components/Text';
import {chainsById} from '@/constants/chains';
import {useThemedStyles} from '@/theme';

import {styles} from './TransactionLink.style';

export const TransactionLink: FC<{
  url: string;
  text: TextProps;
}> = ({url, text}) => {
  const style = useThemedStyles(styles);

  return (
    <ExternalLink url={url}>
      <View style={style.row}>
        <Text size="xs" underline {...text} />
        <Icon provider="custom" name="externalLink" size={12} />
      </View>
    </ExternalLink>
  );
};

export const EtherscanLink: FC<{
  txHash: string;
  chainId: number;
}> = ({txHash, chainId}) => {
  const explorerUrl =
    chainsById[chainId]?.explorerUrl || 'https://etherscan.io/';

  return (
    <TransactionLink
      url={`${explorerUrl}tx/${txHash}`}
      text={{id: 'collect.pending.etherscanLink'}}
    />
  );
};

export const JiffyscanLink: FC<{
  userOpHash: string;
  chainId: number;
}> = ({userOpHash, chainId}) => {
  const chain = chainsById[chainId];
  let url = `https://jiffyscan.xyz/userOpHash/${userOpHash}`;

  if (chain.jiffyscanCode) {
    url = `${url}?network=${chain.jiffyscanCode}`;
  }

  return (
    <TransactionLink url={url} text={{id: 'collect.pending.jiffyscanLink'}} />
  );
};

export default TransactionLink;
