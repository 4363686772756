import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';

import {useAppDispatch} from '@/hooks/useRedux';
import {ICollectTransaction} from '@/modules/Collect/types';
import ExpandableToast from '@/modules/Toasts/ExpandableToast';
import {updateTransaction} from '@/store/collectTransactions/slice';
import {RootStackNavigationParams, Routes} from '@/types/routes';

interface IProps {
  transaction: ICollectTransaction;
}

const TransactionToast: FC<IProps> = ({transaction}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();

  const {transactionStep, transactionError} = transaction;

  return (
    <ExpandableToast
      onPress={() => {
        dispatch(
          updateTransaction({id: transaction.id, update: {isMinimized: false}}),
        );
        navigation.navigate(Routes.Collect, {
          slug: transaction.slug,
          transactionId: transaction.id,
          referral: transaction.referralAddress,
        });
      }}
      text={{
        id: transactionError
          ? 'collect.toast.failed'
          : transactionStep === 'success'
          ? 'collect.toast.completed'
          : transactionStep === 'checkout'
          ? 'collect.toast.cancelled'
          : 'collect.toast.pending',
      }}
      isLoading={
        !transactionError && !['success', 'checkout'].includes(transactionStep)
      }
    />
  );
};

export default TransactionToast;
