import React, {forwardRef, ReactNode} from 'react';
import {useIntl} from 'react-intl';
import {StyleProp, TextInput, TextInputProps} from 'react-native';

import {IIconProps} from '@/components/Icon';
import Icon from '@/components/Icon/Icon';
import Space from '@/components/Space/Space';
import {useTheme, useThemedStyles} from '@/theme';

import {styles} from './Input.style';

export interface IInputProps extends TextInputProps {
  placeholder?: string;
  placeholderId?: string;
  multiline?: boolean;
  style?: StyleProp<any>;
  inputStyle?: TextInputProps['style'];
  prefixIcon?: IIconProps;
  prefixComponent?: ReactNode;
  suffixComponent?: ReactNode;
}

const Input = forwardRef<TextInput, IInputProps>(
  (
    {
      value,
      onChangeText,
      placeholder,
      placeholderId,
      multiline = false,
      style: styleProp,
      inputStyle,
      prefixIcon,
      prefixComponent,
      suffixComponent,
      ...inputProps
    },
    ref,
  ) => {
    const style = useThemedStyles(styles);
    const theme = useTheme();
    const {formatMessage} = useIntl();

    return (
      <Space style={[style.inputWrapper, styleProp]}>
        {prefixComponent}
        {prefixIcon && <Icon {...prefixIcon} />}
        <TextInput
          style={[
            style.input,
            !!value && style.inputActive,
            multiline && style.inputMultiline,
            inputStyle,
          ]}
          value={value}
          autoCapitalize="none"
          onChangeText={onChangeText}
          placeholder={
            placeholderId ? formatMessage({id: placeholderId}) : placeholder
          }
          placeholderTextColor={theme.colors.textColor}
          multiline={multiline}
          textAlignVertical={multiline ? 'top' : undefined}
          ref={ref}
          {...inputProps}
        />
        {suffixComponent}
      </Space>
    );
  },
);

export default Input;
