import {useMutation} from '@tanstack/react-query';
import {disconnect} from '@wagmi/core';

import {useToast} from '@/modules/Toasts';

export const useDisconnect = () => {
  const {showToast} = useToast();

  const {
    mutate,
    mutateAsync,
    isLoading: isDisconnecting,
  } = useMutation({
    networkMode: 'online',
    mutationFn: () => disconnect(),
    onError: () => {
      showToast({
        textId: 'disconnect.error',
      });
    },
  });

  return {
    disconnect: mutate,
    disconnectAsync: mutateAsync,
    isDisconnecting,
  };
};
