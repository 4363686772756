import React, {FC} from 'react';

import {IIconProps} from '@/components/Icon';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {
  WalletsPicker,
  AddExternalWalletModal,
  AddPasskeyWalletModal,
} from '@/modules/Wallets';

const modalCloseIcon: IIconProps = {
  name: 'arrowLeft',
  provider: 'custom',
};

const CollectModalsRouter: FC = () => {
  const {
    modal,
    pushModal,
    popModal,

    user,
    selectedOffer,
    walletsSettings,
    setWalletsSettings,
  } = useCollectState();

  if (modal === 'walletsPicker' && user && walletsSettings) {
    return (
      <WalletsPicker
        isOpen
        onClose={popModal}
        user={user}
        settings={walletsSettings}
        chainId={selectedOffer?.chainId}
        onAddExternal={() => pushModal('addExternalWallet')}
        onAddPasskey={() => pushModal('addPasskeyWallet')}
        onConfirm={settings => {
          setWalletsSettings(settings);
          popModal();
        }}
      />
    );
  }

  if (modal === 'addPasskeyWallet') {
    return (
      <AddPasskeyWalletModal
        isOpen
        onClose={popModal}
        closeIcon={modalCloseIcon}
      />
    );
  }

  if (modal === 'addExternalWallet') {
    return (
      <AddExternalWalletModal
        isOpen
        onClose={popModal}
        closeIcon={modalCloseIcon}
      />
    );
  }

  return null;
};

export default CollectModalsRouter;
