import React, {FC, ReactNode, useCallback, useMemo, useState} from 'react';
import {Platform, ScrollView, StyleSheet, Vibration, View} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import {createFeedItemForArtist, getArtistModalLink} from '../../utils';
import IconButton from '@/components/IconButton';
import Link from '@/components/Link';
import SpinLoader from '@/components/SpinLoader';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {useArtistFollow} from '@/hooks/useArtistFollow';
import {useRecyclingHandler} from '@/hooks/useRecyclingHandler';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useFeedItemMutation} from '@/queries/feed';
import ArtistFeedCardTrack from '@/screens/Feed/components/ArtistFeedCardTrack';
import BaseFeedCard from '@/screens/Feed/components/FeedCard/BaseFeedCard';
import {useFeedAudio} from '@/screens/Feed/components/FeedCard/useFeedAudio';
import Progress from '@/screens/Feed/components/Progress';
import TimeToRelease from '@/screens/Feed/components/TimeToRelease';
import {setShouldPlay, toggleFeedPlay} from '@/store/feed';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {IFeedItem, IFeedItemWithArtist} from '@/types/feed';
import {ImageSize} from '@/types/media';
import {analytics} from '@/utils/analytics';

import {styles} from './ArtistFeedCard.styles';

interface IArtistFeedCardProps {
  feedItem: IFeedItemWithArtist;
  toggleHide?: (feedItem: IFeedItem) => void;
  toggleLike?: (feedItem: IFeedItem) => void;
  isActive: boolean;
  next: () => void;
  setCarouselEnabled: (enabled: boolean) => void;
}

const ArtistFeedCard: React.FC<IArtistFeedCardProps> = ({
  feedItem,
  toggleHide,
  toggleLike,
  isActive,
  next,
  setCarouselEnabled,
}) => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const activeUserId = useAppSelector(selectActiveUserId);

  const {artist} = feedItem;
  const tracks = artist.tracks.slice(0, 5);
  const firstTrack = tracks[0];
  const [feedCardTrack, setFeedCardTrack] = useState<ITrack | undefined>(
    firstTrack,
  );

  useRecyclingHandler(artist.id, () => {
    setFeedCardTrack(tracks[0]);
  });

  const onTrackEnd = () => {
    const trackIndex = tracks.findIndex(
      track => track.id === feedCardTrack?.id,
    );
    if (trackIndex === tracks.length - 1) {
      setFeedCardTrack(firstTrack);
      next();
    } else if (trackIndex !== -1) {
      setFeedCardTrack(tracks[trackIndex + 1]);
    }
  };

  const {isPlaying, isBuffering, progress} = useFeedAudio(
    feedCardTrack,
    isActive,
    onTrackEnd,
  );

  const backgroundImageUri =
    feedCardTrack?.lossyArtworkUrl || feedItem.artist.avatarUrl;

  const onTrackPress = useCallback((track: ITrack) => {
    setFeedCardTrack(track);
    dispatch(setShouldPlay(true));
  }, []);

  const isOwnFeed = activeUserId === feedItem.userId;
  const LikeHandler = isOwnFeed ? ArtistLikeHandler : SpiedArtistLikeHandler;

  return (
    <BaseFeedCard>
      {progress && (
        <Progress
          progress={progress}
          onDragStart={() => setCarouselEnabled(false)}
          onDragEnd={() => setCarouselEnabled(true)}
        />
      )}
      <View style={style.tracksContainer}>
        {backgroundImageUri && (
          <Animated.View
            key={feedCardTrack?.id}
            style={style.artwork}
            entering={FadeIn}
            exiting={Platform.select({
              native: FadeOut.delay(300),
            })}>
            <StaticImage
              source={{uri: backgroundImageUri}}
              resizeWidth={ImageSize.feedCard}
              style={StyleSheet.absoluteFillObject}
            />
          </Animated.View>
        )}
        <View style={[style.mask, style.artworkMask]} />
        {isBuffering && (
          <View style={style.bufferingIndicator}>
            <SpinLoader />
          </View>
        )}
        <ScrollView
          style={style.trackList}
          contentContainerStyle={style.trackListContent}
          showsVerticalScrollIndicator={false}
          bounces={false}>
          {tracks.map(track => (
            <ArtistFeedCardTrack
              key={track.id}
              track={track}
              onPress={onTrackPress}
              isActive={track.id === feedCardTrack?.id}
            />
          ))}
          {tracks.length === 0 && (
            <View style={style.empty}>
              <Text
                align="center"
                id="feed.artistFeedCard.empty"
                color="invertedTextColor"
              />
            </View>
          )}
        </ScrollView>
        <TimeToRelease track={feedCardTrack} style={style.timeToRelease} />
      </View>
      <View style={style.actionsContainer}>
        <View style={style.actionsRow}>
          <View style={style.action}>
            {toggleHide && (
              <IconButton
                onPress={() => toggleHide(feedItem)}
                icon={{
                  provider: 'custom',
                  name: 'visibilityOff',
                }}
              />
            )}
          </View>
          <View style={style.artistAvatarContainer}>
            {feedItem.artist.avatarUrl && (
              <StaticImage
                source={{uri: feedItem.artist.avatarUrl}}
                style={style.artistAvatar}
                resizeWidth={ImageSize.thumbnail}
              />
            )}
            <View style={style.mask} />
            <IconButton
              rippleDisabled
              onPress={() => dispatch(toggleFeedPlay())}
              icon={{
                provider: 'custom',
                name: isPlaying ? 'pause' : 'play',
                color: 'invertedTextColor',
              }}
              disabled={!feedCardTrack}
            />
          </View>
          <View style={style.action}>
            <LikeHandler feedItem={feedItem} toggleLike={toggleLike}>
              {({isLiked, handleLike}) => (
                <TransparentButton
                  rippleDisabled
                  onPress={() => {
                    if (!isLiked) {
                      Vibration.vibrate();
                    }

                    handleLike();
                  }}
                  text={{
                    id: isLiked ? 'unfollow' : 'follow',
                  }}
                />
              )}
            </LikeHandler>
          </View>
        </View>
        <View style={style.artistLinkWrapper}>
          <Link
            {...getArtistModalLink(feedItem.artist.slug)}
            style={style.artistLink}>
            {({hover}) => (
              <Text
                align="center"
                numberOfLines={1}
                underline={hover}
                weight="semibold">
                {feedItem.artist.name}
              </Text>
            )}
          </Link>
        </View>
      </View>
    </BaseFeedCard>
  );
};

interface ILikeHandlerProps {
  feedItem: IFeedItemWithArtist;
  toggleLike?: (item: IFeedItem) => void;
  children: (props: ILikeHandlerRenderProps) => ReactNode;
}

interface ILikeHandlerRenderProps {
  isLiked: boolean;
  handleLike: () => void;
}

const ArtistLikeHandler: FC<ILikeHandlerProps> = ({
  feedItem,
  toggleLike,
  children,
}) => {
  const isLiked = feedItem.userAction === 'like';

  return children({
    isLiked,
    handleLike: () => {
      toggleLike?.(feedItem);
    },
  });
};

const SpiedArtistLikeHandler: FC<ILikeHandlerProps> = ({
  feedItem,
  children,
}) => {
  const userId = useAppSelector(selectActiveUserId);

  const {getIsFollowed} = useArtistFollow();

  const {mutate: upsertFeedItem} = useFeedItemMutation(userId!, {
    skipOptimisticUpdate: true,
  });

  const isLiked = useMemo(
    () => getIsFollowed(feedItem.artist.id),
    [feedItem, getIsFollowed],
  );

  return children({
    isLiked,
    handleLike: () => {
      upsertFeedItem(
        createFeedItemForArtist(
          userId!,
          feedItem.artist,
          isLiked ? null : 'like',
        ),
      );
      analytics.feedItemReacted(
        feedItem.id,
        isLiked ? null : 'like',
        feedItem.entityType,
      );
    },
  });
};

export default React.memo(ArtistFeedCard);
