import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import DynamicImage from '@/components/DynamicImage/DynamicImage';
import Icon from '@/components/Icon/Icon';
import IconButton from '@/components/IconButton';
import PlaybackRateButton from '@/components/Player/components/PlaybackRateButton';
import TrackSlidingInfo from '@/components/Player/components/TrackSlidingInfo';
import PlayerControls from '@/components/PlayerControls';
import PlayerProgress from '@/components/PlayerProgress/PlayerProgress';
import ProviderLink from '@/components/ProviderLink/ProviderLink';
import Share from '@/components/Share';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import TrackPlaylistToggler from '@/components/TrackPlaylistToggler/TrackPlaylistToggler';
import TransparentButton from '@/components/TransparentButton';
import {useFavToggle} from '@/hooks/useFavToggle';
import {usePlayerProgress} from '@/hooks/usePlayerProgress';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useResponsive} from '@/hooks/useResponsive';
import {useCollect} from '@/modules/Collect';
import {useExternalSigner} from '@/modules/ExternalWallet';
import {usePlaylistCreatorQuery} from '@/queries/publicPlaylists';
import TrackPlayer, {RepeatMode} from '@/services/trackPlayer';
import {
  changeRateMode,
  changeRepeatMode,
  playNextTrack,
  playPreviousTrack,
  seekTo,
  selectCurrentTrackKey,
  selectHasNext,
  selectIsBuffering,
  selectIsPlaying,
  selectIsShuffle,
  selectNextTrack,
  selectQueue,
  selectRateMode,
  selectRepeatMode,
  togglePlay,
  toggleShuffle,
} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {isPodcast} from '@/utils/player';
import {getReferralPlaylistFromQueue} from '@/utils/referrals';
import {getTrackUrl} from '@/utils/share';
import {getTrackArtistsNames} from '@/utils/tracks';

import {styles} from './Player.style';
import {usePlayerKeyboardHandler} from './usePlayerKeyboardHandler';

interface IProps {
  currentTrack: ITrack;
}

const Player: FC<IProps> = ({currentTrack}) => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();
  const {address} = useExternalSigner();
  const {isMobile} = useResponsive();
  const {duration} = usePlayerProgress();

  const currentTrackKey = useAppSelector(selectCurrentTrackKey);
  const nextTrack = useAppSelector(selectNextTrack);
  const queue = useAppSelector(selectQueue);
  const isPlaying = useAppSelector(selectIsPlaying);
  const isBuffering = useAppSelector(selectIsBuffering);
  const repeatMode = useAppSelector(selectRepeatMode);
  const rateMode = useAppSelector(selectRateMode);
  const isShuffle = useAppSelector(selectIsShuffle);
  const hasNext = useAppSelector(selectHasNext);
  const {isFav, toggleFav} = useFavToggle(currentTrack.id);
  const referral = usePlaylistCreatorQuery(getReferralPlaylistFromQueue(queue));

  const {
    collect,
    isLoading: isCollectLoading,
    icon: collectIcon,
    textId: collectTextId,
  } = useCollect(currentTrack, referral);

  usePlayerKeyboardHandler();

  const onProgressChange = (value: number) => dispatch(seekTo(value));

  const onSeekForward = async (seconds: number) => {
    const currentPosition = await TrackPlayer.getPosition();
    const trackDuration = await TrackPlayer.getDuration();
    const newPosition = Math.min(currentPosition + seconds, trackDuration);
    dispatch(seekTo(newPosition));
  };

  const onSeekBackward = async (seconds: number) => {
    const currentPosition = await TrackPlayer.getPosition();
    const newPosition = Math.max(currentPosition - seconds, 0);
    dispatch(seekTo(newPosition));
  };

  const onPlayPress = () => {
    dispatch(togglePlay());
  };

  const onNextPress = () => {
    dispatch(playNextTrack());
  };

  const onPreviousPress = () => {
    dispatch(playPreviousTrack());
  };

  const onRepeatModePress = () => {
    dispatch(changeRepeatMode());
  };

  const onShufflePress = () => {
    dispatch(toggleShuffle());
  };

  const onRateModePress = () => {
    dispatch(changeRateMode());
  };

  const goToTrack = (slugOrId: string) => {
    navigation.navigate(Routes.MainNavigation, {
      screen: Routes.Track,
      params: {
        slug: slugOrId,
        referral,
      },
    });
  };

  const goToArtist = (slugOrId: string) => {
    navigation.navigate(Routes.MainNavigation, {
      screen: Routes.Artist,
      params: {
        slugOrId,
      },
    });
  };

  return (
    <View style={style.wrapper}>
      <ProviderLink
        align="center"
        platformId={currentTrack.platformId}
        url={currentTrack.websiteUrl}
        analyticsInfo={{
          screen: Routes.Player,
          id: currentTrack.id,
        }}
        size="xs"
        referral={referral}
      />
      <Space h="s" />
      <View style={style.artworkContainer}>
        <TouchableOpacity
          onPress={() => goToTrack(currentTrack.slug)}
          activeOpacity={1}
          style={style.artworkPositioner}>
          <FadeInOut style={style.artworkPlaceholder} />
          {currentTrack.lossyArtworkUrl && (
            <DynamicImage
              resizeWidth={ImageSize.player}
              source={{uri: currentTrack.lossyArtworkUrl}}
              style={style.artwork}
              showSpinner
              spinnerSize={32}
              hasBorderRadius
            />
          )}
        </TouchableOpacity>
      </View>

      <View style={style.space} />

      <View style={style.infoContainer}>
        <View style={style.titleContainer} key={`${currentTrackKey}_info`}>
          <TrackSlidingInfo
            track={currentTrack}
            goToTrack={goToTrack}
            goToArtist={goToArtist}
          />
        </View>

        {isPodcast(duration) && (
          <PlaybackRateButton
            key={`${currentTrackKey}_rate`}
            ml="m"
            rateMode={rateMode}
            onRateModePress={onRateModePress}
          />
        )}

        <Space w="m" />
        <IconButton
          onPress={toggleFav}
          icon={{
            name: 'heart',
            provider: 'custom',
            fill: isFav,
            color: isFav ? 'favoritesColor' : 'textColor',
          }}
          hitSlop="s"
        />
      </View>

      <View style={style.space} />

      <PlayerProgress
        key={`${currentTrackKey}_progress`}
        onProgressChange={onProgressChange}
        isLoading={isPlaying && isBuffering}
      />

      <View style={style.space} />

      <View style={style.controlsContainer}>
        {isPodcast(duration) ? (
          <IconButton
            onPress={() => onSeekBackward(15)}
            icon={{name: 'seekBackward15', provider: 'custom'}}
            hitSlop="s"
          />
        ) : (
          <IconButton
            onPress={onShufflePress}
            icon={{name: 'shuffle', provider: 'custom'}}
            selected={isShuffle}
            hitSlop="s"
          />
        )}
        <Space w="m" />
        <View style={style.mainControls}>
          <PlayerControls
            onPlay={onPlayPress}
            onNext={onNextPress}
            onPrevious={onPreviousPress}
            isPlaying={isPlaying}
            hasNext={hasNext}
          />
        </View>
        <Space w="m" />
        {isPodcast(duration) ? (
          <IconButton
            onPress={() => onSeekForward(15)}
            icon={{name: 'seekForward15', provider: 'custom'}}
            hitSlop="s"
          />
        ) : (
          <IconButton
            onPress={onRepeatModePress}
            selected={repeatMode !== RepeatMode.Off}
            icon={{
              provider: 'custom',
              name: repeatMode === RepeatMode.Track ? 'repeatOne' : 'repeat',
            }}
            hitSlop="s"
          />
        )}
      </View>

      <View style={style.space} />

      <View style={style.controlsContainer}>
        <TrackPlaylistToggler track={currentTrack}>
          {({openModal}) => (
            <IconButton
              onPress={openModal}
              icon={{provider: 'custom', name: 'playlistAdd'}}
              hitSlop="s"
            />
          )}
        </TrackPlaylistToggler>
        <Space w="m" />

        <Space style={style.collectButtonWrapper}>
          <FadeInOut enabled={isCollectLoading}>
            <TransparentButton
              onPress={collect}
              text={{id: collectTextId}}
              icon={{
                name: collectIcon,
                provider: 'custom',
                size: 22,
              }}
            />
          </FadeInOut>
        </Space>

        <Space w="m" />
        <Share
          url={getTrackUrl(currentTrack, address)}
          title={currentTrack.title}>
          {({onPress, icon}) => (
            <IconButton onPress={onPress} icon={icon} hitSlop="s" />
          )}
        </Share>
      </View>

      <Space h="s" />

      {isMobile && (
        <TouchableOpacity
          onPress={() => navigation.navigate(Routes.Queue)}
          activeOpacity={0.8}
          style={style.queueOpener}>
          {nextTrack ? (
            <View style={style.nextTrackRow}>
              <View style={style.queueIcon}>
                <Icon provider="custom" name="arrowUp" />
              </View>
              <View style={style.nextTrackText}>
                <Text
                  align="center"
                  numberOfLines={1}
                  id="player.nextInfo"
                  values={{
                    next: (
                      <Text align="center" weight="semibold" id="player.next" />
                    ),
                    track: nextTrack.track.title,
                    artist: getTrackArtistsNames(nextTrack.track),
                  }}
                />
              </View>
              <View style={style.queueIcon} />
            </View>
          ) : (
            <View style={style.queueTextRow}>
              <View style={[style.queueIcon, style.queueIcon_floating]}>
                <Icon provider="custom" name="arrowUp" />
              </View>
              <Text align="center" weight="semibold" id="player.queue" />
            </View>
          )}
        </TouchableOpacity>
      )}
    </View>
  );
};

export default Player;
