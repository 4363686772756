import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const FONT_SIZE = 14;

export const styles = createThemedStyles(theme => ({
  inputWrapper: {
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.backgroundLight,
    height: spacing.xl,
  },
  input: {
    alignSelf: 'center',
    flex: 1,
    color: theme.colors.textColor,
    fontSize: FONT_SIZE,
    lineHeight: Math.round(1.2 * FONT_SIZE),
    fontFamily: theme.font.regular,
  },
  inputActive: {
    fontFamily: theme.font.semibold,
  },
}));
