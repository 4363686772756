import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    container: {
      flex: 1,
      paddingBottom: responsive.isMobile ? safeAreaInsets.bottom : 0,
    },
    header: {
      padding: spacing.xs,
      paddingTop: spacing.xs + safeAreaInsets.top,
      paddingHorizontal: spacing.s,
      backgroundColor: theme.colors.backgroundDark,
    },
    scrollContainer: {
      flexGrow: 1,
    },
    scrollContent: {
      flexGrow: 1,
    },
    scrollTouchable: {
      padding: spacing.l,
      gap: spacing.l,
    },
  }),
);
