import React, {FC} from 'react';
import {Pressable, TouchableOpacity, View} from 'react-native';

import Divider from '@/components/Divider';
import Icon from '@/components/Icon';
import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip';
import {getVerticalHitSlop} from '@/constants/spacing';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {IBaseUser} from '@/types/user';
import {formatPrice, prettifyAddress} from '@/utils/ethereum';

import {styles, ARROW_SIZE} from './CollectWalletsSettings.style';

interface IProps {
  user: IBaseUser;
}

const CollectWalletsSettings: FC<IProps> = ({user}) => {
  const style = useThemedStyles(styles);

  const {pushModal, paymentWallet, deliveryWallet, balance} = useCollectState();
  const hasMultipleWallets = user.addresses.filter(a => a.isWallet).length > 1;
  const differentWallets =
    paymentWallet &&
    deliveryWallet &&
    paymentWallet.address !== deliveryWallet.address;

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={style.container}
      onPress={() => pushModal('walletsPicker')}>
      <View style={style.infoContainer}>
        <View style={style.row}>
          <View style={style.rowLabel}>
            <Text size="xs" id="collect.walletsSettings.paymentMethod" />
          </View>
          <View style={style.addressContainer}>
            {paymentWallet && <WalletInfo wallet={paymentWallet} />}
            {balance && (
              <Text size="xs" align="right">
                {formatPrice(
                  balance.value,
                  {
                    decimals: balance.decimals,
                    symbol: balance.symbol,
                  },
                  5,
                )}
              </Text>
            )}
          </View>
        </View>

        {hasMultipleWallets && (
          <>
            <View>
              <Divider />
              <Icon
                name="arrowRight"
                provider="custom"
                style={style.floatingArrow}
              />
            </View>
            <View style={style.row}>
              <View style={style.rowLabel}>
                {differentWallets ? (
                  <Tooltip
                    position="top-left"
                    content={
                      <Text
                        size="xxs"
                        id="collect.walletsSettings.receivingWallet.disclaimer"
                        style={style.tooltipContent}
                      />
                    }>
                    {({open, close}) => (
                      <Pressable
                        onPress={open}
                        onHoverIn={open}
                        onHoverOut={close}
                        hitSlop={getVerticalHitSlop('xs')}
                        style={style.tooltipRow}>
                        <Text
                          size="xs"
                          id="collect.walletsSettings.receivingWallet"
                        />
                        <Icon size={16} name="info" provider="custom" />
                      </Pressable>
                    )}
                  </Tooltip>
                ) : (
                  <Text
                    size="xs"
                    id="collect.walletsSettings.receivingWallet"
                  />
                )}
              </View>
              <View style={style.addressContainer}>
                {deliveryWallet && <WalletInfo wallet={deliveryWallet} />}
              </View>
            </View>
          </>
        )}
      </View>

      <View style={style.arrowContainer}>
        {!hasMultipleWallets && (
          <Icon name="arrowRight" provider="custom" size={ARROW_SIZE} />
        )}
      </View>
    </TouchableOpacity>
  );
};

const WalletInfo: FC<{wallet: IAddress}> = ({wallet}) => {
  const style = useThemedStyles(styles);

  const isPasskey = isPasskeyWallet(wallet);
  const ens = wallet.ens;

  return (
    <View style={style.addressInfo}>
      <Text size="xs">
        {isPasskey ? 'Passkey Wallet' : ens || prettifyAddress(wallet.address)}
      </Text>
      {(isPasskey || ens) && (
        <Text size="xs">({prettifyAddress(wallet.address)})</Text>
      )}
    </View>
  );
};

export default CollectWalletsSettings;
