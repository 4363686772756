import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ITxError, TransactionStep} from '@/types/transactions';

export interface IPendingTransaction {
  id: string;
  step: TransactionStep;
  txHash?: string;
  error?: ITxError;
}

export interface TransactionsState {
  pendingTransactions: IPendingTransaction[];
}

export const initialState: TransactionsState = {
  pendingTransactions: [],
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addPendingTransaction: (
      state,
      action: PayloadAction<IPendingTransaction>,
    ) => {
      state.pendingTransactions = [
        ...state.pendingTransactions,
        {
          id: action.payload.id,
          txHash: action.payload.txHash,
          step: action.payload.step,
        },
      ];
    },
    updateTransactionData: (
      state,
      action: PayloadAction<{
        id: string | null;
        step?: TransactionStep;
        txHash?: string;
        error?: ITxError;
      }>,
    ) => {
      if (action.payload.id) {
        const {id, ...updatedData} = action.payload;
        state.pendingTransactions = state.pendingTransactions.map(tx => {
          if (tx.id === id) {
            return {
              ...tx,
              ...updatedData,
            };
          }

          return tx;
        });
      }
    },
    removeTransaction: (state, action: PayloadAction<{id: string | null}>) => {
      if (action.payload.id) {
        state.pendingTransactions = state.pendingTransactions.filter(
          tx => tx.id !== action.payload.id,
        );
      }
    },
  },
});

export const {addPendingTransaction, updateTransactionData, removeTransaction} =
  transactionsSlice.actions;

export default transactionsSlice.reducer;
