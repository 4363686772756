import {gql} from 'graphql-request';

import {Sentry} from '@/services/sentry';
import {
  IApiResponseArtist,
  IApiResponseArtistWithTracks,
  IApiResponseFeedItem,
  IApiResponseTrack,
  IApiResponseTrackWithNFTFactory,
} from '@/types/api';
import {
  IArtist,
  IArtistWithTracks,
  ITrack,
  ITrackWithNFTFactory,
} from '@/types/common';
import {IFeedEntityType, IFeedItem, IMessageFeedItem} from '@/types/feed';
import {IBaseUser, IUser} from '@/types/user';
import {isAddressValid} from '@/utils/ethereum';
import {getUniqueExternalLinksByFieldName} from '@/utils/functions';
import {getAudioUrl, getImageUrl} from '@/utils/ipfs';
import {parseUserMetadata} from '@/utils/user';

export const ARTIST_FRAGMENT = gql`
  fragment ArtistDetails on Artist {
    id
    createdAtTime
    name
    slug
    description
    customTheme
    predefinedThemeName
    avatarUrl
    avatarIpfsHash
    artistProfilesByArtistId {
      nodes {
        avatarUrl
        avatarIpfsHash
        createdAtTime
        name
        platformId
        platformInternalId
        websiteUrl
      }
    }
    userId
    userByUserId {
      externalLinksUsersByUserId {
        nodes {
          url
          type
          id
          updatedAtTime
        }
      }
      addressesByUserId(filter: {isSession: {notEqualTo: true}}) {
        nodes {
          address: id
          ens: ensName
          avatarUrl: ensAvatar
          isPublic
          isSession
          isWallet
          isPasskey
          metadata
        }
      }
    }
  }
`;

export const parseApiArtist = (artist: IApiResponseArtist): IArtist => ({
  id: artist.id,
  slug: artist.slug,
  createdAtTime: artist.createdAtTime,
  name: artist.name,
  description: artist.description,
  customTheme: artist.customTheme,
  predefinedThemeName: artist.predefinedThemeName,
  userId: artist.userId,
  avatarUrl: getImageUrl(artist.avatarIpfsHash, artist.avatarUrl),
  addresses: artist.userByUserId.addressesByUserId.nodes.map(addressNode => ({
    address: addressNode.address,
    ens: addressNode.ens,
    avatarUrl: addressNode.avatarUrl,
    isPublic: addressNode.isPublic,
    isSession: addressNode.isSession,
    isWallet: addressNode.isWallet,
    isPasskey: addressNode.isPasskey,
    metadata: addressNode.metadata,
  })),
  externalLinks: getUniqueExternalLinksByFieldName(
    artist.userByUserId.externalLinksUsersByUserId.nodes,
    'type',
  ),
});

export const TRACK_FRAGMENT = gql`
  fragment TrackDetails on ProcessedTrack {
    id
    platformInternalId
    title
    slug
    createdAtTime
    platformId
    websiteUrl
    lossyAudioUrl
    lossyArtworkUrl
    lossyAudioIpfsHash
    lossyArtworkIpfsHash
    lossyArtworkMimeType
    artistId
    artistByArtistId {
      ...ArtistDetails
    }
    artistBySupportingArtist {
      name
      slug
      id
    }
    duration
    chorusStart
    mintStart
  }
  ${ARTIST_FRAGMENT}
`;

export const ARTIST_WITH_5_TRACKS_FRAGMENT = gql`
  fragment ArtistWithTracksDetails on Artist {
    ...ArtistDetails
    processedTracksByArtistId(first: 5) {
      nodes {
        ...TrackDetails
      }
    }
  }
  ${TRACK_FRAGMENT}
`;

export const TRACK_WITH_NFT_FACTORY_DETAILS_FRAGMENT = gql`
  fragment TrackWithNftFactoryDetails on ProcessedTrack {
    ...TrackDetails
    nftFactoriesProcessedTracksByProcessedTrackId {
      edges {
        node {
          nftFactoryByNftFactoryId {
            contractType
            address
            standard
          }
        }
      }
    }
  }
  ${TRACK_FRAGMENT}
`;

export const parseApiTrack = (track: IApiResponseTrack): ITrack => {
  const lossyAudioUrl =
    getAudioUrl(track?.lossyAudioIpfsHash) || track.lossyAudioUrl;
  const lossyArtworkUrl = getImageUrl(
    track.lossyArtworkIpfsHash,
    track.lossyArtworkUrl,
    track.lossyArtworkMimeType,
  );

  return {
    id: track.id,
    platformInternalId: track.platformInternalId,
    title: track.title,
    slug: track.slug,
    createdAtTime: track.createdAtTime,
    platformId: track.platformId,
    description: track.description,
    websiteUrl: track.websiteUrl,
    lossyAudioUrl,
    lossyArtworkUrl,
    lossyArtworkIpfsHash: track.lossyArtworkIpfsHash,
    lossyArtworkMimeType: track.lossyArtworkMimeType,
    artistId: track.artistId,
    artist: parseApiArtist(track.artistByArtistId),
    supportingArtist: track.artistBySupportingArtist,
    duration: track.duration !== null ? Number(track.duration) : undefined,
    chorusStart:
      track.chorusStart !== null ? Number(track.chorusStart) : undefined,
    mintStart: track.mintStart,
  };
};

export const parseApiArtistWithTracks = (
  artist: IApiResponseArtistWithTracks,
): IArtistWithTracks => {
  return {
    ...parseApiArtist(artist),
    tracks: artist.processedTracksByArtistId.nodes.map(parseApiTrack),
  };
};

export const parseApiTrackWithNFTFactory = (
  track: IApiResponseTrackWithNFTFactory,
): ITrackWithNFTFactory => {
  const parsedTrack = parseApiTrack(track);

  return {
    ...parsedTrack,
    nftFactory:
      track.nftFactoriesProcessedTracksByProcessedTrackId.edges[0]?.node
        .nftFactoryByNftFactoryId,
  };
};

export const BASE_USER_FRAGMENT = gql`
  fragment BaseUserFragment on User {
    id
    name
    description
    avatarUrl
    avatarIpfsHash
    addressesByUserId(filter: {isSession: {notEqualTo: true}}) {
      nodes {
        address: id
        ens: ensName
        avatarUrl: ensAvatar
        isPublic
        isSession
        isWallet
        isPasskey
        metadata
      }
    }
    customTheme
    predefinedThemeName
    externalLinksUsersByUserId {
      nodes {
        url
        type
        id
      }
    }
  }
`;

export const USER_ARTIST_PROFILE_FRAGMENT = gql`
  fragment UserArtistProfileFragment on User {
    artistsByUserId {
      nodes {
        id
        slug
        name
        avatarUrl
        description
        customTheme
        predefinedThemeName
      }
    }
  }
`;

// We want to make sure that user name is human friendly, which is either custom name, ens or lens profile.
// So if user name is an address we prefer to set it to null and then fallback to actual address on component level
export const parseUserName = (name: string | null) => {
  if (!name || isAddressValid(name)) {
    return null;
  }

  return name;
};

export const parseApiBaseUser = (userNode: any): IBaseUser => {
  const avatarUrl = getImageUrl(userNode.avatarIpfsHash, userNode.avatarUrl);

  return {
    id: userNode.id,
    name: parseUserName(userNode.name),
    description: userNode.description,
    avatarUrl: avatarUrl,
    addresses: userNode.addressesByUserId.nodes.map((addressNode: any) => ({
      address: addressNode.address,
      ens: addressNode.ens,
      avatarUrl: addressNode.avatarUrl,
      isPublic: addressNode.isPublic,
      isSession: addressNode.isSession,
      isWallet: addressNode.isWallet,
      isPasskey: addressNode.isPasskey,
      metadata: addressNode.metadata,
    })),
    predefinedThemeName: userNode.predefinedThemeName,
    customTheme: userNode.customTheme,
    externalLinks: getUniqueExternalLinksByFieldName(
      userNode.externalLinksUsersByUserId.nodes,
      'type',
    ),
  };
};

export const parseApiUser = (userNode: any): IUser => {
  const baseUser = parseApiBaseUser(userNode);
  const artistNode = userNode.artistsByUserId.nodes[0];

  return {
    ...baseUser,
    metadata: parseUserMetadata(userNode.metadata),
    artistProfile: artistNode && {
      id: artistNode.id,
      slug: artistNode.slug,
      name: artistNode.name,
      userId: artistNode.userId,
      avatarUrl: artistNode.avatarUrl,
      description: artistNode.description,
      predefinedThemeName: artistNode.predefinedThemeName,
      customTheme: artistNode.customTheme,
    },
  };
};

export const parseFeedItem = (
  feedItem: IApiResponseFeedItem,
): IFeedItem | null => {
  const {artistByEntityId, processedTrackByEntityId, ...strippedItem} =
    feedItem;

  switch (feedItem.entityType) {
    case IFeedEntityType.artist:
      if (!feedItem.artistByEntityId) {
        const err = new Error(
          `Artist not found in feed item with ID + ${feedItem.id}`,
        );
        Sentry.captureException(err);

        return null;
      }

      const artist = parseApiArtistWithTracks(artistByEntityId);

      return {
        ...strippedItem,
        artist,
        entityType: IFeedEntityType.artist,
      };
    case IFeedEntityType.track:
      if (!feedItem.processedTrackByEntityId) {
        const err = new Error(
          `Track not found in feed item with ID + ${feedItem.id}`,
        );
        Sentry.captureException(err);

        return null;
      }

      const track = parseApiTrack(processedTrackByEntityId);

      return {
        ...strippedItem,
        track,
        entityType: IFeedEntityType.track,
      };
    case IFeedEntityType.message:
      // @ts-ignore
      return {
        ...strippedItem,
        entityType: IFeedEntityType.message,
      } as IMessageFeedItem;
    case IFeedEntityType.refill:
      return {
        ...strippedItem,
        entityType: IFeedEntityType.refill,
      };

    default:
      const err = new Error(
        `Unknown feed item entity type + ${feedItem.entityType}`,
      );
      Sentry.captureException(err);

      return null;
  }
};
