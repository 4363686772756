import React, {FC} from 'react';

import SuccessfulTransfer from '@/modules/Transfer/components/SuccessfulTransfer';
import TransferCheckout from '@/modules/Transfer/components/TransferCheckout';
import TransferErrorInfo from '@/modules/Transfer/components/TransferErrorInfo';
import {useTransferState} from '@/modules/Transfer/useTransferState';

const TransferRouter: FC = () => {
  const {transferStep, transactionError} = useTransferState();

  if (transactionError) {
    return <TransferErrorInfo />;
  }

  if (transferStep === 'success') {
    return <SuccessfulTransfer />;
  }

  return <TransferCheckout />;
};

export default TransferRouter;
