import React, {FC} from 'react';
import {View} from 'react-native';
import {useBalance} from 'wagmi';

import {FadeInOut} from '@/components/AnimationWrappers';
import Divider from '@/components/Divider';
import Icon from '@/components/Icon';
import ShadowCard from '@/components/ShadowCard';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {chainsById} from '@/constants/chains';
import {useTopUp} from '@/modules/TopUp';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {formatPrice} from '@/utils/ethereum';

import {styles} from './WalletTile.style';

interface IProps {
  wallet: IAddress;
  chainId: number;
}

const WalletTile: FC<IProps> = ({wallet, chainId}) => {
  const style = useThemedStyles(styles);
  const {topUp} = useTopUp();
  const chain = chainsById[chainId];

  const {data: balance} = useBalance({
    address: wallet.address,
    chainId,
    staleTime: 0,
    watch: true,
  });

  return (
    <ShadowCard style={style.container}>
      <View style={style.row}>
        <View style={style.chainRow}>
          {!!chain.icon && (
            <Icon name={`chain_${chain.icon}`} provider="custom" size={32} />
          )}
          <Text weight="semibold">{chain.name}</Text>
        </View>
        <View style={style.balanceContainer}>
          <Text size="xs" id="wallet.balance" />
          {balance ? (
            <Text weight="semibold">
              {formatPrice(balance.value, {
                decimals: balance.decimals,
                symbol: balance.symbol,
              })}
            </Text>
          ) : (
            <FadeInOut>
              <Text weight="semibold">0.00</Text>
            </FadeInOut>
          )}
        </View>
      </View>
      <View style={style.dividerWrapper}>
        <Divider style={style.divider} />
      </View>
      <View style={style.actionsContainer}>
        <TransparentButton
          onPress={() => topUp({address: wallet.address, chainId})}
          text={{id: 'wallet.topUp'}}
          icon={{provider: 'custom', name: 'creditCard'}}
        />
      </View>
    </ShadowCard>
  );
};

export default WalletTile;
