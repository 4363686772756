import React, {FC, ReactNode} from 'react';
import {
  FieldValues,
  FormProvider,
  useController,
  UseControllerProps,
  UseFormReturn,
} from 'react-hook-form';
import {View} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import IconButton from '@/components/IconButton';
import Input, {IInputProps} from '@/components/Input';
import RichTextEditor from '@/components/RichTextEditor';
import {IRichTextEditorProps} from '@/components/RichTextEditor/types';
import Space from '@/components/Space/Space';
import Text, {TextProps} from '@/components/Text';
import spacing from '@/constants/spacing';

export const Form = <T extends FieldValues>(props: {
  children: ReactNode;
  form: UseFormReturn<T>;
}) => {
  const {children, form} = props;

  return <FormProvider {...form}>{children}</FormProvider>;
};

const FormInput = <T extends FieldValues>(
  props: UseControllerProps<T> &
    Omit<IInputProps, 'onChangeText' | 'value' | 'onBlur'>,
) => {
  const {field} = useController(props);
  const {value, onChange, onBlur} = field;

  return (
    <Input onChangeText={onChange} onBlur={onBlur} value={value} {...props} />
  );
};

const FormRichTextEditor = <T extends FieldValues>(
  props: UseControllerProps<T> &
    Omit<IRichTextEditorProps, 'initialHtml' | 'onHtmlChange'>,
) => {
  const {field} = useController(props);
  const {value, onChange} = field;

  return (
    <RichTextEditor onHtmlChange={onChange} initialHtml={value} {...props} />
  );
};

const FormError: FC<{name: string; defaultMessageId?: string} & TextProps> = ({
  name,
  defaultMessageId,
  ...textProps
}) => {
  const {fieldState} = useController({name});
  const {error} = fieldState;
  const messageId = error?.message || defaultMessageId;

  if (!error || !messageId) {
    return null;
  }

  return (
    <View>
      <Animated.View
        entering={FadeIn}
        exiting={FadeOut}
        style={{position: 'absolute', left: 0, right: 0, top: spacing.xxs}}>
        <Text size="xxs" numberOfLines={1} id={messageId} {...textProps} />
      </Animated.View>
    </View>
  );
};

const Field: FC<{
  label: TextProps;
  description?: TextProps;
  children: ReactNode;
  onRemove?: () => void;
}> = ({label, description, children, onRemove}) => {
  return (
    <Space fw mb="l">
      <Text size="xs" weight="semibold" {...label} />
      <Space h="xxs" />
      {description && (
        <Space mt="xxs" mb="s">
          <Text size="xs" {...description} />
        </Space>
      )}
      <View
        style={{flexDirection: 'row', alignItems: 'center', gap: spacing.xs}}>
        <Space flex>{children}</Space>
        {onRemove && (
          <IconButton
            onPress={onRemove}
            icon={{name: 'remove', provider: 'custom'}}
          />
        )}
      </View>
    </Space>
  );
};

const Divider = () => <Space h="l" />;

const Header: FC<{header: TextProps; description?: TextProps}> = ({
  header,
  description,
}) => (
  <Space mb="s">
    <Text size="m" weight="semibold" {...header} />
    {description && (
      <Space mt="xs">
        <Text size="xs" {...description} />
      </Space>
    )}
  </Space>
);

Form.Input = FormInput;
Form.RichTextEditor = FormRichTextEditor;
Form.Error = FormError;
Form.Field = Field;
Form.Divider = Divider;
Form.Header = Header;
