import {useNavigation} from '@react-navigation/native';
import React, {useCallback} from 'react';

import {FollowableArtistCard} from '@/components/ArtistCard';
import Button from '@/components/Button';
import {InfinityListEmpty} from '@/components/InfinityList';
import InfinityList from '@/components/InfinityList';
import ScreenLoader from '@/components/ScreenLoader';
import Space from '@/components/Space/Space';
import SpinningList from '@/components/SpinningList';
import Text from '@/components/Text/Text';
import TrackCard, {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import {OnScroll} from '@/hooks/useAnimatedHeader';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch} from '@/hooks/useRedux';
import {useLatestArtistsQuery} from '@/queries/artists';
import {useReleasesForYouQuery} from '@/queries/releasesForYou';
import {playNewQueue} from '@/store/player';
import {useTheme, useThemedStyles} from '@/theme';
import {ITrack, PlayContextType} from '@/types/common';
import {ExploreStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './ReleasesForYou.styles';

interface IReleasesForYouProps {
  userId: string;
  titleId?: string;
  descriptionId?: string;
  emptyStateTextId?: string;
  showSuggestedFollows?: boolean;
  onScroll: OnScroll;
}

const ReleasesForYou: React.FC<IReleasesForYouProps> = ({
  userId,
  titleId = 'releasesForYou.title',
  descriptionId = 'releasesForYou.description',
  emptyStateTextId = 'releasesForYou.empty',
  showSuggestedFollows = true,
  onScroll,
}) => {
  const dispatch = useAppDispatch();

  const style = useThemedStyles(styles);

  const {
    releasesForYouTracks: tracks,
    query: {
      isLoading,
      isError,
      isFetching,
      refetch,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
    },
  } = useReleasesForYouQuery(userId);
  const {isTrackActive} = useIsTrackActive(PlayContextType.releasesForYou);

  const onPlay = useCallback(
    (track: ITrack, index?: number) => {
      if (!tracks) {
        return;
      }
      dispatch(
        playNewQueue({
          trackIds: tracks.map(t => t.id),
          index,
          context: {
            source: 'ReleasesForYou',
            type: PlayContextType.releasesForYou,
            titleId,
          },
        }),
      );
    },
    [tracks],
  );

  if (tracks?.length === 0) {
    return (
      <EmptyState
        textId={emptyStateTextId}
        showSuggestedFollows={showSuggestedFollows}
        done={refetch}
        isFetching={isFetching}
      />
    );
  }

  if (!tracks || isLoading) {
    return <ScreenLoader />;
  }

  return (
    <InfinityList
      data={tracks}
      itemSize={TRACK_CARD_HEIGHT}
      keyExtractor={item => item.id}
      extraData={isTrackActive}
      renderItem={({item, index}) => (
        <TrackCard
          track={item}
          isActive={isTrackActive(item.id, index)}
          index={index}
          showArtist
          onPlay={onPlay}
        />
      )}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage || isLoading}
      ListEmptyComponent={
        isError ? <InfinityListEmpty isError={isError} /> : null
      }
      ListHeaderComponent={
        <Space mh="m" mb="s">
          <Text id={descriptionId} style={style.textCentered} />
        </Space>
      }
      contentContainerStyle={style.scrollContent}
      onScroll={onScroll}
      refresh={refetch}
    />
  );
};

interface IEmptyStateProps {
  textId: string;
  showSuggestedFollows: boolean;
  done: () => void;
  isFetching?: boolean;
}

const EmptyState: React.FC<IEmptyStateProps> = ({
  textId,
  showSuggestedFollows,
  done,
  isFetching = false,
}) => {
  const theme = useTheme();
  const style = useThemedStyles(styles);

  const exploreNavigation = useNavigation<ExploreStackNavigationParams>();

  const {artists} = useLatestArtistsQuery();
  const HIGHLIGHTS_COUNT = 15;
  const CAROUSEL_ITEMS_COUNT = 5;

  return (
    <Space>
      <Space m="m">
        <Text id={textId} />
      </Space>

      {showSuggestedFollows && (
        <>
          <SpinningList
            pagePadding="m"
            title={
              <Text
                weight="semibold"
                size="s"
                id="explore.latestArtists"
                uppercase={theme.text.sectionHeaderUppercase}
              />
            }
            items={artists.slice(0, HIGHLIGHTS_COUNT)}
            keyExtractor={artist => artist.id}
            renderItem={artist => <FollowableArtistCard artist={artist} />}
            itemsPerPage={CAROUSEL_ITEMS_COUNT}
            moreButton
            moreNavigation={() => exploreNavigation.navigate(Routes.Artists)}
          />

          <Space m="m" mt="l" style={style.actions}>
            <Button
              onPress={done}
              textId="releasesForYou.done"
              style={style.actionButton}
              isLoading={isFetching}
            />
          </Space>
        </>
      )}
    </Space>
  );
};

export default ReleasesForYou;
