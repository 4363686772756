import React from 'react';
import {View} from 'react-native';

import BaseFeedCard from '../FeedCard/BaseFeedCard';
import {styles} from '../FeedCard/common.styles';
import Icon from '@/components/Icon';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {useAppDispatch} from '@/hooks/useRedux';
import {setOnboardingInfo} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {analytics} from '@/utils/analytics';

import OnboardingActions from './OnboardingActions';

const FeedWelcomeCard: React.FC = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(
      setOnboardingInfo({key: 'showFeedOnboardingFeedWelcome', value: false}),
    );
    analytics.feedOnboardingCompleted('feedWelcome');
  };

  return (
    <BaseFeedCard>
      <View style={style.imageContainer}>
        <Space p="s" style={style.featureContainer}>
          <Text
            id="feed.onboardingFeedWelcome.title"
            size="l"
            weight="bold"
            style={style.centeredText}
          />
          <Space mb="s" />
          <View style={style.bulletContainer}>
            <Icon
              name="bellHeart"
              provider="custom"
              style={style.bulletIcon}
              size={26}
            />
            <Text
              id="feed.onboardingFeedWelcome.newTracks"
              style={style.bulletText}
            />
          </View>

          <View style={style.bulletContainer}>
            <Icon
              name="group"
              provider="custom"
              style={style.bulletIcon}
              size={26}
            />
            <Text
              id="feed.onboardingFeedWelcome.discoverArtists"
              style={style.bulletText}
            />
          </View>
          <View style={style.bulletContainer}>
            <Icon
              name="personSearch"
              provider="custom"
              style={style.bulletIcon}
              size={26}
            />
            <Text
              id="feed.onboardingFeedWelcome.spy"
              style={style.bulletText}
            />
          </View>
        </Space>
      </View>

      <OnboardingActions onClose={onClose} />
    </BaseFeedCard>
  );
};

export default FeedWelcomeCard;
