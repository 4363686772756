import React, {useMemo} from 'react';
import {FlatList, FlatListProps} from 'react-native';
import Animated from 'react-native-reanimated';

import Space from '@/components/Space/Space';
import {LIST_SETTINGS} from '@/constants/listsSettings';
import spacing from '@/constants/spacing';
import {createItemLayoutGetter} from '@/utils/layout';

import {IInfinityListLoaderProps, IInfinityListLayoutProps} from './types';
import {useInfinityList} from './useInfinityList';

export interface IInfinityListProps<ListItemType>
  extends FlatListProps<ListItemType>,
    IInfinityListLoaderProps,
    IInfinityListLayoutProps {}

const AnimatedFlatList = Animated.createAnimatedComponent(
  FlatList,
) as typeof FlatList;

const Separator = () => <Space mt="xs" />;

function InfinityListInner<ListItemType>(
  {
    itemSize,
    itemSpacing = spacing.xs,
    headerHeight,
    fetchNextPage,
    refresh,
    isFetchingNextPage = false,
    hasNextPage = true,
    ...flatListProps
  }: IInfinityListProps<ListItemType>,
  ref: React.ForwardedRef<FlatList>,
) {
  const {onEndReached, FooterLoader, refreshControl} = useInfinityList({
    fetchNextPage,
    isFetchingNextPage,
    refresh,
    hasNextPage,
  });

  const getItemLayout = useMemo(
    () =>
      createItemLayoutGetter({
        itemHeight: itemSize,
        itemSpacing,
        headerHeight,
      }),
    [itemSize, itemSpacing, headerHeight],
  );

  return (
    <AnimatedFlatList
      ref={ref}
      onEndReached={fetchNextPage && onEndReached}
      ListFooterComponent={FooterLoader}
      contentContainerStyle={{
        flexGrow: 1,
        padding: spacing.s,
        paddingTop: spacing.xs,
      }}
      ItemSeparatorComponent={Separator}
      showsVerticalScrollIndicator={false}
      onEndReachedThreshold={1}
      refreshControl={refreshControl}
      windowSize={LIST_SETTINGS.windowSize}
      getItemLayout={getItemLayout}
      {...flatListProps}
    />
  );
}

const InfinityList = React.forwardRef(InfinityListInner) as <ListItemType>(
  props: IInfinityListProps<ListItemType> & {
    ref?: React.ForwardedRef<FlatList>;
  },
) => ReturnType<typeof InfinityListInner>;

export default InfinityList;
