import {NativeScrollEvent, NativeSyntheticEvent} from 'react-native';
import {Gesture} from 'react-native-gesture-handler';
import {
  useAnimatedRef,
  useSharedValue,
  scrollTo,
} from 'react-native-reanimated';

import {ISwipeHandlerInput} from '@/components/Slider/types';

export const useSwipeHandler = ({itemWidth, gap}: ISwipeHandlerInput) => {
  const scrollViewRef = useAnimatedRef();
  const scrollPosition = useSharedValue(0);
  const scrollPositionSnapshot = useSharedValue(0);
  const swipeDirection = useSharedValue<-1 | 1>(1);

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) =>
    (scrollPosition.value = event.nativeEvent.contentOffset.x);

  const swipeGesture = Gesture.Pan()
    .onStart(() => {
      scrollPositionSnapshot.value = scrollPosition.value;
    })
    .onUpdate(e => {
      const newScrollPosition = scrollPositionSnapshot.value - e.translationX;
      swipeDirection.value = newScrollPosition > scrollPosition.value ? 1 : -1;

      scrollTo(scrollViewRef, newScrollPosition, 0, false);
    })
    .onEnd(() => {
      const round = swipeDirection.value > 0 ? Math.floor : Math.ceil;
      const page =
        round(scrollPosition.value / itemWidth.value) + swipeDirection.value;

      scrollTo(scrollViewRef, page * (itemWidth.value + gap), 0, true);
    });

  return {
    scrollViewRef,
    swipeGesture,
    onScroll,
  };
};
