import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {useStore} from 'react-redux';

import * as playlistApi from '@/api/playlist';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {
  addPlaylist,
  changePlaylistId,
  removeFromDeletedPlaylists,
  removePlaylist,
  selectDeletedPlaylistById,
  selectPlaylistById,
  setPlaylists,
  updatePlaylist,
  updatePlaylistUpdatedClientTime,
  updatePlaylistUpdatedServerTime,
} from '@/store/playlists';
import {
  selectActiveUserId,
  selectSessionsList,
  selectSignerByUserId,
} from '@/store/user';
import {
  IForkPlaylistRequestPayload,
  IPlaylist,
  IPlaylistFormSubmitData,
  IPublishPlaylistRequestPayload,
  IUpdatePlaylistData,
  PlaylistType,
} from '@/types/playlists';
import {QueryKeys} from '@/types/queryKeys';
import {omit} from '@/utils/functions';
import {
  generatePlaylistId,
  getPlaylistFromError,
  isLocalPlaylistId,
  toggleTrackAndHandleSuggestion,
} from '@/utils/playlists';
import {getSignerFromSessionKey} from '@/utils/signer';
import { getArtistUserFollowId } from '@/types/follows';
import { addArtistUserFollow, deleteArtistUserFollow } from '@/api/follows';

const notInitializedError = new Error('user is not initialized');

export const useUsersPlaylistsQuery = (enabled: boolean) => {
  const sessions = useAppSelector(selectSessionsList);
  const dispatch = useAppDispatch();

  useQuery(
    [QueryKeys.userPlaylists],
    async () => {
      const playlists = await playlistApi.fetchPlaylistsForSigners(
        sessions.map(session => getSignerFromSessionKey(session.sessionKey)),
      );
      dispatch(setPlaylists({playlists}));
      return null;
    },
    {
      enabled: enabled && sessions.length > 0,
      cacheTime: 0,
      staleTime: 1000 * 30,
      refetchOnWindowFocus: true,
    },
  );
};

// Hook for saving local playlist to the server. It gets local playlist id as input.
// Once device is online, it reads current state of local playlist from redux, saves it to the server, and replaces local playlist id with the server one.
// It assumes that playlist is already created in redux, so it shouldn't be use directly (except of useSyncPlaylist hook, which saves unsynced changes on app start).
// Instead, we should use useCreatePlaylist hook which wraps it and creates playlist locally before it tries to send it to server.
export const useCreatePlaylistMutation = () => {
  const {getState} = useStore();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: ({id}: {id: string}) => {
      const playlist = selectPlaylistById(getState(), id);

      if (!playlist.collector) {
        return Promise.reject(notInitializedError);
      }
      const signer = selectSignerByUserId(getState(), playlist.collector)!;

      if (playlist.type === PlaylistType.artist && playlist.artistId && playlist.collector && signer) {
        addArtistUserFollow({ artistId: playlist.artistId, userId: playlist.collector }, signer);
      }

      return playlistApi.publishPlaylist(
        playlist,
        signer,
      );
    },
    onSuccess: async (data, variables) => {
      dispatch(
        changePlaylistId({
          oldId: variables.id,
          newId: data.id,
        }),
      );
      dispatch(
        updatePlaylistUpdatedServerTime({
          id: data.id,
        }),
      );
    },
    onError: async (error: {status: number}, variables) => {
      const playlist = selectPlaylistById(getState(), variables.id);

      if (
        error.status === 409 &&
        playlist?.type === PlaylistType.favorites &&
        playlist?.id
      ) {
        dispatch(removePlaylist({id: playlist.id, isLocalOnly: true}));
      }
    },
  });
};

export const useCreatePlaylist = () => {
  const dispatch = useAppDispatch();
  const {getState} = useStore();
  const {mutate: createPlaylistMutation, ...mutation} =
    useCreatePlaylistMutation();

  const dispatchAddPlaylist = (
    newPlaylistData:
      | IPublishPlaylistRequestPayload
      | IForkPlaylistRequestPayload,
  ) => {
    const id = generatePlaylistId();
    dispatch(
      addPlaylist({
        id,
        ...newPlaylistData,
        collector: newPlaylistData.collector || selectActiveUserId(getState()),
        updatedAtTime: Date.now(),
      }),
    );

    return id;
  };

  const createPlaylist = (
    newPlaylistData:
      | IPublishPlaylistRequestPayload
      | IForkPlaylistRequestPayload,
  ) => {
    const id = dispatchAddPlaylist(newPlaylistData);
    createPlaylistMutation({id});
  };

  const createPlaylistAsync = async (
    newPlaylistData:
      | IPublishPlaylistRequestPayload
      | IForkPlaylistRequestPayload,
  ) => {
    const id = dispatchAddPlaylist(newPlaylistData);
    return mutation.mutateAsync({id});
  };

  return {
    ...mutation,
    createPlaylistMutation: createPlaylist,
    createPlaylistAsyncMutation: createPlaylistAsync,
  };
};

export const useUpdatePlaylist = () => {
  const dispatch = useAppDispatch();
  const {getState} = useStore();

  const mutation = useMutation({
    onMutate: ({id, updatedPlaylist}: IUpdatePlaylistData) => {
      dispatch(
        updatePlaylist({
          id,
          updatedPlaylist,
        }),
      );
      dispatch(updatePlaylistUpdatedClientTime({id}));
    },
    mutationFn: async ({id}: IUpdatePlaylistData) => {
      if (isLocalPlaylistId(id)) {
        // Skip api call if playlist is not saved to the server yet.
        // It's updated locally in onMutate callback above and will be saved to the server properly once app is back online and useCreatePlaylist mutation is called.
        return Promise.resolve();
      }

      const playlist = selectPlaylistById(getState(), id);

      if (!playlist.collector) {
        return Promise.reject(notInitializedError);
      }

      await playlistApi.updatePlaylist(
        id,
        playlist,
        selectSignerByUserId(getState(), playlist.collector)!,
      );
    },
    onSuccess: (data, variables) => {
      if (!isLocalPlaylistId(variables.id)) {
        dispatch(updatePlaylistUpdatedServerTime({id: variables.id}));
      }
    },
    onError: (error: any) => {
      const playlist = getPlaylistFromError(error);

      if (playlist) {
        dispatch(
          updatePlaylist({
            id: playlist.id,
            updatedPlaylist: playlist,
          }),
        );
        dispatch(updatePlaylistUpdatedServerTime({id: playlist.id}));
      }
    },
  });

  return {
    ...mutation,
    updatePlaylistMutation: mutation.mutate,
  };
};

// Hook for sending unsaved changes in playlist to server on app start.
// It shouldn't be used directly for updating playlists. Use useUpdatePlaylist hook instead.
export const useSyncUnsavedPlaylist = () => {
  const {getState} = useStore();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: (playlist: IPlaylist) =>
      playlistApi.updatePlaylist(
        playlist.id,
        playlist,
        selectSignerByUserId(getState(), playlist.collector)!,
      ),
    onSuccess: data => {
      dispatch(updatePlaylistUpdatedServerTime({id: data.id}));
    },
    onError: (error: any) => {
      const playlist = getPlaylistFromError(error);

      if (playlist) {
        dispatch(
          updatePlaylist({
            id: playlist.id,
            updatedPlaylist: playlist,
          }),
        );
        dispatch(updatePlaylistUpdatedServerTime({id: playlist.id}));
      }
    },
  });
};

export const useUpdatePlaylistTracks = () => {
  const dispatch = useAppDispatch();
  const {updatePlaylistMutation, ...mutation} = useUpdatePlaylist();

  return {
    updatePlaylistTracksMutation: ({
      playlistId,
      trackIds,
    }: {
      playlistId: string;
      trackIds: string[];
    }) => {
      dispatch(updatePlaylist({id: playlistId, updatedPlaylist: {trackIds}}));

      return updatePlaylistMutation({
        id: playlistId,
        updatedPlaylist: {trackIds},
      });
    },
    ...mutation,
  };
};

export const useToggleTrackInPlaylist = () => {
  const {getState} = useStore();
  const {updatePlaylistMutation, ...mutation} = useUpdatePlaylist();

  const toggleTrack = (playlistId: string, trackId: string) => {
    const playlist = selectPlaylistById(getState(), playlistId);

    if (!playlist) {
      return;
    }

    const {trackIds, contributions, suggestions} =
      toggleTrackAndHandleSuggestion(playlist, trackId, playlist.collector);

    return {trackIds, contributions, suggestions};
  };

  const toggleTrackInPlaylistMutation = ({
    playlistId,
    trackId,
  }: {
    playlistId: string;
    trackId: string;
  }) => {
    const updatedPlaylist = toggleTrack(playlistId, trackId);
    if (!updatedPlaylist) {
      return;
    }

    return updatePlaylistMutation({
      id: playlistId,
      updatedPlaylist,
    });
  };

  const toggleTrackInPlaylistAsyncMutation = ({
    playlistId,
    trackId,
  }: {
    playlistId: string;
    trackId: string;
  }) => {
    const updatedPlaylist = toggleTrack(playlistId, trackId);
    if (!updatedPlaylist) {
      return;
    }
    return mutation.mutateAsync({
      id: playlistId,
      updatedPlaylist,
    });
  };

  return {
    toggleTrackInPlaylistMutation,
    toggleTrackInPlaylistAsyncMutation,
    ...mutation,
  };
};

export const useUpdatePlaylistSuggestions = () => {
  const {getState} = useStore();
  const {updatePlaylistMutation, ...mutation} = useUpdatePlaylist();

  return {
    updateSuggestion: ({
      playlistId,
      trackId,
      accept,
    }: {
      playlistId: string;
      trackId: string;
      accept: boolean;
    }) => {
      const playlist = selectPlaylistById(getState(), playlistId);
      const suggestionInfo = playlist.suggestions?.[trackId];

      if (!suggestionInfo) {
        return;
      }

      if (accept) {
        const {trackIds, contributions, suggestions} =
          toggleTrackAndHandleSuggestion(playlist, trackId);

        updatePlaylistMutation({
          id: playlistId,
          updatedPlaylist: {
            trackIds,
            suggestions,
            contributions,
          },
        });
      } else {
        updatePlaylistMutation({
          id: playlistId,
          updatedPlaylist: {
            suggestions: omit(playlist.suggestions!, trackId),
          },
        });
      }
    },
    ...mutation,
  };
};

// Hook for sending info about removed playlists to the server.
// It shouldn't be use directly (except useSyncPlaylist). Use useDeletePlaylist instead.
export const useDeletePlaylistMutation = () => {
  const dispatch = useAppDispatch();
  const {getState} = useStore();

  return useMutation({
    mutationFn: (id: string): Promise<any> => {
      const deletedPlaylist = selectDeletedPlaylistById(getState(), id);

      if (!deletedPlaylist) {
        return Promise.resolve();
      }

      const signer = selectSignerByUserId(getState(), deletedPlaylist.collector)!;

      if (deletedPlaylist.type === PlaylistType.artist && deletedPlaylist.artistId && signer) {
        const id = getArtistUserFollowId({ userId: deletedPlaylist.collector, artistId: deletedPlaylist.artistId });
        deleteArtistUserFollow(id, signer);
      }

      return playlistApi.deletePlaylist(
        id,
        signer,
      );
    },
    onSuccess: (data, id) => {
      dispatch(removeFromDeletedPlaylists({id}));
    },
    onError: (error: AxiosResponse, id) => {
      // consider 404s as deleted already and clean up local state
      if (error.status === 404) {
        dispatch(removeFromDeletedPlaylists({id}));
      }
    },
  });
};

export const useDeletePlaylist = () => {
  const dispatch = useAppDispatch();
  const mutation = useDeletePlaylistMutation();

  return {
    ...mutation,
    deletePlaylistMutation: (id: string) => {
      dispatch(removePlaylist({id, isLocalOnly: isLocalPlaylistId(id)}));
      mutation.mutate(id);
    },
    deletePlaylistAsyncMutation: (id: string) => {
      dispatch(removePlaylist({id, isLocalOnly: isLocalPlaylistId(id)}));
      return mutation.mutateAsync(id);
    },
  };
};

export const useForkPlaylist = () => {
  const {createPlaylistMutation} = useCreatePlaylist();

  const fork = ({
    basePlaylist,
    title,
    description,
    collector,
  }: IPlaylistFormSubmitData & {
    basePlaylist: IPlaylist;
  }) => {
    const trackIds: string[] = basePlaylist.trackIds || [];

    return createPlaylistMutation({
      title,
      description,
      collector: collector!,
      trackIds,
      type: PlaylistType.custom,
      parentPlaylistId: basePlaylist.id,
    });
  };

  return {
    fork,
  };
};
