import {Dimensions, Platform, StyleSheet} from 'react-native';

import spacing, {DESKTOP_LAYOUT_SIZE} from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const {height} = Dimensions.get('window');

export const styles = createThemedStyles(theme => ({
  wrapper: {
    flex: 1,
    paddingHorizontal: spacing.s,
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: theme.colors.backdrop,
    ...StyleSheet.absoluteFillObject,
  },
  modal: {
    zIndex: 1,
    maxHeight: Platform.select({
      web: 0.8 * height,
      default: 0.6 * height,
    }),
    width: '100%',
    overflow: 'hidden',
    alignSelf: 'center',
  },
  modalSmallDesktop: {
    width: '66%',
  },
  modalDesktop: {
    maxWidth: DESKTOP_LAYOUT_SIZE.maxModalWidth,
    maxHeight: DESKTOP_LAYOUT_SIZE.maxModalHeight * 0.8,
  },
  card: {
    maxHeight: '100%',
    padding: 0,
    backgroundColor: theme.colors.backgroundLight,
    overflow: 'hidden',
  },
  header: {
    backgroundColor: theme.colors.primaryDark,
    paddingVertical: spacing.xs,
    paddingHorizontal: spacing.l + spacing.xs * 2,
  },
  closeIcon: {
    position: 'absolute',
    left: spacing.xs,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
