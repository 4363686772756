import {useIntl} from 'react-intl';

import {useToast} from '@/modules/Toasts';
import {Clipboard} from '@/services/clipboard';
import {IShareProperties} from '@/types/common';

export const useShare = () => {
  const {formatMessage} = useIntl();
  const {showToast} = useToast();

  const share = async ({
    title,
    titleId,
    message,
    messageId,
    url,
  }: IShareProperties) => {
    try {
      const formattedTitle = titleId ? formatMessage({id: titleId}) : title;
      const formattedMessage = messageId
        ? formatMessage({id: messageId})
        : message;

      await navigator?.share?.({
        title: formattedTitle,
        text: formattedMessage,
        url: url,
      });
    } catch (error) {}
  };

  const copy = async ({url}: IShareProperties) => {
    try {
      await Clipboard.copy(url);
      showToast({textId: 'share.copied'});
    } catch (error) {}
  };

  const canShare = !!navigator?.share;

  return {share: canShare ? share : copy, canShare};
};
