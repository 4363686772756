import axios from 'axios';

import {IPurchaseParams, IRampPurchase, ITopUpParams} from '../types';
import {chainsById} from '@/constants/chains';
import {config} from '@/constants/config';
import {ITopUpTransaction} from '@/store/topUp';

export const rampApi = axios.create({
  baseURL: 'https://api.ramp.network/api/host-api',
});

rampApi.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error.response),
);

export const getSharedRampParams = ({
  address,
  chainId,
  amount,
  userId,
  email,
}: ITopUpParams) => {
  const chainConfig = chainsById[chainId];

  if (!chainConfig.rampSymbol) {
    throw new Error('Top Up Error - chain not supported');
  }

  return {
    // Hardcode api key and url to make it work with OTA update
    hostApiKey: 'mvohcjhzsmvkdtysb2wc64zcbw5sbkymctvfdxmg',
    // hostApiKey: config.RAMP_API_KEY,
    hostAppName: 'Spinamp',
    hostLogoUrl: 'https://app.spinamp.xyz/spin-logo.png',
    enabledFlows: ['ONRAMP'],
    defaultFlow: 'ONRAMP',
    userAddress: address,
    swapAsset: chainConfig.rampSymbol,
    swapAmount: amount?.toString(),
    webhookStatusUrl: `${config.SPINAMP_BACKEND_URL}/ramp/webhook?userId=${userId}`,
    userEmailAddress: email,
  };
};

export const fetchTopUpStatus = ({
  id,
  token,
}: IPurchaseParams): Promise<IRampPurchase> =>
  rampApi.get(`purchase/${id}`, {params: {secret: token}});

export const getTopUpTxUrl = (transaction: ITopUpTransaction) =>
  // `${config.RAMP_TX_URL}/${transaction.id}?secret=${transaction.token}`;
  `https://transactions.ramp.network/#/details/${transaction.id}?secret=${transaction.token}`;
