import React from 'react';
import {View} from 'react-native';

import BaseFeedCard from '../FeedCard/BaseFeedCard';
import {styles} from '../FeedCard/common.styles';
import FeedCardActions from '../FeedCard/FeedCardActions';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import Loader from '@/components/Loader';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useGenerateFeedMutation} from '@/queries/feed';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';

const NUM_REFILL_ITEMS = 7;

export function useGeneratePopularArtistsFeed(limit?: number) {
  const activeUserId = useAppSelector(selectActiveUserId);

  const toast = useToast();

  const {generateFeed, mutation} = useGenerateFeedMutation(
    activeUserId,
    undefined,
    undefined,
    limit,
  );

  const generatePopularArtists = () =>
    generateFeed([{name: 'popular-artists', args: []}], {
      onError: (_error, _variables, _options) => {
        toast.showToast({
          textId: 'feed.popularArtistsRefill.error',
        });
      },
    });

  return {generatePopularArtists, mutation};
}

const PopularArtistIntroCard: React.FC = () => {
  const style = useThemedStyles(styles);

  const {mutation, generatePopularArtists} =
    useGeneratePopularArtistsFeed(NUM_REFILL_ITEMS);

  return (
    <BaseFeedCard>
      <View style={style.imageContainer}>
        <Space p="s" style={style.featureContainer}>
          <Icon
            name="groupHeart"
            provider="custom"
            style={style.featureIcon}
            size={48}
          />
          <Space pb="m" />
          <Text
            id="feed.onboardingPopularArtists.title"
            weight="bold"
            style={style.centeredText}
          />
          <Space mb="xxs" />
          <Text
            id="feed.onboardingPopularArtists.body"
            style={style.centeredText}
          />
        </Space>
      </View>

      <Space p="xs">
        <Text id="feed.onboarding.continue" size="xs" align="right" />
      </Space>

      <View style={style.infoContainer}>
        <View style={style.actionLabels}>
          <Text id="feed.hide" />
          <Text id="feed.play" />
          <Text id="feed.like" />
        </View>

        <FeedCardActions
          actions={[
            <IconButton
              onPress={() => {}}
              icon={{provider: 'custom', name: 'visibilityOff'}}
              disabled
            />,
            <IconButton
              onPress={() => {}}
              icon={{provider: 'custom', name: 'play'}}
              disabled
            />,
            <>
              {mutation.isLoading ? (
                <Loader size={'xs'} weight="regular" />
              ) : (
                <IconButton
                  icon={{provider: 'custom', name: 'heart'}}
                  onPress={generatePopularArtists}
                  style={{border: 'none', borderWidth: 0}}
                />
              )}
            </>,
          ]}
        />
      </View>
    </BaseFeedCard>
  );
};

export default PopularArtistIntroCard;
