import {useState} from 'react';

import {useCreatePlaylist} from '@/queries/ownedPlaylists';
import {IPlaylistFormSubmitData, PlaylistType} from '@/types/playlists';

export const useNewPlaylistModal = () => {
  const [isNewPlaylistModalOpen, setIsNewPlaylistModalOpen] = useState(false);
  const toggleNewPlaylistModal = () => setIsNewPlaylistModalOpen(v => !v);
  const {createPlaylistMutation} = useCreatePlaylist();

  const submitNewPlaylist = ({
    title,
    description,
    collector,
    mintable,
    chainId,
  }: IPlaylistFormSubmitData) => {
    createPlaylistMutation({
      title,
      description,
      collector,
      trackIds: [],
      type: PlaylistType.custom,
      mintable,
      chainId,
    });
    setIsNewPlaylistModalOpen(false);
  };

  return {
    isNewPlaylistModalOpen,
    toggleNewPlaylistModal,
    submitNewPlaylist,
  };
};
