import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {
  fetchUserWithCollection,
  fetchCollection,
  fetchAddressCollection,
} from '@/api/collection';
import {useDbQuery} from '@/queries/db';
import {ICollectionTrack} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {omit} from '@/utils/functions';
import {flattenItems, getNextPageParam} from '@/utils/pagination';

export const useCollectionQuery = (userId?: string) => {
  const {db, updateDb} = useDbQuery();
  const query = useInfiniteQuery(
    [QueryKeys.collection, userId],
    async ({pageParam}) => {
      const {items: collectionTracks, pageInfo} = await fetchCollection(
        userId!,
        pageParam,
      );
      updateDb({
        tracks: collectionTracks.map(collectionTrack =>
          omit(collectionTrack, 'count'),
        ),
      });

      return {
        pageInfo,
        items: collectionTracks.map(track => ({
          id: track.id,
          count: track.count,
        })),
      };
    },
    {
      enabled: !!userId,
      getNextPageParam,
    },
  );

  const collection: ICollectionTrack[] = useMemo(
    () =>
      flattenItems(query.data).map(({id, count}) => ({
        ...db.tracks[id],
        count,
      })),
    [db.tracks, query.data],
  );

  return {
    collection,
    query,
  };
};

export const useEmbedCollectionQuery = (slug: string) => {
  const query = useQuery([QueryKeys.collectionEmbed, slug], async () =>
    fetchUserWithCollection(slug),
  );

  return {
    user: query?.data?.user,
    collection: query?.data?.tracks || [],
    query,
  };
};

export const useAddressCollectionQuery = (address?: string) => {
  const {db, updateDb} = useDbQuery();
  const query = useInfiniteQuery(
    [QueryKeys.addressCollection, address],
    async ({pageParam}) => {
      const {items: collectionTracks, pageInfo} = await fetchAddressCollection(
        address!,
        pageParam,
      );
      updateDb({
        tracks: collectionTracks.map(collectionTrack =>
          omit(collectionTrack, 'count'),
        ),
      });

      return {
        pageInfo,
        items: collectionTracks.map(track => ({
          id: track.id,
          count: track.count,
        })),
      };
    },
    {
      enabled: !!address,
      getNextPageParam,
    },
  );

  const collection: ICollectionTrack[] = useMemo(
    () =>
      flattenItems(query.data).map(({id, count}) => ({
        ...db.tracks[id],
        count,
      })),
    [db.tracks, query.data],
  );

  return {
    collection,
    query,
  };
};
