import {useAppSelector} from '@/hooks/useRedux';
import {selectMinimizedTransactions} from '@/store/collectTransactions/selectors';

export const useTransactionToasts = () => {
  const transactions = useAppSelector(selectMinimizedTransactions);

  return {
    transactions,
  };
};
