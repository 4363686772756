import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const PAGE_PADDING = 's';
export const SECTION_MARGIN = 'm';

export const styles = createThemedStyles(() => ({
  container: {
    paddingBottom: spacing[SECTION_MARGIN],
  },
  platformCard: {
    minHeight: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
