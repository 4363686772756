import React, {FC} from 'react';
import {View} from 'react-native';

import {useCollectTransaction} from '../useCollectTransaction';
import CardButton from '@/components/CardButton';
import DashedDivider from '@/components/DashedDivider';
import ScrollContainer from '@/components/ScrollContainer';
import ShadowFooter from '@/components/ShadowFooter';
import Text from '@/components/Text';
import CollectWalletsSettings from '@/modules/Collect/components/CollectWalletsSettings';
import EditionPicker from '@/modules/Collect/components/EditionPicker';
import Offer from '@/modules/Collect/components/Offer';
import TrackSummary from '@/modules/Collect/components/TrackSummary';
import {IMintOfferInfo} from '@/modules/Collect/types';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {useLensFollowValidation} from '@/modules/Collect/validation/useLensFollowValidation';
import {usePasskeyOnboarding} from '@/modules/Collect/validation/usePasskeyOnboarding';
import {useLogin} from '@/modules/Login';
import {
  useBalanceValidation,
  useIsWalletConnectedValidation,
  useChainValidation,
  useTransactionValidation,
} from '@/modules/Transactions';
import {useThemedStyles} from '@/theme';
import {isNotNil} from '@/utils/functions';

import {styles} from './Checkout.style';

interface IProps {
  selectedOffer: IMintOfferInfo;
  mintOffers: IMintOfferInfo[];
}

const Checkout: FC<IProps> = ({selectedOffer, mintOffers}) => {
  const style = useThemedStyles(styles);

  const {openLoginModal} = useLogin();
  const {
    track,
    setSelectedOfferId,
    selectedOfferTotalPrice,
    user,
    quantity,
    setQuantity,
    transactionStep,
    paymentWallet,
    balance,
    pushModal,
    collectInfoQuery,
    gasEstimation,
  } = useCollectState();

  const PasskeyOnboarding = usePasskeyOnboarding();
  const LensFollowError = useLensFollowValidation();
  const ConnectedWalletError = useIsWalletConnectedValidation({
    wallet: paymentWallet,
    onChangeWallet: () => pushModal('walletsPicker'),
  });
  const InsufficientBalanceError = useBalanceValidation({
    wallet: paymentWallet,
    balance,
    price: selectedOfferTotalPrice,
    chainId: selectedOffer.chainId,
    onChangeWallet: () => pushModal('walletsPicker'),
  });
  const WrongChainError = useChainValidation({
    wallet: paymentWallet,
    chainId: selectedOffer?.chainId,
  });
  const InvalidTransactionError = useTransactionValidation(gasEstimation);

  const {executeTransaction} = useCollectTransaction(selectedOffer);

  const validationErrors = [
    LensFollowError,
    ConnectedWalletError,
    WrongChainError,
    PasskeyOnboarding,
    InsufficientBalanceError,
    InvalidTransactionError,
  ].filter(isNotNil);

  const isMultiEdition = mintOffers.length > 1;

  return (
    <View style={style.container}>
      <ScrollContainer contentStyle={style.scrollContent}>
        <View>
          {isMultiEdition && (
            <Text
              style={style.editionHeader}
              weight="semibold"
              id="collect.editions.header"
              size="xs"
              align="center"
            />
          )}

          {isMultiEdition ? (
            <EditionPicker
              track={track}
              onSelectOffer={setSelectedOfferId}
              selectedOffer={selectedOffer}
              mintOffers={mintOffers}
              disabled={transactionStep !== 'checkout'}
            />
          ) : (
            <TrackSummary track={track} />
          )}
        </View>

        {user && (
          <>
            <DashedDivider style={style.divider} />
            <CollectWalletsSettings user={user} />
          </>
        )}
      </ScrollContainer>

      <ShadowFooter style={style.actionsContainer}>
        <Offer
          offer={selectedOffer}
          quantity={quantity}
          setQuantity={setQuantity}
          disabled={transactionStep !== 'checkout'}
        />

        {validationErrors[0]?.message}

        {!user ? (
          <CardButton
            onPress={() => {
              openLoginModal();
            }}
            text={{id: 'collect.loginToCollect'}}
            isLoading={transactionStep !== 'checkout'}
            loaderProps={{textId: 'collect.processing'}}
          />
        ) : validationErrors[0] ? (
          validationErrors[0].action
        ) : (
          <CardButton
            disabled={
              collectInfoQuery.isPreviousData ||
              gasEstimation.isFetching ||
              !gasEstimation.totalGas
            }
            onPress={executeTransaction}
            text={{id: 'collect.payAndCollect'}}
            isLoading={transactionStep !== 'checkout'}
            loaderProps={{textId: 'collect.processing'}}
          />
        )}
      </ShadowFooter>
    </View>
  );
};

export default Checkout;
