import {RouteProp, useRoute} from '@react-navigation/native';
import React from 'react';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import Screen from '@/components/Screen/Screen';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {useTrackBySlugQuery, useTrackDetailsQuery} from '@/queries/tracks';
import {ThemedScreen, useThemedScreenStyles} from '@/theme';
import {MainStackParams, Routes} from '@/types/routes';

import {styles} from './Track.style';
import TrackInfo from './TrackInfo';
import TrackScreenContents from './TrackScreenContents';

const TrackScreen = () => {
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const {
    params: {slug, referral, autoplay, redirect},
  } = useRoute<RouteProp<MainStackParams, Routes.Track>>();
  const {
    track,
    query: {isFetching},
  } = useTrackBySlugQuery(slug);
  const {
    trackDetails,
    query: {isInitialLoading: areDetailsLoading},
  } = useTrackDetailsQuery(track?.id);

  const {artistThemes} = useCustomThemesConfigQuery();

  const {theme} = useThemedScreenStyles(styles, {
    customTheme: track?.artist.customTheme,
    predefinedThemeName:
      track?.artist &&
      (track.artist.predefinedThemeName || artistThemes[track.artist.id]),
  });

  return (
    <ThemedScreen theme={theme}>
      <Screen>
        <Header title={track?.title} showBack scrollPosition={scrollPosition} />
        <AsyncContent
          isLoading={isFetching && !track}
          isError={!track}
          data={track}
          renderContent={loadedTrack => (
            <TrackScreenContents
              refreshEnabled
              track={loadedTrack}
              areDetailsLoading={areDetailsLoading}
              trackInfo={
                <TrackInfo
                  linkEnabled={false}
                  key={loadedTrack.id}
                  track={loadedTrack}
                  showLoader={areDetailsLoading}
                  description={trackDetails?.description}
                  referral={referral}
                  autoPlay={!!autoplay}
                  redirect={redirect}
                />
              }
              onScroll={onScroll}
            />
          )}
        />
      </Screen>
    </ThemedScreen>
  );
};

export default TrackScreen;
