import React, {FC} from 'react';
import {TouchableOpacity} from 'react-native';
import Animated, {useAnimatedStyle, withTiming} from 'react-native-reanimated';

import Text, {TextProps} from '@/components/Text/Text';
import {usePlatformsQuery} from '@/queries/platforms';
import {MusicPlatform} from '@/types/common';
import {Routes} from '@/types/routes';
import {analytics} from '@/utils/analytics';
import {openLinkSafe} from '@/utils/linking';
import {getExternalReferralUrl} from '@/utils/referrals';

interface IProps {
  platformId: MusicPlatform;
  url?: string | null;
  referral?: string;
  analyticsInfo: {
    screen: Routes;
    id: string;
  };
}

const ProviderLink: FC<IProps & TextProps> = ({
  platformId,
  url,
  analyticsInfo,
  referral,
  ...textProps
}) => {
  const {
    platformNames,
    query: {isLoading},
  } = usePlatformsQuery();

  const animatedStyle = useAnimatedStyle(
    () => ({
      opacity: withTiming(Number(!isLoading), {
        duration: 200,
      }),
    }),
    [isLoading],
  );

  if (!url) {
    return null;
  }

  const openUrl = async () => {
    analytics.platformLinkPressed(analyticsInfo.screen, analyticsInfo.id);
    openLinkSafe(getExternalReferralUrl(url, platformId, referral));
  };

  return (
    <Animated.View style={animatedStyle}>
      <TouchableOpacity onPress={openUrl} disabled={isLoading}>
        <Text {...textProps} numberOfLines={1}>
          <Text id="provider.openUrl" {...textProps} />
          <Text {...textProps}>{platformNames[platformId] || platformId}</Text>
        </Text>
      </TouchableOpacity>
    </Animated.View>
  );
};

export default ProviderLink;
