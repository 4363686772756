import {spinampServicesApi} from '@/services/spinampServicesApi';

type IOperation = 'upsert' | 'update' | 'delete';

export const submitEntityFieldUpdate = async <T>(
  entity: string,
  update: T,
  signMessage: (message: string) => Promise<string>,
  operation: IOperation = 'update',
) => {
  const instruction = {
    entity: entity,
    operation,
    data: update,
  };
  const signature = await signMessage(JSON.stringify(instruction));

  return spinampServicesApi.post('messages', instruction, {
    headers: {
      'x-signature': signature,
    },
  });
};
