import API from '@/services/spinampApi';
import {formatFirebaseId} from '@/utils/api';

interface ITrendingTrack {
  trackId: string;
}

export const fetchTrendingTracks = async (): Promise<ITrendingTrack[]> => {
  const response: ITrendingTrack[] = await API.get('trending');
  return response.map(({trackId}) => ({trackId: formatFirebaseId(trackId)}));
};
