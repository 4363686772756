import {gql} from 'graphql-request';

import {fetchUserBySlug} from '@/api/user';
import {getChainByCode} from '@/constants/chains';
import {pipelineApi} from '@/services/pipelineApi';
import {IPageInfo, IPaginatedResponse} from '@/types/api';
import {ICollectionTrack} from '@/types/common';
import {parseApiTrack, TRACK_FRAGMENT} from '@/utils/apiModelParsers';

export const fetchCollection = async (
  userId: string,
  after?: string,
): Promise<IPaginatedResponse<ICollectionTrack>> => {
  const response = await pipelineApi.request(
    gql`
      query UserCollection($userId: String, $after: Cursor) {
        userCollection(_userid: $userId, after: $after, first: 100) {
          nodes {
            processedTrackByProcessedTrackId {
              ...TrackDetails
            }
            count
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
      ${TRACK_FRAGMENT}
    `,
    {
      userId,
      after,
    },
  );

  const tracks: ICollectionTrack[] = response.userCollection.nodes
    .filter((node: any) => !!node.processedTrackByProcessedTrackId)
    .map((node: any) => ({
      ...parseApiTrack(node.processedTrackByProcessedTrackId),
      count: node.count,
    }));
  const pageInfo: IPageInfo = response.userCollection.pageInfo;

  return {
    pageInfo,
    items: tracks,
  };
};

export const fetchUserWithCollection = async (slug: string) => {
  const user = await fetchUserBySlug(slug);

  if (!user) {
    return Promise.reject(new Error('Not found'));
  }

  const tracksPage = await fetchCollection(user.id);

  return {
    user,
    tracks: tracksPage.items,
  };
};

export const fetchAddressCollection = async (
  address: string,
  after?: string,
): Promise<IPaginatedResponse<ICollectionTrack>> => {
  const response = await pipelineApi.request(
    gql`
      query AddressCollection($address: String, $after: Cursor) {
        addressCollection(useraddress: $address, after: $after, first: 100) {
          nodes {
            processedTrackByProcessedTrackId {
              ...TrackDetails
            }
            count
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
      ${TRACK_FRAGMENT}
    `,
    {
      address,
      after,
    },
  );

  const tracks: ICollectionTrack[] = response.addressCollection.nodes
    .filter((node: any) => !!node.processedTrackByProcessedTrackId)
    .map((node: any) => ({
      ...parseApiTrack(node.processedTrackByProcessedTrackId),
      count: node.count,
    }));
  const pageInfo: IPageInfo = response.addressCollection.pageInfo;

  return {
    pageInfo,
    items: tracks,
  };
};

export const fetchNftInfoForUserTrack = async (
  trackId: string,
  addresses: string[],
) => {
  const response = await pipelineApi.request(
    gql`
      query NftInfoForTrack($trackId: String!) {
        processedTrackById(id: $trackId) {
          nftsProcessedTracksByProcessedTrackId {
            nodes {
              nftByNftId {
                chainId
                nftFactoryByNftFactoryId {
                  standard
                  address
                  id
                }
              }
            }
          }
        }
      }
    `,
    {
      trackId,
    },
  );

  const nftInfo =
    response.processedTrackById.nftsProcessedTracksByProcessedTrackId.nodes[0]
      .nftByNftId;
  const chainId = getChainByCode(nftInfo.chainId).id;
  const tokenType: string = nftInfo.nftFactoryByNftFactoryId.standard;
  const contract: string = nftInfo.nftFactoryByNftFactoryId.address;
  const nftFactoryId: string = nftInfo.nftFactoryByNftFactoryId.id;

  const tokensResponse = await pipelineApi.request(
    gql`
      query Tokens($nftFactoryId: String, $addresses: [String!]) {
        allNfts(
          filter: {
            nftsCollectorsByNftId: {
              some: {addressId: {in: $addresses}, amount: {equalTo: 1}}
            }
            nftFactoryId: {equalTo: $nftFactoryId}
          }
        ) {
          nodes {
            tokenId
            nftsCollectorsByNftId(first: 1, filter: {amount: {equalTo: 1}}) {
              nodes {
                addressId
              }
            }
          }
        }
      }
    `,
    {
      nftFactoryId,
      addresses,
    },
  );

  const tokenInfo = tokensResponse.allNfts.nodes[0];

  if (!tokenInfo) {
    throw new Error('No token matching provided criteria');
  }

  const tokenId: string = tokenInfo.tokenId;
  const ownerAddress: string =
    tokenInfo.nftsCollectorsByNftId.nodes[0].addressId;

  return {
    contract,
    tokenId,
    tokenType,
    chainId,
    ownerAddress,
  };
};
