import {useNavigation} from '@react-navigation/native';
import {createContext, FC, ReactNode} from 'react';
import {encodeFunctionData} from 'viem';
import {useBalance} from 'wagmi';

import {useUserById} from '@/hooks/useActiveUser';
import {useAppDispatch} from '@/hooks/useRedux';
import {useGasEstimation} from '@/modules/Transactions';
import {IGasEstimation} from '@/modules/Transactions/types';
import {ITransfer, ITransferParams, TransferModal} from '@/modules/Transfer';
import {TransferStep} from '@/modules/Transfer/types';
import {useSyncTransferState} from '@/modules/Transfer/useSyncTransferState';
import {getTransferWriteContractInput} from '@/modules/Transfer/utils';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Sentry} from '@/services/sentry';
import {
  resetTransferDetails,
  setTransferDeliveryAddress,
  updateTransfer,
} from '@/store/transfers/slice';
import {IAddress, IBalance, ITrack} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';
import {IUser} from '@/types/user';
import {areAddressesEqual} from '@/utils/ethereum';

export interface ITransferStateContext {
  transferId: string;
  track: ITrack;
  user?: IUser;
  senderWallet?: IAddress;
  balance?: IBalance;
  deliveryWalletAddress?: string;
  deliveryWallet?: IAddress;
  setDeliveryWalletAddress: (address: string) => void;
  transferParams: ITransferParams;
  close: () => void;
  transferStep: TransferStep;
  setTransferStep: (step: TransferStep) => void;
  txHash: string | undefined;
  setTxHash: (txHash: string | undefined) => void;
  userOpHash: string | undefined;
  setUserOpHash: (txHash: string | undefined) => void;
  transactionError: any | undefined;
  setTransactionError: (error: any | undefined) => void;
  gasEstimation: IGasEstimation;
  openedModal: TransferModal;
  setOpenedModal: (modal: TransferModal) => void;
  closeModal: () => void;
  resetTransferDetails: () => void;
}

export const TransferStateContext = createContext<
  ITransferStateContext | undefined
>(undefined);

interface IProps {
  transferId: string | undefined;
  initialUser?: IUser;
  track: ITrack;
  initialTransferParams: ITransferParams;
  children: ReactNode;
}

const TransferStateProvider: FC<IProps> = ({
  transferId,
  initialUser,
  initialTransferParams,
  track,
  children,
}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();

  const {transfer} = useSyncTransferState({
    transferId,
    track,
    transferParams: initialTransferParams,
    initialUser,
  });

  const user = useUserById(transfer.userId);

  const senderWallet = user?.addresses.find(a =>
    areAddressesEqual(a.address, transfer.transferParams.from),
  );

  const deliveryWallet = user?.addresses.find(a =>
    areAddressesEqual(a.address, transfer.transferParams.to),
  );

  const gasEstimation = useGasEstimation(
    {
      chainId: transfer.transferParams.chainId,
      wallet: senderWallet,
      transaction: transfer.transferParams.to
        ? {
            to: transfer.transferParams.contract,
            data: encodeFunctionData(
              getTransferWriteContractInput(transfer.transferParams),
            ),
          }
        : undefined,
      onError: error => {
        Sentry.captureException(error, {
          tags: {
            transfer: true,
            simulation: true,
            passkeyWallet: !!senderWallet && isPasskeyWallet(senderWallet),
          },
          extra: {
            trackId: track.id,
            wallet: senderWallet,
          },
        });
      },
    },
    transfer.transferStep === 'checkout',
  );

  const {data: balance} = useBalance({
    address: senderWallet?.address,
    chainId: transfer.transferParams.chainId,
    watch: true,
    enabled: !!senderWallet,
  });

  const updateTransferField =
    <T extends keyof ITransfer>(field: T) =>
    (value: ITransfer[typeof field]) =>
      dispatch(updateTransfer({id: transfer.id, update: {[field]: value}}));

  return (
    <TransferStateContext.Provider
      value={{
        transferId: transfer.id,
        track,
        user,
        senderWallet,
        balance,
        deliveryWallet,
        deliveryWalletAddress: transfer.transferParams.to,
        setDeliveryWalletAddress: (address: string) =>
          dispatch(setTransferDeliveryAddress({id: transfer.id, address})),
        transferParams: transfer.transferParams,
        close: () => navigation.goBack(),
        transferStep: transfer.transferStep,
        setTransferStep: updateTransferField('transferStep'),
        txHash: transfer.txHash,
        setTxHash: updateTransferField('txHash'),
        userOpHash: transfer.userOpHash,
        setUserOpHash: updateTransferField('userOpHash'),
        transactionError: transfer.transactionError,
        setTransactionError: updateTransferField('transactionError'),
        gasEstimation,
        openedModal: transfer.modal,
        setOpenedModal: updateTransferField('modal'),
        closeModal: () => updateTransferField('modal')(undefined),
        resetTransferDetails: () =>
          dispatch(resetTransferDetails({id: transfer.id})),
      }}>
      {children}
    </TransferStateContext.Provider>
  );
};

export default TransferStateProvider;
