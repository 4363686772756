import {useAppSelector} from '@/hooks/useRedux';
import {selectMinimizedTransfers} from '@/store/transfers/selectors';

export const useTransferToasts = () => {
  const transfers = useAppSelector(selectMinimizedTransfers);

  return {
    transfers,
  };
};
