import React, {FC} from 'react';
import {View} from 'react-native';

import IconButton from '@/components/IconButton';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {
  shareTxSuccessOnLens,
  shareTxSuccessOnTwitter,
  shareTxSuccessOnWarpcast,
} from '@/utils/share';

import {styles} from './ShareButtons.style';

interface IProps {
  track: ITrack;
}

const ShareButtons: FC<IProps> = ({track}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.shareContainer}>
      <View style={style.shareHeader}>
        <View style={style.shareLine} />
        <Text weight="semibold">Share now on</Text>
        <View style={style.shareLine} />
      </View>
      <View style={style.shareActionButtons}>
        <IconButton
          onPress={() => shareTxSuccessOnTwitter(track)}
          icon={{name: 'twitter', provider: 'custom', size: spacing.l * 2}}
          size={spacing.l * 2}
        />
        <IconButton
          onPress={() => shareTxSuccessOnLens(track)}
          icon={{name: 'hey', provider: 'custom', size: spacing.l * 2}}
          size={spacing.l * 2}
        />
        <IconButton
          onPress={() => shareTxSuccessOnWarpcast(track)}
          icon={{name: 'warpcast', provider: 'custom', size: spacing.l * 2}}
          size={spacing.l * 2}
        />
      </View>
    </View>
  );
};

export default ShareButtons;
