import React, {FC, ReactNode} from 'react';
import {StyleProp, TouchableOpacity, View} from 'react-native';

import Icon from '@/components/Icon';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import UploadProgress from '@/components/UploadProgress';
import {IImageUploaderResult} from '@/hooks/useImageUploader';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';

import {styles, UPLOAD_PROGRESS_HEIGHT} from './ImagePicker.style';

interface IProps extends IImageUploaderResult {
  previewSize: number;
  placeholder?: ReactNode;
  resizeWidth?: ImageSize | number;
  currentImageUri?: string;
  circular?: boolean;
  previewStyle?: StyleProp<any>;
  minSize?: number;
}

const ImagePicker: FC<IProps> = ({
  previewSize,
  placeholder,
  pickedImage,
  currentImageUri,
  previewStyle,
  resizeWidth,
  circular,
  selectImage,
  isUploading,
  uploadProgress,
  sizeError,
  minSize,
}) => {
  const style = useThemedStyles(styles);
  const uri = pickedImage?.uri || currentImageUri;

  return (
    <View style={style.avatarContainer}>
      <TouchableOpacity onPress={selectImage} activeOpacity={0.8}>
        <View
          style={[
            style.avatarEditIcon,
            circular && style.avatarEditIcon_circular,
          ]}>
          <Icon name="edit" provider="custom" size={18} />
        </View>
        <View
          style={[
            style.avatarPreviewWrapper,
            {width: previewSize, height: previewSize},
            circular && {borderRadius: previewSize / 2},
            previewStyle,
          ]}>
          {uri ? (
            <StaticImage
              source={{
                uri,
              }}
              style={style.avatarPreview}
              resizeWidth={resizeWidth}
            />
          ) : (
            placeholder
          )}
        </View>
      </TouchableOpacity>
      <View style={style.infoContainer}>
        {isUploading && (
          <UploadProgress
            height={UPLOAD_PROGRESS_HEIGHT}
            progress={uploadProgress}
            textProps={{size: 'xxs'}}
          />
        )}
        {sizeError && (
          <Text
            size="xxs"
            id="avatarPicker.tooSmall"
            align="center"
            values={{size: minSize}}
          />
        )}
      </View>
    </View>
  );
};

export default ImagePicker;
