import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';

import Background from '@/components/Background/Background';
import {DesktopPlayerThemed} from '@/components/Layout/DesktopPlayer';
import PromotionBanner from '@/components/PromotionBanner';
import WebMenu from '@/components/WebMenu';
import {useResponsive} from '@/hooks/useResponsive';
import {useThemedStyles} from '@/theme';
import {useTheme} from '@/theme';

import {styles} from './Layout.style';
import {useAppSelector} from '@/hooks/useRedux';
import {selectIsActive} from '@/store/player';
import HoverOpacity from '@/components/HoverOpacity/HoverOpacity';

const Layout: FC<{children: ReactNode}> = ({children}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();
  const {isMobile} = useResponsive();
  const isActive = useAppSelector(selectIsActive);

  if (isMobile) {
    return (
      <>
        <PromotionBanner />
        {children}
      </>
    );
  }

  return (
    <View style={style.root}>
      {theme.background?.image && <Background />}
      <WebMenu />
      <View style={style.content}>
        <View style={style.opaqueOverlay} />
        {children}
      </View>

      <View style={style.playerContainer}>
        <HoverOpacity containerStyle={style.player} isActive={isActive}>
          <View style={style.opaqueOverlay} />
          <DesktopPlayerThemed />
        </HoverOpacity>
      </View>
    </View>
  );
};

export default Layout;
