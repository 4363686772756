import {ITrack} from '@/types/common';

export interface ITrackPlayer {
  add: (track: ITrack, index?: number) => void;
  reset: () => void;
  play: () => void;
  pause: () => void;
  playTrack: (track: ITrack) => Promise<void>;
  seekTo: (position: number) => Promise<void>;
  getPosition: () => Promise<number>;
  getDuration: () => Promise<number>;
  setRateMode: (rateMode: RateMode) => Promise<void>;
  addEventListener: (eventName: string, callback: (data: any) => void) => void;
  removeEventListener?: (
    eventName: string,
    callback: (data: any) => void,
  ) => void;
  handlePlaybackError?: () => Promise<void>;
  clearCache?: () => Promise<void>;
}

export enum RepeatMode {
  Off,
  Track,
  Queue,
}

export type RateMode = 1 | 1.5 | 2;
