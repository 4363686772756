import React, {useCallback, useEffect, useState} from 'react';
import {View} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import {isGenreChannel, setFeedItemUserId} from '../utils';
import IconButton from '@/components/IconButton/IconButton';
import {useInfinityList} from '@/components/InfinityList/useInfinityList';
import OpacityGradient from '@/components/OpacityGradient';
import ScreenLoader from '@/components/ScreenLoader';
import {MINUTE, SECOND} from '@/constants/time';
import {OnScroll} from '@/hooks/useAnimatedHeader';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useFeedItemMutation, useRegeneratingFeedQuery} from '@/queries/feed';
import {SetScrollToTop} from '@/screens/Feed/components/FeedCarousel/shared';
import FeedInlineError from '@/screens/Feed/components/FeedInlineError';
import FeedList from '@/screens/Feed/components/FeedList';
import {setShouldPlay as setFeedShouldPlay} from '@/store/feed';
import {selectIsPlaying} from '@/store/player';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {PlayContextType} from '@/types/common';
import {IFeedItem, ILocalMessageFeedItem} from '@/types/feed';
import {analytics} from '@/utils/analytics';

import {styles} from './Feed.styles';
import FeedCarousel from './FeedCarousel/FeedCarousel';
import {
  useFeedItemsWithArtificialCards,
  useFeedItemsWithPinnedCards,
} from './useFeedItemsWithArtificialCards';
import {useIsFeedLoading} from './useIsFeedLoading';
import {useTabs} from './useTabs';

interface IFeedProps {
  userId: string;
  onScroll: OnScroll;
  resetHeaderScroll: () => void;
  titleId?: string;
  hideActions?: boolean;
  scrollToTop?: React.RefObject<() => void>;
  setScrollToTop?: SetScrollToTop;
  initialIndex?: number;
  onIndexChange?: (index: number) => void;
}

const newFeedConditions = {userAction: null} as const;
const hidesFeedConditions = {userAction: 'hide'} as const;
const likesFeedConditions = {userAction: 'like'} as const;

const Feed: React.FC<IFeedProps> = ({
  userId,
  onScroll,
  resetHeaderScroll,
  titleId = 'feed.title',
  hideActions = false,
  scrollToTop,
  setScrollToTop,
  initialIndex = 0,
  onIndexChange,
}) => {
  const dispatch = useAppDispatch();
  const style = useThemedStyles(styles);

  const [activeIndex, setActiveIndex] = useState(initialIndex);

  const isMainPlayerPlaying = useAppSelector(selectIsPlaying);
  const myUserId = useAppSelector(selectActiveUserId);

  useEffect(() => {
    if (myUserId && userId !== myUserId) {
      analytics.feedSpyRequested(myUserId, userId);
    }
  }, [myUserId, userId]);

  useEffect(() => {
    if (isMainPlayerPlaying) {
      dispatch(setFeedShouldPlay(false));
    }
  }, [isMainPlayerPlaying]);

  const {
    feedItems,
    query: feedQuery,
    regenerateFeed,
  } = useRegeneratingFeedQuery(userId, newFeedConditions, {
    staleTime: 10 * SECOND,
  });

  const {onEndReached} = useInfinityList({
    fetchNextPage: feedQuery.fetchNextPage,
    isFetchingNextPage: feedQuery.isFetchingNextPage,
    hasNextPage: feedQuery.hasNextPage,
  });

  const {feedItems: hiddenFeedItems, query: hiddenFeedQuery} =
    useRegeneratingFeedQuery(userId, hidesFeedConditions, {
      staleTime: 5 * MINUTE,
    });

  const {feedItems: likedFeedItems, query: likedFeedQuery} =
    useRegeneratingFeedQuery(userId, likesFeedConditions, {
      staleTime: 5 * MINUTE,
    });

  const {tab, changeTab} = useTabs();

  useEffect(() => {
    resetHeaderScroll();
  }, [tab]);

  const toast = useToast();

  const feedItemMutation = useFeedItemMutation(userId, {
    onError: _error => {
      console.error('feedItemMutation', _error);
      toast.showToast({textId: 'feed.genericError'});
    },
  });

  const {feedItemsWithActiveCard, pinFeedItem, unpinFeedItem} =
    useFeedItemsWithPinnedCards(feedItems);

  const feedItemsWithOnboarding = useFeedItemsWithArtificialCards(
    feedItemsWithActiveCard,
    userId,
  );

  const toggleLike = useCallback(
    (feedItem: IFeedItem | ILocalMessageFeedItem) => {
      if (feedItem.entityType === 'localMessage') {
        return;
      }

      const action = feedItem.userAction === 'like' ? null : 'like';

      // We want to keep the card in the feed so the user can keep listening
      // after liking it. We add it here as an additional card, so when it gets removed as
      // per feedItemMutation.mutate(), it stays in the user's feed.
      // we don't pin items if they were liked from anywhere other than the feed card (eg track modal)
      if (action === 'like') {
        pinFeedItem({
          ...feedItem,
          userAction: action,
        });
      } else {
        unpinFeedItem(feedItem);
      }

      feedItemMutation.mutate({
        ...setFeedItemUserId(userId, feedItem),
        userAction: action,
      });

      analytics.feedItemReacted(feedItem.id, action, feedItem.entityType);
    },
    [feedItemMutation.mutate, userId],
  );

  const toggleHide = useCallback(
    (feedItem: IFeedItem | ILocalMessageFeedItem) => {
      if (feedItem.entityType === 'localMessage') {
        return;
      }
      unpinFeedItem(feedItem);

      const action = feedItem.userAction === 'hide' ? null : 'hide';

      feedItemMutation.mutate({
        ...setFeedItemUserId(userId, feedItem),
        userAction: action,
      });

      analytics.feedItemReacted(feedItem.id, action, feedItem.entityType);
    },
    [feedItemMutation.mutate, userId],
  );

  const isFeedLoading = useIsFeedLoading(userId, 0);

  return (
    <>
      <Animated.View
        key={tab}
        entering={FadeIn}
        exiting={FadeOut}
        style={style.content}>
        {(() => {
          if (tab === 'hides') {
            return (
              <FeedList
                data={hiddenFeedItems}
                query={hiddenFeedQuery}
                hideActions={hideActions}
                toggleLike={toggleLike}
                toggleHide={toggleHide}
                onScroll={onScroll}
                playContext={PlayContextType.feedHides}
                queueTitleId={titleId}
              />
            );
          }

          if (tab === 'likes') {
            return (
              <FeedList
                data={likedFeedItems}
                query={likedFeedQuery}
                hideActions={hideActions}
                toggleLike={toggleLike}
                toggleHide={toggleHide}
                onScroll={onScroll}
                playContext={PlayContextType.feedLikes}
                queueTitleId={titleId}
              />
            );
          }

          if (feedItemsWithOnboarding && feedItemsWithOnboarding.length > 0) {
            return (
              <View style={style.carouselContainer}>
                <FeedCarousel
                  feedItems={feedItemsWithOnboarding}
                  activeIndex={activeIndex}
                  setActiveIndex={index => {
                    setActiveIndex(index);
                    onIndexChange?.(index);
                  }}
                  onRefresh={regenerateFeed}
                  toggleHide={hideActions ? undefined : toggleHide}
                  toggleLike={hideActions ? undefined : toggleLike}
                  onEndReached={onEndReached}
                  queueTitleId={titleId}
                  setScrollToTop={setScrollToTop}
                />
              </View>
            );
          }

          if (isFeedLoading) {
            return <ScreenLoader />;
          }

          return (
            <FeedInlineError
              isFetching={feedQuery.isFetching}
              onRefetch={feedQuery.refetch}
            />
          );
        })()}
        <View style={style.gradientContainer}>
          <OpacityGradient
            color={'backgroundLight'}
            style={style.gradient}
            vertical={true}
          />
        </View>
      </Animated.View>

      {!isGenreChannel(userId) && (
        <Animated.View style={style.tabs} entering={FadeIn} exiting={FadeOut}>
          <IconButton
            rippleDisabled
            onPress={() => changeTab('hides')}
            icon={{
              provider: 'custom',
              name: 'feedHides',
              size: 30,
            }}
            style={{opacity: tab === 'hides' ? 1 : 0.3}}
          />
          <IconButton
            rippleDisabled
            onPress={() => {
              if (tab === 'new') {
                scrollToTop?.current?.();
                return;
              }
              changeTab('new');
            }}
            icon={{
              provider: 'custom',
              name: 'feedVinyl',
              size: 70,
            }}
            style={{opacity: tab === 'new' ? 1 : 0.3}}
          />
          <IconButton
            rippleDisabled
            onPress={() => changeTab('likes')}
            icon={{
              provider: 'custom',
              name: 'feedLikes',
              size: 30,
            }}
            style={{opacity: tab === 'likes' ? 1 : 0.3}}
          />
        </Animated.View>
      )}
    </>
  );
};

export default Feed;
