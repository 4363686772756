import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    borderTopLeftRadius: theme.box.borderRadius,
    borderTopRightRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.backgroundLight,

    shadowColor: theme.colors.primary,
    shadowOffset: {width: 0, height: -spacing.xxs / 2},
    shadowOpacity: 0.1,
    shadowRadius: spacing.xxs / 2,
  },
}));
