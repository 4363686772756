import {useNavigation} from '@react-navigation/native';
import {useBalance} from 'wagmi';

import {useActiveUser} from '@/hooks/useActiveUser';
import {useTrackCollectInfoQuery} from '@/modules/Collect/queries/collect';
import {getOfferIcon} from '@/modules/Collect/utils';
import {ITrack} from '@/types/common';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {getUserPaymentWallet} from '@/utils/user';

export const useCollect = (track: ITrack, referral?: string) => {
  const user = useActiveUser();
  const navigation = useNavigation<RootStackNavigationParams>();
  const paymentAddress = user && getUserPaymentWallet(user)?.address;

  const {collectInfo, query} = useTrackCollectInfoQuery({
    trackId: track.id,
    userAddress: paymentAddress,
  });
  const offerChainId = collectInfo?.mintOffers[0]?.chainId;

  // Prefetch balance when offer chain is loaded
  useBalance({
    address: paymentAddress,
    chainId: offerChainId,
    enabled: !!user && !!offerChainId,
  });

  return {
    collect: () =>
      navigation.navigate(Routes.Collect, {slug: track.slug, referral}),
    isLoading: query.isLoading,
    icon: getOfferIcon(collectInfo),
    textId: collectInfo?.isAlreadyOwned
      ? 'collect.button.more'
      : 'collect.button',
  };
};
