import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const UPLOAD_PROGRESS_HEIGHT = spacing.xs * 1.5;
const EDIT_ICON_SIZE = spacing.l + spacing.xxs;

export const styles = createThemedStyles(theme => ({
  avatarContainer: {
    alignItems: 'center',
    marginTop: spacing.xs,
    marginBottom: -spacing.xs,
  },
  avatarPreviewWrapper: {
    overflow: 'hidden',
    backgroundColor: theme.colors.background,
    borderRadius: theme.box.borderRadius,
  },
  avatarPreview: {
    width: '100%',
    height: '100%',
  },
  avatarEditIcon: {
    position: 'absolute',
    top: -EDIT_ICON_SIZE / 4,
    right: -EDIT_ICON_SIZE / 4,
    width: EDIT_ICON_SIZE,
    height: EDIT_ICON_SIZE,
    borderRadius: EDIT_ICON_SIZE / 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.background,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    zIndex: 1,
  },
  avatarEditIcon_circular: {
    top: EDIT_ICON_SIZE / 4,
    right: EDIT_ICON_SIZE / 4,
  },
  infoContainer: {
    marginTop: spacing.xs,
    minHeight: UPLOAD_PROGRESS_HEIGHT,
    alignSelf: 'stretch',
  },
}));
