import React, {createContext, FC, ReactNode, useMemo} from 'react';
import {Dimensions} from 'react-native';
import {Drawer} from 'react-native-drawer-layout';

import spacing from '@/constants/spacing';
import {useBooleanState} from '@/hooks/useBooleanState';
import {useAppSelector} from '@/hooks/useRedux';
import {selectCanGoBack} from '@/store/navigation';
import {noop} from '@/utils/functions';
import {isIOS} from '@/utils/platform';

import DrawerMenu from './DrawerMenu';

interface IDrawerContext {
  openDrawer: () => void;
  closeDrawer: () => void;
  onDrawerNavigation: (navigationAction: () => void) => void;
}

const SCREEN_WIDTH = Dimensions.get('window').width;

export const DrawerContext = createContext<IDrawerContext>({
  openDrawer: noop,
  closeDrawer: noop,
  onDrawerNavigation: noop,
});

const DrawerMenuProvider: FC<{children: ReactNode}> = ({children}) => {
  const [isOpen, openDrawer, closeDrawer] = useBooleanState(false);
  const canGoBack = useAppSelector(selectCanGoBack);

  // Used to fire navigation actions from drawer.
  // It adds timeout before closing drawer, so all animations work nice together
  const onDrawerNavigation = (navigationAction: () => void) => {
    navigationAction();
    setTimeout(closeDrawer, isIOS ? 600 : 0);
  };

  const contextValue = useMemo(
    () => ({
      openDrawer,
      closeDrawer,
      onDrawerNavigation,
    }),
    [],
  );

  return (
    <DrawerContext.Provider value={contextValue}>
      <Drawer
        open={isOpen}
        onOpen={openDrawer}
        onClose={closeDrawer}
        drawerStyle={{width: 0.66 * SCREEN_WIDTH}}
        drawerType="slide"
        drawerPosition="left"
        renderDrawerContent={() => <DrawerMenu />}
        swipeEdgeWidth={canGoBack ? 0 : spacing.m}>
        {children}
      </Drawer>
    </DrawerContext.Provider>
  );
};

export default DrawerMenuProvider;
