import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';

import {useAppDispatch} from '@/hooks/useRedux';
import ExpandableToast from '@/modules/Toasts/ExpandableToast';
import {ITransfer} from '@/modules/Transfer';
import {updateTransfer} from '@/store/transfers/slice';
import {RootStackNavigationParams, Routes} from '@/types/routes';

interface IProps {
  transfer: ITransfer;
}

const TransferToast: FC<IProps> = ({transfer}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();

  const {transferStep, transactionError} = transfer;

  return (
    <ExpandableToast
      onPress={() => {
        dispatch(
          updateTransfer({id: transfer.id, update: {isMinimized: false}}),
        );
        navigation.navigate(Routes.Transfer, {
          slug: transfer.slug,
          transferId: transfer.id,
          ...transfer.transferParams,
        });
      }}
      text={{
        id: transactionError
          ? 'transfer.toast.failed'
          : transferStep === 'success'
          ? 'transfer.toast.completed'
          : transferStep === 'checkout'
          ? 'transfer.toast.cancelled'
          : 'transfer.toast.pending',
      }}
      isLoading={
        !transactionError && !['success', 'checkout'].includes(transferStep)
      }
    />
  );
};

export default TransferToast;
