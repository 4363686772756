import {ITrackPlayer, RateMode} from '@/services/trackPlayer/types';

import {updateMetadata} from './mediaSession';

const initializeAudio = () => {
  const audio = new Audio();
  audio.preload = 'auto';
  audio.autoplay = false;
  return audio;
};

const audio = initializeAudio();

export const TrackPlayer: ITrackPlayer = {
  add: track => {
    audio.src = track.lossyAudioUrl;
    updateMetadata(track);
  },
  reset: () => {
    TrackPlayer.pause();
    audio.currentTime = 0;
  },
  play: async () => {
    try {
      await audio.play();
    } catch (e) {
      // Safe to ignore here - errors are catch and handled in playbackService
    }
  },
  pause: () => {
    audio.pause();
  },
  playTrack: async track => {
    await TrackPlayer.add(track);
    audio.currentTime = 0;
    audio.playbackRate = 1;
    await TrackPlayer.play();
  },
  seekTo: (position: number) => {
    audio.currentTime = position;
    return Promise.resolve();
  },
  getPosition: () => Promise.resolve(audio.currentTime),
  getDuration: () =>
    Promise.resolve(isNaN(audio.duration) ? 0 : audio.duration),
  setRateMode: (rateMode: RateMode) => {
    audio.playbackRate = rateMode;
    return Promise.resolve();
  },
  addEventListener: (eventName, callback) => {
    audio.addEventListener(eventName, callback);
  },
  removeEventListener: (eventName, callback) => {
    audio.removeEventListener(eventName, callback);
  },
};
