import React from 'react';

import FeedWelcomeCard from '../Onboarding/FeedWelcomeCard';
import PopularArtistIntroCard from '../Onboarding/PopularArtistIntroCard';
import SpinampWelcomeCard from '../Onboarding/SpinampWelcomeCard';
import SpyWelcomeCard from '../Onboarding/SpyWelcomeCard';
import Icon, {IIconProps} from '@/components/Icon';
import Space from '@/components/Space';
import Text from '@/components/Text';
import NotificationsCard from '@/screens/Feed/components/Onboarding/NotificationsCard';
import {useThemedStyles} from '@/theme';
import {ILocalMessageFeedItem} from '@/types/feed';

import BaseFeedCard from './BaseFeedCard';
import {styles} from './common.styles';

interface ILocalMessageFeedCardProps {
  feedItem: ILocalMessageFeedItem;
}

const LocalMessageFeedCard: React.FC<ILocalMessageFeedCardProps> = ({
  feedItem,
}) => {
  if (feedItem.localMessageId === 'spinampWelcome') {
    return <SpinampWelcomeCard />;
  }

  if (feedItem.localMessageId === 'feedWelcome') {
    return <FeedWelcomeCard />;
  }

  if (feedItem.localMessageId === 'popularArtists') {
    return <PopularArtistIntroCard />;
  }

  if (feedItem.localMessageId === 'spyWelcome') {
    return <SpyWelcomeCard />;
  }

  if (feedItem.localMessageId === 'emptySpyFeed') {
    return (
      <GenericLocalMessageFeedItem bodyTextId="feed.impersonating.empty" />
    );
  }

  if (feedItem.localMessageId === 'notificationsOnboarding') {
    return <NotificationsCard />;
  }

  return null;
};

interface IGenericMessageFeedItemProps {
  iconProps?: IIconProps;
  headingTextId?: string;
  bodyTextId?: string;
}

export const GenericLocalMessageFeedItem: React.FC<
  IGenericMessageFeedItemProps
> = ({iconProps, headingTextId, bodyTextId}) => {
  const style = useThemedStyles(styles);

  return (
    <BaseFeedCard>
      <Space p="s" style={style.featureContainer}>
        {iconProps && (
          <Space pb="m">
            <Icon style={style.featureIcon} {...iconProps} size={48} />
          </Space>
        )}
        {headingTextId && (
          <Space mb="xxs">
            <Text id={headingTextId} weight="bold" style={style.centeredText} />
          </Space>
        )}
        {bodyTextId && <Text id={bodyTextId} style={style.centeredText} />}
      </Space>
    </BaseFeedCard>
  );
};

export default LocalMessageFeedCard;
