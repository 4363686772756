export type TransactionErrorCode =
  | 'WRONG_PASSKEY_SIGNER'
  | 'OFFER_NOT_AVAILABLE'
  | 'OFFER_PRICE_CHANGED'
  | 'WALLET_NOT_CONNECTED'
  | 'NO_WALLET_SELECTED'
  | 'USER_CANCELLED'
  | 'APPROVAL_TRANSACTION_REVERTED'
  | 'TRANSACTION_REVERTED';

interface TransactionErrorDetails {
  chainId: number;
  txHash: string;
}

export class TransactionError extends Error {
  status: TransactionErrorCode;
  transactionDetails?: TransactionErrorDetails;

  constructor(
    status: TransactionErrorCode,
    transactionDetails?: TransactionErrorDetails,
  ) {
    super(status);
    this.status = status;
    this.transactionDetails = transactionDetails;
  }
}
