// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* There's unfortunately no other way to style this */
.spinamp-text-editor .ql-toolbar.ql-snow {
    border: none
}

.spinamp-text-editor .ql-container.ql-container {
    height: 300px;
    border: none;
    border-top: 1px solid !important;
    border-color: transparent;
}

.spinamp-text-editor .ql-editor.ql-blank::before {
    color: inherit;
}

.spinamp-text-editor .ql-snow.ql-toolbar button {
    color: inherit;
}

.spinamp-text-editor .ql-snow .ql-stroke {
    color: inherit;
    stroke: currentColor;
}

.spinamp-text-editor .ql-snow .ql-fill {
    color: inherit;
    fill: currentColor;
}

`, "",{"version":3,"sources":["webpack://./src/components/RichTextEditor/styles.css"],"names":[],"mappings":"AAAA,qDAAqD;AACrD;IACI;AACJ;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,gCAAgC;IAChC,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB","sourcesContent":["/* There's unfortunately no other way to style this */\n.spinamp-text-editor .ql-toolbar.ql-snow {\n    border: none\n}\n\n.spinamp-text-editor .ql-container.ql-container {\n    height: 300px;\n    border: none;\n    border-top: 1px solid !important;\n    border-color: transparent;\n}\n\n.spinamp-text-editor .ql-editor.ql-blank::before {\n    color: inherit;\n}\n\n.spinamp-text-editor .ql-snow.ql-toolbar button {\n    color: inherit;\n}\n\n.spinamp-text-editor .ql-snow .ql-stroke {\n    color: inherit;\n    stroke: currentColor;\n}\n\n.spinamp-text-editor .ql-snow .ql-fill {\n    color: inherit;\n    fill: currentColor;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
