import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const HEIGHT = 1;
const MARGIN = spacing.xs;
export const LINE_SEPARATOR_HEIGHT = HEIGHT + MARGIN * 2;

export const styles = createThemedStyles(theme => ({
  separator: {
    height: HEIGHT,
    marginVertical: MARGIN,
    width: '100%',
  },
}));
