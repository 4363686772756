import React, {FC} from 'react';

import CardButton from '@/components/CardButton';
import Space from '@/components/Space';
import TransparentButton from '@/components/TransparentButton';
import WarningBox from '@/components/WarningBox';
import CollectLayout from '@/modules/Collect/components/CollectLayout';
import {TransactionError} from '@/modules/Collect/errors';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {EtherscanLink} from '@/modules/Transactions';
import {PasskeyError} from '@/services/passkey';
import {useThemedStyles} from '@/theme';
import {isOSXBrowser} from '@/utils/platform';
import {getUserDisplayName} from '@/utils/user';

import {styles} from './TransactionErrorInfo.style';

const TransactionErrorInfo: FC = () => {
  const style = useThemedStyles(styles);

  const {
    user,
    transactionError,
    collectInfo,
    closeCollectModal,
    resetTxDetails,
  } = useCollectState();

  if (
    transactionError instanceof PasskeyError &&
    transactionError.status === 'MISSING_BLOB'
  ) {
    return (
      <CollectLayout
        minimized
        style={style.actions}
        header={
          <WarningBox
            title={{id: 'collect.error.missingBlob'}}
            message={{
              id: isOSXBrowser
                ? 'collect.error.missingBlob.osx'
                : 'collect.error.missingBlob.default',
            }}
          />
        }>
        <CardButton
          onPress={resetTxDetails}
          text={{id: 'collect.error.tryAgain'}}
        />
        <TransparentButton
          style={style.secondaryAction}
          onPress={closeCollectModal}
          text={{id: 'collect.error.goBack'}}
        />
      </CollectLayout>
    );
  }

  if (
    transactionError instanceof TransactionError &&
    transactionError.status === 'WRONG_PASSKEY_SIGNER'
  ) {
    return (
      <CollectLayout
        minimized
        style={style.actions}
        header={
          <WarningBox
            title={{id: 'collect.error.wrongSigner'}}
            message={{
              id: 'collect.error.wrongSigner.message',
              values: {user: user ? getUserDisplayName(user) : ''},
            }}
          />
        }>
        <CardButton
          onPress={resetTxDetails}
          text={{id: 'collect.error.tryAgain'}}
        />
        <TransparentButton
          style={style.secondaryAction}
          onPress={closeCollectModal}
          text={{id: 'collect.error.goBack'}}
        />
      </CollectLayout>
    );
  }

  if (
    transactionError instanceof TransactionError &&
    transactionError.status === 'OFFER_NOT_AVAILABLE'
  ) {
    return (
      <CollectLayout
        minimized
        style={style.actions}
        header={
          <WarningBox
            title={{id: 'collect.error.notAvailable'}}
            message={{id: 'collect.error.notAvailable.message'}}
          />
        }>
        {collectInfo?.availability === 'direct' ? (
          <>
            <CardButton
              onPress={resetTxDetails}
              text={{id: 'collect.error.notAvailable.change'}}
            />
            <TransparentButton
              style={style.secondaryAction}
              onPress={closeCollectModal}
              text={{id: 'collect.error.goBack'}}
            />
          </>
        ) : collectInfo?.availability === 'external' ||
          collectInfo?.availability === 'unknown' ? (
          <>
            <CardButton
              onPress={resetTxDetails}
              text={{id: 'collect.error.notAvailable.details'}}
            />
            <TransparentButton
              style={style.secondaryAction}
              onPress={closeCollectModal}
              text={{id: 'collect.error.goBack'}}
            />
          </>
        ) : (
          <CardButton
            onPress={closeCollectModal}
            text={{id: 'collect.error.goBack'}}
          />
        )}
      </CollectLayout>
    );
  }

  if (
    transactionError instanceof TransactionError &&
    transactionError.status === 'OFFER_PRICE_CHANGED'
  ) {
    return (
      <CollectLayout
        minimized
        style={style.actions}
        header={
          <WarningBox
            title={{id: 'collect.error.offerChanged'}}
            message={{id: 'collect.error.offerChanged.message'}}
          />
        }>
        <CardButton
          onPress={resetTxDetails}
          text={{id: 'collect.error.seeDetails'}}
        />
        <TransparentButton
          style={style.secondaryAction}
          onPress={closeCollectModal}
          text={{id: 'collect.error.goBack'}}
        />
      </CollectLayout>
    );
  }

  if (
    transactionError instanceof TransactionError &&
    transactionError.status === 'APPROVAL_TRANSACTION_REVERTED'
  ) {
    return (
      <CollectLayout
        minimized
        style={style.actions}
        header={
          <WarningBox
            title={{id: 'collect.error.approval.reverted'}}
            action={
              transactionError.transactionDetails && (
                <Space mt="xs">
                  <EtherscanLink
                    txHash={transactionError.transactionDetails.txHash}
                    chainId={transactionError.transactionDetails.chainId}
                  />
                </Space>
              )
            }
          />
        }>
        <CardButton
          onPress={resetTxDetails}
          text={{id: 'collect.error.tryAgain'}}
        />
        <TransparentButton
          style={style.secondaryAction}
          onPress={closeCollectModal}
          text={{id: 'collect.error.goBack'}}
        />
      </CollectLayout>
    );
  }

  if (
    transactionError instanceof TransactionError &&
    transactionError.status === 'TRANSACTION_REVERTED'
  ) {
    return (
      <CollectLayout
        minimized
        style={style.actions}
        header={
          <WarningBox
            title={{id: 'collect.error.reverted'}}
            action={
              transactionError.transactionDetails && (
                <Space mt="xs">
                  <EtherscanLink
                    txHash={transactionError.transactionDetails.txHash}
                    chainId={transactionError.transactionDetails.chainId}
                  />
                </Space>
              )
            }
          />
        }>
        <CardButton
          onPress={resetTxDetails}
          text={{id: 'collect.error.tryAgain'}}
        />
        <TransparentButton
          style={style.secondaryAction}
          onPress={closeCollectModal}
          text={{id: 'collect.error.goBack'}}
        />
      </CollectLayout>
    );
  }

  return (
    <CollectLayout
      minimized
      style={style.actions}
      header={
        <WarningBox
          title={{id: 'collect.error.generic'}}
          message={{id: 'collect.error.generic.message'}}
        />
      }>
      <CardButton
        onPress={resetTxDetails}
        text={{id: 'collect.error.tryAgain'}}
      />
      <TransparentButton
        style={style.secondaryAction}
        onPress={closeCollectModal}
        text={{id: 'collect.error.goBack'}}
      />
    </CollectLayout>
  );
};

export default TransactionErrorInfo;
