import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useStore} from 'react-redux';

import {
  fetchFullUserById,
  fetchUserByAddress,
  fetchUserBySlug,
  fetchUserEmail,
  fetchUserProfiles,
  fetchUsersByIds,
  updateUserMetadata,
} from '@/api/user';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {
  selectActiveUserSigner,
  selectSelectedUserId,
  selectSignedInUserIds,
  setActiveUserId,
} from '@/store/user';
import {DeepPartial} from '@/types/helpers';
import {QueryKeys} from '@/types/queryKeys';
import {IUser, IUserMetadata} from '@/types/user';
import {getUserSlug, mergeUserMetadata} from '@/utils/user';

export const useUserProfilesQuery = (isRoot: boolean = false) => {
  const {getState} = useStore();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const loggedUsersIds = useAppSelector(selectSignedInUserIds);

  const query = useQuery({
    queryKey: [QueryKeys.userProfiles],
    queryFn: () => fetchUserProfiles(loggedUsersIds),
    enabled: isRoot && loggedUsersIds.length > 0,
    staleTime: 1000, // It prevents additional request after data is prefetched in `store/user/actions`
  });
  const users = query.data;

  useEffect(() => {
    if (isRoot && users?.length) {
      users.forEach(user => {
        queryClient.setQueryData(
          [QueryKeys.userBySlug, getUserSlug(user)],
          user,
        );
      });

      // This is just for migration purpose after implementing multiple profiles.
      // Users which are already logged in won't have `activeUserId` value set, so we need to set if for them.
      // Normally `activeUserId`` will be set after linkWallet function call.
      if (!selectSelectedUserId(getState())) {
        dispatch(setActiveUserId(users[0].id));
      }
    }
  }, [isRoot, users]);

  return {
    users,
  };
};

export const useUserQuery = (slug?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.userBySlug, slug],
    queryFn: () => fetchUserBySlug(slug!),
    enabled: !!slug,
    staleTime: 1000 * 60 * 5, // 5min,
  });

  return {
    user: query.data || null,
    query,
  };
};

export const useFullUserByIdQuery = (userId?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.userById, userId],
    queryFn: () => fetchFullUserById(userId!),
    enabled: !!userId,
    staleTime: 1000 * 60 * 5, // 5min,
  });

  return {
    user: query.data || null,
    query,
  };
};

export const useUserByAddressQuery = (address?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.baseUserByAddress, address],
    queryFn: () => fetchUserByAddress(address!),
    enabled: !!address,
    staleTime: 0,
    cacheTime: 1000 * 60 * 5, // 5min,
  });

  return {
    user: query.data || null,
    query,
  };
};

export const useUsersByIdsQuery = (usersIds: string[] = []) => {
  const query = useQuery({
    queryKey: [QueryKeys.usersByIds, ...usersIds],
    queryFn: () => fetchUsersByIds(usersIds),
    enabled: usersIds.length > 0,
    keepPreviousData: true,
    // staleTime: 1000 * 60 * 5, // 5min
  });

  return {
    users: query.data || [],
    query,
  };
};

export const useUserEmailQuery = () => {
  const selectedUserId = useAppSelector(selectSelectedUserId);
  const signer = useAppSelector(selectActiveUserSigner);

  const query = useQuery({
    queryKey: [QueryKeys.userEmail, signer?.address],
    queryFn: () => fetchUserEmail(signer!),
    enabled: !!signer && !!selectedUserId,
    staleTime: 5 * 60 * 1000,
  });

  return {
    email: query.data,
    query,
  };
};

// Mutations
export const useUpdateUserMetadataMutation = () => {
  const {showToast} = useToast();
  const queryClient = useQueryClient();
  const signer = useAppSelector(selectActiveUserSigner);

  return useMutation({
    retry: 0,
    mutationFn: async (update: {
      user: IUser;
      metadataUpdate: DeepPartial<IUserMetadata>;
    }) => {
      if (!signer) {
        throw new Error('missing signer');
      }

      return updateUserMetadata(update.metadataUpdate, update.user, signer!);
    },
    onMutate: update => {
      const profiles = queryClient.getQueryData<IUser[]>([
        QueryKeys.userProfiles,
      ]);

      queryClient.setQueryData<IUser[]>(
        [QueryKeys.userProfiles],
        currentProfiles =>
          currentProfiles?.map(profile => {
            if (profile.id === update.user.id) {
              return {
                ...profile,
                metadata: mergeUserMetadata(
                  profile.metadata,
                  update.metadataUpdate,
                ),
              };
            }

            return profile;
          }),
      );

      return {profiles};
    },
    onError: (error, variables, context) => {
      context?.profiles &&
        queryClient.setQueryData([QueryKeys.userProfiles], context.profiles);
      showToast({textId: 'wallets.saveMetadataError'});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: [QueryKeys.userProfiles]});
    },
  });
};
