import {NavigationState, StackActions} from '@react-navigation/native';
import {getFeedItemId} from 'api-utils';

import {IArtistWithTracks, ITrack} from '@/types/common';
import {
  Genre,
  GENRES,
  IFeedEntityType,
  IFeedItem,
  IFeedUserAction,
  ILocalMessageFeedItem,
  IFeedItemRaw,
} from '@/types/feed';
import {Routes} from '@/types/routes';
import {isTruthy} from '@/utils/functions';

export const getArtistLink = (slugOrId: string) => ({
  to: {
    screen: Routes.MainNavigation,
    params: {
      screen: Routes.Artist,
      params: {slugOrId},
    },
  },
  action: StackActions.push(Routes.Artist, {slugOrId}),
});

export const getArtistModalLink = (slugOrId: string) => ({
  to: {
    screen: Routes.MainNavigation,
    params: {
      screen: Routes.ArtistModal,
      params: {slugOrId},
    },
  },
  action: StackActions.push(Routes.ArtistModal, {slugOrId}),
});

export const getTrackLink = (slug: string) => ({
  to: {
    screen: Routes.MainNavigation,
    params: {
      screen: Routes.Track,
      params: {slug: slug},
    },
  },
});

export const getTrackModalLink = (slug: string) => ({
  to: {
    screen: Routes.MainNavigation,
    params: {
      screen: Routes.TrackModal,
      params: {slug},
    },
  },
  action: StackActions.push(Routes.TrackModal, {slug}),
});

export const setFeedItemUserId = (
  userId: string,
  feedItem: IFeedItem,
): IFeedItem => {
  return {
    ...feedItem,
    userId,
    id: getFeedItemId({...feedItem, userId}),
  };
};

export const getFeedTrackIds = (
  feedItems: (IFeedItem | ILocalMessageFeedItem)[] | null,
) =>
  feedItems
    ?.map(item => item.entityType === IFeedEntityType.track && item.entityId)
    .filter(isTruthy);

export const isFeedFocused = (rootState: NavigationState) => {
  const mainNavigation = rootState.routes.find(
    route => route.name === Routes.MainNavigation,
  );

  if (mainNavigation?.state?.index === undefined) {
    return false;
  }

  const areTabsFocused =
    mainNavigation.state.routes[mainNavigation.state.index]?.name ===
    Routes.Tabs;

  if (!areTabsFocused) {
    return false;
  }

  const tabsNavigation = mainNavigation.state.routes.find(
    route => route.name === Routes.Tabs,
  );

  return (
    tabsNavigation?.state?.index !== undefined &&
    tabsNavigation.state.routes[tabsNavigation.state.index].name ===
      Routes.Channels
  );
};

const genresByUserId = GENRES.reduce<{[userId: string]: Genre}>(
  (result, genre) => ({
    ...result,
    [genre.userId]: genre,
  }),
  {},
);

export const isGenreChannel = (userId: string) => !!genresByUserId[userId];

const createRawFeedItem = (
  input: Pick<
    IFeedItemRaw,
    'userId' | 'entityType' | 'entityId' | 'userAction'
  > & {
    track?: ITrack;
    artist?: IArtistWithTracks;
  },
) => ({
  ...input,
  id: getFeedItemId({
    userId: input.userId,
    entityId: input.entityId,
    entityType: input.entityType,
  }),
  updatedAtTime: new Date().toISOString(),
  createdAtTime: new Date().toISOString(),
});

export const createFeedItemForTrack = (
  userId: string,
  track: ITrack,
  userAction?: IFeedUserAction,
) => ({
  ...createRawFeedItem({
    userId,
    userAction,
    entityId: track.id,
    entityType: IFeedEntityType.track,
  }),
  track,
});

export const createFeedItemForArtist = (
  userId: string,
  artist: IArtistWithTracks,
  userAction?: IFeedUserAction,
) => ({
  ...createRawFeedItem({
    userId,
    userAction,
    entityId: artist.id,
    entityType: IFeedEntityType.artist,
    artist,
  }),
  artist,
});
