import {useCallback, useMemo, useState} from 'react';
import {debounce} from 'throttle-debounce';

import {useNotificationsPermissionQuery} from '@/hooks/usePushNotifications';
import {useAppSelector} from '@/hooks/useRedux';
import {isGenreChannel} from '@/screens/Feed/utils';
import {selectActiveUserId, selectOnboardingInfo} from '@/store/user';
import {IFeedItem, IFeedEntityType, ILocalMessageFeedItem} from '@/types/feed';
import {sortFeedItems} from '@/utils/feed';
import {isNative} from '@/utils/platform';

const spinampWelcome: ILocalMessageFeedItem = {
  id: 'spinampWelcome',
  localMessageId: 'spinampWelcome',
  entityType: IFeedEntityType.localMessage,
};

const feedWelcome: ILocalMessageFeedItem = {
  id: 'feedWelcome',
  localMessageId: 'feedWelcome',
  entityType: IFeedEntityType.localMessage,
};

const popularArtists: ILocalMessageFeedItem = {
  id: 'popularArtists',
  localMessageId: 'popularArtists',
  entityType: IFeedEntityType.localMessage,
};

const spyWelcome: ILocalMessageFeedItem = {
  id: 'spyWelcome',
  localMessageId: 'spyWelcome',
  entityType: IFeedEntityType.localMessage,
};

const emptySpyFeed: ILocalMessageFeedItem = {
  id: 'emptySpyFeed',
  localMessageId: 'emptySpyFeed',
  entityType: IFeedEntityType.localMessage,
};

const notificationsOnboarding: ILocalMessageFeedItem = {
  id: 'notificationsOnboarding',
  localMessageId: 'notificationsOnboarding',
  entityType: IFeedEntityType.localMessage,
};

const NOTIFICATION_CARD_POSITION = 10;

export const useFeedItemsWithArtificialCards = (
  feedItems: (IFeedItem | ILocalMessageFeedItem)[] | null,
  userId: string,
) => {
  const {
    showFeedOnboardingSpinampWelcome,
    showFeedOnboardingFeedWelcome,
    showFeedOnboardingSpyWelcome,
    showFeedOnboardingNotifications,
  } = useAppSelector(selectOnboardingInfo);
  const activeUserId = useAppSelector(selectActiveUserId);

  const {areNotificationsEnabled} = useNotificationsPermissionQuery();

  return useMemo(() => {
    if (!feedItems) {
      return null;
    }
    const feedItemsWithArtificialCards = [...feedItems];

    if (userId === activeUserId) {
      if (feedItems.length === 0) {
        feedItemsWithArtificialCards.unshift(popularArtists);
      }

      if (showFeedOnboardingFeedWelcome) {
        feedItemsWithArtificialCards.unshift(feedWelcome);
      }

      if (showFeedOnboardingSpinampWelcome) {
        feedItemsWithArtificialCards.unshift(spinampWelcome);
      }

      if (
        isNative &&
        showFeedOnboardingNotifications &&
        !areNotificationsEnabled &&
        feedItemsWithArtificialCards.length > NOTIFICATION_CARD_POSITION
      ) {
        feedItemsWithArtificialCards.splice(
          NOTIFICATION_CARD_POSITION - 1,
          0,
          notificationsOnboarding,
        );
      }
    } else if (showFeedOnboardingSpyWelcome && !isGenreChannel(userId)) {
      feedItemsWithArtificialCards.unshift(spyWelcome);
    } else if (feedItemsWithArtificialCards.length === 0) {
      feedItemsWithArtificialCards.unshift(emptySpyFeed);
    }

    return feedItemsWithArtificialCards;
  }, [
    feedItems,
    showFeedOnboardingSpinampWelcome,
    showFeedOnboardingFeedWelcome,
    showFeedOnboardingSpyWelcome,
    showFeedOnboardingNotifications,
    areNotificationsEnabled,
    activeUserId,
  ]);
};

/**
 * Custom hook to manage feed items which need to persist in the feed throughout feed query refreshes.
 * This is useful for when we want to keep a liked card in the feed temporarily,
 * to allow the user a chance to undo the action, to continue listening to the song, etc.
 */
export const useFeedItemsWithPinnedCards = (feedItems: IFeedItem[] | null) => {
  const [additions, setAdditions] = useState<IFeedItem[]>([]);
  const pinFeedItem = (feedItem: IFeedItem) => {
    setAdditions(_additions => {
      if (_additions.some(item => item.id === feedItem.id)) {
        return _additions;
      }
      return [..._additions, feedItem];
    });
  };

  /**
   * Removes a feed item from the additions list after a debounce time.
   * Debounce is used to prevent the feed item from flickering back into the feed after the query refresh.
   */
  const unpinFeedItem = useCallback(
    debounce(5, (feedItem: IFeedItem) => {
      setAdditions(_additions =>
        _additions.filter(item => item.id !== feedItem.id),
      );
    }),
    [setAdditions],
  );

  // Applies additions to feedItems. They will be applied in the correct place
  // as long as their updatedAt times have not been changed since they were in the
  // right place (in other words make sure to call addFeedItem() before feedItemMutation.mutate)
  const feedItemsWithActiveCard = useMemo(() => {
    if (feedItems === null) {
      return null;
    }

    const newFeedItems = [
      ...feedItems,
      ...additions.filter(
        addition => !feedItems.some(item => item.id === addition.id),
      ),
    ];
    newFeedItems.sort(sortFeedItems);
    return newFeedItems;
  }, [feedItems, additions]);

  return {feedItemsWithActiveCard, pinFeedItem, unpinFeedItem};
};
