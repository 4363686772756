import React, {FC} from 'react';
import {Pressable, View} from 'react-native';

import Quantity from '../Quantity';
import {FadeInOut} from '@/components/AnimationWrappers';
import Divider from '@/components/Divider';
import Icon from '@/components/Icon';
import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip';
import {chainsById} from '@/constants/chains';
import {getVerticalHitSlop} from '@/constants/spacing';
import {IMintOfferInfo} from '@/modules/Collect/types';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {useThemedStyles} from '@/theme';
import {formatPrice} from '@/utils/ethereum';

import {styles} from './Offer.style';

interface IProps {
  offer: IMintOfferInfo;
  quantity: number;
  setQuantity: (quantity: number) => void;
  disabled?: boolean;
}

const Offer: FC<IProps> = ({
  offer,
  quantity,
  setQuantity,
  disabled = false,
}) => {
  const style = useThemedStyles(styles);
  const {collectInfoQuery, gasEstimation, selectedOfferTotalPrice} =
    useCollectState();

  return (
    <View>
      <View style={style.row}>
        <Text size="xs" id="collect.quantity" />
        <Quantity
          quantity={quantity}
          setQuantity={setQuantity}
          disabled={disabled}
        />
      </View>

      <Divider />

      <View style={style.row}>
        <View style={style.infoColumn}>
          <Text size="xs" id="collect.totalCost" />
          <Tooltip
            position="top-left"
            containerStyle={style.tooltipContainer}
            content={
              <>
                <FadeInOut enabled={gasEstimation.isFetching}>
                  <View style={style.tooltipRow}>
                    <Text size="xs" id="collect.networkFee" />
                    <Text size="xs">
                      {formatPrice(
                        gasEstimation.totalGas || BigInt(0),
                        offer.price.currency,
                        7,
                      )}
                    </Text>
                  </View>
                </FadeInOut>
                <Divider />
                <View style={style.tooltipRow}>
                  <Text size="xs" id="collect.trackPrice" />
                  <Text size="xs">
                    {formatPrice(
                      BigInt(offer.price.value),
                      offer.price.currency,
                    )}
                  </Text>
                </View>
              </>
            }>
            {({open, close}) => (
              <Pressable
                onPress={open}
                onHoverIn={open}
                onHoverOut={close}
                hitSlop={getVerticalHitSlop('xs')}
                style={style.infoRow}>
                <Text size="xxs" id="collect.includingFees" />
                <Icon size={14} name="info" provider="custom" />
              </Pressable>
            )}
          </Tooltip>
        </View>
        <View style={style.infoColumn}>
          <FadeInOut
            enabled={
              collectInfoQuery.isPreviousData || gasEstimation.isFetching
            }>
            {selectedOfferTotalPrice ? (
              <Text size="s" weight="semibold">
                {formatPrice(selectedOfferTotalPrice, offer.price.currency)}
              </Text>
            ) : null}
          </FadeInOut>
          <Text size="xxs" style={style.chainInfo}>
            {chainsById[offer.chainId]?.name}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Offer;
