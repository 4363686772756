import {useCallback} from 'react';

import {useNotificationsModal} from '@/hooks/useNotificationsModal';
import {useAppSelector} from '@/hooks/useRedux';
import {useDbQuery} from '@/queries/db';
import {
  FEED_QUERY_DEFAULT_OPTIONS,
  IFeedQueryOptions,
  toggleLikeFeedItem,
} from '@/queries/feed';
import {useCreatePlaylist, useDeletePlaylist} from '@/queries/ownedPlaylists';
import {queryClient} from '@/services/reactQuery';
import {Sentry} from '@/services/sentry';
import {selectFollowedArtistsIds, selectUserPlaylists} from '@/store/playlists';
import {selectActiveUserId} from '@/store/user';
import {IFeedEntityType} from '@/types/feed';
import {PlaylistType} from '@/types/playlists';
import {QueryKeys} from '@/types/queryKeys';

/**
 * Allows seeing if an artist is followed and toggling between following/unfollowing them.
 *
 * Note: if you try to follow an artistId which is not in our local DB, this will fail.
 */
export const useArtistFollow = (
  options: IFeedQueryOptions = FEED_QUERY_DEFAULT_OPTIONS,
) => {
  const {openNotificationModal} = useNotificationsModal();

  const {deletePlaylistAsyncMutation} = useDeletePlaylist();
  const {createPlaylistAsyncMutation} = useCreatePlaylist();
  const playlists = useAppSelector(selectUserPlaylists);
  const followedArtistsIds = useAppSelector(selectFollowedArtistsIds);
  const userId = useAppSelector(selectActiveUserId);

  const {db} = useDbQuery();

  const follow = async (artistId: string) => {
    const artist = db.artists[artistId];

    if (!artist) {
      Sentry.captureMessage(
        `Can't follow artist ${artistId} because it is not in the local db cache`,
      );

      return;
    }

    await createPlaylistAsyncMutation({
      title: artist.name,
      type: PlaylistType.artist,
      artistId: artist.id,
    });

    await toggleLikeFeedItem(
      userId,
      artistId,
      IFeedEntityType.artist,
      false,
      options,
    );

    if (!options.skipFeedQueryInvalidation) {
      queryClient.invalidateQueries([QueryKeys.feed, userId]);
    }

    openNotificationModal('notificationsPrompt.artist');
  };

  const unfollow = useCallback(
    async (artistId: string) => {
      const artistFollow = playlists.find(p => p.artistId === artistId);

      if (!artistFollow) {
        return null;
      }
      await deletePlaylistAsyncMutation(artistFollow.id);
      await toggleLikeFeedItem(
        userId,
        artistId,
        IFeedEntityType.artist,
        true,
        options,
      );
      if (!options.skipFeedQueryInvalidation) {
        queryClient.invalidateQueries([QueryKeys.feed, userId]);
      }
    },
    [playlists],
  );
  const getIsFollowed = useCallback(
    (artistId: string) => !!followedArtistsIds[artistId],
    [followedArtistsIds],
  );

  const toggleFollow = useCallback(
    (artistId: string) =>
      getIsFollowed(artistId) ? unfollow(artistId) : follow(artistId),
    [getIsFollowed, playlists],
  );

  return {
    getIsFollowed,
    toggleFollow,
    follow,
    unfollow,
  };
};
