import React, {useMemo} from 'react';

import {ArtistCard} from '@/components/ArtistCard';
import {IAction} from '@/components/CardMenu';
import {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import TrackCard from '@/components/TrackCard/TrackCard';
import {ITrack} from '@/types/common';
import {IFeedItem, IFeedEntityType} from '@/types/feed';

export const FEED_CARD_INLINE_HEIGHT = TRACK_CARD_HEIGHT;

interface IProps {
  feedItem: IFeedItem;
  onTrackPlay: (track: ITrack) => void;
  toggleLike?: (feedItem: IFeedItem) => void;
  toggleHide?: (feedItem: IFeedItem) => void;
  isActive: boolean;
}

const FeedCardInline: React.FC<IProps> = ({
  feedItem,
  onTrackPlay,
  toggleLike,
  toggleHide,
  isActive,
}) => {
  const isLiked = feedItem.userAction === 'like';
  const isHidden = feedItem.userAction === 'hide';

  const actions = useMemo(() => {
    if (isHidden && toggleLike) {
      return [
        {
          onPress: () => toggleLike(feedItem),
          icon: {
            name: 'heart',
            provider: 'custom',
            fill: isLiked,
            color: isLiked ? 'favoritesColor' : 'textColor',
          },
        } satisfies IAction,
      ];
    }

    if (isLiked && toggleHide) {
      return [
        {
          onPress: () => toggleHide(feedItem),
          icon: {
            provider: 'custom',
            name: 'visibilityOff',
          },
        } satisfies IAction,
      ];
    }
  }, [toggleLike, toggleHide, isLiked, isHidden, feedItem]);

  switch (feedItem.entityType) {
    case IFeedEntityType.track:
      return (
        <TrackCard
          track={feedItem.track}
          onPlay={onTrackPlay}
          showArtist
          isActive={isActive}
          exposedActions={actions}
        />
      );

    case IFeedEntityType.artist:
      return <ArtistCard artist={feedItem.artist} actions={actions} />;
    case IFeedEntityType.message:
      // don't show messages once they've been read
      return null;
  }
};

export default React.memo(FeedCardInline);
