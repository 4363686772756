import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {fetchAllPlatforms, fetchPlatformTracks} from '@/api/platforms';
import {useDbQuery} from '@/queries/db';
import {ITrack} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {getAllTracksList, getTracksList} from '@/utils/db';
import {getNextPageParam} from '@/utils/pagination';
import {sortTracksByDate} from '@/utils/tracks';

export const usePlatformsQuery = (isRoot = false) => {
  const query = useQuery([QueryKeys.platforms], fetchAllPlatforms, {
    refetchOnMount: isRoot,
  });

  const platforms = query.data || [];
  const platformNames: {[platformId: string]: string} = useMemo(
    () =>
      platforms.reduce(
        (result, platform) => ({
          ...result,
          [platform.id]: platform.name,
        }),
        {},
      ),
    [platforms],
  );

  return {
    platforms,
    platformNames,
    query,
  };
};

export const usePlatformTracksQuery = (platformId: string) => {
  const {db, updateDb} = useDbQuery();
  const query = useInfiniteQuery(
    [QueryKeys.platformTracks, platformId],
    async ({pageParam}) => {
      const {items: tracks, pageInfo} = await fetchPlatformTracks(
        platformId,
        pageParam,
      );
      updateDb({tracks});
      return {
        pageInfo,
        items: tracks.map(track => track.id),
      };
    },
    {
      staleTime: 1000 * 60,
      getNextPageParam,
    },
  );

  const tracks = useMemo(() => {
    // if there is no data return locally filtered data from central tracks cache
    if (!query.data) {
      return getAllTracksList(db)
        .filter(track => track.platformId === platformId)
        .sort(sortTracksByDate);
    }

    return query.data.pages.reduce<ITrack[]>(
      (result, page) => [...result, ...getTracksList(db, page.items)],
      [],
    );
  }, [query.data, db, platformId]);

  return {
    tracks,
    query,
  };
};

export const useEmbedPlatformQuery = (id?: string) => {
  const query = useQuery(
    [QueryKeys.artistDetails, id],
    () => fetchPlatformTracks(id!),
    {
      enabled: !!id,
    },
  );

  return {
    tracks: query.data?.items || [],
    query,
  };
};
