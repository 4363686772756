import React, {FC, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {
  SlideInLeft,
  SlideInRight,
  SlideOutLeft,
  SlideOutRight,
} from 'react-native-reanimated';

import CardButton from '@/components/CardButton';
import Checkbox from '@/components/Checkbox';
import Collapse from '@/components/Collapse';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import Modal, {IModalProps} from '@/components/Modal';
import ScrollContainer from '@/components/ScrollContainer';
import ShadowFooter from '@/components/ShadowFooter';
import SpinampWalletIcon from '@/components/SpinampWalletIcon';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useIsInitialRender} from '@/hooks/useIsInitialRender';
import {useResponsive} from '@/hooks/useResponsive';
import {WalletSelectItem} from '@/modules/Wallets';
import AddWalletButton from '@/modules/Wallets/components/AddWalletButton';
import {IWalletsSettings} from '@/modules/Wallets/types';
import {useUpdateUserMetadataMutation} from '@/queries/user';
import {Passkey} from '@/services/passkey';
import {useThemedStyles} from '@/theme';
import {IUser, IUserMetadata} from '@/types/user';
import {prettifyAddress} from '@/utils/ethereum';
import {
  getUserPasskeyWallet,
  getUserWallets,
  sortUserWallets,
} from '@/utils/user';

import {styles} from './WalletsPicker.style';

type Step = keyof IWalletsSettings;

interface IProps extends IModalProps {
  user: IUser;
  settings: IWalletsSettings;
  onAddPasskey: () => void;
  onAddExternal: () => void;
  onConfirm: (settings: IWalletsSettings) => void;
  chainId?: number;
}

const WalletsPicker: FC<IProps> = ({
  user,
  settings,
  onConfirm,
  onAddPasskey,
  onAddExternal,
  chainId,
  ...modalProps
}) => {
  const style = useThemedStyles(styles);
  const {isMobile} = useResponsive();

  const updateUserMetadataMutation = useUpdateUserMetadataMutation();

  const [step, setStep] = useState<Step>('paymentAddress');

  const [paymentAddress, setPaymentAddress] = useState(settings.paymentAddress);
  const [deliveryAddress, setDeliveryAddress] = useState(
    settings.deliveryAddress,
  );
  const [saveDefaultPayment, setSaveDefaultPayment] = useState(false);
  const [saveDefaultDelivery, setSaveDefaultDelivery] = useState(false);
  const [useSame, setUseSame] = useState(
    settings.paymentAddress === settings.deliveryAddress,
  );

  const passkeyWallet = getUserPasskeyWallet(user);
  const hasMultipleWallets = getUserWallets(user).length > 1;

  const handlers = {
    paymentAddress: {
      address: paymentAddress,
      setAddress: setPaymentAddress,
      defaultWallet: user.metadata?.defaultPaymentWallet,
    },
    deliveryAddress: {
      address: deliveryAddress,
      setAddress: setDeliveryAddress,
      defaultWallet: user.metadata?.defaultDeliveryWallet,
    },
  };

  const confirm = () => {
    if (saveDefaultPayment || saveDefaultDelivery) {
      const metadata: Partial<IUserMetadata> = {};
      saveDefaultPayment && (metadata.defaultPaymentWallet = paymentAddress);
      saveDefaultDelivery && (metadata.defaultDeliveryWallet = deliveryAddress);

      updateUserMetadataMutation.mutate({
        user,
        metadataUpdate: metadata,
      });
    }

    onConfirm({
      paymentAddress,
      deliveryAddress:
        step === 'paymentAddress' ? paymentAddress : deliveryAddress,
    });
  };

  const isInitialRender = useIsInitialRender();

  return (
    <Modal
      {...modalProps}
      style={style.modal}
      animatedContainerProps={{
        key: step,
        entering:
          isInitialRender || !isMobile
            ? undefined
            : step === 'deliveryAddress'
            ? SlideInRight
            : SlideInLeft,
        exiting: !isMobile
          ? undefined
          : step === 'paymentAddress'
          ? SlideOutLeft
          : SlideOutRight,
      }}>
      <ScrollContainer contentStyle={style.scrollContent}>
        <View style={style.header}>
          <View style={style.headerAction}>
            {step === 'paymentAddress' ? (
              <IconButton
                icon={{name: 'close', provider: 'custom'}}
                onPress={modalProps.onClose}
              />
            ) : (
              <IconButton
                icon={{name: 'arrowLeft', provider: 'custom'}}
                onPress={() => setStep('paymentAddress')}
              />
            )}
          </View>
          <View style={style.headerTitle}>
            <Text
              align="center"
              weight="semibold"
              size="s"
              id={
                step === 'deliveryAddress'
                  ? 'collect.walletPicker.delivery.title'
                  : 'collect.walletPicker.payment.title'
              }
              numberOfLines={1}
            />
          </View>
          <View style={[style.headerAction, style.stepper]}>
            {hasMultipleWallets && (
              <Text weight="semibold">{`${step === 'deliveryAddress' ? 2 : 1}/${
                useSame ? '1' : '2'
              }`}</Text>
            )}
          </View>
        </View>

        {!passkeyWallet && Passkey.isSupported && step === 'paymentAddress' && (
          <View style={style.addSpinampWalletContainer}>
            <SpinampWalletIcon
              size={spacing.s * 2}
              style={style.spinampWalletIcon}
            />

            <Text
              align="center"
              size="xs"
              weight="semibold"
              id="wallets.addSpinampWallet.header"
            />

            <Text
              align="center"
              size="xs"
              id="wallets.addSpinampWallet.description"
            />

            <CardButton
              onPress={onAddPasskey}
              text={{id: 'wallets.addSpinampWallet', size: 'xs'}}
            />
          </View>
        )}

        <View style={style.walletsHeader}>
          <Text size="s" id="collect.walletPicker.header" />
        </View>

        {sortUserWallets(user).map(wallet => (
          <WalletSelectItem
            key={wallet.address}
            wallet={wallet}
            onSelect={handlers[step].setAddress}
            selectedWalletAddress={handlers[step].address}
            chainId={chainId}
          />
        ))}
        {onAddExternal && <AddWalletButton onPress={onAddExternal} />}
      </ScrollContainer>
      {step === 'paymentAddress' ? (
        <ShadowFooter style={style.footer}>
          {step === 'paymentAddress' && hasMultipleWallets && (
            <TouchableOpacity
              activeOpacity={0.6}
              style={style.checkboxContainer}
              onPress={() => setUseSame(v => !v)}>
              <Checkbox value={useSame} />
              <Text size="xs" id="collect.walletPicker.sameReceiving" />
            </TouchableOpacity>
          )}

          <Collapse
            isOpen={
              hasMultipleWallets &&
              paymentAddress !== user?.metadata?.defaultPaymentWallet
            }
            containerStyle={style.collapseWrapper}>
            <View style={style.collapseContent}>
              <TouchableOpacity
                activeOpacity={0.6}
                style={style.checkboxContainer}
                onPress={() => setSaveDefaultPayment(v => !v)}>
                <Checkbox value={saveDefaultPayment} />
                <Text
                  size="xs"
                  id="collect.walletPicker.setAsDefault.payment"
                />
              </TouchableOpacity>
            </View>
          </Collapse>

          <CardButton
            onPress={useSame ? confirm : () => setStep('deliveryAddress')}
            text={{
              id: useSame
                ? 'collect.walletPicker.confirm'
                : 'collect.walletPicker.chooseReceiving',
            }}
          />
        </ShadowFooter>
      ) : (
        <ShadowFooter style={style.footer}>
          <Collapse
            isOpen={
              hasMultipleWallets &&
              deliveryAddress !== user.metadata?.defaultDeliveryWallet
            }
            containerStyle={style.collapseWrapper}>
            <View style={style.collapseContent}>
              <TouchableOpacity
                activeOpacity={0.6}
                style={style.checkboxContainer}
                onPress={() => setSaveDefaultDelivery(v => !v)}>
                <Checkbox value={saveDefaultDelivery} />
                <Text
                  size="xs"
                  id="collect.walletPicker.setAsDefault.delivery"
                />
              </TouchableOpacity>
            </View>
          </Collapse>

          <View style={style.selectedWallets}>
            <TouchableOpacity
              activeOpacity={0.8}
              style={style.selectedWallet}
              onPress={() => setStep('paymentAddress')}>
              <Text
                size="xs"
                weight="semibold"
                id="collect.walletsSettings.paymentMethod"
              />
              <View style={style.addressRow}>
                <Text size="xs">{prettifyAddress(paymentAddress)}</Text>
                <Icon name="arrowRight" provider="custom" size={14} />
              </View>
            </TouchableOpacity>

            <View style={style.selectedWallet}>
              <Text
                size="xs"
                weight="semibold"
                id="collect.walletsSettings.receivingWallet"
                align="right"
              />
              <Text size="xs" align="right">
                {prettifyAddress(deliveryAddress)}
              </Text>
            </View>
          </View>
          <CardButton
            onPress={confirm}
            text={{id: 'collect.walletPicker.confirm'}}
          />
        </ShadowFooter>
      )}
    </Modal>
  );
};

export default WalletsPicker;
