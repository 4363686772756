import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
  },
  headerSide: {
    flex: 1,
    flexDirection: 'column',
  },
  headerLineTop: {
    height: 2,
    backgroundColor: theme.colors.invertedTextColor,
    paddingHorizontal: 15,
    marginHorizontal: 15,
  },
  headerLineBottomLeft: {
    height: 2,
    backgroundColor: theme.colors.invertedTextColor,
    marginHorizontal: 10,
    paddingRight: 15,
    marginRight: 20,
  },
  headerLineBottomRight: {
    height: 2,
    backgroundColor: theme.colors.invertedTextColor,
    marginHorizontal: 10,
    paddingRight: 15,
    marginLeft: 20,
  },
  headerLineSeparator: {
    height: 3,
  },
}));
