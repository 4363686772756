import React, {FC, ReactNode} from 'react';
import {useBalance} from 'wagmi';

import AddressInfo from '@/components/AddressInfo';
import FlatCard from '@/components/FlatCard';
import Space from '@/components/Space';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {formatPrice} from '@/utils/ethereum';

import {styles} from './WalletCard.style';

export interface IWalletCardProps {
  wallet: IAddress;
  onPress: (address: string) => void;
  isSelected?: boolean;
  chainId?: number;
  suffix?: ReactNode;
  disabled?: boolean;
  showConnectionIndicator?: boolean;
}

const WalletCard: FC<IWalletCardProps> = ({
  wallet,
  onPress,
  isSelected = false,
  chainId,
  suffix,
  disabled = false,
  showConnectionIndicator = true,
}) => {
  const style = useThemedStyles(styles);

  const {data: balance} = useBalance({
    address: wallet.address,
    chainId,
    enabled: !!chainId,
  });

  return (
    <FlatCard
      onPress={() => onPress(wallet.address)}
      disabled={disabled}
      isSelected={isSelected}
      style={style.wallet}>
      <Space flex>
        <AddressInfo
          address={wallet}
          avatarSize={spacing.m * 1.5}
          showConnectionIndicator={showConnectionIndicator}
        />
      </Space>
      {chainId && balance && (
        <Text size="xs">
          {formatPrice(
            balance.value,
            {
              decimals: balance.decimals,
              symbol: balance.symbol,
            },
            4,
          )}
        </Text>
      )}
      {suffix}
    </FlatCard>
  );
};

export default WalletCard;
