import {useConnectModal} from '@rainbow-me/rainbowkit';
import {
  useAccount,
  useSignMessage,
  useConfig,
  useNetwork,
  useConnect,
  useWalletClient,
} from 'wagmi';
import 'wagmi/window';

import {IUseExternalSigner} from '@/modules/ExternalWallet/useExternalSigner/types';
import {analytics} from '@/utils/analytics';
import {formatAddressChecksum} from '@/utils/ethereum';
import {isMobileBrowser} from '@/utils/platform';

export const useExternalSigner = (): IUseExternalSigner => {
  const {openConnectModal} = useConnectModal();
  const {connect: directConnect, connectors} = useConnect();
  const {data: walletClient} = useWalletClient();

  const account = useAccount();
  const config = useConfig();
  const {signMessageAsync} = useSignMessage();
  const address = account?.address
    ? formatAddressChecksum(account.address)
    : undefined;
  const {chain} = useNetwork();

  const connect = () => {
    analytics.externalWalletConnectStarted();
    if (isMobileBrowser) {
      directConnect({connector: connectors[0]});
    } else {
      openConnectModal?.();
    }

    return Promise.resolve(undefined);
  };

  const signMessage = async (message: string): Promise<string> => {
    if (!address) {
      return Promise.reject('No wallet connected!');
    }

    return signMessageAsync({message});
  };

  const switchNetwork = async (chainId: number) => {
    try {
      await config.connector?.switchChain?.(chainId);
    } catch (error) {}
  };

  const injectedEthereum = window.ethereum;
  const canSwitchAccountsProgrammatically = !!injectedEthereum;
  const switchAccounts = canSwitchAccountsProgrammatically
    ? () =>
        injectedEthereum
          .request({
            method: 'wallet_requestPermissions',
            params: [
              {
                eth_accounts: {},
              },
            ],
          })
          .then(walletPermissions => walletPermissions.length)
    : undefined;

  return {
    isConnected: !!address && account.isConnected,
    connector: account?.connector?.id,
    address,
    connect,
    switchAccounts,
    signMessage,
    switchNetwork,
    activeNetwork: chain?.id,
    sendTransaction: walletClient?.sendTransaction,
  };
};
