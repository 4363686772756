import React from 'react';

import {useTransferState} from '@/modules/Transfer/useTransferState';
import {
  WalletPicker,
  AddExternalWalletModal,
  AddPasskeyWalletModal,
} from '@/modules/Wallets';
import AddressInputModal from '@/modules/Wallets/components/AddressInputModal';

const TransferModals = () => {
  const {
    user,
    transferParams,
    openedModal,
    setOpenedModal,
    closeModal,
    deliveryWalletAddress,
    setDeliveryWalletAddress,
  } = useTransferState();

  if (!user) {
    return null;
  }

  if (openedModal === 'walletPicker') {
    return (
      <WalletPicker
        titleId="transfer.walletPicker.title"
        isOpen
        user={user}
        selectedWalletAddress={deliveryWalletAddress}
        excludedAddress={transferParams.from}
        onConfirm={setDeliveryWalletAddress}
        chainId={transferParams.chainId}
        onClose={closeModal}
        onAddExternalWallet={() => setOpenedModal('addExternalWallet')}
        onTypeOtherAddress={() => setOpenedModal('addCustomAddress')}
        avoidKeyboard
      />
    );
  }

  if (openedModal === 'addCustomAddress') {
    return (
      <AddressInputModal
        isOpen
        onSubmit={setDeliveryWalletAddress}
        onClose={closeModal}
      />
    );
  }

  if (openedModal === 'addPasskeyWallet') {
    return (
      <AddPasskeyWalletModal
        isOpen
        onClose={() => setOpenedModal('walletPicker')}
        onSuccess={setDeliveryWalletAddress}
      />
    );
  }

  if (openedModal === 'addExternalWallet') {
    return (
      <AddExternalWalletModal
        isOpen
        onClose={() => setOpenedModal('walletPicker')}
        onSuccess={setDeliveryWalletAddress}
      />
    );
  }

  return null;
};

export default TransferModals;
