import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  imageContainer: {
    height: '100%',
    flexShrink: 1,
    overflow: 'hidden',
  },
  bufferingIndicator: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer: {
    flexGrow: 1,
    padding: spacing.xs,
    alignItems: 'flex-start',
    borderTopWidth: 0,
    borderColor: theme.colors.borderColor,
  },
  collectButton: {
    paddingHorizontal: 0,
  },
}));
