import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {FC, useEffect, useState} from 'react';
import {Linking, ScrollView} from 'react-native';

import {linkAddressToUser, linkSpinampWallet} from '@/api/user';
import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon/Icon';
import Modal, {IModalProps} from '@/components/Modal';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAppSelector} from '@/hooks/useRedux';
import {predictSpinampWallet} from '@/modules/Wallets/passkeyWallet';
import {parsePasskeyError, Passkey, PasskeyError} from '@/services/passkey';
import {Sentry} from '@/services/sentry';
import {selectActiveUserSigner} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {QueryKeys} from '@/types/queryKeys';
import {IInternalSigner} from '@/types/session';
import {createInternalSigner} from '@/utils/signer';
import {getUserName} from '@/utils/user';

import {styles} from './AddPasskeyWalletModal.style';

interface IProps extends IModalProps {
  onSuccess?: (address: string) => void;
}

const AddPasskeyWalletModal: FC<IProps> = ({onSuccess, ...modalProps}) => {
  const style = useThemedStyles(styles);
  const queryClient = useQueryClient();

  const user = useActiveUser();
  const userSigner = useAppSelector(selectActiveUserSigner);

  const [smartWalletAddress, setSmartWalletAddress] = useState<string>();
  const [credentialId, setCredentialId] = useState<string>();
  const [error, setError] = useState<{messageId: string; error?: any}>();

  const createPasskey = useMutation({
    mutationFn: async () => {
      if (!user) {
        throw new Error('Missing user');
      }

      const passkey = await Passkey.create({
        id: user.id,
        name: getUserName(user) || user.id,
      });

      setCredentialId(passkey.credentialId);
    },
    onError: _error => {
      const _errorMessage = parsePasskeyError(_error);

      if (!_errorMessage) {
        return;
      }

      Sentry.captureException(_error, {
        tags: {
          addingPasskey: true,
          passkey: _error instanceof PasskeyError,
        },
      });

      setError({
        messageId: _errorMessage,
        error: _error,
      });
    },
  });

  const linkPasskey = useMutation({
    mutationFn: async () => {
      if (!user || !userSigner) {
        throw new Error('No user available during adding passkey wallet.');
      }

      if (!credentialId) {
        throw new Error('Passkey credential not set');
      }

      const passkeySigner = createInternalSigner();
      const predictedWallet = await predictSpinampWallet(
        passkeySigner.sessionKey,
      );
      await Passkey.writeBlob({
        credentialId: credentialId!,
        message: passkeySigner.sessionKey,
      });
      await linkAddressToUser({
        userId: user.id,
        signerToLink: passkeySigner,
        authSigner: userSigner as IInternalSigner,
        addressProperties: {
          isPasskey: true,
          isWallet: false,
          isPublic: false,
          isSession: false,
        },
      });
      await linkSpinampWallet(
        user.id,
        passkeySigner,
        predictedWallet,
        credentialId,
      );

      await queryClient.invalidateQueries([QueryKeys.userProfiles]);
      setSmartWalletAddress(predictedWallet.address);
    },
    onError: (_error: any) => {
      const _errorMessage = parsePasskeyError(_error);

      if (!_errorMessage) {
        return;
      }

      Sentry.captureException(_error, {
        tags: {
          passkeyWallet: true,
          passkey: _error instanceof PasskeyError,
        },
      });

      setError({
        messageId: _errorMessage,
        error: _error,
      });
    },
    networkMode: 'always',
    retry: 0,
  });

  const onClose = () => {
    modalProps.onClose();
    if (onSuccess && smartWalletAddress) {
      onSuccess(smartWalletAddress);
    }
  };

  useEffect(() => {
    createPasskey.mutate();
  }, []);

  return (
    <Modal {...modalProps} onClose={onClose} titleId="wallets.addPasskey.title">
      <ScrollView
        showsVerticalScrollIndicator={false}
        bounces={false}
        contentContainerStyle={style.content}>
        {(() => {
          if (!Passkey.isBlobSupported) {
            return (
              <>
                <Text id="passkey.error.blobNotSupported" />
                <CardButton
                  onPress={modalProps.onClose}
                  text={{children: 'Close'}}
                />
              </>
            );
          }

          if (error) {
            return (
              <>
                <Space style={style.error}>
                  <Icon name="warning" provider="custom" />
                  <Text weight="semibold" id="login.error.title" size="m" />
                </Space>
                <Text id={error.messageId} size="xs" />

                {error.error instanceof PasskeyError &&
                  error.error.status === 'SAVE_BLOB_ERROR' && (
                    <CardButton
                      onPress={() => {
                        Linking.openURL(
                          'App-Prefs:PASSWORDS&path=PASSWORD_OPTIONS',
                        );
                        setError(undefined);
                      }}
                      text={{id: 'login.error.enableKeychain'}}
                    />
                  )}

                <CardButton
                  onPress={() => setError(undefined)}
                  text={{id: 'tryAgain'}}
                />
              </>
            );
          }

          if (smartWalletAddress) {
            return (
              <>
                <Text id="wallets.addPasskey.smartWallet.info" />
                <CardButton
                  onPress={onClose}
                  text={{id: 'wallets.addPasskey.smartWallet.confirm'}}
                />
              </>
            );
          }

          if (credentialId) {
            return (
              <>
                <Text id="wallets.addPasskey.linkPasskeyInfo" />
                <CardButton
                  onPress={linkPasskey.mutate}
                  text={{id: 'wallets.addPasskey.linkPasskey'}}
                  isLoading={linkPasskey.isLoading}
                />
              </>
            );
          }

          return (
            <>
              <Text id="wallets.addPasskey.passkeyModal.info" />
              <CardButton
                onPress={createPasskey.mutate}
                text={{id: 'wallets.addPasskey.passkeyModal.action'}}
                isLoading={createPasskey.isLoading}
                loaderProps={{
                  textId: 'wallets.addPasskey.passkeyModal.loading',
                }}
              />
            </>
          );
        })()}
      </ScrollView>
    </Modal>
  );
};

export default AddPasskeyWalletModal;
