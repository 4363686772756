import {useQuery} from '@tanstack/react-query';

import {IGasEstimation} from '@/modules/Transactions/types';
import {estimateGas} from '@/modules/Wallets/passkeyWallet';
import {IAddress} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {
  estimateGasLimit as estimateEOAGasLimit,
  getChainClient,
} from '@/utils/ethereum';

interface IUseGasEstimationInput {
  chainId?: number;
  wallet?: IAddress;
  transaction?: {
    to: string;
    data: string;
    value?: string;
  };
  onError?: (error: unknown) => void;
}

export const useGasEstimation = (
  {chainId, wallet, transaction, onError}: IUseGasEstimationInput,
  enabled = true,
): IGasEstimation => {
  const query = useQuery({
    queryKey: [QueryKeys.gasPrice, chainId, transaction?.data, wallet?.address],
    queryFn: async () => {
      try {
        if (!transaction || !chainId) {
          throw new Error(
            'Transaction params not provided to useGasEstimation query function',
          );
        }

        if (!wallet || !wallet.isPasskey) {
          const client = getChainClient(chainId);
          const from =
            wallet?.address || '0x3470b2cb9Ae4a1147fd1beBA60410C587045D319';

          const [
            gasLimit,
            {maxFeePerGas = BigInt(0), maxPriorityFeePerGas = BigInt(0)},
          ] = await Promise.all([
            estimateEOAGasLimit(chainId, {
              from,
              to: transaction.to,
              data: transaction.data,
              value: transaction.value,
            }),
            client.estimateFeesPerGas(),
          ]);

          return {
            totalGas: maxFeePerGas * gasLimit,
            maxFeePerGas,
            maxPriorityFeePerGas,
            gasLimit,
          };
        } else {
          if (!wallet.metadata?.spinampWallet?.signer) {
            throw new Error(
              `signer metadata missing on wallet: ${wallet.address}`,
            );
          }

          return await estimateGas({
            address: wallet.address,
            signer: wallet.metadata.spinampWallet.signer,
            chainId: chainId,
            transaction: {
              to: transaction.to,
              data: transaction.data,
              value: transaction.value,
            },
          });
        }
      } catch (error) {
        onError?.(error);
        return Promise.reject(error);
      }
    },
    enabled: enabled && !!transaction && !!chainId,
    refetchInterval: 15000,
    keepPreviousData: false,
    cacheTime: 0,
    retry: false,
  });

  return {
    ...query.data,
    isError: query.isError,
    isFetching: query.isFetching,
  };
};
