import TransferABI from '@/abi/TransferABI.json';
import {ITransfer, ITransferParams} from '@/modules/Transfer';
import {AddressString, ITrack} from '@/types/common';
import {IUser} from '@/types/user';
import {generateId} from '@/utils/functions';

export const areParamsValid = ({
  tokenId,
  tokenType,
  contract,
  chainId,
  from,
}: ITransferParams) => {
  return Boolean(
    from &&
      chainId &&
      contract &&
      tokenId &&
      ['erc721', 'erc1155'].includes(tokenType),
  );
};

export const getTransferWriteContractInput = (
  transferParams: ITransferParams,
) => {
  const tokenType = transferParams.tokenType as 'erc721' | 'erc1155';

  if (!['erc721', 'erc1155'].includes(tokenType)) {
    throw new Error('Token standard not supported ');
  }

  const address = transferParams.contract as AddressString;

  const params = {
    erc721: {
      abi: TransferABI.erc721,
      address,
      functionName: 'transferFrom',
      args: [
        transferParams.from,
        transferParams.to,
        BigInt(transferParams.tokenId),
      ],
    },
    erc1155: {
      abi: TransferABI.erc1155,
      address,
      functionName: 'safeTransferFrom',
      args: [
        transferParams.from,
        transferParams.to,
        BigInt(transferParams.tokenId),
        1,
        0x00,
      ],
    },
  };

  return params[tokenType];
};

const getDefaultDeliveryAddress = (
  user: IUser,
  transferParams: ITransferParams,
) => {
  if (transferParams.to) {
    return transferParams.to;
  }

  if (
    user.metadata.defaultDeliveryWallet &&
    user.metadata.defaultDeliveryWallet !== transferParams.from
  ) {
    return user.metadata.defaultDeliveryWallet;
  }

  return undefined;
};

export const initTransferState = ({
  track,
  user,
  transferParams,
}: {
  track: ITrack;
  transferParams: ITransferParams;
  user?: IUser;
}): ITransfer => {
  const id = generateId();

  return {
    createdDate: Date.now(),
    id,
    isMinimized: false,
    transferParams: {
      ...transferParams,
      to: user && getDefaultDeliveryAddress(user, transferParams),
    },
    userId: user?.id,
    slug: track.slug,
    transferStep: 'checkout',
    modal: undefined,
    txHash: undefined,
    userOpHash: undefined,
    transactionError: undefined,
  };
};
