import {useNavigation, StackActions} from '@react-navigation/native';
import React from 'react';
import {StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import CardMenu, {IAction} from '@/components/CardMenu';
import Icon from '@/components/Icon/Icon';
import Link from '@/components/Link';
import Space from '@/components/Space/Space';
import SpinLoader from '@/components/SpinLoader';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import TrackPlaylistToggler from '@/components/TrackPlaylistToggler/TrackPlaylistToggler';
import spacing from '@/constants/spacing';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {addTrackToQueue, selectQueue} from '@/store/player';
import {selectCardsTheming} from '@/store/theme';
import {LocalThemeProvider, useTheme, useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {
  MainStackNavigationParams,
  RootStackNavigationParams,
  Routes,
} from '@/types/routes';
import {isTruthy} from '@/utils/functions';
import {isWeb} from '@/utils/platform';
import {getTrackArtists} from '@/utils/tracks';

import {styles} from './TrackCard.style';

export interface ITrackCardProps {
  track: ITrack;
  onPlay: (track: ITrack, index?: number) => void;
  count?: number;
  isActive?: boolean;
  onDelete?: (track: ITrack) => void;
  showArtist?: boolean;
  showAddToQueue?: boolean;
  navigationAction?: 'push' | 'navigate';
  isDraggable?: boolean;
  isDragged?: boolean;
  drag?: () => void;
  onPress?: () => void;
  // might be provided to be passed to onPlay callback for queue management
  index?: number;
  referral?: string;
  trackStyle?: StyleProp<ViewStyle>;
  artworkContainerStyle?: StyleProp<ViewStyle>;
  artworkhasBorderRadius?: boolean;
  additionalActions?: IAction[];
  exposedActions?: IAction[];
  goToTrackModal?: boolean;
  showLoader?: boolean;
}

export const TrackCardBase: React.FC<ITrackCardProps> = ({
  track,
  onPlay,
  count,
  isActive = false,
  onDelete,
  showArtist = false,
  showAddToQueue = true,
  navigationAction = 'push',
  goToTrackModal = false,
  isDraggable = false,
  isDragged = false,
  drag = () => {},
  referral,
  onPress,
  additionalActions,
  exposedActions,
  trackStyle,
  artworkContainerStyle,
  artworkhasBorderRadius = false,
  index,
  showLoader = false,
}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();
  const navigation = useNavigation<MainStackNavigationParams>();
  const rootNavigation = useNavigation<RootStackNavigationParams>();

  const dispatch = useAppDispatch();
  const {showToast} = useToast();
  const {id, slug, title, lossyArtworkUrl} = track;
  const queue = useAppSelector(selectQueue);

  const goToTrack = () => {
    if (goToTrackModal) {
      const action =
        navigationAction === 'push'
          ? rootNavigation.push
          : rootNavigation.navigate;
      action(Routes.TrackModal, {slug});
      return;
    }
    const action =
      navigationAction === 'push' ? navigation.push : navigation.navigate;

    action(Routes.Track, {
      slug,
      referral,
    });
  };

  const addToQueue = () => {
    dispatch(addTrackToQueue({trackId: id}));
    showToast({textId: 'queue.added'});
  };

  const onTrackCardPress = () => {
    onPlay(track, index);
    onPress?.();
  };

  const getArtistLink = (slugOrId: string) => ({
    to: {
      screen: Routes.MainNavigation,
      params: {
        screen: Routes.Artist,
        params: {slugOrId},
      },
    },
    action:
      navigationAction === 'push'
        ? StackActions.push(Routes.Artist, {slugOrId})
        : undefined,
  });

  const textColor = (actionColorResolver: any) =>
    isActive ? actionColorResolver('activeText') : actionColorResolver();

  return (
    <Space flex style={style.trackCardContainer}>
      <TouchableCard
        disabled={isDragged}
        highlight={isActive}
        onPress={onTrackCardPress}
        containerStyle={style.trackTouchableCardContainer}
        style={[style.track, trackStyle]}
        // add negative hit slop to prevent press events on border near card menu
        hitSlop={{
          right: -theme.box.borderRightWidth,
          bottom: -theme.box.borderBottomWidth,
        }}>
        {({actionColor, pressed}) => {
          const backgroundColor = pressed
            ? 'primary'
            : isActive
            ? 'active'
            : 'background';

          return (
            <>
              {lossyArtworkUrl && (
                <TouchableOpacity
                  onPress={goToTrack}
                  style={[style.artworkContainer, artworkContainerStyle]}
                  activeOpacity={0.8}>
                  <StaticImage
                    resizeWidth={ImageSize.thumbnail}
                    source={{uri: lossyArtworkUrl}}
                    style={style.artwork}
                    hasBorderRadius={artworkhasBorderRadius}
                    fallback={
                      <View style={style.fallbackIcon}>
                        <Icon name="music-tone-alt" size={24} />
                      </View>
                    }
                  />
                </TouchableOpacity>
              )}
              <Space style={style.content}>
                <Space flex pr="xxs" pv="xxs" style={style.trackSpace}>
                  <View style={style.titleRow}>
                    <Text
                      style={style.title}
                      weight="semibold"
                      numberOfLines={1}
                      color={textColor(actionColor)}>
                      {title}
                    </Text>
                    {count && count > 1 && (
                      <Space pl="xs">
                        <Text
                          size="s"
                          weight="light"
                          color={textColor(actionColor)}>
                          (x{count})
                        </Text>
                      </Space>
                    )}
                  </View>
                  {showArtist && (
                    <View style={style.artistLinkWrapper}>
                      {getTrackArtists(track).map((a, _index) => (
                        <React.Fragment key={_index}>
                          {_index > 0 && (
                            <View style={style.artistJoiner}>
                              <Text
                                size="xs"
                                numberOfLines={1}
                                color={textColor(actionColor)}>
                                &
                              </Text>
                            </View>
                          )}
                          <Link {...getArtistLink(a.slug)}>
                            {({hover}) => (
                              <Text
                                size="xs"
                                underline={hover}
                                numberOfLines={1}
                                color={textColor(actionColor)}>
                                {a.name}
                              </Text>
                            )}
                          </Link>
                        </React.Fragment>
                      ))}
                    </View>
                  )}
                </Space>
                {showLoader && (
                  <Animated.View
                    entering={FadeIn}
                    exiting={FadeOut}
                    style={style.loaderContainer}>
                    <SpinLoader />
                  </Animated.View>
                )}
                <TrackPlaylistToggler track={track}>
                  {({openModal}) => (
                    <CardMenu
                      exposedActions={exposedActions}
                      collapsedActions={[
                        ...(additionalActions || []),
                        {
                          icon: {
                            name: 'info',
                            provider: 'custom',
                          },
                          onPress: goToTrack,
                        } satisfies IAction,
                        {
                          icon: {
                            name: 'playlistAdd',
                            provider: 'custom',
                          },
                          onPress: openModal,
                        } satisfies IAction,
                        queue &&
                          showAddToQueue &&
                          ({
                            icon: {
                              name: 'queueAdd',
                              provider: 'custom',
                            },
                            onPress: addToQueue,
                          } satisfies IAction),
                        onDelete &&
                          ({
                            icon: {
                              name: 'minus',
                              provider: 'custom',
                            },
                            onPress: () => onDelete(track),
                          } satisfies IAction),
                      ].filter(isTruthy)}
                      iconColor={textColor(actionColor)}
                      backgroundColor={backgroundColor}
                    />
                  )}
                </TrackPlaylistToggler>
              </Space>
            </>
          );
        }}
      </TouchableCard>
      {isDraggable && (
        <TouchableOpacity
          disabled={isDragged}
          delayLongPress={isWeb ? 0 : 150}
          onLongPress={drag}
          hitSlop={{right: spacing.s}}
          style={style.dragToggler}>
          <Icon size={20} name="drag-handle" provider="material" />
        </TouchableOpacity>
      )}
    </Space>
  );
};

const TrackCardThemed: React.FC<ITrackCardProps> = props => {
  const cardsThemingEnabled = useAppSelector(selectCardsTheming);
  const {artistThemes, platformThemes} = useCustomThemesConfigQuery();
  const {artist, platformId} = props.track;

  if (cardsThemingEnabled) {
    return (
      <LocalThemeProvider
        customTheme={artist.customTheme}
        predefinedThemeName={
          artist.predefinedThemeName ||
          artistThemes[artist.id] ||
          platformThemes[platformId]
        }>
        <TrackCardBase {...props} />
      </LocalThemeProvider>
    );
  }

  return <TrackCardBase {...props} />;
};

export default React.memo(TrackCardThemed);
