import spacing from '@/constants/spacing';
import {calculateBackgroundColorLight, createThemedStyles} from '@/theme';

const ICON_WIDTH = 48;

export const styles = createThemedStyles(theme => ({
  opaqueOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.opaqueOverlay,
  },
  menu: {
    width: 300,
    borderLeftWidth: 1,
    borderColor: theme.colors.borderColor,
    zIndex: 1,
  },
  menu_collapsed: {
    width: ICON_WIDTH + 1,
    borderLeftWidth: 0,
  },
  submenu: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    backgroundColor: theme.colors.backgroundLight,
    borderColor: theme.colors.borderColor,
    borderRightWidth: 2,
    borderLeftWidth: 2,
    transition: 'transform 200ms linear',
    opacity: theme.background?.image ? 0 : 1,
  },
  submenu_opened: {
    // @ts-ignore - it's web only so it's fine
    transform: 'translateX(100%)',
    opacity: 1,
    transition: 'transform 200ms linear, opacity 100ms linear',
    transitionDelay: theme.background?.image ? '150ms, 0s' : undefined,
  },
  container: {
    overflow: 'hidden',
    backgroundColor: calculateBackgroundColorLight(theme),
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  scrollViewContent: {
    paddingTop: spacing.xs,
    flexGrow: 1,
  },
  navigationSection: {
    marginBottom: spacing.xs,
  },
}));
