import {UseInfiniteQueryResult} from '@tanstack/react-query';
import React, {FC, useCallback} from 'react';
import {View} from 'react-native';

import Button from '@/components/Button';
import InfinityList from '@/components/InfinityList';
import Loader from '@/components/Loader';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {OnScroll} from '@/hooks/useAnimatedHeader';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch} from '@/hooks/useRedux';
import {GenericLocalMessageFeedItem} from '@/screens/Feed/components/FeedCard/LocalMessageFeedCard';
import FeedCardInline, {
  FEED_CARD_INLINE_HEIGHT,
} from '@/screens/Feed/components/FeedCardInline';
import FeedInlineError from '@/screens/Feed/components/FeedInlineError';
import {getFeedTrackIds} from '@/screens/Feed/utils';
import {playNewQueue} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {ITrack, PlayContextType} from '@/types/common';
import {IFeedEntityType, IFeedItem, ILocalMessageFeedItem} from '@/types/feed';

import {styles} from './FeedList.style';

interface IProps {
  data: IFeedItem[] | null;
  query: UseInfiniteQueryResult;
  hideActions: boolean;
  toggleLike: (feedItem: IFeedItem | ILocalMessageFeedItem) => void;
  toggleHide: (feedItem: IFeedItem | ILocalMessageFeedItem) => void;
  onScroll: OnScroll;
  playContext: PlayContextType;
  queueTitleId: string;
}

const FeedList: FC<IProps> = ({
  data,
  toggleHide,
  toggleLike,
  query,
  hideActions,
  onScroll,
  playContext,
  queueTitleId = 'feed.title',
}) => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();

  const {isTrackActive} = useIsTrackActive(playContext);

  const onPlay = useCallback(
    (track: ITrack) => {
      const trackIds = getFeedTrackIds(data);

      if (!trackIds || trackIds.length === 0) {
        return;
      }

      dispatch(
        playNewQueue({
          trackIds,
          trackId: track.id,
          context: {
            source: 'Feed',
            type: playContext,
            titleId: queueTitleId,
          },
        }),
      );
    },
    [data, playContext, queueTitleId],
  );

  if (query.isError && (!data || data.length === 0)) {
    return (
      <FeedInlineError
        isFetching={query.isFetching}
        onRefetch={query.refetch}
      />
    );
  }

  return (
    <InfinityList
      data={data}
      itemSize={FEED_CARD_INLINE_HEIGHT}
      keyExtractor={item => item.id}
      extraData={isTrackActive}
      contentContainerStyle={style.scrollContent}
      renderItem={({item}) => {
        const isActive =
          item.entityType === IFeedEntityType.track &&
          isTrackActive(item.track.id);

        return (
          <FeedCardInline
            feedItem={item}
            toggleLike={hideActions ? undefined : toggleLike}
            toggleHide={hideActions ? undefined : toggleHide}
            onTrackPlay={onPlay}
            isActive={isActive}
          />
        );
      }}
      onScroll={onScroll}
      fetchNextPage={query.fetchNextPage}
      hasNextPage={query.hasNextPage}
      isFetchingNextPage={query.isFetchingNextPage || query.isLoading}
      ListEmptyComponent={
        query.isLoading ? (
          <Space pv="s">
            <Loader />
          </Space>
        ) : (
          <GenericLocalMessageFeedItem bodyTextId="feed.empty.hides.body" />
        )
      }
      refresh={query.refetch}
      ListFooterComponent={<ListFooter items={data} query={query} />}
    />
  );
};

export default FeedList;

const ListFooter: React.FC<{
  items: IFeedItem[] | null;
  query: UseInfiniteQueryResult;
}> = ({items, query}) => {
  const style = useThemedStyles(styles);

  if (!query.isLoading && !query.isError && !(items?.length === 0)) {
    return (
      <View style={style.footer}>
        <Button
          isLoading={query.isFetchingNextPage}
          disabled={!query.isError && !query.hasNextPage}
          text={query.hasNextPage ? 'Load More' : 'Nothing more to load'}
          onPress={() => query.fetchNextPage()}
        />
      </View>
    );
  }

  // The contentful error: if there is an error but data is not falsy/empty, the FeedInlineError
  // where the error UI is defined is not rendered.
  // This is where we show the error in this case (for partially loaded data).
  if (items && items.length !== 0 && query.isError) {
    return (
      <View style={style.footer}>
        <Button
          isLoading={query.isFetching}
          onPress={query.refetch}
          textId="error.reload"
        />
        <Text id="error.description" size="xs" />
      </View>
    );
  }
};
