import {useMutation} from '@tanstack/react-query';
import {encodeFunctionData} from 'viem';

import {useStore} from '@/hooks/useRedux';
import {useTransactionEventTracking} from '@/modules/Transactions/eventsTracking';
import {ITransactionEvent} from '@/modules/Transactions/types';
import {useTransferState} from '@/modules/Transfer/useTransferState';
import {getTransferWriteContractInput} from '@/modules/Transfer/utils';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {selectTransferById} from '@/store/transfers/selectors';
import {generateId} from '@/utils/functions';

export const useTrackTransferEvent = () => {
  const {getState} = useStore();

  const {
    transferId,
    track,
    senderWallet,
    deliveryWalletAddress,
    gasEstimation,
  } = useTransferState();

  const {mutate} = useTransactionEventTracking();

  const trackEvent = (eventName: ITransactionEvent['eventName']) => {
    const transfer = selectTransferById(getState(), transferId);

    if (transfer?.userId && senderWallet) {
      mutate({
        id: generateId(),
        timestamp: Date.now(),
        transactionId: transfer.id,
        type: 'transfer' as ITransactionEvent['type'],
        userId: transfer.userId,
        eventName,
        trackId: track.id,
        chainId: transfer.transferParams.chainId,
        tokenId: transfer.transferParams.tokenId,
        address: senderWallet.address,
        isPasskey: isPasskeyWallet(senderWallet),
        receivingAddress: deliveryWalletAddress,
        amount: gasEstimation?.totalGas?.toString(),
        txHash: transfer.txHash,
        userOpHash: transfer.userOpHash,
        error: transfer.transactionError?.message,
        callData: encodeFunctionData(
          getTransferWriteContractInput(transfer.transferParams),
        ),
      });
    }
  };

  return {
    trackEvent,
  };
};
