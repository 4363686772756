export enum QueryKeys {
  db = 'db',

  latestTracks = 'latestTracks',

  tracksByIds = 'tracksByIds',
  trackBySlug = 'trackBySlug',
  trackDetails = 'trackDetails',
  trackEmbed = 'trackEmbed',
  earliestTrackReleaseTime = 'earliestTrackReleaseTime',

  latestArtists = 'latestArtists',
  artistBySlug = 'artistBySlug',
  artistDetails = 'artistDetails',
  artistTracks = 'artistTracks',
  artistsByIds = 'artistsByIds',

  search = 'search',
  searchTracks = 'searchTracks',
  searchArtists = 'searchArtists',
  searchUsers = 'searchUsers',
  searchPlaylists = 'searchPlaylists',

  exploreData = 'exploreData',

  platforms = 'platforms',
  platformTracks = 'platformTracks',

  collection = 'collection',
  addressCollection = 'addressCollection',
  collectionEmbed = 'collectionEmbed',

  userPlaylists = 'userPlaylists',
  playlistById = 'playlistById',
  playlistCollaborators = 'playlistCollaborators',
  playlistWithTracksById = 'playlistWithTracksById',
  collectorPlaylists = 'collectorPlaylists',
  playlistCreator = 'playlistCreator',
  playlistVotes = 'playlistVotes',

  tracksAlbum = 'tracksAlbum',

  trendingTracks = 'trendingTracks',
  trendingPlaylists = 'trendingPlaylists',

  nftActivityForTrack = 'nftActivityForTrack',
  nftActivityForArtist = 'nftActivityForArtist',
  nftActivityForCollector = 'nftActivityForCollector',
  allNFTActivity = 'allNFTActivity',

  collectInfoForTrack = 'collectInfoForTrack',

  nftOfferForTrack = 'nftOfferForTrack',
  nftOfferForPlaylist = 'nftOfferForPlaylist',

  history = 'history',

  releasesForYou = 'releasesForYou',
  feed = 'feed',
  feedCount = 'feedCount',

  customThemesConfig = 'customThemesConfig',
  themes = 'themes',

  userKeys = 'userKeys',
  userProfiles = 'userProfiles',
  userEmail = 'userEmail',
  userBySlug = 'userBySlug',
  usersByIds = 'usersByIds',
  userById = 'userById',
  baseUserByAddress = 'baseUserByAddress',

  notifications = 'notifications',
  walletById = 'walletById',

  spinampWrapped = 'spinampWrapped',

  estimatedTopUpPrice = 'estimatedTopUpPrice',

  notificationsPermissions = 'notificationsPermissions',
  notificationToken = 'notificationToken',

  topUpStatus = 'topUpStatus',

  codePush = 'codePush',

  gasPrice = 'gasPrice',

  walletConnector = 'walletConnector',
}

export const CACHE_EXCLUDED_KEYS = [
  QueryKeys.search,
  QueryKeys.baseUserByAddress,
  QueryKeys.collectInfoForTrack,
  QueryKeys.nftOfferForTrack,
  QueryKeys.nftOfferForPlaylist,

  QueryKeys.estimatedTopUpPrice,
  QueryKeys.topUpStatus,
  QueryKeys.gasPrice,

  QueryKeys.codePush,
];
