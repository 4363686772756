import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useMemo} from 'react';

import {
  fetchPlaylistById,
  fetchPlaylistsForCollector,
  fetchPlaylistCollector,
  fetchAlbumForTrack,
  fetchPlaylistVotes,
  voteOnTrack,
} from '@/api/playlist';
import {fetchTracksByIds} from '@/api/track';
import {fetchUsersByIds} from '@/api/user';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useTracksByIdsQuery} from '@/queries/tracks';
import {selectPlaylistOrFollowedPlaylist} from '@/store/playlists';
import {selectActiveUserId, selectActiveUserSigner} from '@/store/user';
import {IPlaylist, IPlaylistVotes, PlaylistType} from '@/types/playlists';
import {QueryKeys} from '@/types/queryKeys';
import {isLocalPlaylistId, sortPlaylistsByUpdateTime} from '@/utils/playlists';

export const usePlaylistByIdQuery = (id: string) => {
  const userId = useAppSelector(selectActiveUserId);
  // Return playlist from local store if it exists. Fetch it otherwise.
  const {playlist: localPlaylist, parentPlaylistId} = useAppSelector(state =>
    selectPlaylistOrFollowedPlaylist(state, id),
  );

  const query = useQuery({
    queryKey: [QueryKeys.playlistById, id],
    queryFn: () => fetchPlaylistById(id),
    staleTime: 1000 * 30,
    enabled: !isLocalPlaylistId(id),
  });

  const playlist = localPlaylist || query.data;
  const {
    tracks,
    query: {isFetching: areTracksFetching},
  } = useTracksByIdsQuery(playlist?.trackIds);

  return {
    playlist,
    parentPlaylistId,
    playlistTracks: tracks,
    isOwnPlaylist: !!playlist && playlist.collector === userId,
    areTracksFetching,
    query,
  };
};

export const usePlaylistVotesQuery = (id?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.playlistVotes, id],
    queryFn: () => fetchPlaylistVotes(id!),
    enabled: !!id && !isLocalPlaylistId(id),
    staleTime: 0,
  });

  return {
    votes: query.data || {},
    query,
  };
};

export const usePlaylistVoteMutation = (playlistId: string) => {
  const queryClient = useQueryClient();
  const signer = useAppSelector(selectActiveUserSigner);
  const userId = useAppSelector(selectActiveUserId);
  const {showToast} = useToast();
  const queryKey = [QueryKeys.playlistVotes, playlistId];

  return useMutation<
    IPlaylistVotes,
    unknown,
    {trackId: string; upvote: boolean},
    IPlaylistVotes | undefined
  >({
    networkMode: 'always',
    retry: 0,
    onMutate: ({trackId, upvote}) => {
      const votesSnapshot = queryClient.getQueryData<IPlaylistVotes>(queryKey);

      if (userId) {
        queryClient.setQueryData<IPlaylistVotes>(queryKey, votes => ({
          ...votes,
          [trackId]: {
            ...votes?.[trackId],
            [userId]: {upvote},
          },
        }));
      }

      return votesSnapshot;
    },
    mutationFn: async ({trackId, upvote}) => {
      if (!signer) {
        throw new Error('user is not initialized');
      }

      return voteOnTrack(playlistId, trackId, upvote, signer);
    },
    onSuccess: votes => {
      queryClient.setQueryData([QueryKeys.playlistVotes, playlistId], votes);
    },
    onError: (error, variables, context) => {
      if (!error) {
        showToast({textId: 'playlist.votes.networkError'});
      } else {
        showToast({textId: 'playlist.votes.error'});
      }

      if (context) {
        queryClient.setQueryData(queryKey, context);
      }
    },
  });
};

export const usePlaylistCollaboratorsQuery = (playlist?: IPlaylist) => {
  // TODO: handle removing old key from cache
  const query = useQuery({
    queryKey: [
      QueryKeys.playlistCollaborators,
      playlist?.id,
      playlist?.collaborators,
    ],
    queryFn: () => fetchUsersByIds(playlist!.collaborators!.map(c => c.userId)),
    enabled: !!playlist?.collaborators?.length,
    keepPreviousData: true,
    staleTime: 1000 * 60,
  });

  return query.data || [];
};

export const useEmbedPlaylistQuery = (playlistId: string) => {
  const query = useQuery(
    [QueryKeys.playlistWithTracksById, playlistId],
    async () => {
      const playlist = await fetchPlaylistById(playlistId);
      const playlistTracks = await fetchTracksByIds(playlist.trackIds || []);
      return {
        playlist,
        playlistTracks,
      };
    },
  );
  const {playlist, playlistTracks = []} = query.data || {};

  return {
    playlist,
    playlistTracks,
    query,
  };
};

export const useCollectorPlaylistsQuery = (userId?: string) => {
  const query = useQuery(
    [QueryKeys.collectorPlaylists, userId],
    () => fetchPlaylistsForCollector(userId!),
    {
      enabled: !!userId,
    },
  );

  const playlists = useMemo(
    () =>
      (query.data || [])
        .filter(playlist => playlist.type === PlaylistType.custom)
        .sort(sortPlaylistsByUpdateTime),
    [query.data],
  );

  return {
    playlists,
    query,
  };
};

export const usePlaylistCreatorQuery = (playlistId?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.playlistCreator, playlistId],
    queryFn: () => fetchPlaylistCollector(playlistId!),
    enabled: !!playlistId,
    staleTime: 1000 * 60 * 60 * 24,
  });

  return (query.data as string) || undefined;
};

export const useTracksAlbumQuery = (trackId?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.tracksAlbum, trackId],
    queryFn: () => fetchAlbumForTrack(trackId!),
    enabled: !!trackId,
    cacheTime: 1000 * 60 * 5,
  });

  return {
    album: query.data || undefined,
    query,
  };
};
