import {useNavigation} from '@react-navigation/native';
import React, {FC, ReactNode} from 'react';
import {TouchableWithoutFeedback, View} from 'react-native';

import {
  createFeedItemForTrack,
  getArtistModalLink,
  getTrackModalLink,
} from '../../utils';
import {FadeInOut} from '@/components/AnimationWrappers';
import Link from '@/components/Link';
import SlidingText from '@/components/SlidingText';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import {useFavToggle} from '@/hooks/useFavToggle';
import {useAppSelector} from '@/hooks/useRedux';
import {useFeedItemMutation} from '@/queries/feed';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {IFeedItem, IFeedItemWithTrack} from '@/types/feed';
import {ImageSize} from '@/types/media';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {analytics} from '@/utils/analytics';
import {getTrackArtists} from '@/utils/tracks';

import {AudioFeedCard} from './AudioFeedCard';
import {styles} from './TrackFeedCard.styles';

interface IProps {
  feedItem: IFeedItemWithTrack;
  toggleLike?: (feedItem: IFeedItem) => void;
  toggleHide?: (feedItem: IFeedItem) => void;
  isActive: boolean;
  next: () => void;
  setCarouselEnabled: (enabled: boolean) => void;
}

const TrackFeedCard: React.FC<IProps> = ({
  feedItem,
  toggleHide,
  toggleLike,
  isActive,
  next,
  setCarouselEnabled,
}) => {
  const style = useThemedStyles(styles);
  const {track} = feedItem;
  const navigation = useNavigation<RootStackNavigationParams>();
  const activeUserId = useAppSelector(selectActiveUserId);

  const ArtistNames = (
    <View style={style.subtitle}>
      {getTrackArtists(track).map((a, _index) => (
        <React.Fragment key={_index}>
          {_index > 0 && (
            <View style={style.artistJoiner}>
              <Text size="m" numberOfLines={1}>
                &
              </Text>
            </View>
          )}
          <Link {...getArtistModalLink(a.slug)} style={{maxWidth: '100%'}}>
            {({hover}) => (
              <Text
                size="m"
                underline={hover}
                numberOfLines={2}
                selectable={false}>
                {a.name}
              </Text>
            )}
          </Link>
        </React.Fragment>
      ))}
    </View>
  );

  const Title = (
    <Link style={{width: '100%'}} {...getTrackModalLink(track.slug)}>
      {({hover}) => (
        <SlidingText
          size="m"
          weight="semibold"
          style={style.title}
          underline={hover}
          numberOfLines={1}
          selectable={false}>
          {track.title}
        </SlidingText>
      )}
    </Link>
  );

  const isOwnFeed = activeUserId === feedItem.userId;
  const LikeHandler = isOwnFeed ? TrackLikeHandler : SpiedTrackLikeHandler;

  return (
    <LikeHandler feedItem={feedItem} toggleLike={toggleLike}>
      {({isLiked, handleLike}) => (
        <AudioFeedCard
          feature={
            <TouchableWithoutFeedback
              onPress={() =>
                navigation.navigate(Routes.TrackModal, {
                  slug: feedItem.track?.slug,
                })
              }>
              <View style={style.image}>
                <StaticImage
                  resizeWidth={ImageSize.feedCard}
                  source={{uri: track.lossyArtworkUrl}}
                  style={{width: '100%', height: '100%'}}
                  resizeMode="cover"
                  fallback={
                    <FadeInOut enabled style={style.imagePlaceholder} />
                  }
                />
              </View>
            </TouchableWithoutFeedback>
          }
          heading={
            <>
              {Title}
              {ArtistNames}
            </>
          }
          feedItem={feedItem}
          track={track}
          toggleHide={toggleHide}
          isLiked={isLiked}
          handleLike={handleLike}
          isActive={isActive}
          next={next}
          setCarouselEnabled={setCarouselEnabled}
        />
      )}
    </LikeHandler>
  );
};

interface ILikeHandlerProps {
  feedItem: IFeedItemWithTrack;
  toggleLike?: (item: IFeedItem) => void;
  children: (props: ILikeHandlerRenderProps) => ReactNode;
}

interface ILikeHandlerRenderProps {
  isLiked: boolean;
  handleLike: () => void;
}

const TrackLikeHandler: FC<ILikeHandlerProps> = ({
  feedItem,
  toggleLike,
  children,
}) => {
  const isLiked = feedItem.userAction === 'like';

  return children({
    isLiked,
    handleLike: () => {
      toggleLike?.(feedItem);
    },
  });
};

const SpiedTrackLikeHandler: FC<ILikeHandlerProps> = ({feedItem, children}) => {
  const userId = useAppSelector(selectActiveUserId);

  const {isFav: isLiked} = useFavToggle(feedItem.track.id);

  const {mutate: upsertFeedItem} = useFeedItemMutation(userId!, {
    skipOptimisticUpdate: true,
  });

  return children({
    isLiked,
    handleLike: () => {
      upsertFeedItem(
        createFeedItemForTrack(
          userId!,
          feedItem.track,
          isLiked ? null : 'like',
        ),
      );

      analytics.feedItemReacted(
        feedItem.id,
        isLiked ? null : 'like',
        feedItem.entityType,
      );
    },
  });
};

export default React.memo(TrackFeedCard);
