import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const PADDING = spacing.s;

export const styles = createThemedStyles(theme => ({
  modal: {
    maxHeight: '80%',
  },
  scrollContent: {
    padding: PADDING,
    gap: spacing.xs * 1.5,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing.s,
    marginBottom: spacing.s,
  },
  headerAction: {
    width: spacing.s * 2,
  },
  headerTitle: {
    flex: 1,
  },
  stepper: {
    alignItems: 'flex-end',
  },
  addSpinampWalletContainer: {
    marginTop: spacing.xs,
    alignItems: 'center',
    gap: spacing.s,
    padding: spacing.m,
    backgroundColor: theme.colors.background,
    borderRadius: theme.box.borderRadius,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderStyle: 'dashed',
  },
  spinampWalletIcon: {
    alignSelf: 'center',
  },
  walletsHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing.s,
  },
  footer: {
    paddingHorizontal: spacing.s,
    paddingVertical: spacing.m,
    gap: spacing.m,
  },
  checkboxContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xs,
    paddingVertical: spacing.xs,
    marginVertical: -spacing.xs,
  },
  collapseWrapper: {
    marginVertical: -spacing.m / 2,
  },
  collapseContent: {
    paddingVertical: spacing.m / 2,
  },
  selectedWallets: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing.xs,
  },
  selectedWallet: {
    gap: spacing.xxs / 2,
  },
  addressRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxs / 2,
  },
}));
