import React, {FC, ReactNode, useEffect, useRef} from 'react';
import {Dimensions, Platform, Pressable, View} from 'react-native';
import Animated, {
  interpolate,
  runOnJS,
  SharedValue,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';

import IconButton from '@/components/IconButton';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';
import {isNative} from '@/utils/platform';

import {styles, ARROW_ICON_SIZE} from './Carousel.style';

interface IProps<ItemType> {
  items: ItemType[];
  renderItem: (item: ItemType) => ReactNode;
  keyExtractor: (item: ItemType) => string;
  onSelectItem: (item: ItemType) => void;
  activeKey: string;
  itemWidth: number;
  onPress: (item: ItemType) => void;
  disabled?: boolean;
}

const INACTIVE_ITEM_SCALE = 0.6;
const INACTIVE_ITEM_OPACITY = 0.4;

const getArrowPosition = (itemWidth: number, offset = spacing.xs) =>
  itemWidth / 2 + INACTIVE_ITEM_SCALE * itemWidth + offset;

const Carousel = <ItemType,>({
  items,
  renderItem,
  keyExtractor,
  onSelectItem,
  itemWidth,
  activeKey,
  onPress,
  disabled = false,
}: IProps<ItemType>) => {
  const style = useThemedStyles(styles);

  const length = items.length;
  const activeIndex = items.findIndex(item => keyExtractor(item) === activeKey);

  const scrollPosition = useSharedValue(0);

  const onScroll = useAnimatedScrollHandler(event => {
    scrollPosition.value = event.contentOffset.x;

    if (scrollPosition.value % itemWidth === 0) {
      const index = Math.max(Math.round(scrollPosition.value / itemWidth), 0);
      runOnJS(onSelectItem)(items[index]);
    }
  });

  const scrollViewRef = useRef<Animated.ScrollView | null>(null);

  useEffect(() => {
    scrollViewRef.current?.scrollTo({
      x: activeIndex * itemWidth,
      animated: true,
    });
  }, [activeIndex]);

  return (
    <View style={style.container}>
      <View
        style={[
          style.arrowContainer,
          {
            transform: [
              {
                translateX: -getArrowPosition(itemWidth),
              },
            ],
          },
        ]}>
        <IconButton
          hitSlop="m"
          disabled={activeIndex === 0 || disabled}
          onPress={() => {
            onSelectItem(items[activeIndex - 1]);
          }}
          icon={{name: 'arrowLeft', provider: 'custom', size: ARROW_ICON_SIZE}}
        />
      </View>

      <Animated.ScrollView
        ref={scrollViewRef}
        horizontal
        scrollEnabled={isNative && !disabled}
        showsHorizontalScrollIndicator={false}
        bounces={false}
        onScroll={onScroll}
        scrollEventThrottle={16}
        style={style.listContainer}
        contentContainerStyle={[
          // @ts-ignore
          {
            paddingHorizontal: Platform.select({
              // @ts-ignore
              native: (Dimensions.get('window').width - itemWidth) / 2,
              web: `calc(50% - ${itemWidth / 2}px)`,
            }),
          },
        ]}
        snapToInterval={itemWidth}>
        {items.map((item, index) => {
          return (
            <CarouselItem
              key={keyExtractor(item)}
              disabled={disabled}
              onPress={() => {
                onPress(item);
              }}
              index={index}
              scrollPosition={scrollPosition}
              itemWidth={itemWidth}>
              {renderItem(item)}
            </CarouselItem>
          );
        })}
      </Animated.ScrollView>

      <View
        style={[
          style.arrowContainer,
          {
            transform: [
              {
                translateX: getArrowPosition(itemWidth),
              },
            ],
          },
        ]}>
        <IconButton
          hitSlop="m"
          disabled={activeIndex === length - 1 || disabled}
          onPress={() => {
            onSelectItem(items[activeIndex + 1]);
          }}
          icon={{name: 'arrowRight', provider: 'custom', size: ARROW_ICON_SIZE}}
        />
      </View>
    </View>
  );
};

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const CarouselItem: FC<{
  children: ReactNode;
  itemWidth: number;
  index: number;
  onPress: () => void;
  disabled?: boolean;
  scrollPosition: SharedValue<number>;
}> = ({
  children,
  itemWidth,
  scrollPosition,
  index,
  onPress,
  disabled = false,
}) => {
  const animatedStyle = useAnimatedStyle(() => {
    const distanceFromPositionX = scrollPosition.value - index * itemWidth;

    return {
      width: itemWidth,
      transform: [
        {translateX: distanceFromPositionX / 3},
        {
          scale: interpolate(
            Math.abs(distanceFromPositionX),
            [0, itemWidth],
            [1, INACTIVE_ITEM_SCALE],
          ),
        },
      ],
      opacity: interpolate(
        Math.abs(distanceFromPositionX),
        [0, itemWidth],
        [1, INACTIVE_ITEM_OPACITY],
      ),
      zIndex: Math.abs(distanceFromPositionX) <= itemWidth / 2 ? 1 : 0,
    };
  });

  return (
    <AnimatedPressable
      style={animatedStyle}
      disabled={disabled}
      onPress={onPress}>
      {children}
    </AnimatedPressable>
  );
};

export default Carousel;
