import {BottomTabBarProps} from '@react-navigation/bottom-tabs';
import {useNavigation} from '@react-navigation/native';
import React, {FC, useEffect} from 'react';
import {View} from 'react-native';
import {useSharedValue} from 'react-native-reanimated';

import {PlayerBarThemed} from '@/components/PlayerBar';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {styles} from '@/navigation/TabsNavigation/TabsNavigationBar.style';
import TabsNavigationBarItem from '@/navigation/TabsNavigation/TabsNavigationBarItem';
import {
  playNextTrack,
  selectCurrentTrack,
  selectHasNext,
  selectIsActive,
  selectIsPlaying,
  togglePlay,
} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {MainStackNavigationParams} from '@/types/routes';
import HoverOpacity from '@/components/HoverOpacity/HoverOpacity';

const TabsNavigationBar: FC<Omit<BottomTabBarProps, 'insets'>> = ({
  state,
  descriptors,
  navigation,
}) => {
  const style = useThemedStyles(styles);
  const mainStackNavigation = useNavigation<MainStackNavigationParams>();

  const dispatch = useAppDispatch();
  const isPlaying = useAppSelector(selectIsPlaying);
  const currentTrack = useAppSelector(selectCurrentTrack);
  const hasNext = useAppSelector(selectHasNext);
  const isActive = useAppSelector(selectIsActive);
  // We are using local animated value for active route index to make tab buttons animation instant and smooth.
  // Value is updated immediately on tab button press and it's also synced in useEffect to handle external route changes.
  const activeIndex = useSharedValue(state.index);

  useEffect(() => {
    activeIndex.value = state.index;
  }, [state.index]);

  return (
    <View>
      <HoverOpacity isActive={isActive} onPress={() => dispatch(togglePlay())}>
        <PlayerBarThemed
          currentTrack={currentTrack}
          isPlaying={isPlaying}
          hasNext={hasNext}
          onPlayPress={() => dispatch(togglePlay())}
          onNextPress={() => dispatch(playNextTrack())}
        />
      </HoverOpacity>
      <View style={style.tabBar}>
        {state.routes.map((route, index) => {
          const {options} = descriptors[route.key];
          // @ts-ignore - react-navigation support custom props on screen options, but there is no way to add types
          const {icon, titleId} = options;

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (isFocused) {
              mainStackNavigation.popToTop();
            }

            if (!isFocused && !event.defaultPrevented) {
              activeIndex.value = index;
              requestAnimationFrame(() => {
                navigation.navigate(route.name);
              });
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };

          return (
            <TabsNavigationBarItem
              key={route.key}
              onLongPress={onLongPress}
              onPress={onPress}
              index={index}
              activeIndex={activeIndex}
              icon={icon}
              titleId={titleId}
            />
          );
        })}
      </View>
    </View>
  );
};

export default TabsNavigationBar;
