import {useNavigation} from '@react-navigation/native';
import {UseQueryResult} from '@tanstack/react-query';
import React, {createContext, FC, ReactNode, useEffect} from 'react';
import {useBalance} from 'wagmi';

import {useUserById} from '@/hooks/useActiveUser';
import {useAppDispatch} from '@/hooks/useRedux';
import {useTrackCollectInfoQuery} from '@/modules/Collect/queries/collect';
import {
  ICollectTransaction,
  IMintOfferInfo,
  ITrackCollectInfo,
  ITransactionToken,
  TransactionStep,
} from '@/modules/Collect/types';
import {useSyncTransactionState} from '@/modules/Collect/useSyncTransactionState';
import {getOptionalUserWalletById} from '@/modules/Collect/utils';
import {useGasEstimation} from '@/modules/Transactions';
import {IGasEstimation} from '@/modules/Transactions/types';
import {IWalletsSettings} from '@/modules/Wallets/types';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Sentry} from '@/services/sentry';
import {
  pushModal,
  popModal,
  updateTransaction,
  resetTransactionDetails,
} from '@/store/collectTransactions/slice';
import {IAddress, IBalance, ITrack} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';
import {IUser} from '@/types/user';
import {addGasToPrice} from '@/utils/ethereum';
import {getLastItem} from '@/utils/functions';

export type CollectModalRoute =
  | 'walletsPicker'
  | 'addExternalWallet'
  | 'addPasskeyWallet';

export interface ICollectStateContext {
  transactionId: string;
  track: ITrack;
  user: IUser | undefined;
  modal: CollectModalRoute;
  pushModal: (route: CollectModalRoute) => void;
  popModal: () => void;
  walletsSettings: IWalletsSettings | undefined;
  setWalletsSettings: (walletsSettings: IWalletsSettings) => void;
  paymentWallet: IAddress | undefined;
  deliveryWallet: IAddress | undefined;
  transferEnabled: boolean;
  quantity: number;
  setQuantity: (quantity: number) => void;
  collectInfo: ITrackCollectInfo | undefined;
  collectInfoQuery: UseQueryResult<ITrackCollectInfo>;
  selectedOffer: IMintOfferInfo | undefined;
  setSelectedOfferId: (offerId: string | undefined) => void;
  selectedOfferTotalPrice: bigint | undefined;
  txHash: string | undefined;
  setTxHash: (txHash: string | undefined) => void;
  approvalTxHash: string | undefined;
  setApprovalTxHash: (txHash: string | undefined) => void;
  transactionError: any | undefined;
  setTransactionError: (error: any | undefined) => void;
  userOpHash: string | undefined;
  setUserOpHash: (hash: string | undefined) => void;
  approvalUserOpHash: string | undefined;
  setApprovalUserOpHash: (hash: string | undefined) => void;
  transactionStep: TransactionStep;
  setTransactionStep: (step: TransactionStep) => void;
  token: ITransactionToken | undefined;
  setToken: (token: ITransactionToken | undefined) => void;
  balance?: IBalance;
  gasEstimation: IGasEstimation;
  resetTxDetails: () => void;
  closeCollectModal: () => void;
}

export const CollectStateContext = createContext<
  ICollectStateContext | undefined
>(undefined);

interface IProps {
  transactionId: string | undefined;
  children: ReactNode;
  track: ITrack;
  referralAddress?: string;
}

const CollectStateProvider: FC<IProps> = ({
  track,
  referralAddress,
  children,
  transactionId,
}) => {
  const dispatch = useAppDispatch();

  const {transaction, isInitialized} = useSyncTransactionState({
    transactionId,
    track,
    referralAddress,
  });

  const user = useUserById(transaction.userId);

  const navigation = useNavigation<RootStackNavigationParams>();

  const transactionStep = transaction.transactionStep;

  const walletsSettings = transaction.walletsSettings;
  const paymentWallet = getOptionalUserWalletById(
    user,
    walletsSettings?.paymentAddress,
  );
  const deliveryWallet = getOptionalUserWalletById(
    user,
    walletsSettings?.deliveryAddress,
  );
  const transferEnabled =
    walletsSettings?.paymentAddress !== walletsSettings?.deliveryAddress;

  const {collectInfo: _collectInfo, query} = useTrackCollectInfoQuery(
    {
      trackId: track.id,
      userAddress: walletsSettings?.paymentAddress,
      quantity: transaction.quantity,
      referralAddress,
    },
    {
      keepPreviousData: true,
    },
  );

  const collectInfo = transaction.collectInfo || _collectInfo;

  // get selected offer by id or auto-select first if not found
  const selectedOffer =
    collectInfo?.mintOffers.find(
      ({id}) => id === transaction.selectedOfferId,
    ) || collectInfo?.mintOffers[0];

  // set available offers in redux, so we can still show proper UI if offers changed during pending transactions
  useEffect(() => {
    if (!isInitialized || !_collectInfo || transactionStep !== 'checkout') {
      return;
    }

    dispatch(
      updateTransaction({
        id: transaction.id,
        update: {collectInfo: _collectInfo},
      }),
    );
  }, [isInitialized, transaction.id, _collectInfo, transactionStep]);

  const {data: balance} = useBalance({
    address: paymentWallet?.address,
    chainId: selectedOffer?.chainId,
    enabled: !!paymentWallet && !!selectedOffer?.chainId,
    watch: true,
  });

  const gasEstimation = useGasEstimation({
    chainId: selectedOffer?.chainId,
    wallet: paymentWallet,
    transaction: selectedOffer && {
      to: selectedOffer.mintTransaction.to,
      data: selectedOffer.mintTransaction.data,
      value: selectedOffer.mintTransaction.value,
    },
    onError: error => {
      Sentry.captureException(error, {
        tags: {
          collect: true,
          simulation: true,
          passkeyWallet: !!paymentWallet && isPasskeyWallet(paymentWallet),
        },
        extra: {
          trackId: track.id,
          wallet: paymentWallet,
        },
      });
    },
  });

  const updateTxField =
    <T extends keyof ICollectTransaction>(field: T) =>
    (value: ICollectTransaction[typeof field]) =>
      dispatch(
        updateTransaction({id: transaction.id, update: {[field]: value}}),
      );

  return (
    <CollectStateContext.Provider
      value={{
        transactionId: transaction.id,

        track,
        user,

        collectInfo,
        collectInfoQuery: query,

        modal: getLastItem(transaction.modalsStack),
        pushModal: (newRoute: CollectModalRoute) => {
          dispatch(
            pushModal({
              id: transaction.id,
              route: newRoute,
            }),
          );
        },
        popModal: () => {
          dispatch(
            popModal({
              id: transaction.id,
            }),
          );
        },

        walletsSettings,
        setWalletsSettings: updateTxField('walletsSettings'),
        paymentWallet,
        deliveryWallet,
        transferEnabled,

        quantity: transaction.quantity,
        setQuantity: updateTxField('quantity'),

        selectedOffer,
        setSelectedOfferId: updateTxField('selectedOfferId'),

        selectedOfferTotalPrice:
          selectedOffer &&
          addGasToPrice(selectedOffer.price.value, gasEstimation.totalGas),

        txHash: transaction.txHash,
        setTxHash: updateTxField('txHash'),
        approvalTxHash: transaction.approvalTxHash,
        setApprovalTxHash: updateTxField('approvalTxHash'),
        userOpHash: transaction.userOpHash,
        setUserOpHash: updateTxField('userOpHash'),
        approvalUserOpHash: transaction.approvalUserOpHash,
        setApprovalUserOpHash: updateTxField('approvalUserOpHash'),
        transactionError: transaction.transactionError,
        setTransactionError: updateTxField('transactionError'),
        token: transaction.token,
        setToken: updateTxField('token'),

        balance,
        gasEstimation,

        transactionStep,
        setTransactionStep: updateTxField('transactionStep'),

        resetTxDetails: () => {
          dispatch(
            resetTransactionDetails({
              id: transaction.id,
            }),
          );
        },

        closeCollectModal: navigation.goBack,
      }}>
      {children}
    </CollectStateContext.Provider>
  );
};

export default CollectStateProvider;
