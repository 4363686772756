import {useMutation} from '@tanstack/react-query';
import {useStore} from 'react-redux';

import {useActiveUser} from '@/hooks/useActiveUser';
import {useRemoveDeviceTokenMutation} from '@/hooks/usePushNotifications';
import {useAppDispatch} from '@/hooks/useRedux';
import {useDisconnect, useExternalSigner} from '@/modules/ExternalWallet';
import {initializeAnonUser, selectSignerByUserId, signOut} from '@/store/user';
import {IBaseUser} from '@/types/user';
import {isAddressOwnedByUser} from '@/utils/user';

const useSignOutMutation = () => {
  const dispatch = useAppDispatch();
  const {address} = useExternalSigner();
  const {disconnect} = useDisconnect();

  return useMutation({
    onMutate: (user: IBaseUser) => {
      dispatch(signOut(user.id));

      if (address && isAddressOwnedByUser(user, address)) {
        disconnect();
      }
    },
    mutationFn: () => dispatch(initializeAnonUser()),
  });
};

export const useSignOut = () => {
  const {getState} = useStore();
  const activeUser = useActiveUser();

  const removeDeviceTokenMutation = useRemoveDeviceTokenMutation();
  const signOutMutation = useSignOutMutation();

  return {
    signOut: () => {
      const signer = selectSignerByUserId(getState(), activeUser?.id);

      if (activeUser && signer) {
        signOutMutation.mutate(activeUser);

        removeDeviceTokenMutation.mutate({
          userId: activeUser.id,
          signer,
        });
      }
    },
  };
};
