import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/store';

export const selectCollectTransactions = (state: RootState) =>
  state.collectTransactions;

export const selectTransactionById = createSelector(
  [selectCollectTransactions, (state, id?: string) => id],
  (transactions, id) => (id ? transactions[id] : undefined),
);

export const selectCollectTransactionsList = createSelector(
  selectCollectTransactions,
  transactions =>
    Object.values(transactions).sort((a, b) => (b.createdDate = a.createdDate)),
);

export const selectMinimizedTransactions = createSelector(
  selectCollectTransactionsList,
  transactions =>
    transactions.filter(
      t =>
        t.isMinimized &&
        (t.txHash ||
          t.approvalTxHash ||
          t.userOpHash ||
          t.approvalUserOpHash ||
          t.transactionError ||
          t.transactionStep === 'checkout'),
    ),
);
