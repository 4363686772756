import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {fetchArtistsByIds} from '@/api/artist';
import {fetchExploreData} from '@/api/explore';
import {useDbQuery} from '@/queries/db';
import {QueryKeys} from '@/types/queryKeys';

export const useExploreQuery = () => {
  const {db, updateDb} = useDbQuery();
  const query = useQuery({
    queryKey: [QueryKeys.exploreData],
    queryFn: async () => {
      const exploreData = await fetchExploreData();
      const artists = await fetchArtistsByIds(
        exploreData.featuredArtists.map(a => a.id),
      );
      updateDb({artists});
      return exploreData;
    },
    staleTime: 60 * 1000,
  });

  const featuredPlaylists = query.data?.featuredPlaylists || [];
  const featuredPlatforms = query.data?.featuredPlatforms || [];
  const promotedPlatforms = query.data?.promotedPlatforms || [];
  const featuredArtists = useMemo(
    () =>
      (query.data?.featuredArtists || [])
        .map(featuredArtist => ({
          ...featuredArtist,
          artist: db.artists[featuredArtist.id],
        }))
        .filter(featuredArtist => !!featuredArtist.artist),
    [db.artists, query.data],
  );
  const showWrapped = query.data?.showWrapped || false;

  return {
    featuredPlaylists,
    featuredPlatforms,
    promotedPlatforms,
    featuredArtists,
    showWrapped,
    query,
  };
};
