import {spinampServicesApi} from '@/services/spinampServicesApi';
import {ISigner} from '@/types/session';
import {formatFirebaseId} from '@/utils/api';

interface IHistoryTrack {
  trackId: string;
  timestamp: string;
}

export const fetchHistory = async (
  signer: ISigner,
  latestTimestamp?: string,
): Promise<IHistoryTrack[]> => {
  const sig = await signer.signMessage('get-my-events');

  const config = {
    params: {
      latestTimestamp,
      sig,
    },
  };

  const response: IHistoryTrack[] = await spinampServicesApi.get(
    'history',
    config,
  );
  return response.map(historyTrack => ({
    ...historyTrack,
    trackId: formatFirebaseId(historyTrack.trackId),
  }));
};
