import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/store';

export const selectTransactions = (state: RootState) => state.transactions;

export const selectPendingTransactions = createSelector(
  selectTransactions,
  transactions => transactions.pendingTransactions,
);
