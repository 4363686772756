import {
  InfiniteData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {useMemo} from 'react';

import {fetchLatestTracks} from '@/api/db';
import {preloadedData} from '@/db/preloadedData';
import {useAppDispatch} from '@/hooks/useRedux';
import {updateSyncTime} from '@/store/player';
import {IPageInfo} from '@/types/api';
import {IArtist, IDatabase, ITrack} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {getAllTracksList, getTracksList, mergeDb} from '@/utils/db';
import {getNextPageParam} from '@/utils/pagination';
import {sortTracksByDate} from '@/utils/tracks';

// This query is a central place to store all tracks and all artists which were ever fetched during app lifecycle.
// This should be always a single source of truth where we can get tracks and artists by their id.
// Every other query which fetches tracks or artists should always call `updateDb` after getting the data and store only tracks/artists ids as a query result.
// DB uses `preloadedData` as a initial data, which for mobile is latest 3000 tracks and is empty on web.
export const useDbQuery = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const {data} = useQuery<IDatabase>(
    [QueryKeys.db],
    () =>
      queryClient.getQueryData<IDatabase>([QueryKeys.db]) || {
        tracks: {},
        artists: {},
      },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      structuralSharing: false,
      initialData: () =>
        preloadedData || {
          tracks: {},
          artists: {},
        },
    },
  );

  const updateDb = ({
    tracks = [],
    artists = [],
  }: {
    tracks?: ITrack[];
    artists?: IArtist[];
  }) => {
    queryClient.setQueryData<IDatabase>([QueryKeys.db], currentDb =>
      mergeDb(currentDb, {tracks, artists}),
    );
    // necessary to trigger redux queue selector after db is updated
    dispatch(updateSyncTime());
  };

  const db = data as IDatabase;

  return {
    db,
    updateDb,
  };
};

export const useLatestTracksQuery = () => {
  const queryClient = useQueryClient();
  const {db, updateDb} = useDbQuery();
  const query = useInfiniteQuery(
    [QueryKeys.latestTracks],
    async ({pageParam}) => {
      const {items: tracks, pageInfo} = await fetchLatestTracks(pageParam);
      updateDb({tracks});
      return {
        pageInfo,
        items: tracks.map(track => track.id),
      };
    },
    {
      getNextPageParam,
    },
  );

  const tracks = useMemo(() => {
    // if there is no data return tracks from central db cache
    if (!query.data) {
      return getAllTracksList(db).slice(0, 100).sort(sortTracksByDate);
    }

    return query.data.pages.reduce<ITrack[]>(
      (result, page) => [...result, ...getTracksList(db, page.items)],
      [],
    );
  }, [query.data, db]);

  const clearLatestTracksPages = () => {
    queryClient.setQueryData<
      InfiniteData<{pageInfo: IPageInfo; items: string[]}> | undefined
    >([QueryKeys.latestTracks], currentData => {
      if (!currentData) {
        return undefined;
      }
      return {
        pages: currentData.pages.slice(0, 1),
        pageParams: currentData.pageParams.slice(0, 1),
      };
    });
  };

  return {
    tracks,
    clearLatestTracksPages,
    query,
  };
};
