import React, {FC, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import CardButton from '@/components/CardButton';
import {Form} from '@/components/Form';
import Icon from '@/components/Icon/Icon';
import Input from '@/components/Input';
import Loader from '@/components/Loader';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useDisconnect, useExternalSigner} from '@/modules/ExternalWallet';
import {useLoginState} from '@/modules/Login';
import {useUsername} from '@/modules/Login/useUsername';
import {useUserByAddressQuery, useUserProfilesQuery} from '@/queries/user';
import {
  loginWithExternalSigner,
  selectIsLinking,
  signupWithExternalSigner,
} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {Connectors} from '@/types/wallet';
import {shortenAddress} from '@/utils/ethereum';
import {isAddressOwnedByUser} from '@/utils/user';

import {styles} from './LinkWallet.style';

const LinkWallet: FC = () => {
  const dispatch = useAppDispatch();
  const style = useThemedStyles(styles);

  const {users: loggedUsers = []} = useUserProfilesQuery();

  const {address, signMessage, connector} = useExternalSigner();
  const {disconnect, disconnectAsync, isDisconnecting} = useDisconnect();
  const isMetaMask = connector === Connectors.metaMask;

  const isLinked =
    !!address && loggedUsers.some(user => isAddressOwnedByUser(user, address));

  const {closeLoginModal, setLoginRoute} = useLoginState();
  const [loginError, setLoginError] = useState<null | string>(null);

  const {user: returningUser, query: returningUserQuery} =
    useUserByAddressQuery(address);
  const {username, setUsername, isUsernameValid} = useUsername();

  const isLinking = useAppSelector(selectIsLinking);
  const isDisconnectDisabled = isLinking || isDisconnecting;

  const onSignupWithWallet = async () => {
    if (address) {
      try {
        setLoginError(null);
        await dispatch(
          signupWithExternalSigner(
            {
              address,
              signMessage,
            },
            username,
          ),
        );
        closeLoginModal();
        setLoginRoute('initial');
      } catch (error) {
        setLoginError('login.linkWallet.error');
      }
    }
  };

  const onLoginWithWallet = async () => {
    if (address && returningUser) {
      setLoginError(null);
      try {
        await dispatch(
          loginWithExternalSigner(
            {
              address,
              signMessage,
            },
            returningUser,
          ),
        );
        closeLoginModal();
      } catch (error) {
        setLoginError('login.linkWallet.error');
      }
    }
  };

  const onDisconnect = async () => {
    try {
      await disconnectAsync();
      setLoginRoute('initial');
    } catch (e) {
      console.log('Error while disconnecting');
    }
  };

  const Disclaimers = (
    <>
      {isMetaMask ? (
        <Text size="xxs" id="login.linkWallet.disclaimer_metamask" />
      ) : (
        <FadeInOut enabled={isDisconnecting}>
          <TouchableOpacity
            activeOpacity={0.8}
            disabled={isDisconnectDisabled}
            onPress={onDisconnect}>
            <Text
              size="xxs"
              id="login.linkWallet.disclaimer"
              values={{
                disconnect: (
                  <Text
                    id="login.linkWallet.alreadyLinked.disconnect"
                    size="xxs"
                    weight="semibold"
                    underline
                  />
                ),
              }}
            />
          </TouchableOpacity>
        </FadeInOut>
      )}
    </>
  );

  if (isLinked) {
    return (
      <Space style={style.content}>
        <Text
          size="xs"
          align="center"
          id={
            isMetaMask
              ? 'login.linkWallet.alreadyLinked_metamask1'
              : 'login.linkWallet.alreadyLinked'
          }
          values={{
            address: (
              <Text size="s" weight="semibold">
                {address && shortenAddress(address)}
              </Text>
            ),
          }}
        />
        {isMetaMask && (
          <Text
            size="xs"
            align="center"
            id="login.linkWallet.alreadyLinked_metamask2"
          />
        )}
        {!isMetaMask && (
          <CardButton
            onPress={disconnect}
            isLoading={isDisconnecting}
            text={{id: 'disconnect'}}
            loaderProps={{
              textId: 'disconnect.loading',
              size: 'xxs',
            }}
          />
        )}
      </Space>
    );
  }

  if (returningUserQuery.isLoading) {
    return (
      <View style={style.loaderWrapper}>
        <Loader />
      </View>
    );
  }

  if (returningUser) {
    return (
      <Space style={style.content}>
        {loginError && <Text id={loginError} size="xs" align="center" />}

        <Icon style={style.icon} name="checked" provider="custom" size={76} />

        <Text align="center" id="login.linkWallet.header" weight="semibold" />

        <Text id="login.linkWallet.description1" size="xs" />
        <Text id="login.linkWallet.description2" size="xs" />

        <View style={style.actions}>
          <CardButton
            onPress={onLoginWithWallet}
            isLoading={isLinking}
            text={{id: 'login.linkWallet.sign'}}
            loaderProps={{
              textId: 'login.linkWallet.verifying',
            }}
          />
          {Disclaimers}
        </View>
      </Space>
    );
  }

  return (
    <Space style={style.content}>
      {loginError && <Text id={loginError} size="xs" align="center" />}

      <Text weight="semibold" id="login.signup.title" align="center" size="l" />

      <Text id="login.linkWallet.new.description" size="xs" />

      <Form.Field label={{id: 'login.signup.username'}}>
        <Input
          placeholder="Music Lover"
          value={username}
          onChangeText={setUsername}
        />
        <Space h="xxs" />
        <Text
          size="xxs"
          numberOfLines={1}
          id={
            isUsernameValid
              ? 'login.signup.username.description'
              : 'profileEdit.error.restrictedName.short'
          }
        />
      </Form.Field>

      <View style={style.actions}>
        <CardButton
          onPress={onSignupWithWallet}
          isLoading={isLinking}
          text={{id: 'login.linkWallet.new.submit'}}
          loaderProps={{
            textId: 'login.linkWallet.verifying',
          }}
        />
        {Disclaimers}
      </View>
    </Space>
  );
};

export default LinkWallet;
