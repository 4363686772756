import {
  IFeedEntityType,
  IFeedItem,
  IFilterableFeedItem,
  IFeedItemWithArtist,
  IFeedItemWithTrack,
  feedItemFilterableProperties,
  IFeedItemRaw,
} from '@/types/feed';
import {isNotNil} from '@/utils/functions';

/**
 * Sorts by insertionId desc, id desc.
 *
 * This should match the sort we request in our GraphQL query for feed items
 * in src/api/feed.ts
 *
 * @param a
 * @param b
 * @returns positive if a < b, negative if b < a, zero if a == b
 */
export function sortFeedItems(a: IFeedItem, b: IFeedItem): number {
  if (
    isNotNil(b.position) &&
    isNotNil(a.position) &&
    b.position !== a.position
  ) {
    return b.position - a.position;
  }

  if (b.createdAtTime !== a.createdAtTime) {
    return (
      new Date(b.createdAtTime).getTime() - new Date(a.createdAtTime).getTime()
    );
  }
  if (
    isNotNil(b.insertionId) &&
    isNotNil(a.insertionId) &&
    b.insertionId !== a.insertionId
  ) {
    return b.insertionId - a.insertionId;
  }

  // If insertionId is equal, sort by id
  return b.id.localeCompare(a.id);
}

export const isTrack = (item: IFeedItem): item is IFeedItemWithTrack =>
  item.entityType === IFeedEntityType.track;
export const isArtist = (item: IFeedItem): item is IFeedItemWithArtist =>
  item.entityType === IFeedEntityType.artist;

/**
 * Throws away all properties that can't be filtered on.
 *
 * @param feedItem
 * @returns
 */
export const getFilterableProperties = (feedItem: IFeedItemRaw) =>
  feedItemFilterableProperties.reduce(
    (acc, key) => ({...acc, [key]: feedItem[key]}),
    {},
  ) as IFilterableFeedItem;
