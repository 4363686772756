import {useInfiniteQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {
  fetchAllNftActivity,
  fetchNftActivityForArtist,
  fetchNftActivityForCollector,
  fetchNftActivityForTrack,
} from '@/api/nftActivity';
import {CACHE_CONFIG} from '@/constants/cacheConfig';
import {NftEventType} from '@/types/nfts';
import {QueryKeys} from '@/types/queryKeys';
import {flattenItems, getNextPageParam} from '@/utils/pagination';

export const useTrackNftActivityQuery = (
  trackId?: string,
  type?: NftEventType,
) => {
  const query = useInfiniteQuery(
    [QueryKeys.nftActivityForTrack, trackId, type],
    ({pageParam}) => fetchNftActivityForTrack(trackId!, pageParam, type),
    {
      enabled: !!trackId,
      cacheTime: CACHE_CONFIG.nftEventsCacheTime,
      getNextPageParam,
    },
  );
  const nftEvents = useMemo(() => flattenItems(query.data), [query.data]);

  return {
    nftEvents,
    query,
  };
};

export const useArtistNftActivityQuery = (
  artistId?: string,
  type?: NftEventType,
) => {
  const query = useInfiniteQuery(
    [QueryKeys.nftActivityForArtist, artistId, type],
    ({pageParam}) => fetchNftActivityForArtist(artistId!, pageParam, type),
    {
      enabled: !!artistId,
      cacheTime: CACHE_CONFIG.nftEventsCacheTime,
      getNextPageParam,
    },
  );
  const nftEvents = useMemo(() => flattenItems(query.data), [query.data]);

  return {
    nftEvents,
    query,
  };
};

export const useCollectorNftActivityQuery = (
  userId?: string,
  type?: NftEventType,
) => {
  const query = useInfiniteQuery(
    [QueryKeys.nftActivityForCollector, userId, type],
    ({pageParam}) => fetchNftActivityForCollector(userId!, pageParam, type),
    {
      enabled: !!userId,
      cacheTime: CACHE_CONFIG.nftEventsCacheTime,
      getNextPageParam,
    },
  );
  const nftEvents = useMemo(() => flattenItems(query.data), [query.data]);

  return {
    nftEvents,
    query,
  };
};

export const useAllActivityQuery = (
  type?: NftEventType,
  enabled: boolean = true,
) => {
  const query = useInfiniteQuery(
    [QueryKeys.allNFTActivity, type],
    ({pageParam}) => fetchAllNftActivity(pageParam, type),
    {
      enabled: enabled,
      refetchInterval: 60000,
      cacheTime: CACHE_CONFIG.nftEventsCacheTime,
      getNextPageParam,
    },
  );
  const nftEvents = useMemo(() => flattenItems(query.data), [query.data]);

  return {
    nftEvents,
    query,
  };
};
