import {RouteProp, useRoute} from '@react-navigation/native';
import React from 'react';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import ModalScreenWrapper from '@/components/ModalScreenWrapper';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {useTrackBySlugQuery, useTrackDetailsQuery} from '@/queries/tracks';
import {LocalThemeProvider} from '@/theme';
import {RootStackParams, Routes} from '@/types/routes';
import {isIOS} from '@/utils/platform';

import TrackInfo from './TrackInfo';
import TrackScreenContents from './TrackScreenContents';

const TrackScreen = () => {
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const {
    params: {slug},
  } = useRoute<RouteProp<RootStackParams, Routes.TrackModal>>();
  const {
    track,
    query: {isFetching},
  } = useTrackBySlugQuery(slug);
  const {
    trackDetails,
    query: {isInitialLoading: areDetailsLoading},
  } = useTrackDetailsQuery(track?.id);

  const {artistThemes} = useCustomThemesConfigQuery();

  return (
    <LocalThemeProvider
      customTheme={track?.artist.customTheme}
      predefinedThemeName={
        track?.artist.predefinedThemeName ||
        artistThemes[track?.artist.id || '']
      }>
      <ModalScreenWrapper isFullscreenOnMobile desktopSize="l">
        <Header
          title={track?.title}
          showBack
          scrollPosition={scrollPosition}
          modalMode={isIOS}
        />
        <AsyncContent
          isLoading={isFetching && !track}
          isError={!track}
          data={track}
          renderContent={loadedTrack => (
            <TrackScreenContents
              track={loadedTrack}
              areDetailsLoading={areDetailsLoading}
              trackInfo={
                <TrackInfo
                  linkEnabled={false}
                  key={loadedTrack.id}
                  track={loadedTrack}
                  showLoader={areDetailsLoading}
                  description={trackDetails?.description}
                />
              }
              onScroll={onScroll}
            />
          )}
        />
      </ModalScreenWrapper>
    </LocalThemeProvider>
  );
};

export default TrackScreen;
