import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CollectModalRoute} from '@/modules/Collect/CollectStateProvider';
import {ICollectTransaction} from '@/modules/Collect/types';
import {IWalletsSettings} from '@/modules/Wallets/types';
import {IUser} from '@/types/user';
import {getLastItem} from '@/utils/functions';

interface ICollectTransactionsState {
  [id: string]: ICollectTransaction;
}

export const initialState: ICollectTransactionsState = {};

export const collectTransactionsSlice = createSlice({
  name: 'collectTransactions',
  initialState,
  reducers: {
    createCollectTransaction: (
      state,
      action: PayloadAction<ICollectTransaction>,
    ) => {
      state[action.payload.id] = action.payload;
    },
    removeCollectTransaction: (state, action: PayloadAction<{id: string}>) => {
      delete state[action.payload.id];
    },
    updateTransaction: (
      state,
      action: PayloadAction<{id: string; update: Partial<ICollectTransaction>}>,
    ) => {
      const {id, update} = action.payload;

      if (state[id]) {
        state[id] = {
          ...state[id],
          ...update,
        };
      }
    },
    setTransactionUser: (
      state,
      action: PayloadAction<{
        id: string;
        user: IUser;
        walletsSettings?: IWalletsSettings;
      }>,
    ) => {
      const {id, user, walletsSettings} = action.payload;

      if (state[id]) {
        state[id] = {
          ...state[id],
          userId: user.id,
          walletsSettings,
        };
      }
    },
    pushModal: (
      state,
      action: PayloadAction<{id: string; route: CollectModalRoute}>,
    ) => {
      const {id, route} = action.payload;
      const {modalsStack} = state[id];

      if (state[id]) {
        state[id] = {
          ...state[id],
          modalsStack:
            getLastItem(modalsStack) === route
              ? modalsStack
              : [...modalsStack, route],
        };
      }
    },
    popModal: (state, action: PayloadAction<{id: string}>) => {
      const {id} = action.payload;
      const {modalsStack} = state[id];

      if (state[id]) {
        state[id] = {
          ...state[id],
          modalsStack: modalsStack.slice(0, -1),
        };
      }
    },
    resetTransactionDetails: (state, action: PayloadAction<{id: string}>) => {
      const {id} = action.payload;

      if (state[id]) {
        state[id] = {
          ...state[id],
          txHash: undefined,
          approvalTxHash: undefined,
          userOpHash: undefined,
          approvalUserOpHash: undefined,
          transactionError: undefined,
          token: undefined,
        };
      }
    },
  },
});

export const {
  createCollectTransaction,
  removeCollectTransaction,
  updateTransaction,
  setTransactionUser,
  pushModal,
  popModal,
  resetTransactionDetails,
} = collectTransactionsSlice.actions;

export default collectTransactionsSlice.reducer;
