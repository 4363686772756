import {useState} from 'react';

import {config} from '@/constants/config';
import {useActiveUser} from '@/hooks/useActiveUser';
import {IPlaylistFormData} from '@/types/playlists';

export const usePlaylistForm = (initialData: IPlaylistFormData = {}) => {
  const activeUser = useActiveUser();
  const [title, setTitle] = useState(initialData.title || '');
  const [description, setDescription] = useState(initialData.description || '');
  const [collector, setCollector] = useState(
    initialData.collector || activeUser?.id,
  );
  const [mintable, setMintable] = useState(initialData.mintable || false);
  const [chainId, setChainId] = useState<string | undefined>(
    initialData.chainId || 'optimism',
  );

  const isDescriptionValid =
    description.length <= config.PLAYLIST_DESCRIPTION_MAX_LENGTH;
  const isTitleValid =
    title.length > 0 && title.length <= config.PLAYLIST_TITLE_MAX_LENGTH;
  const isFormValid = isTitleValid && isDescriptionValid;

  return {
    title,
    setTitle,
    description,
    setDescription,
    formattedTitle: title.trim(),
    formattedDescription: description.trim(),
    isFormValid,
    titleMaxLength: config.PLAYLIST_TITLE_MAX_LENGTH,
    descriptionMaxLength: config.PLAYLIST_DESCRIPTION_MAX_LENGTH,
    collector,
    setCollector,
    mintable,
    setMintable,
    chainId,
    setChainId,
  };
};
