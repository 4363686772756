import React, {FC} from 'react';
import {Pressable, StyleProp, View} from 'react-native';

import Icon, {IIconProps} from '@/components/Icon';
import Loader, {ILoaderProps} from '@/components/Loader';
import Text, {TextProps} from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './CardButton.style';

interface IProps {
  onPress: () => void;
  text?: TextProps;
  icon?: IIconProps;
  style?: StyleProp<any>;
  isLoading?: boolean;
  disabled?: boolean;
  loaderProps?: ILoaderProps;
}

const CardButton: FC<IProps> = ({
  onPress,
  text,
  icon,
  disabled = false,
  isLoading = false,
  loaderProps,
  style: customStyle,
}) => {
  const style = useThemedStyles(styles);

  return (
    <Pressable
      onPress={onPress}
      disabled={disabled || isLoading}
      // @ts-ignore
      style={({pressed, hovered}) => [
        style.card,
        hovered && style.hovered,
        pressed && style.pressed,
        (disabled || isLoading) && style.disabled,
        customStyle,
      ]}>
      {({pressed}) => {
        const color = pressed ? 'invertedTextColor' : 'textColor';

        return (
          <>
            <View style={[style.content, isLoading && style.content_loading]}>
              {icon && <Icon color={color} {...icon} />}
              {text && <Text weight="semibold" {...text} color={color} />}
            </View>

            {isLoading && (
              <View style={style.loaderContainer}>
                <Loader {...loaderProps} />
              </View>
            )}
          </>
        );
      }}
    </Pressable>
  );
};

export default CardButton;
