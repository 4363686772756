import {Dimensions} from 'react-native';

import {PILL_HEIGHT} from '@/components/Pill';
import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const PADDING_TOP = spacing.xs;
const PADDING_BOTTOM = spacing.xs * 1.5;
const PADDING_HORIZONTAL = spacing.xs;

export const styles = createThemedStyles(theme => ({
  container: {
    height: PILL_HEIGHT + PADDING_TOP + PADDING_BOTTOM,
    justifyContent: 'center',
  },
  pillsScrollContent: {
    paddingTop: PADDING_TOP,
    paddingHorizontal: PADDING_HORIZONTAL,
    paddingBottom: PADDING_BOTTOM,
    gap: spacing.xs,
  },
  pill: {
    height: PILL_HEIGHT,
    borderRadius: PILL_HEIGHT / 2,
  },
  userPill: {
    paddingLeft: spacing.xxs / 2,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
    paddingTop: PADDING_TOP,
    paddingHorizontal: spacing.m,
    paddingBottom: PADDING_BOTTOM,
  },
  input: {
    minHeight: spacing.l + spacing.xs,
    paddingVertical: 0,
    paddingHorizontal: spacing.xxs,
    flex: 1,
  },
  cancelButton: {
    paddingHorizontal: spacing.xs,
  },
  searchResults: {
    position: 'absolute',
    left: spacing.m,
    top: PILL_HEIGHT + PADDING_TOP + PADDING_BOTTOM,
    width: Math.min(280, Dimensions.get('window').width - spacing.m),
    borderRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.background,
    paddingVertical: spacing.xxs,

    shadowColor: theme.colors.textColor,
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 20,
  },
  searchItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
    paddingVertical: spacing.xs,
    paddingHorizontal: spacing.xs * 1.5,
  },
  divider: {
    paddingHorizontal: spacing.xs * 1.5,
  },
  emptyState: {
    padding: spacing.s,
  },
}));
