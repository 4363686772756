import {gql} from 'graphql-request';

import {CACHE_CONFIG} from '@/constants/cacheConfig';
import {pipelineApi} from '@/services/pipelineApi';
import {IPageInfo, IPaginatedResponse} from '@/types/api';
import {ITrack} from '@/types/common';
import {parseApiTrack, TRACK_FRAGMENT} from '@/utils/apiModelParsers';

export const fetchLatestTracks = async (
  after?: string,
  limit: number = 100,
): Promise<IPaginatedResponse<ITrack>> => {
  const response = await pipelineApi.request(
    gql`
      query LatestTracks($after: Cursor, $limit: Int) {
        allProcessedTracks(
          after: $after
          first: $limit
          orderBy: CREATED_AT_TIME_DESC
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...TrackDetails
          }
        }
      }
      ${TRACK_FRAGMENT}
    `,
    {after, limit},
  );

  const tracks: ITrack[] = response.allProcessedTracks.nodes.map(parseApiTrack);
  const pageInfo: IPageInfo = response.allProcessedTracks.pageInfo;

  return {
    pageInfo,
    items: tracks,
  };
};

export const prefetchLatestTracks = async (
  limit: number = CACHE_CONFIG.latestTracksLimit,
) => {
  const page = await fetchLatestTracks(undefined, limit);
  return page.items;
};
