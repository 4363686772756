import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const ARROW_SIZE = spacing.l;

export const styles = createThemedStyles(() => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxs,
  },
  infoContainer: {
    flex: 1,
  },
  arrowContainer: {
    width: ARROW_SIZE,
  },
  floatingArrow: {
    position: 'absolute',
    right: -ARROW_SIZE - spacing.xxs,
    top: -ARROW_SIZE / 2,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: spacing.s,
    paddingVertical: spacing.xs,
  },
  rowLabel: {
    flexShrink: 1,
  },
  addressContainer: {
    gap: spacing.xxs,
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  tooltipRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxs,
    flexWrap: 'wrap',
  },
  tooltipContent: {
    maxWidth: 250,
    padding: spacing.xxs,
  },
  addressInfo: {
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: spacing.xxs / 2,
  },
}));
