import {useEffect, useRef} from 'react';
import {useStore} from 'react-redux';

import {useActiveUser} from '@/hooks/useActiveUser';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {
  getUserDefaultWalletsSettings,
  initTransactionState,
} from '@/modules/Collect/utils';
import {selectTransactionById} from '@/store/collectTransactions/selectors';
import {
  createCollectTransaction,
  removeCollectTransaction,
  setTransactionUser,
  updateTransaction,
} from '@/store/collectTransactions/slice';
import {ITrack} from '@/types/common';

interface IUseInitTransactionInput {
  transactionId?: string;
  track: ITrack;
  referralAddress?: string;
}

/**
 * Hook responsible for initializing transaction data in redux.
 * If `transactionId` is provided, it will read existing transaction from the store.
 * If `transactionId` is not provided, it will create a transaction in the store and read it
 *
 * It also removes transaction from the store or set is as minimized when component unmounts.
 */
export const useSyncTransactionState = ({
  transactionId,
  track,
  referralAddress,
}: IUseInitTransactionInput) => {
  const {getState} = useStore();
  const dispatch = useAppDispatch();

  const user = useActiveUser();

  const transactionDraft = useRef(
    initTransactionState({track, user, referralAddress}),
  ).current;

  const reduxTransaction = useAppSelector(state =>
    selectTransactionById(state, transactionId || transactionDraft.id),
  );

  const transaction = reduxTransaction || transactionDraft;

  useEffect(() => {
    if (!reduxTransaction) {
      dispatch(createCollectTransaction(transactionDraft));
    }
  }, [reduxTransaction]);

  useEffect(() => {
    return () => {
      const currentTransactionStep = selectTransactionById(
        getState(),
        transaction.id,
      )?.transactionStep;

      if (
        currentTransactionStep === 'checkout' ||
        currentTransactionStep === 'success'
      ) {
        dispatch(removeCollectTransaction({id: transaction.id}));
      } else {
        dispatch(
          updateTransaction({
            id: transaction.id,
            update: {
              isMinimized: true,
            },
          }),
        );
      }
    };
  }, [transaction.id]);

  // Set user data on transaction after using signed in from collect modal
  useEffect(() => {
    if (user) {
      dispatch(
        setTransactionUser({
          id: transaction.id,
          user,
          walletsSettings: getUserDefaultWalletsSettings(user),
        }),
      );
    }
  }, [user?.id, transaction.id]);

  return {
    transaction,
    isInitialized: !!reduxTransaction,
  };
};
