import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import IconButton from '@/components/IconButton';
import InfinityList from '@/components/InfinityList';
import LineSeparator, {LINE_SEPARATOR_HEIGHT} from '@/components/LineSeparator';
import {
  COLLECTOR_NFT_EVENT_HEIGHT,
  CollectorNftEvent,
} from '@/components/NftEvent';
import NftEventsEmpty from '@/components/NftEventsEmpty';
import NftEventsFilters, {
  useNftEventsFilters,
} from '@/components/NftEventsFilters';
import OverScrollMask from '@/components/OverScrollMask';
import PlaylistCard, {PLAYLIST_CARD_HEIGHT} from '@/components/PlaylistCard';
import Screen from '@/components/Screen/Screen';
import Space from '@/components/Space/Space';
import Tabs, {ITab} from '@/components/Tabs';
import Text from '@/components/Text/Text';
import {TRACK_CARD_HEIGHT, CollectedTrackCard} from '@/components/TrackCard';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useCanEditCollector} from '@/hooks/useCanEdit';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch} from '@/hooks/useRedux';
import {useCollectionQuery} from '@/queries/collection';
import {useCollectorNftActivityQuery} from '@/queries/nftActivity';
import {useCollectorPlaylistsQuery} from '@/queries/publicPlaylists';
import {useUserQuery} from '@/queries/user';
import {playNewQueue} from '@/store/player';
import {ThemedScreen, useThemedScreenStyles} from '@/theme';
import {ITrack, PlayContextType} from '@/types/common';
import {
  MainStackNavigationParams,
  MainStackParams,
  Routes,
} from '@/types/routes';
import {getUserAddress, getUserDisplayName, getUserSlug} from '@/utils/user';

import {styles} from './Collector.style';
import CollectorInfo from './components/CollectorInfo';

const tabsConfig: ITab[] = [
  {
    id: 'collection',
    textId: 'collector.collection',
  },
  {
    id: 'playlists',
    textId: 'collector.playlists',
  },
  {
    id: 'activity',
    textId: 'collector.activity',
    icon: {
      name: 'trending-up',
      provider: 'material',
    },
  },
];

const CollectorScreen = () => {
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const dispatch = useAppDispatch();
  const navigation = useNavigation<MainStackNavigationParams>();

  const {
    params: {slug, tab = tabsConfig[0].id},
  } = useRoute<RouteProp<MainStackParams, Routes.Collector>>();

  const changeTab = (newTab: string) => {
    navigation.setParams({tab: newTab});
  };

  const {user, query: userQuery} = useUserQuery(slug);

  const {style, theme} = useThemedScreenStyles(styles, {
    customTheme: user?.customTheme,
    predefinedThemeName: user?.predefinedThemeName,
  });

  const isOwnProfile = useCanEditCollector(user);

  const goToThemeEdit = () =>
    navigation.navigate(Routes.CollectorThemeEdit, {
      slug: getUserSlug(user!),
    });

  const filtersConfig = useNftEventsFilters();
  const userAddress = user ? getUserAddress(user) : undefined;
  const {isTrackActive} = useIsTrackActive(
    isOwnProfile ? PlayContextType.ownCollection : PlayContextType.collection,
    slug,
  );
  const {collection, query: collectionQuery} = useCollectionQuery(user?.id);
  const {playlists, query: playlistsQuery} = useCollectorPlaylistsQuery(
    user?.id,
  );
  const {nftEvents, query: eventsQuery} = useCollectorNftActivityQuery(
    user?.id,
    filtersConfig.activeFilter,
  );
  const showFilters =
    tab === 'activity' &&
    (nftEvents.length > 0 || !!filtersConfig.activeFilter);

  const playCollection = useCallback(
    (track?: ITrack) => {
      const trackIds = collection.map(({id}) => id);

      dispatch(
        playNewQueue({
          trackId: track?.id,
          trackIds,
          context: {
            source: isOwnProfile ? 'OwnCollection' : 'Collection',
            type: isOwnProfile
              ? PlayContextType.ownCollection
              : PlayContextType.collection,
            id: slug,
            titleId: isOwnProfile ? 'playlists.nft' : undefined,
            title: isOwnProfile ? undefined : getUserDisplayName(user!),
          },
        }),
      );
    },
    [collection, isOwnProfile, slug, user],
  );

  return (
    <ThemedScreen theme={theme}>
      <Screen>
        <Header
          title={user ? getUserDisplayName(user) : ''}
          showBack
          scrollPosition={scrollPosition}
          backgroundColor={user ? 'backgroundDark' : undefined}
          rightActions={
            <>
              {isOwnProfile && (
                <IconButton
                  onPress={goToThemeEdit}
                  icon={{name: 'palette', provider: 'custom'}}
                />
              )}
            </>
          }
        />
        <AsyncContent
          isLoading={userQuery.isLoading}
          isError={!user}
          errorProps={{textId: 'collector.notFound'}}
          data={user}
          renderContent={loadedUser => {
            const ListHeaderComponent = (
              <View style={style.header}>
                <OverScrollMask />
                <CollectorInfo user={loadedUser} isOwnProfile={isOwnProfile} />
                <Tabs
                  tabs={tabsConfig}
                  activeTab={tab}
                  setActiveTab={changeTab}
                />
                {showFilters && <NftEventsFilters {...filtersConfig} />}
              </View>
            );

            const commonListProps = {
              getItemType: () => tab,
              contentContainerStyle: style.scrollContent,
              ListHeaderComponent,
              onScroll,
              refresh: () =>
                Promise.all([
                  userQuery.refetch(),
                  playlistsQuery.refetch(),
                  eventsQuery.refetch(),
                  collectionQuery.refetch(),
                ]),
            };

            // We have to return exactly the same component at the root of each tab to prevent header from unmounting and keep scroll position
            if (tab === 'collection') {
              return (
                <InfinityList
                  itemSize={TRACK_CARD_HEIGHT}
                  data={collection}
                  keyExtractor={track => `track_${track.id}`}
                  extraData={isTrackActive}
                  renderItem={({item: track}) => (
                    <CollectedTrackCard
                      ownerAddresses={loadedUser.addresses}
                      isOwnedTrack={isOwnProfile}
                      track={track}
                      isActive={isTrackActive(track.id)}
                      count={track.count}
                      onPlay={playCollection}
                      showArtist
                    />
                  )}
                  fetchNextPage={collectionQuery.fetchNextPage}
                  hasNextPage={collectionQuery.hasNextPage}
                  isFetchingNextPage={
                    collectionQuery.isFetchingNextPage ||
                    collectionQuery.isLoading
                  }
                  ListEmptyComponent={
                    collectionQuery.isLoading ? null : (
                      <Space pv="xs">
                        <Text
                          id="collector.emptyCollection"
                          size="xs"
                          align="center"
                        />
                      </Space>
                    )
                  }
                  {...commonListProps}
                />
              );
            }

            if (tab === 'playlists') {
              return (
                <InfinityList
                  data={playlists}
                  itemSize={PLAYLIST_CARD_HEIGHT}
                  keyExtractor={playlist => `playlist_${playlist.id}`}
                  renderItem={({item: playlist}) => (
                    <PlaylistCard playlist={playlist} showCreator={false} />
                  )}
                  isFetchingNextPage={
                    playlists.length === 0 && playlistsQuery.isLoading
                  }
                  ListEmptyComponent={
                    playlistsQuery.isLoading ? null : (
                      <Space pv="xs">
                        <Text
                          id="collector.emptyPlaylists"
                          size="xs"
                          align="center"
                        />
                      </Space>
                    )
                  }
                  {...commonListProps}
                />
              );
            }

            return (
              <InfinityList
                data={nftEvents}
                itemSize={COLLECTOR_NFT_EVENT_HEIGHT}
                itemSpacing={LINE_SEPARATOR_HEIGHT}
                keyExtractor={event => `event_${event.id}`}
                extraData={userAddress}
                renderItem={({item: event}) => (
                  <CollectorNftEvent event={event} user={loadedUser} />
                )}
                fetchNextPage={eventsQuery.fetchNextPage}
                hasNextPage={eventsQuery.hasNextPage}
                isFetchingNextPage={
                  eventsQuery.isFetchingNextPage || eventsQuery.isLoading
                }
                ItemSeparatorComponent={LineSeparator}
                ListEmptyComponent={
                  eventsQuery.isLoading ? null : (
                    <Space pv="xs">
                      <NftEventsEmpty
                        messageId="collector"
                        filter={filtersConfig.activeFilter}
                        size="xs"
                        align="center"
                      />
                    </Space>
                  )
                }
                {...commonListProps}
              />
            );
          }}
        />
      </Screen>
    </ThemedScreen>
  );
};

export default CollectorScreen;
