import {useMutation} from '@tanstack/react-query';

import {noop} from '@/utils/functions';

export const usePushNotifications = noop;

export const useNotificationsPermissionMutation = () => {
  return {requestPermission: noop};
};

export const useRemoveDeviceTokenMutation = () =>
  useMutation({mutationFn: () => Promise.resolve()});

export const useNotificationsPermissionQuery = () => ({
  areNotificationsEnabled: false,
});
