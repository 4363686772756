import AsyncStorage from '@react-native-async-storage/async-storage';
import {AnyAction, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import createMigrate from 'redux-persist/es/createMigrate';

import {migrations} from '@/store/migrations';
import {isLocalDev} from '@/utils/platform';

import collectTransactionsReducer from './collectTransactions';
import eventsReducer from './events';
import feedReducer from './feed';
import navigationReducer from './navigation';
import playerReducer from './player';
import playlistsReducer from './playlists';
import themeReducer from './theme';
import topUpReducer from './topUp';
import transactionsReducer from './transactions';
import transfersReducer from './transfers';
import userReducer from './user';

const rootReducer = combineReducers({
  player: playerReducer,
  feed: feedReducer,
  playlists: playlistsReducer,
  user: userReducer,
  theme: themeReducer,
  events: eventsReducer,
  navigation: navigationReducer,
  transactions: transactionsReducer,
  topUp: topUpReducer,
  collectTransactions: collectTransactionsReducer,
  transfers: transfersReducer,
});

const persistConfig = {
  key: 'root',
  version: 23,
  storage: AsyncStorage,
  migrate: createMigrate(migrations, {debug: false}),
  blacklist: [
    'navigation',
    'transactions',
    'feed',
    'collectTransactions',
    'transfers',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: isLocalDev,
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export type AppStore = typeof store;
