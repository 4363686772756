import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const SCREEN_PADDING_TOP = spacing.xs;

export const styles = createThemedStyles((theme, responsive) => ({
  content: {
    flex: 1,
  },
  carouselContainer: {
    paddingTop: SCREEN_PADDING_TOP,
  },
  gradientContainer: {
    height: spacing.s,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  tabs: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: spacing.l + spacing.xxs,
    paddingBottom: responsive.isMobile ? spacing.xs * 1.5 : spacing.m,
    width: '100%',
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
}));
