import {useQueryClient} from '@tanstack/react-query';
import {Vibration} from 'react-native';
import {isHex} from 'viem';

import {APPLE_REVIEWER_ACCOUNT} from '@/constants/appleReview';
import {TransactionError} from '@/modules/Collect/errors';
import {IMintOfferInfo} from '@/modules/Collect/types';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {useTrackCollectEvent} from '@/modules/Collect/useTrackCollectEvent';
import {useExternalSigner} from '@/modules/ExternalWallet';
import {sendTransaction, waitForReceipt} from '@/modules/Wallets/passkeyWallet';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Passkey, PasskeyError} from '@/services/passkey';
import {Sentry} from '@/services/sentry';
import {IAddress} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {
  areAddressesEqual,
  getChainClient,
  getTokenFromLogs,
} from '@/utils/ethereum';
import {getSignerFromSessionKey} from '@/utils/signer';

const getPasskeyPrivateKey = (credentialId?: string) => {
  if (credentialId === APPLE_REVIEWER_ACCOUNT.credentialId) {
    return Promise.resolve({privateKey: APPLE_REVIEWER_ACCOUNT.privateKey});
  }

  return Passkey.get(credentialId);
};

export const useCollectTransaction = (offer: IMintOfferInfo) => {
  const queryClient = useQueryClient();

  const {
    user,
    paymentWallet,
    track,
    setTxHash,
    setApprovalTxHash,
    setUserOpHash,
    setApprovalUserOpHash,
    setTransactionError,
    setTransactionStep,
    setToken,
    collectInfoQuery,
    resetTxDetails,
    gasEstimation,
  } = useCollectState();
  const {sendTransaction: sendExternalTransaction} = useExternalSigner();

  const {trackEvent} = useTrackCollectEvent();

  const collectWithPasskeyWallet = async (passkeyWallet: IAddress) => {
    setTransactionStep('waitingForPasskey');
    const {credentialId, signer} = passkeyWallet.metadata?.spinampWallet || {};
    const {privateKey} = await getPasskeyPrivateKey(credentialId);

    if (
      signer &&
      !areAddressesEqual(signer, getSignerFromSessionKey(privateKey).address)
    ) {
      throw new TransactionError('WRONG_PASSKEY_SIGNER');
    }

    const {mintTransaction, approvalTransaction, chainId} = offer;

    if (approvalTransaction) {
      setTransactionStep('approvalTransaction');
      const approvalUserOpHash = await sendTransaction({
        privateKey,
        chainId,
        transaction: {
          to: approvalTransaction.to,
          data: approvalTransaction.data,
        },
      });
      setApprovalUserOpHash(approvalUserOpHash);
      trackEvent('receivedApprovalUserOpHsh');

      const {status, transactionHash: approvalTxHash} = await waitForReceipt({
        privateKey,
        chainId,
        hash: approvalUserOpHash,
      });

      setApprovalTxHash(approvalTxHash);
      trackEvent('receivedApprovalTxHsh');

      if (status !== 'success') {
        throw new TransactionError('APPROVAL_TRANSACTION_REVERTED', {
          chainId,
          txHash: approvalTxHash,
        });
      }
    }

    setTransactionStep('mainTransaction');
    const userOpHash = await sendTransaction({
      privateKey,
      chainId,
      transaction: {
        to: mintTransaction.to,
        data: mintTransaction.data,
        value: BigInt(mintTransaction.value || 0),
      },
      gasSettings: gasEstimation,
    });

    setUserOpHash(userOpHash);
    trackEvent('receivedTransactionUserOpHash');

    const receipt = await waitForReceipt({
      privateKey,
      chainId,
      hash: userOpHash,
    });
    const {status, transactionHash: txHash} = receipt;

    setTxHash(txHash);
    trackEvent('receivedTransactionTxHash');

    if (status !== 'success') {
      throw new TransactionError('TRANSACTION_REVERTED', {chainId, txHash});
    }

    setToken(getTokenFromLogs(receipt.logs, receipt.transactionHash));
  };

  const collectWithExternalWallet = async () => {
    if (!sendExternalTransaction) {
      throw new TransactionError('WALLET_NOT_CONNECTED');
    }

    const {mintTransaction, approvalTransaction, chainId} = offer;

    if (approvalTransaction) {
      setTransactionStep('approvalTransaction');
      const approvalTxHash = await sendExternalTransaction({
        to: approvalTransaction.to,
        data: approvalTransaction.data,
      });
      setApprovalTxHash(approvalTxHash);
      trackEvent('receivedApprovalTxHsh');

      const {status} = await getChainClient(chainId).waitForTransactionReceipt({
        hash: approvalTxHash,
      });

      if (status !== 'success') {
        throw new TransactionError('APPROVAL_TRANSACTION_REVERTED', {
          chainId,
          txHash: approvalTxHash,
        });
      }
    }

    setTransactionStep('mainTransaction');
    const txHash = await sendExternalTransaction({
      to: mintTransaction.to,
      data: mintTransaction.data,
      value: BigInt(mintTransaction.value || 0),
      gas: gasEstimation.gasLimit,
      maxFeePerGas: gasEstimation.maxFeePerGas,
      maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
    });

    if (!txHash || !isHex(txHash)) {
      // For some wallets canceling transaction results in returning "null" txHash instead of throwing an error
      throw new TransactionError('USER_CANCELLED');
    }

    setTxHash(txHash);
    trackEvent('receivedTransactionTxHash');

    const receipt = await getChainClient(chainId).waitForTransactionReceipt({
      hash: txHash,
    });

    if (receipt.status !== 'success') {
      throw new TransactionError('TRANSACTION_REVERTED', {chainId, txHash});
    }

    setToken(getTokenFromLogs(receipt.logs, receipt.transactionHash));
  };

  const executeTransaction = async () => {
    try {
      trackEvent('confirmed');
      resetTxDetails();

      if (!paymentWallet) {
        throw new TransactionError('NO_WALLET_SELECTED');
      }

      setTransactionStep('validatingOffer');

      const {data: refetchedOfferInfo} = await collectInfoQuery.refetch({
        throwOnError: true,
      });
      const refetchedOffer = refetchedOfferInfo?.mintOffers.find(
        ({id}) => id === offer.id,
      );

      if (!refetchedOffer) {
        throw new TransactionError('OFFER_NOT_AVAILABLE');
      }

      if (refetchedOffer.price.value !== offer.price.value) {
        throw new TransactionError('OFFER_PRICE_CHANGED');
      }

      if (isPasskeyWallet(paymentWallet)) {
        await collectWithPasskeyWallet(paymentWallet);
      } else {
        await collectWithExternalWallet();
      }

      setTransactionStep('success');
      trackEvent('success');
      Vibration.vibrate();

      // Invalidate queries related to collected nft after few seconds, so transaction has time to be indexed.
      // Timeout value should be adjusted after indexing time is improved.
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.collection, user?.id],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.addressCollection, paymentWallet.address],
          refetchType: 'all',
        });
      }, 10000);
    } catch (error: any) {
      setTransactionStep('checkout');

      if (
        (error instanceof PasskeyError && error.status === 'USER_CANCELLED') ||
        (error instanceof TransactionError &&
          error.status === 'USER_CANCELLED') ||
        // Those are errors code for canceling transactions in different external wallets I managed to catch
        error?.code === 5000 ||
        error?.cause?.code === 4001
      ) {
        trackEvent('cancelled');
        return;
      }

      Sentry.captureException(error, {
        tags: {
          collect: true,
          passkeyWallet: !!paymentWallet && isPasskeyWallet(paymentWallet),
        },
        extra: {
          trackId: track.id,
          wallet: paymentWallet,
        },
      });
      setTransactionError(error);
      trackEvent('error');
      collectInfoQuery.refetch();
    }
  };

  return {
    executeTransaction,
  };
};
