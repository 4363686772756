import React, {FC} from 'react';
import {View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import CardButton from '@/components/CardButton';
import Divider from '@/components/Divider';
import FlatCard from '@/components/FlatCard';
import ScrollContainer from '@/components/ScrollContainer';
import ShadowFooter from '@/components/ShadowFooter';
import Space from '@/components/Space';
import SpinLoader from '@/components/SpinLoader';
import Text from '@/components/Text';
import {getChainById} from '@/constants/chains';
import {
  EtherscanLink,
  JiffyscanLink,
  useTransactionValidation,
} from '@/modules/Transactions';
import {
  useBalanceValidation,
  useChainValidation,
  useIsWalletConnectedValidation,
} from '@/modules/Transactions';
import TrackInfoCard from '@/modules/Transfer/components/TrackInfoCard';
import {useTransferTransaction} from '@/modules/Transfer/components/useTransferTransaction';
import {useTransferState} from '@/modules/Transfer/useTransferState';
import {useDeliverWalletValidation} from '@/modules/Transfer/validation/useDeliveryWalletValidation';
import {WalletCard} from '@/modules/Wallets';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {useThemedStyles} from '@/theme';
import {formatPrice, prettifyAddress} from '@/utils/ethereum';
import {isNotNil} from '@/utils/functions';

import {styles} from './TransferCheckout.style';

const TransferCheckout: FC = () => {
  const style = useThemedStyles(styles);

  const {
    track,
    transferParams,
    senderWallet,
    balance,
    deliveryWalletAddress,
    deliveryWallet,
    txHash,
    userOpHash,
    transferStep,
    gasEstimation,
    setOpenedModal,
  } = useTransferState();

  const {executeTransfer} = useTransferTransaction();

  const WrongDeliveryWalletError = useDeliverWalletValidation();
  const ConnectedWalletError = useIsWalletConnectedValidation({
    wallet: senderWallet,
  });
  const WrongChainError = useChainValidation({
    wallet: senderWallet,
    chainId: transferParams.chainId,
  });
  const InsufficientBalanceError = useBalanceValidation({
    wallet: senderWallet,
    balance,
    price: gasEstimation.totalGas,
    chainId: transferParams.chainId,
  });
  const InvalidTransactionError = useTransactionValidation(gasEstimation);

  const validationError = [
    WrongDeliveryWalletError,
    ConnectedWalletError,
    WrongChainError,
    InsufficientBalanceError,
    InvalidTransactionError,
  ].filter(isNotNil)[0];

  return (
    <>
      <ScrollContainer contentStyle={style.content}>
        <View style={style.header}>
          <Text id="transfer.sendFrom" size="xs" align="center" />
          {senderWallet?.isPasskey ? (
            <Text size="xs">
              Passkey Wallet ({prettifyAddress(transferParams.from)})
            </Text>
          ) : senderWallet?.ens ? (
            <Text size="xs">
              {senderWallet.ens} ({prettifyAddress(transferParams.from)})
            </Text>
          ) : (
            <Text size="xs">{prettifyAddress(transferParams.from)}</Text>
          )}
        </View>

        <Divider />

        <View>
          <Text style={style.sectionTitle} id="transfer.assetHeader" />
          <TrackInfoCard track={track} />
        </View>

        <Divider />

        <View>
          <Text style={style.sectionTitle} id="transfer.receivingWallet" />
          {deliveryWallet ? (
            <WalletCard
              wallet={deliveryWallet}
              onPress={() => setOpenedModal('walletPicker')}
              disabled={transferStep !== 'checkout'}
              suffix={
                transferStep === 'checkout' ? (
                  <Text
                    style={style.deliveryWalletCardAction}
                    id="transfer.receivingWallet.change"
                    weight="semibold"
                    size="xs"
                  />
                ) : null
              }
            />
          ) : deliveryWalletAddress ? (
            <FlatCard
              style={style.customWalletCard}
              onPress={() => setOpenedModal('walletPicker')}
              disabled={transferStep !== 'checkout'}>
              <Text size="xs">{prettifyAddress(deliveryWalletAddress)}</Text>
              {transferStep === 'checkout' && (
                <Text
                  style={style.deliveryWalletCardAction}
                  id="transfer.receivingWallet.change"
                  weight="semibold"
                  size="xs"
                />
              )}
            </FlatCard>
          ) : (
            <FlatCard
              onPress={() => setOpenedModal('walletPicker')}
              style={style.selectWalletCard}>
              <Text id="transfer.selectReceivingWallet" weight="semibold" />
            </FlatCard>
          )}
        </View>

        <View style={style.transactionDetails}>
          {transferStep !== 'checkout' && (
            <>
              <SpinLoader />

              <Space h="xs" />

              {txHash || (!!senderWallet && isPasskeyWallet(senderWallet)) ? (
                <Text
                  weight="semibold"
                  size="s"
                  id="transfer.pending.waitingForReceipt"
                  align="center"
                />
              ) : (
                <Text
                  weight="semibold"
                  size="s"
                  id="transfer.pending.waitingForSignature"
                  align="center"
                />
              )}

              {(txHash || userOpHash) && (
                <View style={style.links}>
                  {userOpHash && (
                    <View style={style.link}>
                      <JiffyscanLink
                        userOpHash={userOpHash}
                        chainId={transferParams.chainId}
                      />
                    </View>
                  )}
                  {txHash && (
                    <View style={style.link}>
                      <EtherscanLink
                        txHash={txHash}
                        chainId={transferParams.chainId}
                      />
                    </View>
                  )}
                </View>
              )}
            </>
          )}
        </View>
      </ScrollContainer>
      <ShadowFooter style={style.footer}>
        <View style={style.networkInfoContainer}>
          {InsufficientBalanceError && balance && (
            <View style={[style.networkRow, style.balance]}>
              <Text
                size="xs"
                id="transfer.balance"
                values={{
                  amount: (
                    <Text size="xs" weight="semibold">
                      {formatPrice(
                        balance.value,
                        {
                          decimals: balance.decimals,
                          symbol: balance.symbol,
                        },
                        5,
                      )}
                    </Text>
                  ),
                }}
              />
            </View>
          )}
          <View style={style.networkRow}>
            <Text size="xs">{getChainById(transferParams.chainId).name}</Text>
            <FadeInOut enabled={gasEstimation.isFetching}>
              <Text
                size="xs"
                id="transfer.networkFee"
                values={{
                  amount: (
                    <Text size="xs" weight="semibold">
                      {formatPrice(
                        gasEstimation.totalGas || BigInt(0),
                        getChainById(transferParams.chainId).chain
                          .nativeCurrency,
                      )}
                    </Text>
                  ),
                }}
              />
            </FadeInOut>
          </View>
        </View>

        {validationError?.message && (
          <View style={style.validationError}>{validationError.message}</View>
        )}

        {validationError ? (
          validationError.action
        ) : (
          <CardButton
            disabled={
              !senderWallet ||
              !!validationError ||
              gasEstimation.isFetching ||
              !gasEstimation.totalGas
            }
            text={{id: 'transfer.send'}}
            loaderProps={{textId: 'transfer.processing'}}
            isLoading={transferStep !== 'checkout'}
            onPress={() => {
              if (senderWallet) {
                executeTransfer(senderWallet);
              }
            }}
            icon={{name: 'transfer', provider: 'custom'}}
          />
        )}
      </ShadowFooter>
    </>
  );
};

export default TransferCheckout;
