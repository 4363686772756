import React from 'react';

import CardButton from '@/components/CardButton';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import WarningBox from '@/components/WarningBox';
import {useDisconnect, useExternalSigner} from '@/modules/ExternalWallet';
import {ITransactionValidationError} from '@/modules/Transactions/types';
import {IAddress} from '@/types/common';
import {Connectors} from '@/types/wallet';
import {areAddressesEqual, prettifyAddress} from '@/utils/ethereum';

interface IWalletValidationInput {
  wallet: IAddress | undefined;
  onChangeWallet?: () => void;
}

export const useIsWalletConnectedValidation = ({
  wallet,
  onChangeWallet,
}: IWalletValidationInput): ITransactionValidationError | undefined => {
  const {address: connectedAddress, connect, connector} = useExternalSigner();
  const {disconnect, isDisconnecting} = useDisconnect();
  const isMetaMask = connector === Connectors.metaMask;

  const showConnectedWalletError =
    !!wallet &&
    wallet.isWallet &&
    !wallet.isPasskey &&
    !areAddressesEqual(wallet.address, connectedAddress);

  if (showConnectedWalletError) {
    return {
      message: (
        <WarningBox
          message={{
            id: isMetaMask
              ? 'transactions.notConnected.message.metamask'
              : connectedAddress
              ? 'transactions.notConnected.message.wrongWallet'
              : 'transactions.notConnected.message',
            values: {
              address: (
                <Text size="xs" weight="semibold">
                  {wallet.ens || prettifyAddress(wallet.address)}
                </Text>
              ),
            },
          }}
        />
      ),
      action: (
        <>
          {isMetaMask ? null : connectedAddress ? (
            <CardButton
              text={{id: 'disconnect', size: 's'}}
              onPress={disconnect}
              isLoading={isDisconnecting}
              loaderProps={{textId: 'disconnect.loading'}}
            />
          ) : (
            <CardButton text={{id: 'connect', size: 's'}} onPress={connect} />
          )}
          {onChangeWallet && (
            <TransparentButton
              style={{alignSelf: 'center'}}
              onPress={onChangeWallet}
              text={{id: 'transactions.notConnected.change'}}
            />
          )}
        </>
      ),
    };
  }

  return undefined;
};
