import {configureChains} from 'wagmi';
import {base, mainnet, optimism, polygon, sepolia, zora} from 'wagmi/chains';
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect';
import {alchemyProvider} from 'wagmi/providers/alchemy';
import {publicProvider} from 'wagmi/providers/public';

import {config} from '@/constants/config';
import {isWeb} from '@/utils/platform';

const metadata = {
  name: config.APP_NAME,
  url: config.WEB_PAGE_URL,
  description: 'Spinamp',
  icons: ['https://app.spinamp.xyz/spin-logo.png'],
};

const projectId = config.WALLETCONNECT_PROJECT_ID;

const {publicClient, chains} = configureChains(
  [mainnet, polygon, optimism, base, zora, sepolia],
  [alchemyProvider({apiKey: config.ALCHEMY_API_KEY}), publicProvider()],
);

// https://explorer.walletconnect.com/
const supportedWalletIds = [
  '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369', // Rainbow
  'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18', // Zerion
  '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f', // Safe
  '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // Trust
  'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a', // Uniswap
  'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // Metamask
];

const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    projectId,
    showQrModal: isWeb,
    metadata,
    qrModalOptions: {
      explorerExcludedWalletIds: 'ALL',
      explorerRecommendedWalletIds: supportedWalletIds,
    },
  },
});

export {
  metadata,
  publicClient,
  chains,
  projectId,
  walletConnectConnector,
  supportedWalletIds,
};
