import React from 'react';

import CardButton from '@/components/CardButton';
import WarningBox from '@/components/WarningBox';
import {chainsById, getChainById} from '@/constants/chains';
import {useExternalSigner} from '@/modules/ExternalWallet';
import {ITransactionValidationError} from '@/modules/Transactions/types';
import {IAddress} from '@/types/common';
import {isNative} from '@/utils/platform';

interface IChainValidationInput {
  wallet: IAddress | undefined;
  chainId: number | undefined;
}

export const useChainValidation = ({
  wallet,
  chainId,
}: IChainValidationInput): ITransactionValidationError | undefined => {
  const {
    address: connectedWalletAddress,
    activeNetwork,
    switchNetwork,
    connector,
  } = useExternalSigner();

  const isMobileMetamask = isNative && connector?.includes('metamask');

  if (
    wallet?.isPasskey ||
    !connectedWalletAddress ||
    chainId === activeNetwork
  ) {
    return undefined;
  }

  return {
    message: (
      <WarningBox
        icon={{name: 'wrongNetwork', provider: 'custom'}}
        title={{id: 'transactions.wrongChain.title'}}
        message={
          isMobileMetamask && chainId
            ? {
                id: 'transactions.wrongChain.message.metamask',
                values: {
                  chain: getChainById(chainId).name,
                },
              }
            : undefined
        }
      />
    ),
    action: chainId && !isMobileMetamask && (
      <CardButton
        onPress={() => switchNetwork?.(chainId)}
        text={{
          id: 'transactions.wrongChain.action',
          values: {
            chain: chainsById[chainId]?.name || chainId,
          },
        }}
      />
    ),
  };
};
