import React from 'react';
import {Dimensions} from 'react-native';
import {GestureDetector} from 'react-native-gesture-handler';
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
} from 'react-native-reanimated';

import {SliderDefaults} from '@/components/Slider/shared';
import {ISliderProps} from '@/components/Slider/types';
import {useSwipeHandler} from '@/components/Slider/useSwipeHandler';

const screenWidth = Dimensions.get('window').width;

const Slider = <ItemType,>({
  data,
  renderItem,
  keyExtractor,
  padding = SliderDefaults.padding,
  gap = SliderDefaults.gap,
  offset = SliderDefaults.offset,
  containerStyle,
}: ISliderProps<ItemType>) => {
  const containerWidth = useSharedValue(screenWidth);
  const itemWidth = useDerivedValue(
    () => containerWidth.value - padding * 2 - offset,
  );
  const itemStyle = useAnimatedStyle(() => ({
    width: itemWidth.value,
  }));

  const {scrollViewRef, swipeGesture, onScroll} = useSwipeHandler({
    itemWidth,
    gap,
  });

  return (
    <GestureDetector gesture={swipeGesture}>
      <Animated.ScrollView
        // @ts-ignore
        ref={scrollViewRef}
        onScroll={onScroll}
        onLayout={event => {
          const {width} = event.nativeEvent.layout;

          if (width !== containerWidth.value) {
            containerWidth.value = width;
          }
        }}
        style={containerStyle}
        contentContainerStyle={{
          paddingHorizontal: padding,
          gap,
        }}
        scrollEventThrottle={16}
        horizontal
        showsHorizontalScrollIndicator={false}
        snapToInterval={itemWidth.value + gap}
        decelerationRate={0.99}>
        {data.map(item => (
          <Animated.View key={keyExtractor(item)} style={itemStyle}>
            {renderItem(item)}
          </Animated.View>
        ))}
      </Animated.ScrollView>
    </GestureDetector>
  );
};

export default Slider;
