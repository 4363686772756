import {useNavigation} from '@react-navigation/native';
import React, {ReactNode, useState} from 'react';
import {Vibration, View} from 'react-native';
import Animated, {
  measure,
  MeasuredDimensions,
  runOnJS,
  runOnUI,
  useAnimatedRef,
  useSharedValue,
} from 'react-native-reanimated';

import IconButton from '@/components/IconButton';
import SpinLoader from '@/components/SpinLoader';
import TrackReleaseNotificationButton from '@/components/TrackReleaseNotificationButton/TrackReleaseNotificationButton';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useAppSelector, useAppDispatch} from '@/hooks/useRedux';
import PostLikePopover from '@/screens/Feed/components/PostLikePopover';
import Progress from '@/screens/Feed/components/Progress';
import TimeToRelease from '@/screens/Feed/components/TimeToRelease';
import {
  selectShouldPlay,
  setShouldPlay as setFeedShouldPlay,
} from '@/store/feed';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {IFeedItem} from '@/types/feed';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {analytics} from '@/utils/analytics';
import {isWeb} from '@/utils/platform';

import {styles} from './AudioFeedCard.styles';
import BaseFeedCard from './BaseFeedCard';
import FeedCardActions from './FeedCardActions';
import {useFeedAudio} from './useFeedAudio';

interface IAudioFeedCardProps {
  feature?: ReactNode;
  heading?: ReactNode;
  feedItem: IFeedItem;
  track: ITrack | undefined;
  isActive: boolean;
  toggleLike?: (feedItem: IFeedItem) => void;
  isLiked: boolean;
  handleLike: () => void;
  toggleHide?: (feedItem: IFeedItem) => void;
  next: () => void;
  setCarouselEnabled: (enabled: boolean) => void;
}

export const AudioFeedCard: React.FC<IAudioFeedCardProps> = ({
  feature,
  heading,
  feedItem,
  track,
  isActive,
  isLiked,
  handleLike,
  toggleHide,
  next,
  setCarouselEnabled,
}) => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const style = useThemedStyles(styles);

  const cardRef = useAnimatedRef<Animated.View>();
  const cardMeasurement = useSharedValue<MeasuredDimensions | null>(null);

  const [isTrackPopoverOpen, setIsTrackPopoverOpen] = useState(false);

  const releaseTime = track?.mintStart ? new Date(track.mintStart) : undefined;

  const {isPlaying, isBuffering, progress} = useFeedAudio(
    track,
    isActive,
    next,
  );

  const openPopover = () => {
    runOnUI(() => {
      cardMeasurement.value = measure(cardRef);
      runOnJS(setIsTrackPopoverOpen)(true);
    })();
  };

  const collectAction = (
    <TransparentButton
      rippleDisabled
      iconSpacing={spacing.xxs / 2}
      style={style.collectButton}
      onPress={() => {
        navigation.navigate(Routes.Collect, {slug: track!.slug});
        analytics.feedCollectClicked(track!.id);
      }}
      text={{
        id: 'buyButton.collect',
        weight: 'semibold',
      }}
      icon={{
        name: 'nftMint',
        provider: 'custom',
      }}
    />
  );

  const notifyAction =
    track && releaseTime ? (
      <TrackReleaseNotificationButton
        track={track}
        analyticsInfo={{screen: Routes.Channels, id: track.id}}
      />
    ) : null;

  const hideAction = toggleHide ? (
    <IconButton
      onPress={() => toggleHide(feedItem)}
      icon={{
        provider: 'custom',
        name: 'visibilityOff',
      }}
    />
  ) : (
    <View />
  );

  const getLeftAction = () => {
    if (!isLiked) {
      return hideAction;
    }

    if (releaseTime && releaseTime > new Date()) {
      if (isWeb) {
        return hideAction;
      }
      return notifyAction;
    }

    return collectAction;
  };

  const playAction = (
    <IconButton
      onPress={() => {
        analytics.feedCardPlayed(feedItem.id, !feedShouldPlay);
        dispatch(setFeedShouldPlay(!feedShouldPlay));
      }}
      icon={{
        provider: 'custom',
        name: isPlaying ? 'pause' : 'play',
      }}
      disabled={!track}
    />
  );

  const likeAction = (
    <IconButton
      onPress={async () => {
        handleLike();

        if (!isLiked && track) {
          Vibration.vibrate();
          openPopover();
        }
      }}
      onLongPress={openPopover}
      icon={{
        name: 'heart',
        provider: 'custom',
        fill: isLiked,
        color: isLiked ? 'favoritesColor' : 'textColor',
      }}
      disabled={!track}
    />
  );

  const feedShouldPlay = useAppSelector(selectShouldPlay);
  const dispatch = useAppDispatch();

  return (
    <BaseFeedCard ref={cardRef}>
      {progress && (
        <Progress
          progress={progress}
          onDragStart={() => setCarouselEnabled(false)}
          onDragEnd={() => setCarouselEnabled(true)}
        />
      )}
      {feature && (
        <View style={style.imageContainer}>
          {feature}
          {isBuffering && (
            <View style={style.bufferingIndicator}>
              <SpinLoader />
            </View>
          )}
        </View>
      )}
      <TimeToRelease track={track} />

      <View style={style.infoContainer}>
        {heading}
        <FeedCardActions actions={[getLeftAction(), playAction, likeAction]} />
      </View>

      {isTrackPopoverOpen && track && (
        <PostLikePopover
          cardMeasurement={cardMeasurement}
          track={track}
          close={() => setIsTrackPopoverOpen(false)}
          bottomOffset={spacing.xxl}
        />
      )}
    </BaseFeedCard>
  );
};
