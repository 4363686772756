import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Divider from '@/components/Divider';
import Icon from '@/components/Icon';
import ScrollContainer from '@/components/ScrollContainer';
import Text from '@/components/Text';
import {IMintOfferInfo} from '@/modules/Collect/types';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {
  OwnedTrackTile,
  EtherscanLink,
  JiffyscanLink,
} from '@/modules/Transactions';
import ShareButtons from '@/modules/Transactions/components/ShareButtons';
import {useThemedStyles} from '@/theme';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {prettifyAddress} from '@/utils/ethereum';
import {awaitScreenTransition} from '@/utils/navigation';
import {getTrackArtistsNames} from '@/utils/tracks';

import {styles} from './SuccessfulTransaction.style';

interface IProps {
  selectedOffer: IMintOfferInfo;
}

const SuccessfulTransaction: FC<IProps> = ({selectedOffer}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();
  const {
    user,
    track,
    txHash,
    userOpHash,
    transferEnabled,
    token,
    walletsSettings,
    deliveryWallet,
    collectInfo,
    closeCollectModal,
  } = useCollectState();

  const showTransfer =
    transferEnabled && !!token && !!walletsSettings && !!deliveryWallet;

  return (
    <ScrollContainer contentStyle={style.container}>
      {!showTransfer && user && <OwnedTrackTile user={user} track={track} />}

      <Icon name="diamondSuccess" provider="custom" size={64} />

      <View style={style.textsContainer}>
        <Text
          id="collect.success.congratulations"
          weight="semibold"
          size="l"
          align="center"
        />

        <View style={style.textsInfo}>
          <Text
            align="center"
            id="collect.success.description1"
            values={{
              track: (
                <Text weight="semibold" align="center">
                  {track.title}
                </Text>
              ),
              artist: getTrackArtistsNames(track),
            }}
          />
          {!showTransfer && (
            <Text align="center" id="collect.success.description2" />
          )}
        </View>
      </View>

      {showTransfer ? (
        <View style={style.transferContainer}>
          <Divider />

          <View style={style.textsInfo}>
            <Text align="center" id="collect.success.transfer.description" />
            {deliveryWallet.ens ? (
              <Text
                align="center"
                id="collect.success.transfer.ens"
                values={{
                  ens: (
                    <Text weight="semibold" align="center">
                      {deliveryWallet.ens}
                    </Text>
                  ),
                  address: prettifyAddress(deliveryWallet.address),
                }}
              />
            ) : (
              <Text align="center">
                {prettifyAddress(deliveryWallet.address)}
              </Text>
            )}
          </View>

          <CardButton
            onPress={async () => {
              closeCollectModal();
              await awaitScreenTransition();
              navigation.navigate(Routes.Transfer, {
                slug: track.slug,
                contract: collectInfo!.contract,
                from: walletsSettings.paymentAddress,
                to: walletsSettings.deliveryAddress,
                tokenId: token.tokenId,
                tokenType: token.type,
                chainId: selectedOffer.chainId,
              });
            }}
            text={{id: 'collect.success.transfer'}}
            icon={{name: 'transfer', provider: 'custom'}}
          />
        </View>
      ) : (
        <View style={style.shareContainer}>
          <ShareButtons track={track} />
        </View>
      )}

      <View style={style.transactionLinks}>
        {(txHash || userOpHash) && (
          <View style={style.links}>
            {userOpHash && (
              <View style={style.link}>
                <JiffyscanLink
                  userOpHash={userOpHash}
                  chainId={selectedOffer.chainId}
                />
              </View>
            )}
            {txHash && (
              <View style={style.link}>
                <EtherscanLink
                  txHash={txHash}
                  chainId={selectedOffer.chainId}
                />
              </View>
            )}
          </View>
        )}
      </View>
    </ScrollContainer>
  );
};

export default SuccessfulTransaction;
