import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const AVATAR_SIZE = 140;

export const styles = createThemedStyles(theme => ({
  container: {
    gap: spacing.s * 2,
    marginBottom: spacing.s * 2,
  },
  avatarWrapper: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
    overflow: 'hidden',
    backgroundColor: theme.colors.background,
  },
  avatar: {
    ...StyleSheet.absoluteFillObject,
  },
  titleText: {
    maxWidth: '100%',
  },
  description: {
    paddingHorizontal: spacing.s,
  },
  socialLinks: {
    flexDirection: 'row',
    gap: spacing.s,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignSelf: 'center',
  },
}));
