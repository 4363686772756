import React from 'react';
import {View} from 'react-native';

import BaseFeedCard from '../FeedCard/BaseFeedCard';
import {styles} from '../FeedCard/common.styles';
import Icon from '@/components/Icon';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {useAppDispatch} from '@/hooks/useRedux';
import {setOnboardingInfo} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {analytics} from '@/utils/analytics';

import OnboardingActions from './OnboardingActions';

const SpinampWelcomeCard: React.FC = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(
      setOnboardingInfo({
        key: 'showFeedOnboardingSpinampWelcome',
        value: false,
      }),
    );
    analytics.feedOnboardingCompleted('spinampWelcome');
  };

  return (
    <BaseFeedCard>
      <View style={style.imageContainer}>
        <Space p="s" style={style.featureContainer}>
          <Icon
            name="spinWow"
            provider="custom"
            style={style.featureIcon}
            size={180}
          />
          <Text
            id="feed.onboardingSpinampWelcome.title"
            weight="bold"
            size="l"
            style={style.centeredText}
          />
          <Space mb="s" />
          <Text
            id="feed.onboardingSpinampWelcome.discover"
            style={style.centeredText}
          />
          <Space mb="l" />
        </Space>
      </View>

      <OnboardingActions onClose={onClose} />
    </BaseFeedCard>
  );
};

export default SpinampWelcomeCard;
