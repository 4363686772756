import {config} from '@/constants/config';
import {fetchWarpcastUsers} from '@/services/neynar';
import {ITrack} from '@/types/common';
import {IPlaylist} from '@/types/playlists';
import {openLinkSafe} from '@/utils/linking';
import {addParamsToUrl} from '@/utils/url';

const createShareLinkGetter = (url: string) => (param: string) =>
  `${config.WEB_APP_URL}/${url}/${encodeURIComponent(param)}`;

export const getArtistUrl = createShareLinkGetter('artist');
export const getCollectorUrl = createShareLinkGetter('collector');
export const getCollectionUrl = createShareLinkGetter('collection');
export const getPlaylistUrl = createShareLinkGetter('playlist');
export const getPlatformUrl = createShareLinkGetter('platform');
export const getSpinampWrappedUrl = createShareLinkGetter('wrapped');

export const getTrackUrl = (track: ITrack, referral?: string) => {
  const url = createShareLinkGetter('track')(track.slug);

  if (referral && track.platformId === 'sound') {
    return addParamsToUrl(url, {referral});
  }

  return url;
};

export const shareOnTwitter = (text: string, url: string) => {
  openLinkSafe(`https://twitter.com/intent/tweet?text=${text}&url=${url}`);
};

export const shareOnWarpcast = (text: string, url: string) => {
  openLinkSafe(`https://warpcast.com/~/compose?text=${text}!&embeds[]=${url}`);
};

const deriveTwitterHandleFromUrl = (url: string) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)\/(?:#!\/)?@?([^\/\?]+)/;

  return url.match(regex)?.[1];
};

const deriveLensHandleFromUrl = (url: string) => {
  const regex = /^https:\/\/hey\.xyz\/u\/([^\/]+)$/;

  return url.match(regex)?.[1];
};

export const shareTxSuccessOnTwitter = (track: ITrack) => {
  const url = getTrackUrl(track);
  const twitterUrl = track.artist.externalLinks?.find(
    link => link.type === 'twitter',
  )?.url;
  const twitterHandle = twitterUrl && deriveTwitterHandleFromUrl(twitterUrl);

  if (twitterHandle) {
    shareOnTwitter(
      `I just collected "${track.title}" by @${twitterHandle} on @spin_amp ~ 💿`,
      url,
    );
  } else {
    shareOnTwitter(
      `I just collected "${track.title}" by ${track.artist.name} on @spin_amp ~ 💿`,
      url,
    );
  }
};

export const shareTxSuccessOnWarpcast = async (track: ITrack) => {
  let artistMentions = track.artist.name;

  try {
    const usernames = await fetchWarpcastUsers(
      track.artist.addresses.map(a => a.address),
    );

    if (usernames.length > 0) {
      artistMentions = usernames.map(username => `@${username}`).join(', ');
    }
  } catch (e) {}

  const url = getTrackUrl(track);
  const text = `I just collected "${track.title}" by ${artistMentions} on @spinamp`;

  shareOnWarpcast(text, url);
};

export const sharePlaylistTxSuccessOnTwitter = (
  playlist: IPlaylist,
  author?: string | null,
) => {
  const url = getPlaylistUrl(playlist.id);
  const text = `I just collected "${playlist.title}" album by ${
    author || 'anonymous creator'
  } on @spin_amp ~ 💿`;

  shareOnTwitter(text, url);
};

export const shareTxSuccessOnLens = (track: ITrack) => {
  const url = getTrackUrl(track);
  const lensUrl = track.artist.externalLinks?.find(
    link => link.type === 'hey',
  )?.url;
  const lensHandle = lensUrl && deriveLensHandleFromUrl(lensUrl);
  const artistMention = lensHandle ? `@lens/${lensHandle}` : track.artist.name;

  const text = `I just collected "${track.title}" by ${artistMention} on @lens/spinamp`;

  openLinkSafe(`https://hey.xyz/?text=${text}&url=${url}`);
};
