import {useState} from 'react';

export type ITab = 'likes' | 'hides' | 'new';

export const useTabs = () => {
  const [tab, setTab] = useState<ITab>('new');

  return {
    tab,
    changeTab: setTab,
  };
};
