import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

const MAX_TOAST_WIDTH = 600;

export const styles = createThemedStyles(() => ({
  toast: {
    width: 'auto',
    maxWidth: MAX_TOAST_WIDTH,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: spacing.s,
    paddingVertical: spacing.xs,
    gap: spacing.s,
  },
  content: {
    flex: isWeb ? 1 : undefined,
  },
}));
