import {useQueryClient} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import {useStore} from 'react-redux';

import {useSyncUnsavedFollowedPlaylist} from '@/queries/followedPlaylists';
import {
  useUsersPlaylistsQuery,
  useSyncUnsavedPlaylist,
  useCreatePlaylistMutation,
  useDeletePlaylistMutation,
} from '@/queries/ownedPlaylists';
import {
  selectDeletedPlaylists,
  selectUnsavedPlaylists,
  selectUnsyncedFollowedPlaylists,
  selectUnsyncedPlaylists,
} from '@/store/playlists';
import {selectSignInProcessStep, selectSessionsUserIds} from '@/store/user';
import {QueryKeys} from '@/types/queryKeys';

import {useAppSelector} from './useRedux';

export const useSyncPlaylists = () => {
  const queryClient = useQueryClient();
  const {getState} = useStore();
  const userIds = useAppSelector(selectSessionsUserIds);
  const signInStep = useAppSelector(selectSignInProcessStep);
  const [hasSyncCompleted, setHasSyncCompleted] = useState(false);

  const {mutateAsync: createPlaylistMutation} = useCreatePlaylistMutation();
  const {mutateAsync: updatePlaylistMutation} = useSyncUnsavedPlaylist();
  const {mutateAsync: updateFollowedPlaylistMutation} =
    useSyncUnsavedFollowedPlaylist();
  const {mutateAsync: deletePlaylistMutation} = useDeletePlaylistMutation();

  useUsersPlaylistsQuery(hasSyncCompleted && signInStep === 'idle');

  async function syncCreatePlaylists() {
    try {
      const uncreatedPlaylists = selectUnsavedPlaylists(getState());

      for (const playlist of uncreatedPlaylists) {
        await createPlaylistMutation({id: playlist.id});
      }
    } catch (error) {
      // ignore errors
    }
  }

  async function syncUpdatePlaylists() {
    const unsyncedPlaylists = selectUnsyncedPlaylists(getState());

    for (const playlist of unsyncedPlaylists) {
      try {
        await updatePlaylistMutation(playlist);
      } catch (error) {
        // ignore errors
      }
    }
  }

  async function syncUpdateFollowedPlaylists() {
    const unsyncedPlaylists = selectUnsyncedFollowedPlaylists(getState());

    for (const playlist of unsyncedPlaylists) {
      try {
        await updateFollowedPlaylistMutation(playlist);
      } catch (error) {
        // ignore errors
      }
    }
  }

  async function syncDeletePlaylists() {
    try {
      const outstandingDeletedPlaylists = selectDeletedPlaylists(getState());
      for (const deletedPlaylist of outstandingDeletedPlaylists) {
        await deletePlaylistMutation(deletedPlaylist.id);
      }
    } catch (error) {
      // ignore errors
    }
  }

  async function syncPlaylists() {
    setHasSyncCompleted(false);
    await syncCreatePlaylists();
    await syncUpdatePlaylists();
    await syncUpdateFollowedPlaylists();
    await syncDeletePlaylists();
    setHasSyncCompleted(true);
  }

  // sync stale data
  useEffect(() => {
    queryClient.cancelQueries([QueryKeys.userPlaylists]);

    if (userIds.length > 0) {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.userPlaylists],
        refetchType: 'none',
      });

      syncPlaylists();
    }
  }, [userIds]);
};
