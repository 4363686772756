import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ISession} from '@/types/session';

interface IUserOnboarding {
  showMarketFeedInfo: boolean;
  showLogoutExplanation: boolean;
  showFeedOnboardingSpinampWelcome: boolean;
  showFeedOnboardingFeedWelcome: boolean;
  showFeedOnboardingSpyWelcome: boolean;
  showFeedOnboardingNotifications: boolean;
}

type SignInProcessStep =
  | 'idle'
  | 'requestingPasskey'
  | 'checkingReturningUser'
  | 'waitingForSignature'
  | 'linking';

export interface UserState {
  activeUserId?: string;
  sessions: {
    [userId: string]: ISession;
  };
  signInProcessStep: SignInProcessStep;
  onboarding: IUserOnboarding; // set of flags used to show/hide onboarding modals/info
  searchHistory: string[];
}

export const initialState: UserState = {
  activeUserId: undefined,
  sessions: {},
  signInProcessStep: 'idle',
  onboarding: {
    showMarketFeedInfo: true,
    showLogoutExplanation: false,
    showFeedOnboardingSpinampWelcome: true,
    showFeedOnboardingFeedWelcome: true,
    showFeedOnboardingSpyWelcome: true,
    showFeedOnboardingNotifications: true,
  },
  searchHistory: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setActiveUserId: (state, action: PayloadAction<string | undefined>) => {
      state.activeUserId = action.payload;
    },
    upsertSession: (state, action: PayloadAction<ISession>) => {
      state.sessions[action.payload.userId] = {
        userId: action.payload.userId,
        sessionKey: action.payload.sessionKey,
        authenticatorAddress: action.payload.authenticatorAddress,
        isAnon: action.payload.isAnon,
      };
    },
    removeSession: (state, action: PayloadAction<{userId: string}>) => {
      delete state.sessions[action.payload.userId];
    },
    setSignInProcessStep: (state, action: PayloadAction<SignInProcessStep>) => {
      state.signInProcessStep = action.payload;
    },
    setOnboardingInfo: (
      state,
      action: PayloadAction<{key: keyof IUserOnboarding; value: boolean}>,
    ) => {
      state.onboarding[action.payload.key] = action.payload.value;
    },
    saveSearchPhrase: (state, action: PayloadAction<string>) => {
      state.searchHistory = [
        ...new Set([action.payload, ...state.searchHistory]),
      ].slice(0, 50);
    },
    clearSearchHistory: state => {
      state.searchHistory = [];
    },
    removeSearchPhrase: (state, action: PayloadAction<string>) => {
      state.searchHistory = state.searchHistory.filter(
        s => s !== action.payload,
      );
    },
  },
});

export const {
  setActiveUserId,
  upsertSession,
  removeSession,
  setSignInProcessStep,
  setOnboardingInfo,
  saveSearchPhrase,
  clearSearchHistory,
  removeSearchPhrase,
} = userSlice.actions;

export default userSlice.reducer;
