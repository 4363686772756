import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/store';

export const selectTransfers = (state: RootState) => state.transfers;

export const selectTransferById = createSelector(
  [selectTransfers, (state, id?: string) => id],
  (transfers, id) => (id ? transfers[id] : undefined),
);

export const selectTransfersList = createSelector(
  selectTransfers,
  transactions =>
    Object.values(transactions).sort((a, b) => (b.createdDate = a.createdDate)),
);

export const selectMinimizedTransfers = createSelector(
  selectTransfersList,
  transactions =>
    transactions.filter(
      t =>
        t.isMinimized &&
        (t.txHash ||
          t.userOpHash ||
          t.transactionError ||
          t.transferStep === 'checkout'),
    ),
);
