import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  imagePlaceholder: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.backgroundDark,
  },
  title: {
    marginBottom: spacing.xs,
    overflow: 'hidden',
    flexDirection: 'row',
  },
  subtitle: {
    maxWidth: '100%',
    flexDirection: 'row',
  },
  artistJoiner: {
    marginHorizontal: spacing.xxs,
  },
  image: {
    maxHeight: '100%',
    flexGrow: 1,
    overflow: 'hidden',
  },
}));
