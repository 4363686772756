import React, {ReactNode, useMemo, useRef, useState} from 'react';
import {
  Dimensions,
  LayoutChangeEvent,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';

import Icon from '@/components/Icon/Icon';
import Space from '@/components/Space';
import SpinLogo from '@/components/SpinLogo';
import Text from '@/components/Text';
import spacing, {HIT_SLOP} from '@/constants/spacing';
import {useThemedStyles} from '@/theme';
import {chunkArray} from '@/utils/functions';

import {SPIN_LOGO_SIZE, styles} from './SpinningList.style';

interface IProps<ItemType> {
  items: ItemType[];
  renderItem: (item: ItemType, index: number) => ReactNode;
  keyExtractor: (item: ItemType) => string;
  title: ReactNode;
  itemsPerPage?: number;
  pagePadding?: keyof typeof spacing;
  itemSpacing?: keyof typeof spacing;
  horizontal?: boolean;
  moreButton?: boolean;
  moreNavigation?: () => void;
}

function SpinningList<ItemType>({
  items,
  renderItem,
  keyExtractor,
  title,
  itemsPerPage = 3,
  pagePadding = 's',
  itemSpacing = 'xs',
  horizontal = false,
  moreButton = false,
  moreNavigation,
}: IProps<ItemType>) {
  const style = useThemedStyles(styles);
  const scrollView = useRef<Animated.ScrollView>(null);
  const [pageWidth, setPageWidth] = useState(Dimensions.get('window').width);

  const itemPages = useMemo(
    () => chunkArray(items, itemsPerPage),
    [items, itemsPerPage],
  );

  const scrollPositionX = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler(event => {
    scrollPositionX.value = event.contentOffset.x;
  });

  const spinStyle = useAnimatedStyle(() => ({
    transform: [
      {
        rotate: `${(scrollPositionX.value / pageWidth) * 360}deg`,
      },
    ],
  }));
  const rewindStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      scrollPositionX.value,
      [pageWidth * (itemPages.length - 2), pageWidth * (itemPages.length - 1)],
      [0, 1],
      {
        extrapolateLeft: Extrapolation.CLAMP,
      },
    ),
  }));

  const onSpinPress = () => {
    const page = Math.round(scrollPositionX.value / pageWidth);
    const isLastPage = page === itemPages.length - 1;
    const nextPageX = isLastPage ? 0 : (page + 1) * pageWidth;
    scrollView.current?.scrollTo({x: nextPageX, animated: true});
  };

  const setLayout = (layout: LayoutChangeEvent) => {
    const {width} = layout.nativeEvent.layout;
    // react-native-web sets elements width to 0 when other screen is moved on top of it for some reason
    if (pageWidth !== width && width > 0) {
      setPageWidth(width);
    }
  };

  const pagePaddingValue = spacing[pagePadding];
  const itemSpacingValue = spacing[itemSpacing];

  const pageContainerStyle: ViewStyle = {
    width: pageWidth,
    flex: 1,
    paddingHorizontal: pagePaddingValue,
  };
  const pageStyle: ViewStyle = {
    flexDirection: horizontal ? 'row' : 'column',
    marginVertical: horizontal ? 0 : -itemSpacingValue / 2,
    marginHorizontal: horizontal ? -itemSpacingValue / 2 : 0,
  };
  const pageItemStyle: ViewStyle = {
    paddingVertical: horizontal ? 0 : itemSpacingValue / 2,
    paddingHorizontal: horizontal ? itemSpacingValue / 2 : 0,
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: horizontal ? `${100 / itemsPerPage}%` : undefined,
  };

  return (
    <>
      <Space
        style={[style.sectionHeader, {paddingHorizontal: pagePaddingValue}]}>
        {title}
        <Space style={style.sectionHeader}>
          {moreButton && (
            <TouchableOpacity hitSlop={HIT_SLOP} onPress={moreNavigation}>
              <Text style={style.moreButton} weight="semibold" id="more" />
            </TouchableOpacity>
          )}
          {items.length > itemsPerPage && (
            <TouchableOpacity
              hitSlop={HIT_SLOP}
              onPress={onSpinPress}
              style={style.spinButton}
              activeOpacity={0.8}>
              <Animated.View style={[style.spinContainer, spinStyle]}>
                <SpinLogo size={SPIN_LOGO_SIZE} showThemeMask={false} />
                <Animated.View style={[style.rewind, rewindStyle]}>
                  <Icon
                    provider="ant"
                    name="fastbackward"
                    size={14}
                    style={style.rewindIcon}
                  />
                </Animated.View>
              </Animated.View>
            </TouchableOpacity>
          )}
        </Space>
      </Space>
      <Space mt="xs" />
      <Animated.ScrollView
        onLayout={setLayout}
        ref={scrollView}
        horizontal
        hitSlop={{left: -spacing.s}}
        decelerationRate={0.99}
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        bounces={false}
        disableIntervalMomentum
        onScroll={scrollHandler}
        scrollEventThrottle={16}>
        {itemPages.map((pageItems, pageIndex) => (
          <View key={pageIndex} style={pageContainerStyle}>
            <View style={pageStyle}>
              {pageItems.map((item, itemIndex) => (
                <View key={keyExtractor(item)} style={pageItemStyle}>
                  {renderItem(item, pageIndex * itemsPerPage + itemIndex)}
                </View>
              ))}
            </View>
          </View>
        ))}
      </Animated.ScrollView>
    </>
  );
}

export default SpinningList;
