import React, {FC} from 'react';

import CardButton from '@/components/CardButton';
import ScrollContainer from '@/components/ScrollContainer';
import Space from '@/components/Space';
import TransparentButton from '@/components/TransparentButton';
import WarningBox from '@/components/WarningBox';
import {EtherscanLink} from '@/modules/Transactions';
import TrackInfoCard from '@/modules/Transfer/components/TrackInfoCard';
import {TransferError} from '@/modules/Transfer/errors';
import {useTransferState} from '@/modules/Transfer/useTransferState';
import {PasskeyError} from '@/services/passkey';
import {useThemedStyles} from '@/theme';
import {isOSXBrowser} from '@/utils/platform';
import {getUserDisplayName} from '@/utils/user';

import {styles} from './TransferErrorInfo.style';

const TransferErrorInfo: FC = () => {
  const style = useThemedStyles(styles);

  const {
    track,
    user,
    senderWallet,
    transactionError,
    txHash,
    transferParams,
    resetTransferDetails,
    close,
  } = useTransferState();

  return (
    <ScrollContainer contentStyle={style.content}>
      <TrackInfoCard track={track} />

      {(() => {
        if (
          transactionError instanceof PasskeyError &&
          transactionError.status === 'MISSING_BLOB'
        ) {
          return (
            <WarningBox
              title={{id: 'collect.error.missingBlob'}}
              message={{
                id: isOSXBrowser
                  ? 'collect.error.missingBlob.osx'
                  : 'collect.error.missingBlob.default',
              }}
            />
          );
        }

        if (
          transactionError instanceof TransferError &&
          transactionError.status === 'WRONG_PASSKEY_SIGNER'
        ) {
          return (
            <WarningBox
              title={{id: 'collect.error.wrongSigner'}}
              message={{
                id: 'collect.error.wrongSigner.message',
                values: {
                  user: user ? getUserDisplayName(user) : transferParams.from,
                },
              }}
            />
          );
        }

        if (
          transactionError instanceof TransferError &&
          transactionError.status === 'TRANSACTION_REVERTED'
        ) {
          return (
            <WarningBox
              title={{id: 'transfer.error.reverted'}}
              action={
                txHash && (
                  <Space mt="xs">
                    <EtherscanLink
                      txHash={txHash}
                      chainId={transferParams.chainId}
                    />
                  </Space>
                )
              }
            />
          );
        }

        return (
          <WarningBox
            title={{id: 'transfer.error.generic'}}
            message={{id: 'transfer.error.generic.message'}}
          />
        );
      })()}

      {senderWallet && (
        <CardButton onPress={resetTransferDetails} text={{id: 'tryAgain'}} />
      )}
      <TransparentButton
        style={style.secondaryAction}
        onPress={close}
        text={{id: 'cancel'}}
      />
    </ScrollContainer>
  );
};

export default TransferErrorInfo;
