import {useNavigation, useScrollToTop} from '@react-navigation/native';
import React, {useCallback, useRef} from 'react';
import Animated from 'react-native-reanimated';
import {useDispatch} from 'react-redux';

import {ArtistCard} from '@/components/ArtistCard';
import AsyncContent from '@/components/AsyncContent';
import EnableNotificationsBanner from '@/components/EnableNotificationsBanner';
import Header from '@/components/Header/Header';
import ImageCard from '@/components/ImageCard';
import PlaylistCard from '@/components/PlaylistCard';
import Screen from '@/components/Screen/Screen';
import Space from '@/components/Space/Space';
import SpinningList from '@/components/SpinningList';
import Text from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import TrackCard from '@/components/TrackCard/TrackCard';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppSelector} from '@/hooks/useRedux';
import {useResponsive} from '@/hooks/useResponsive';
import {useLatestArtistsQuery} from '@/queries/artists';
import {useLatestTracksQuery} from '@/queries/db';
import {useExploreQuery} from '@/queries/explore';
import {usePlatformsQuery} from '@/queries/platforms';
import {useReleasesForYouQuery} from '@/queries/releasesForYou';
import {useTrendingPlaylistsQuery} from '@/queries/trendingPlaylists';
import {useTrendingTracksQuery} from '@/queries/trendingTracks';
import {useSpinampWrappedQuery} from '@/queries/useSpinampWrappedQuery';
import {playNewQueue} from '@/store/player';
import {selectActiveUserId, selectSelectedUserId} from '@/store/user';
import {useTheme, useThemedStyles} from '@/theme';
import {ITrack, PlayContextType} from '@/types/common';
import {
  ExploreStackNavigationParams,
  MainStackNavigationParams,
  Routes,
} from '@/types/routes';
import {getPlatformName} from '@/utils/musicPlatforms';
import {isWeb} from '@/utils/platform';

import {styles, SECTION_MARGIN, PAGE_PADDING} from './Explore.style';

const HIGHLIGHTS_COUNT = 15;

const Explore = () => {
  const style = useThemedStyles(styles);
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const theme = useTheme();
  const {isMobile} = useResponsive();
  const carouselItemsCount = isMobile ? 3 : 5;
  const scrollViewRef = useRef<Animated.ScrollView | null>(null);
  const exploreNavigation = useNavigation<ExploreStackNavigationParams>();
  const mainNavigation = useNavigation<MainStackNavigationParams>();
  const dispatch = useDispatch();
  const {
    featuredPlaylists,
    featuredPlatforms,
    promotedPlatforms,
    featuredArtists,
    showWrapped,
    query: {isLoading: isExploreLoading},
  } = useExploreQuery();

  const activeUser = useActiveUser();
  const userId = useAppSelector(selectActiveUserId);
  const signedUserId = useAppSelector(selectSelectedUserId);

  const {isWrappedEmpty} = useSpinampWrappedQuery(signedUserId, showWrapped);

  const {releasesForYouTracks} = useReleasesForYouQuery(userId, true);

  const {
    trendingTracks,
    query: {isLoading: areTrendingTracksLoading},
  } = useTrendingTracksQuery();

  const {
    trendingPlaylists,
    query: {isLoading: areTrendingPlaylistsLoading},
  } = useTrendingPlaylistsQuery();

  const {
    platformNames,
    query: {isLoading: arePlatformsLoading},
  } = usePlatformsQuery();
  const {
    tracks,
    query: {isLoading: areTracksLoading},
  } = useLatestTracksQuery();
  const {artists} = useLatestArtistsQuery();

  useScrollToTop(scrollViewRef);
  const {isTrackActive: isReleasesForYouTrackActive} = useIsTrackActive(
    PlayContextType.releasesForYou,
  );
  const {isTrackActive: isLatestTrackActive} = useIsTrackActive(
    PlayContextType.latest,
  );
  const {isTrackActive: isTrendingTrackActive} = useIsTrackActive(
    PlayContextType.trending,
  );

  const playTrackFromReleasesForYou = useCallback(
    (track: ITrack) =>
      dispatch(
        playNewQueue({
          trackIds: releasesForYouTracks?.map(t => t.id) ?? [],
          trackId: track.id,
          context: {
            source: 'ReleasesForYouExplore',
            type: PlayContextType.releasesForYou,
            titleId: 'releasesForYou.title',
          },
        }),
      ),
    [releasesForYouTracks],
  );

  const playTrackFromLatest = useCallback(
    (track: ITrack) =>
      dispatch(
        playNewQueue({
          trackIds: tracks.map(t => t.id),
          trackId: track.id,
          context: {
            source: 'LatestExplore',
            type: PlayContextType.latest,
            titleId: 'playlists.latest',
          },
        }),
      ),
    [tracks],
  );

  const playTrackFromTrending = useCallback(
    (track: ITrack) =>
      dispatch(
        playNewQueue({
          trackIds: trendingTracks.map(t => t.id),
          trackId: track.id,
          context: {
            source: 'TrendingExplore',
            type: PlayContextType.trending,
            titleId: 'explore.trendingTracks',
          },
        }),
      ),
    [trendingTracks],
  );

  return (
    <Screen>
      <Header
        titleId="explore.title"
        showMenu
        scrollPosition={scrollPosition}
        mockTitle
      />
      <AsyncContent
        isLoading={
          isExploreLoading ||
          areTracksLoading ||
          areTrendingTracksLoading ||
          areTrendingPlaylistsLoading ||
          arePlatformsLoading
        }
        loaderProps={{delay: 0}}>
        {() => (
          <Animated.ScrollView
            onScroll={onScroll}
            scrollEventThrottle={16}
            ref={scrollViewRef}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={style.container}>
            {activeUser && !isWeb && (
              <EnableNotificationsBanner firstTimeOnly />
            )}
            {showWrapped && !isWrappedEmpty && (
              <Space ph="s" pt="xs">
                <TouchableCard
                  onPress={() =>
                    mainNavigation.navigate(Routes.SpinampWrapped)
                  }>
                  {({pressed}) => {
                    const color = pressed ? 'invertedTextColor' : 'textColor';

                    return (
                      <>
                        <Text
                          weight="semibold"
                          size="m"
                          align="center"
                          color={color}
                          id="spinampWrapped.title"
                        />
                        <Space h="xs" />
                        <Text
                          align="center"
                          size="xxs"
                          color={color}
                          id="spinampWrapped.banner.description"
                        />
                      </>
                    );
                  }}
                </TouchableCard>
              </Space>
            )}
            {featuredPlaylists.length > 0 && (
              <Space ph={PAGE_PADDING} mt={SECTION_MARGIN}>
                <Space style={style.sectionHeader}>
                  <Text
                    weight="semibold"
                    align="center"
                    id="explore.featured"
                    uppercase={theme.text.sectionHeaderUppercase}
                  />
                </Space>
                {featuredPlaylists.map(playlist => (
                  <Space mt="xs" key={playlist.id}>
                    <ImageCard
                      onPress={() =>
                        mainNavigation.navigate(Routes.Playlist, {
                          id: playlist.id,
                        })
                      }
                      bannerURL={playlist.bannerURL}
                      aspectRatio={5}
                      transparent
                    />
                  </Space>
                ))}
              </Space>
            )}

            {featuredArtists.length > 0 && (
              <Space ph={PAGE_PADDING} mt={SECTION_MARGIN}>
                <Space style={style.sectionHeader}>
                  <Text
                    weight="semibold"
                    align="center"
                    id="explore.featuredArtists"
                    uppercase={theme.text.sectionHeaderUppercase}
                  />
                </Space>
                {featuredArtists.map(featuredArtist => (
                  <Space mt="xs" key={featuredArtist.id}>
                    <ImageCard
                      onPress={() =>
                        mainNavigation.navigate(Routes.Artist, {
                          slugOrId: featuredArtist.artist.slug,
                        })
                      }
                      fallbackText={featuredArtist.artist.name}
                      bannerURL={featuredArtist.bannerURL}
                      aspectRatio={5}
                      transparent
                    />
                  </Space>
                ))}
              </Space>
            )}

            {promotedPlatforms.length > 0 && (
              <Space ph={PAGE_PADDING} mt={SECTION_MARGIN}>
                <Space style={style.sectionHeader}>
                  <Text
                    weight="semibold"
                    align="center"
                    id="explore.featuredPlatforms"
                    uppercase={theme.text.sectionHeaderUppercase}
                  />
                </Space>
                {promotedPlatforms.map(platform => (
                  <Space mt="xs" key={platform.id}>
                    <ImageCard
                      onPress={() =>
                        mainNavigation.navigate(Routes.Platform, {
                          platformId: platform.id,
                        })
                      }
                      fallbackText={getPlatformName(platform.id, platformNames)}
                      bannerURL={platform.bannerURL}
                      aspectRatio={5}
                    />
                  </Space>
                ))}
              </Space>
            )}

            {releasesForYouTracks && releasesForYouTracks.length > 0 && (
              <Space mt={SECTION_MARGIN}>
                <SpinningList
                  pagePadding={PAGE_PADDING}
                  title={
                    <Text
                      weight="semibold"
                      size="s"
                      id="releasesForYou.title"
                      uppercase={theme.text.sectionHeaderUppercase}
                    />
                  }
                  items={releasesForYouTracks.slice(0, HIGHLIGHTS_COUNT)}
                  keyExtractor={track => track.id}
                  renderItem={track => (
                    <TrackCard
                      track={track}
                      showArtist
                      isActive={isReleasesForYouTrackActive(track.id)}
                      onPlay={playTrackFromReleasesForYou}
                    />
                  )}
                  itemsPerPage={carouselItemsCount}
                  moreButton
                  moreNavigation={() =>
                    mainNavigation.navigate(Routes.ReleasesForYou)
                  }
                />
              </Space>
            )}

            {tracks.length > 0 && (
              <Space mt={SECTION_MARGIN}>
                <SpinningList
                  pagePadding={PAGE_PADDING}
                  title={
                    <Text
                      weight="semibold"
                      size="s"
                      id="explore.latestTracks"
                      uppercase={theme.text.sectionHeaderUppercase}
                    />
                  }
                  items={tracks.slice(0, HIGHLIGHTS_COUNT)}
                  keyExtractor={track => track.id}
                  renderItem={track => (
                    <TrackCard
                      track={track}
                      showArtist
                      isActive={isLatestTrackActive(track.id)}
                      onPlay={playTrackFromLatest}
                    />
                  )}
                  itemsPerPage={carouselItemsCount}
                  moreButton
                  moreNavigation={() =>
                    exploreNavigation.navigate(Routes.LatestTracks)
                  }
                />
              </Space>
            )}

            {trendingTracks.length > 0 && (
              <Space mt={SECTION_MARGIN}>
                <SpinningList
                  pagePadding={PAGE_PADDING}
                  title={
                    <Text
                      weight="semibold"
                      size="s"
                      id="explore.trendingTracks"
                      uppercase={theme.text.sectionHeaderUppercase}
                    />
                  }
                  items={trendingTracks.slice(0, HIGHLIGHTS_COUNT)}
                  keyExtractor={track => track.id}
                  renderItem={track => (
                    <TrackCard
                      track={track}
                      showArtist
                      isActive={isTrendingTrackActive(track.id)}
                      onPlay={playTrackFromTrending}
                    />
                  )}
                  itemsPerPage={carouselItemsCount}
                  moreButton
                  moreNavigation={() =>
                    exploreNavigation.navigate(Routes.TrendingTracks)
                  }
                />
              </Space>
            )}

            {trendingPlaylists.length > 0 && (
              <Space mt={SECTION_MARGIN}>
                <SpinningList
                  pagePadding={PAGE_PADDING}
                  title={
                    <Text
                      weight="semibold"
                      size="s"
                      id="explore.trendingPlaylists"
                      uppercase={theme.text.sectionHeaderUppercase}
                    />
                  }
                  items={trendingPlaylists.slice(0, HIGHLIGHTS_COUNT)}
                  keyExtractor={playlist => playlist.id}
                  renderItem={playlist => <PlaylistCard playlist={playlist} />}
                  itemsPerPage={carouselItemsCount}
                  moreButton
                  moreNavigation={() =>
                    exploreNavigation.navigate(Routes.TrendingPlaylists)
                  }
                />
              </Space>
            )}

            {featuredPlatforms.length > 0 && (
              <Space mt={SECTION_MARGIN}>
                <SpinningList
                  pagePadding={PAGE_PADDING}
                  title={
                    <Text
                      weight="semibold"
                      size="s"
                      id="explore.platforms"
                      uppercase={theme.text.sectionHeaderUppercase}
                    />
                  }
                  items={featuredPlatforms}
                  keyExtractor={platformId => platformId}
                  horizontal
                  renderItem={platformId => (
                    <TouchableCard
                      key={platformId}
                      onPress={() =>
                        mainNavigation.navigate(Routes.Platform, {
                          platformId,
                        })
                      }
                      style={style.platformCard}
                      pv="m">
                      {({actionColor}) => (
                        <Text
                          weight="semibold"
                          align="center"
                          adjustsFontSizeToFit
                          numberOfLines={1}
                          color={actionColor()}>
                          {getPlatformName(platformId, platformNames)}
                        </Text>
                      )}
                    </TouchableCard>
                  )}
                  itemsPerPage={3}
                />
              </Space>
            )}

            {artists.length > 0 && (
              <Space mt={SECTION_MARGIN}>
                <SpinningList
                  pagePadding={PAGE_PADDING}
                  title={
                    <Text
                      weight="semibold"
                      size="s"
                      id="explore.latestArtists"
                      uppercase={theme.text.sectionHeaderUppercase}
                    />
                  }
                  items={artists.slice(0, HIGHLIGHTS_COUNT)}
                  keyExtractor={artist => artist.id}
                  renderItem={artist => <ArtistCard artist={artist} />}
                  itemsPerPage={carouselItemsCount}
                  moreButton
                  moreNavigation={() =>
                    exploreNavigation.navigate(Routes.Artists)
                  }
                />
              </Space>
            )}
          </Animated.ScrollView>
        )}
      </AsyncContent>
    </Screen>
  );
};

export default Explore;
