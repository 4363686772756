import {useNavigation} from '@react-navigation/native';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {FlatList, TouchableOpacity} from 'react-native';
import Animated, {interpolate, useAnimatedStyle} from 'react-native-reanimated';

import DraggableList, {IDragEndParams} from '@/components/DraggableList';
import Icon from '@/components/Icon';
import Screen from '@/components/Screen/Screen';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import spacing from '@/constants/spacing';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {LibraryCategory} from '@/screens/Library/types';
import QueueItem from '@/screens/Queue/QueueItem';
import {
  playTrackInQueue,
  removeTrackFromQueue,
  reorderQueueTracks,
  selectCurrentTrackId,
  selectCurrentTrackKey,
  selectQueue,
  selectQueueOriginalTracks,
} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {IQueueItemWithTrack, PlayContextType} from '@/types/common';
import {
  MainStackNavigationParams,
  Routes,
  TabsNavigationParams,
} from '@/types/routes';
import {isAddressValid, prettifyAddress} from '@/utils/ethereum';
import {isWeb} from '@/utils/platform';

import {styles, CONTENT_TOP_PADDING} from './Queue.style';

const Separator = () => <Space mt="xs" />;

const getScrollIndex = (currentIndex: number) => Math.max(0, currentIndex - 1);

const Queue = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const listRef = useRef<FlatList | null>(null);
  const mainNavigation = useNavigation<MainStackNavigationParams>();
  const tabsNavigation = useNavigation<TabsNavigationParams>();

  const queue = useAppSelector(selectQueue);
  const queueTracks = useAppSelector(selectQueueOriginalTracks);
  const currentTrackKey = useAppSelector(selectCurrentTrackKey);
  const currentTrackId = useAppSelector(selectCurrentTrackId);
  const isEmpty = queueTracks.length === 0;
  const showPlaylistTitle = !!(queue?.context.title || queue?.context.titleId);
  const isTitleLinkDisabled =
    !queue || [PlayContextType.search].includes(queue.context.type);

  const currentTrackIndex = useMemo(
    () => queueTracks.findIndex(({key}) => key === currentTrackKey),
    [currentTrackKey, queueTracks],
  );
  const initialScrollIndex = isWeb ? 0 : getScrollIndex(currentTrackIndex);

  const onPlay = useCallback((trackKey: string) => {
    dispatch(
      playTrackInQueue({
        trackKey,
      }),
    );
  }, []);

  const onDelete = useCallback((trackKey: string) => {
    dispatch(removeTrackFromQueue({trackKey}));
  }, []);

  const onTitlePress = () => {
    if (!queue) {
      return;
    }

    const {type, id, slug} = queue.context;

    if (
      [PlayContextType.collection, PlayContextType.ownCollection].includes(
        type,
      ) &&
      id
    ) {
      mainNavigation.navigate(Routes.Collector, {
        slug: id,
      });
    } else if (type === PlayContextType.walletCollection && id) {
      mainNavigation.navigate(Routes.Wallet, {
        id,
      });
    } else if (type === PlayContextType.latest) {
      tabsNavigation.navigate(Routes.ExploreNavigation, {
        screen: Routes.LatestTracks,
        initial: false,
      });
    } else if (type === PlayContextType.trending) {
      tabsNavigation.navigate(Routes.ExploreNavigation, {
        screen: Routes.TrendingTracks,
        initial: false,
      });
    } else if (type === PlayContextType.playlist && id) {
      mainNavigation.navigate(Routes.Playlist, {id});
    } else if (type === PlayContextType.playlistSuggestions && id) {
      mainNavigation.navigate(Routes.Playlist, {id});
    } else if (type === PlayContextType.platform && id) {
      mainNavigation.navigate(Routes.Platform, {
        platformId: id,
      });
    } else if (type === PlayContextType.artist && slug) {
      mainNavigation.navigate(Routes.Artist, {slugOrId: slug});
    } else if (type === PlayContextType.favorites) {
      tabsNavigation.navigate(Routes.LibraryNavigation, {
        screen: Routes.Library,
        params: {
          category: LibraryCategory.favorites,
        },
      });
    } else if (type === PlayContextType.history) {
      tabsNavigation.navigate(Routes.LibraryNavigation, {
        screen: Routes.Library,
        params: {
          category: LibraryCategory.history,
        },
      });
    }
  };

  const onDragEnd = (params: IDragEndParams<IQueueItemWithTrack>) => {
    dispatch(
      reorderQueueTracks(
        params.data.map(item => ({
          trackId: item.trackId,
          key: item.key,
          originalIndex: item.originalIndex,
        })),
      ),
    );
  };

  const scrollToIndex = (index: number, animated: boolean = false) => {
    listRef.current?.scrollToIndex({
      index: getScrollIndex(index),
      animated,
    });
  };

  const {scrollPosition, onScrollOffsetChange} = useAnimatedHeader();
  const headerDividerStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      scrollPosition.value,
      [1, CONTENT_TOP_PADDING],
      [0, 1],
    ),
  }));

  useEffect(() => {
    // initialScrollIndex is not supported on web. We need to run it manually
    if (isWeb && !isEmpty) {
      scrollToIndex(currentTrackIndex, false);
    }
  }, []);

  useEffect(() => {
    if (!currentTrackId) {
      navigation.goBack();
    }
  }, [currentTrackId]);

  if (!queue || !currentTrackId) {
    return null;
  }

  return (
    <Screen>
      <Space style={style.header}>
        <Space style={style.headerContent}>
          <Space style={style.headerTitle}>
            <Text
              id="queue.header"
              weight="semibold"
              size="l"
              numberOfLines={1}
            />
            {showPlaylistTitle && (
              <Space mt="xxs" style={style.playlistTitle}>
                <TouchableOpacity
                  onPress={onTitlePress}
                  disabled={isTitleLinkDisabled}>
                  <Text
                    id={queue.context.titleId}
                    size="s"
                    numberOfLines={1}
                    adjustsFontSizeToFit>
                    {queue.context.type === PlayContextType.collection &&
                    queue.context.title &&
                    isAddressValid(queue.context.title)
                      ? prettifyAddress(queue.context.title)
                      : queue.context.title}
                  </Text>
                </TouchableOpacity>
              </Space>
            )}
          </Space>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Space pr="xs">
              <Icon name="arrow-down" size={18} />
            </Space>
          </TouchableOpacity>
        </Space>
      </Space>
      <Animated.View style={[style.headerDivider, headerDividerStyle]} />
      <DraggableList
        // @ts-ignore
        ref={listRef}
        itemSize={TRACK_CARD_HEIGHT}
        itemSpacing={spacing.xs}
        draggingEnabled
        onDragEnd={onDragEnd}
        data={queueTracks}
        style={style.container}
        contentContainerStyle={style.content}
        keyExtractor={item => item.key}
        initialNumToRender={20}
        initialScrollIndex={initialScrollIndex}
        renderItem={({item, isDragged, drag}) => (
          <QueueItem
            isCurrentlyPlaying={item.key === currentTrackKey}
            trackKey={item.key}
            track={item.track}
            isDraggable
            isDragged={isDragged}
            drag={drag}
            showArtist
            onPlay={onPlay}
            onDelete={onDelete}
            showAddToQueue={false}
            navigationAction="navigate"
          />
        )}
        ItemSeparatorComponent={Separator}
        showsVerticalScrollIndicator={false}
        onScrollOffsetChange={onScrollOffsetChange}
      />
    </Screen>
  );
};

export default Queue;
