import {IQueueItem, ITrack} from '@/types/common';
import {generateId} from '@/utils/functions';

export const isAudioUrl = (url: string) => {
  const urlParts = url.split('.');
  const extension = urlParts[urlParts.length - 1];

  return ['wav', 'mp3'].includes(extension);
};

export const findTrackIndex = (
  tracks: ITrack[],
  id?: string | null,
): number => {
  if (!id) {
    return -1;
  }

  return tracks.findIndex(track => track.id === id);
};

export const getPercentageProgress = (progress: number, duration: number) => {
  if (!duration) {
    return 0;
  }

  return Math.round((100 * 100 * progress) / duration) / 100;
};

// isPodcast is a simplistic classification for tracks longer than 10 minutes
export const isPodcast = (duration: number | undefined): boolean =>
  duration && duration >= 10 * 60 ? true : false;

export const mapTrackIdToQueue = (
  trackId: string,
  originalIndex?: number,
): IQueueItem => ({
  trackId,
  key: generateId(),
  originalIndex,
});

export const shuffleQueue = <T>(array: T[]) => {
  // Fisher-Yates Shuffle algorithm
  const shuffledArray = [...array];
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [
      shuffledArray[randomIndex],
      shuffledArray[currentIndex],
    ];
  }

  return shuffledArray;
};
