import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = spacing.xl;

export const styles = createThemedStyles(() => ({
  container: {
    marginBottom: spacing.xs,
    gap: spacing.l + spacing.xs,
  },
  header: {
    gap: spacing.m,
    alignItems: 'center',
  },
  tiles: {
    marginHorizontal: -spacing.s,
    alignSelf: 'stretch',
    // workaround to shadow being cut on web
    ...Platform.select({
      web: {
        marginVertical: -spacing.s,
        paddingVertical: spacing.s,
      },
    }),
  },
  ownerContainer: {
    alignItems: 'center',
  },
  ownerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
}));
