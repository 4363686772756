import React, {FC} from 'react';
import {StyleProp} from 'react-native';
import Animated, {FadeIn} from 'react-native-reanimated';

import Loader from '@/components/Loader/Loader';
import {TextProps} from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './ScreenLoader.style';

export interface IScreenLoaderProps {
  loaderProps?: TextProps;
  style?: StyleProp<any>;
  delay?: number;
}

const ScreenLoader: FC<IScreenLoaderProps> = ({
  style,
  delay = 1000,
  loaderProps = {},
}) => {
  const screenLoaderStyle = useThemedStyles(styles);

  return (
    <Animated.View
      style={[screenLoaderStyle.container, style]}
      entering={FadeIn.duration(150).delay(delay)}>
      <Loader size="l" {...loaderProps} />
    </Animated.View>
  );
};

export default ScreenLoader;
