import {Sentry} from '@/services/sentry';
import {IArtistUserFollow} from '@/types/follows';
import {ISigner} from '@/types/session';

import {submitEntityFieldUpdate} from './entityFieldUpdates';

export const addArtistUserFollow = async (
  follow: IArtistUserFollow,
  signer: ISigner,
) => {
  try {
    await submitEntityFieldUpdate(
      'artist_user_follows',
      follow,
      signer.signMessage,
      'upsert',
    );
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const deleteArtistUserFollow = async (id: string, signer: ISigner) => {
  try {
    await submitEntityFieldUpdate(
      'artist_user_follows',
      {id},
      signer.signMessage,
      'delete',
    );
  } catch (e) {
    Sentry.captureException(e);
  }
};
