import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {View} from 'react-native';

import AsyncContent from '@/components/AsyncContent';
import ClosingHeader from '@/components/ClosingHeader';
import ModalScreenWrapper from '@/components/ModalScreenWrapper';
import SpinLoader from '@/components/SpinLoader';
import Text from '@/components/Text';
import {useActiveUser} from '@/hooks/useActiveUser';
import TransferModals from '@/modules/Transfer/components/TransferModals';
import TransferRouter from '@/modules/Transfer/components/TransferRouter';
import TransferStateProvider from '@/modules/Transfer/TransferStateProvider';
import {areParamsValid} from '@/modules/Transfer/utils';
import {useTrackBySlugQuery} from '@/queries/tracks';
import {useThemedStyles} from '@/theme';
import {RootStackParams, Routes} from '@/types/routes';

import {styles} from './TransferScreen.style';

const TransferScreen = () => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation();
  const {params} = useRoute<RouteProp<RootStackParams, Routes.Transfer>>();
  const {slug, transferId, ...transferParams} = params;

  const user = useActiveUser();

  const {track, query} = useTrackBySlugQuery(slug);

  useEffect(() => {
    if (!params.slug || !areParamsValid(params)) {
      navigation.goBack();
    }
  }, [params]);

  if (!slug || !areParamsValid(transferParams)) {
    return null;
  }

  return (
    <ModalScreenWrapper
      titleId="transfer.title"
      isFullscreenOnMobile
      desktopSize="l">
      <ClosingHeader />
      <View style={style.container}>
        <AsyncContent
          data={track}
          isLoading={query.isLoading}
          isError={!track}
          renderContent={loadedTrack => (
            <TransferStateProvider
              transferId={transferId}
              track={loadedTrack}
              initialTransferParams={transferParams}
              initialUser={user}>
              <TransferRouter />
              <TransferModals />
            </TransferStateProvider>
          )}
          loaderComponent={
            <View style={style.content}>
              <SpinLoader />
            </View>
          }
          errorComponent={
            <View style={style.content}>
              <Text id="notFound" weight="semibold" />
            </View>
          }
        />
      </View>
    </ModalScreenWrapper>
  );
};

export default TransferScreen;
