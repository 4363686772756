import {useMemo} from 'react';

import {useNotificationsModal} from '@/hooks/useNotificationsModal';
import {useAppSelector} from '@/hooks/useRedux';
import {
  FEED_QUERY_DEFAULT_OPTIONS,
  IFeedQueryOptions,
  toggleLikeFeedItem,
} from '@/queries/feed';
import {useToggleTrackInPlaylist} from '@/queries/ownedPlaylists';
import {selectFavsPlaylist, selectFavsTrackIds} from '@/store/playlists';
import {selectActiveUserId} from '@/store/user';
import {IFeedEntityType} from '@/types/feed';

export const useFavToggle = (
  trackId?: string,
  options: IFeedQueryOptions = FEED_QUERY_DEFAULT_OPTIONS,
) => {
  const {openNotificationModal} = useNotificationsModal();

  const favPlaylist = useAppSelector(selectFavsPlaylist);
  const favTrackIds = useAppSelector(selectFavsTrackIds);
  const userId = useAppSelector(selectActiveUserId);

  const isFav = useMemo(
    () => !!trackId && !!favTrackIds[trackId],
    [trackId, favPlaylist],
  );

  const {toggleTrackInPlaylistAsyncMutation} = useToggleTrackInPlaylist();

  const toggleFav = async () => {
    if (favPlaylist && trackId) {
      if (!isFav) {
        openNotificationModal('notificationsPrompt.artist');
      }

      await toggleTrackInPlaylistAsyncMutation({
        playlistId: favPlaylist.id,
        trackId: trackId,
      });
      toggleLikeFeedItem(
        userId,
        trackId,
        IFeedEntityType.track,
        isFav,
        options,
      );
    }
  };

  return {
    isFav,
    toggleFav,
  };
};

/**
 * This is a special version of useFavToggle with a different signature that allows using the hook when you don't know yet what the
 * trackId will be. You only need to pass the trackId when invoking the functions this hook returns.
 */
export const useFavToggleDynamic = (
  options: IFeedQueryOptions = FEED_QUERY_DEFAULT_OPTIONS,
) => {
  const {openNotificationModal} = useNotificationsModal();

  const {toggleTrackInPlaylistAsyncMutation} = useToggleTrackInPlaylist();
  const favorites = useAppSelector(selectFavsPlaylist);
  const userId = useAppSelector(selectActiveUserId);

  const isTrackFaved = (trackId: string) =>
    !!trackId && !!favorites && favorites?.trackIds?.includes(trackId);

  const toggleFaveTrack = async (trackId: string) => {
    if (!isTrackFaved(trackId)) {
      openNotificationModal('notificationsPrompt.artist');
    }

    await toggleTrackInPlaylistAsyncMutation({
      playlistId: favorites.id,
      trackId: trackId,
    });

    toggleLikeFeedItem(
      userId,
      trackId,
      IFeedEntityType.track,
      !!isTrackFaved(trackId),
      options,
    );
  };

  return {
    isTrackFaved,
    toggleFaveTrack,
  };
};
