import {useQuery} from '@tanstack/react-query';

import {fetchTrendingPlaylistsData} from '@/api/trendingPlaylists';
import {QueryKeys} from '@/types/queryKeys';

export const useTrendingPlaylistsQuery = () => {
  const query = useQuery(
    [QueryKeys.trendingPlaylists],
    fetchTrendingPlaylistsData,
  );

  return {
    trendingPlaylists: query.data || [],
    query,
  };
};
