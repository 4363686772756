import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {Pressable, View} from 'react-native';

import IconButton from '@/components/IconButton';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useFavToggle} from '@/hooks/useFavToggle';
import {useAppSelector} from '@/hooks/useRedux';
import {useFeedItemMutation} from '@/queries/feed';
import {createFeedItemForTrack} from '@/screens/Feed/utils';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {RootStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './ArtistFeedCardTrack.style';

interface IProps {
  track: ITrack;
  onPress: (track: ITrack) => void;
  isActive: boolean;
}

const ArtistFeedCardTrack: FC<IProps> = ({track, onPress, isActive}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  const {isFav} = useFavToggle(track.id);

  const userId = useAppSelector(selectActiveUserId)!;

  const {mutate: upsertFeedItem} = useFeedItemMutation(userId!, {
    skipOptimisticUpdate: true,
  });

  return (
    <Pressable
      onPress={() => onPress(track)}
      onLongPress={() => {
        navigation.navigate(Routes.TrackModal, {slug: track.slug});
      }}
      key={track.id}
      style={({pressed}) => [style.track, pressed && style.track_pressed]}>
      {/* @ts-ignore */}
      {({hovered}) => (
        <>
          <View
            style={[
              style.trackBackground,
              hovered && style.trackBackground_hover,
              isActive && style.trackBackground_active,
            ]}
          />
          <View style={style.trackContent}>
            <Text
              size="xs"
              color="invertedTextColor"
              weight={isActive ? 'semibold' : 'regular'}
              numberOfLines={1}
              flex>
              {track.title}
            </Text>

            {isActive && (
              <IconButton
                onPress={() =>
                  upsertFeedItem(
                    createFeedItemForTrack(
                      userId!,
                      track,
                      isFav ? null : 'like',
                    ),
                  )
                }
                size={spacing.l}
                rippleDisabled
                icon={{
                  name: 'heart',
                  provider: 'custom',
                  color: 'invertedTextColor',
                  fill: isFav,
                }}
              />
            )}
            {isActive && (
              <IconButton
                onPress={() => {
                  navigation.navigate(Routes.TrackModal, {
                    slug: track.slug,
                  });
                }}
                size={spacing.l}
                rippleDisabled
                icon={{
                  name: 'info',
                  provider: 'custom',
                  color: 'invertedTextColor',
                }}
              />
            )}
          </View>
        </>
      )}
    </Pressable>
  );
};

export default React.memo(ArtistFeedCardTrack);
