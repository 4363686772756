import {useQueryClient} from '@tanstack/react-query';
import {Vibration} from 'react-native';
import {encodeFunctionData, isHex} from 'viem';
import {useWalletClient} from 'wagmi';

import {TransferError} from '@/modules/Transfer/errors';
import {useTrackTransferEvent} from '@/modules/Transfer/useTrackTransferEvent';
import {useTransferState} from '@/modules/Transfer/useTransferState';
import {getTransferWriteContractInput} from '@/modules/Transfer/utils';
import {sendTransaction, waitForReceipt} from '@/modules/Wallets/passkeyWallet';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Passkey, PasskeyError} from '@/services/passkey';
import {Sentry} from '@/services/sentry';
import {AddressString, IAddress} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {areAddressesEqual, getChainClient} from '@/utils/ethereum';
import {getSignerFromSessionKey} from '@/utils/signer';

export const useTransferTransaction = () => {
  const queryClient = useQueryClient();

  const {
    user,
    track,
    transferParams,
    setTransferStep,
    setTxHash,
    setUserOpHash,
    setTransactionError,
    resetTransferDetails,
    gasEstimation,
  } = useTransferState();
  const {data: walletClient} = useWalletClient();

  const {trackEvent} = useTrackTransferEvent();

  const transferFromPasskey = async (senderWallet: IAddress) => {
    setTransferStep('waitingForPasskey');
    const {credentialId, signer} = senderWallet.metadata?.spinampWallet || {};
    const {privateKey} = await Passkey.get(credentialId);

    if (
      signer &&
      !areAddressesEqual(signer, getSignerFromSessionKey(privateKey).address)
    ) {
      throw new TransferError('WRONG_PASSKEY_SIGNER');
    }

    setTransferStep('executingTransaction');

    const userOpHash = await sendTransaction({
      privateKey,
      chainId: transferParams.chainId,
      transaction: {
        to: transferParams.contract as AddressString,
        data: encodeFunctionData(getTransferWriteContractInput(transferParams)),
      },
      gasSettings: {
        callGasLimit: gasEstimation.callGasLimit,
        preVerificationGas: gasEstimation.preVerificationGas,
        verificationGasLimit: gasEstimation.verificationGasLimit,
        maxFeePerGas: gasEstimation.maxFeePerGas,
        maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
      },
    });

    setUserOpHash(userOpHash);
    trackEvent('receivedTransactionUserOpHash');

    setTransferStep('waitingForReceipt');

    const {transactionHash, status} = await waitForReceipt({
      privateKey,
      chainId: transferParams.chainId,
      hash: userOpHash,
    });

    setTxHash(transactionHash);
    trackEvent('receivedTransactionTxHash');

    if (status !== 'success') {
      throw new TransferError('TRANSACTION_REVERTED');
    }
  };

  const transferFromExternal = async () => {
    if (!walletClient) {
      throw new TransferError('WALLET_NOT_CONNECTED');
    }

    if (walletClient.account.address !== transferParams.from) {
      throw new TransferError('WRONG_WALLET_CONNECTED');
    }

    setTransferStep('executingTransaction');
    const txHash = await walletClient.writeContract({
      gas: gasEstimation.gasLimit,
      maxFeePerGas: gasEstimation.maxFeePerGas,
      maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
      ...getTransferWriteContractInput(transferParams),
    });

    if (!txHash || !isHex(txHash)) {
      // For some wallets canceling transaction results in returning "null" txHash instead of throwing an error
      throw new TransferError('USER_CANCELLED');
    }

    setTxHash(txHash);
    trackEvent('receivedTransactionTxHash');

    setTransferStep('waitingForReceipt');

    const receipt = await getChainClient(
      transferParams.chainId,
    ).waitForTransactionReceipt({
      hash: txHash,
    });

    if (receipt.status !== 'success') {
      throw new TransferError('TRANSACTION_REVERTED');
    }
  };

  const executeTransfer = async (senderWallet: IAddress) => {
    try {
      trackEvent('confirmed');

      resetTransferDetails();

      if (isPasskeyWallet(senderWallet)) {
        await transferFromPasskey(senderWallet);
      } else {
        await transferFromExternal();
      }

      setTransferStep('success');
      Vibration.vibrate();
      trackEvent('success');

      // Invalidate queries related to collected nft after few seconds, so transaction has time to be indexed.
      // Timeout value should be adjusted after indexing time is improved.
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.collection, user?.id],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.addressCollection, transferParams.from],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.addressCollection, transferParams.to],
          refetchType: 'all',
        });
      }, 10000);
    } catch (error: any) {
      setTransferStep('checkout');

      if (
        (error instanceof PasskeyError && error.status === 'USER_CANCELLED') ||
        (error instanceof TransferError && error.status === 'USER_CANCELLED') ||
        // Those are errors code for canceling transactions in different external wallets I managed to catch
        error?.code === 5000 ||
        error?.cause?.code === 4001
      ) {
        trackEvent('cancelled');
        resetTransferDetails();
        return;
      }

      Sentry.captureException(error, {
        tags: {
          transfer: true,
          passkeyWallet: isPasskeyWallet(senderWallet),
        },
        extra: {
          wallet: senderWallet,
          trackId: track.id,
        },
      });

      setTransactionError(error);
      trackEvent('error');
    }
  };

  return {
    executeTransfer,
  };
};
