import {useEffect, useRef} from 'react';
import {useStore} from 'react-redux';

import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {ITransferParams} from '@/modules/Transfer/types';
import {initTransferState} from '@/modules/Transfer/utils';
import {selectTransferById} from '@/store/transfers/selectors';
import {
  createTransfer,
  removeTransfer,
  updateTransfer,
} from '@/store/transfers/slice';
import {ITrack} from '@/types/common';
import {IUser} from '@/types/user';

interface IUseInitTransferInput {
  transferId?: string;
  track: ITrack;
  transferParams: ITransferParams;
  initialUser?: IUser;
}

/**
 * Hook responsible for initializing transfer data in redux.
 * If `transferId` is provided, it will read existing transfer from the store.
 * If `transferId` is not provided, it will create a transfer in the store and read it
 *
 * It also removes transfer from the store or set is as minimized when component unmounts.
 */
export const useSyncTransferState = ({
  transferId,
  track,
  transferParams,
  initialUser,
}: IUseInitTransferInput) => {
  const {getState} = useStore();
  const dispatch = useAppDispatch();

  const transferDraft = useRef(
    initTransferState({track, user: initialUser, transferParams}),
  ).current;

  const reduxTransfer = useAppSelector(state =>
    selectTransferById(state, transferId || transferDraft.id),
  );

  const transfer = reduxTransfer || transferDraft;

  useEffect(() => {
    if (!reduxTransfer) {
      dispatch(createTransfer(transferDraft));
    }
  }, [reduxTransfer]);

  useEffect(() => {
    return () => {
      const transferStep = selectTransferById(
        getState(),
        transfer.id,
      )?.transferStep;

      if (transferStep === 'checkout' || transferStep === 'success') {
        dispatch(removeTransfer({id: transfer.id}));
      } else {
        dispatch(
          updateTransfer({
            id: transfer.id,
            update: {
              isMinimized: true,
            },
          }),
        );
      }
    };
  }, [transfer.id]);

  return {
    transfer,
  };
};
