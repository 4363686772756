import React from 'react';
import {StyleProp, View} from 'react-native';

import Icon, {IIconProps} from '@/components/Icon/Icon';
import Space from '@/components/Space/Space';
import StaticImage from '@/components/StaticImage';
import Text, {TextProps} from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {noop} from '@/utils/functions';
import {getTrackArtistsNames} from '@/utils/tracks';

import {styles} from './TrackCard.style';

export interface ITrackCardProps {
  track: ITrack;
  isActive?: boolean;
  onPress?: (track: ITrack) => void;
  suffixIcon?: IIconProps;
  suffixText?: TextProps;
  cardStyle?: StyleProp<any>;
  disabled?: boolean;
}

export const TrackCardPreview: React.FC<ITrackCardProps> = React.memo(
  ({
    track,
    isActive = false,
    onPress = noop,
    suffixIcon,
    suffixText,
    cardStyle,
    disabled,
  }) => {
    const style = useThemedStyles(styles);
    const {title, lossyArtworkUrl} = track;

    const textColor = (actionColorResolver: any) =>
      isActive ? actionColorResolver('activeText') : actionColorResolver();

    return (
      <Space flex style={style.trackCardContainer}>
        <TouchableCard
          disabled={disabled}
          highlight={isActive}
          onPress={() => onPress(track)}
          containerStyle={[style.trackTouchableCardContainer, cardStyle]}
          style={style.track}>
          {({actionColor}) => {
            return (
              <>
                {lossyArtworkUrl && (
                  <View style={style.artworkContainer}>
                    <StaticImage
                      resizeWidth={ImageSize.thumbnail}
                      source={{uri: lossyArtworkUrl}}
                      style={style.artwork}
                      fallback={
                        <View style={style.fallbackIcon}>
                          <Icon name="music-tone-alt" size={24} />
                        </View>
                      }
                    />
                  </View>
                )}
                <Space style={style.content}>
                  <Space flex pr="xxs" pv="xxs" style={style.trackSpace}>
                    <View style={style.titleRow}>
                      <Text
                        style={style.title}
                        weight="semibold"
                        numberOfLines={1}
                        color={textColor(actionColor)}>
                        {title}
                      </Text>
                    </View>

                    <Text
                      size="xs"
                      numberOfLines={1}
                      color={textColor(actionColor)}>
                      {getTrackArtistsNames(track)}
                    </Text>
                  </Space>
                </Space>
                {suffixIcon && (
                  <Space ph="xs" style={{alignSelf: 'center'}}>
                    <Icon {...suffixIcon} color={textColor(actionColor)} />
                  </Space>
                )}
                {suffixText && (
                  <Space ph="xs" style={{alignSelf: 'center'}}>
                    <Text {...suffixText} color={textColor(actionColor)} />
                  </Space>
                )}
              </>
            );
          }}
        </TouchableCard>
      </Space>
    );
  },
);
