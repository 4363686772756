import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/store';
import {getSignerFromSessionKey} from '@/utils/signer';

export const selectUser = (state: RootState) => state.user;

export const selectSessions = createSelector(selectUser, user => user.sessions);

export const selectSessionsList = createSelector(selectSessions, sessions =>
  Object.values(sessions),
);

export const selectSessionsUserIds = createSelector(selectSessions, sessions =>
  Object.keys(sessions),
);

// selects id of anon user - before login
export const selectAnonSession = createSelector(
  selectSessionsList,
  sessionsList => sessionsList.find(s => s.isAnon),
);

// selects id of actively selected user in profile picker - after login
export const selectSelectedUserId = createSelector(
  selectUser,
  user => user.activeUserId,
);

// selects selected user id if is logged in or anon user id otherwise
export const selectActiveUserId = createSelector(
  selectSelectedUserId,
  selectAnonSession,
  (activeUserId, anonSession) => activeUserId || anonSession?.userId,
);

export const selectIsSessionInitialized = createSelector(
  selectSessionsList,
  sessionsList => sessionsList.length > 0,
);

export const selectActiveUserSigner = createSelector(
  selectSessions,
  selectActiveUserId,
  (sessions, userId) => {
    if (!userId || !sessions[userId]) {
      return undefined;
    }

    return getSignerFromSessionKey(sessions[userId].sessionKey);
  },
);

export const selectSignerByUserId = createSelector(
  [selectSessions, (state: RootState, userId?: string) => userId],
  (sessions, userId) => {
    if (!userId || !sessions[userId]) {
      return undefined;
    }

    return getSignerFromSessionKey(sessions[userId].sessionKey);
  },
);

export const selectSignedInUserIds = createSelector(selectUser, user =>
  Object.values(user.sessions)
    .filter(session => !session.isAnon)
    .map(session => session.userId),
);

export const selectSignInProcessStep = createSelector(
  selectUser,
  user => user.signInProcessStep,
);

export const selectIsLinking = createSelector(
  selectSignInProcessStep,
  linkingProcess => linkingProcess === 'linking',
);

export const selectOnboardingInfo = createSelector(
  selectUser,
  user => user.onboarding,
);

export const selectSearchHistory = createSelector(
  selectUser,
  user => user.searchHistory,
);
