import {useQuery} from '@tanstack/react-query';

import {fetchCustomThemesConfig, fetchThemes} from '@/api/themes';
import {QueryKeys} from '@/types/queryKeys';

const STALE_TIME = 1000 * 60 * 5; // 5 minutes

export const useCustomThemesConfigQuery = (isRoot = false) => {
  const query = useQuery(
    [QueryKeys.customThemesConfig],
    fetchCustomThemesConfig,
    {
      refetchOnMount: isRoot,
      refetchOnWindowFocus: isRoot,
      refetchOnReconnect: isRoot,
      staleTime: STALE_TIME,
    },
  );

  const artistThemes = query.data?.artistThemes || {};
  const platformThemes = query.data?.platformThemes || {};
  const playlistThemes = query.data?.playlistThemes || {};
  const availableThemes = query.data?.availableThemes || [];

  return {
    artistThemes,
    platformThemes,
    playlistThemes,
    availableThemes,
    query,
  };
};

export const useThemesQuery = (isRoot = false) => {
  const query = useQuery([QueryKeys.themes], fetchThemes, {
    refetchOnMount: isRoot,
    refetchOnWindowFocus: isRoot,
    refetchOnReconnect: isRoot,
    staleTime: STALE_TIME,
  });

  const themesData = query.data || {};

  return {
    themesData,
    query,
  };
};
